import { useState } from "react"
import style from "../CreationModal.module.css"
import { useCompanies } from "providers/company/CompaniesProvider"

// Models
import { OfferModel } from "models"

// Components
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Dropdown, { DropdownVariants } from "components/base/Dropdown/Dropdown"
import Popup, { PopupVariants } from "components/base/Popup"
import { t } from "i18next"
import { deleteOffer } from "api/offers"

type PropType = {
    offers: OfferModel[]
    onChange?: (offers: OfferModel[]) => void
    onEditOffer?: (offer: OfferModel) => void
}

const Offers = ({ offers, onChange = () => {}, onEditOffer = () => {} }: PropType) => {
    const { currentCompany } = useCompanies({ autoFetch: false })!

    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)

    const handleDeleteOffer = (index: number) => {
        const newOffers = [...offers]
        const deletedOffers = newOffers.splice(index, 1)
        if (deletedOffers.length !== 1) {
            console.error(
                "handleDeleteOffer did not get one offer from splice, got: ",
                deletedOffers
            )
            return
        }

        const deleted = deletedOffers[0]
        if (!currentCompany || !deleted.id) {
            return
        }

        deleteOffer(currentCompany?.company_name, deleted.id)
            .then((res) => {
                onChange(newOffers)
            })
            .catch((err) => {
                console.error("delete offer failed: ", err)
            })
    }
    const handleRemoveOffer = (index: number) => {
        const newOffers = [...offers]
        newOffers.splice(index, 1)
        onChange(newOffers)
    }

    const handleEditOffer = (offer: OfferModel) => {
        onEditOffer(offer)
    }

    if (!offers || offers.length === 0) return <></>
    return (
        <div className="mt-6">
            <Popup
                open={deleteConfirmation}
                setOpen={setDeleteConfirmation}
                variant={PopupVariants.deleteConfirmation}
                onConfirm={handleDeleteOffer}
                title={t("common.deleteX", {
                    type: t("common.offer"),
                })}
                content={t("post.deleteOfferConfirm")}
                cancelButtonText={t("common.cancel")}
                cofirmationsButtonText={t("common.delete")}
            />
            {offers.map((offer, index) => {
                return (
                    <div
                        key={index}
                        className="bg-blueBackground rounded flex justify-between p-2 mb-1"
                    >
                        <div className={`w-full flex justify-between`}>
                            <div className={`flex justify-between p-1`}>
                                {offer?.images.length ? (
                                    <img
                                        alt="offer"
                                        className={"object-scale-down"}
                                        width="72"
                                        height="53"
                                        src={offer?.images[0].med}
                                    />
                                ) : (
                                    <img
                                        src="https://i.ibb.co/V0yvnY5/image.png"
                                        alt=""
                                        className={"object-scale-down"}
                                        width="72"
                                        height="53"
                                    />
                                )}
                            </div>
                            <div className={`w-full flex flex-row px-0.5`}>
                                <div className="w-1/2">
                                    <div className={`w-28 text-sm truncate font-body`}>
                                        {offer.title}
                                    </div>
                                    <div
                                        className={`${style["newsLetterPreviewText"]} text-xs font-body leading-none overflow-ellipsis overflow-scroll h-12 w-32`}
                                    >
                                        {offer.content}
                                    </div>
                                </div>
                                <div className="w-1/2 flex flex-col">
                                    <div className="flex flex-row items-center text-sm mr-2">
                                        <span className="mr-1">{t("post.originalPrice")}</span>
                                        <span className="font-bold">{offer.price} kr</span>
                                    </div>
                                    <div className="flex flex-row items-center text-sm mr-2">
                                        <span className="mr-1">{t("post.discount")}</span>
                                        <span className="font-bold">{offer.discount}%</span>
                                    </div>
                                    <div className="flex flex-row items-center text-sm">
                                        <span className="mr-1">{t("post.newPrice")}</span>
                                        <span className="font-bold">{offer.new_price} kr</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Dropdown
                                variant={DropdownVariants.verticalDots}
                                className={`mt-1`}
                                menu={[
                                    {
                                        text: t("common.edit"),
                                        onClick: () => handleEditOffer(offer),
                                    },
                                    {
                                        text: t("common.remove"),
                                        onClick: () => handleRemoveOffer(index),
                                    },
                                    {
                                        text: t("common.delete"),
                                        onClick: () => setDeleteConfirmation(true), //handleDeleteOffer(index),
                                    },
                                ]}
                            >
                                <MoreVertIcon
                                    className="hover:text-primary cursor-pointer"
                                    aria-hidden="true"
                                />
                            </Dropdown>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Offers
