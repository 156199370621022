import { ComponentModel, ListComponent } from "template/components"
import { FormEvent } from "react"

const ListEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ListComponent
    save: () => void
}) => {
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    return (
        <form onSubmit={submit}>
            <div className="grid grid-cols-2">
                <p>List data</p>
                <input
                    type={"text"}
                    className="border-solid border-2 border-grey-400"
                    value={component.list_data}
                    onChange={(e) => {
                        component.list_data = e.target.value
                        save()
                    }}
                />
            </div>
        </form>
    )
}

export default ListEditor
