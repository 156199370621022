import axios from "axios"

import CONSTS from "constants/CONSTS"

//Models
import {
    NewsletterModel,
    NewsletterRespModel,
    ValidateNewsletterRespModel,
} from "models/Newsletter"

// helpers
import { defaultHeaders } from "api/helpers"

interface CreateNewsletterResponse {
    newsletter: NewsletterRespModel
}

// Get newsletter by id
const getNewsletter = async (companyId: string, id: string): Promise<CreateNewsletterResponse> => {
    return axios({
        method: "GET",
        url: `/companies/${companyId}/newsletters/${id}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
        validateStatus: (status) => {
            return status === 200
        },
    }).then((res) => {
        const newsletter = res.data.newsletter
        let valid = ValidateNewsletterRespModel(newsletter)
        if (!valid.ok) {
            console.error({
                raisedBy: "server",
                error: "The server responded with invalid data",
                raw: `Failed to validate response: ${valid.reason}, ${valid.field}`,
                code: 0,
            })
        }

        newsletter.created_at = newsletter.created_at ? new Date(newsletter.created_at) : null
        newsletter.updated_at = newsletter.updated_at ? new Date(newsletter.updated_at) : null
        newsletter.expiration = newsletter.expiration ? new Date(newsletter.expiration) : null
        newsletter.publish_time = newsletter.publish_time ? new Date(newsletter.publish_time) : null

        return { newsletter }
    })
}

// Get all newsletters by companyId
const getNewsletters = async (companyId: string) => {
    return axios({
        method: "GET",
        url: `/companies/${companyId}/newsletters`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
        validateStatus: (status) => {
            return status === 200
        },
    })
}

// Create newsletter
const createNewsletter = async (newsletter: NewsletterModel): Promise<CreateNewsletterResponse> => {
    return axios({
        method: "POST",
        url: `/companies/${newsletter.company_name}/newsletters`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
        data: newsletter,
        validateStatus: (status) => {
            return status === 201
        },
    }).then((res) => {
        return res.data
    })
}

// Update newsletter
const updateNewsletter = async (newsletter: NewsletterModel) => {
    return axios({
        method: "PATCH",
        url: `/companies/${newsletter.company_name}/newsletters/${newsletter.id}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
        data: newsletter,
    })
}

// Delete newsletter
const deleteNewsletter = async (companyName: string, newsletterId: string) => {
    return axios({
        method: "DELETE",
        url: `/companies/${companyName}/newsletters/${newsletterId}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
    })
}

export { getNewsletter, getNewsletters, createNewsletter, updateNewsletter, deleteNewsletter }
