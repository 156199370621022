import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Template, { ComponentModel } from "template/components"
import Spinner from "./base/Spinner"

type AppPreviewRef = {
    updateTheme: (theme: { [index: string]: any }) => void
    updateRoot: (root: ComponentModel) => void
    runResolve: (resolve: string) => void
    uploadImage: (name: string, image: Uint8Array) => void
} | null

type Props = {
    isDemo?: boolean
    template?: Template
    width?: number
    height?: number
    company_name: string
    company_id: string
    app_backend_url: string
}

const AppPreview = forwardRef<AppPreviewRef, Props>((props, ref) => {
    const [loading, setLoading] = useState(true)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    const { t } = useTranslation()

    const getFn = (name: string) => {
        let fn = (iframeRef.current!.contentWindow! as any)[name]
        return fn
    }
    const setFn = (name: string, fn: Function) => {
        let window = iframeRef.current!.contentWindow! as any
        window[name] = fn
    }

    const updateTheme = (theme: { [index: string]: any }) => {
        let fn = getFn("updateTheme")
        if (fn != null) {
            fn(JSON.stringify(theme))
        }
    }

    const updateRoot = (root: ComponentModel) => {
        let fn = getFn("updateRoot")
        if (fn != null) {
            fn(JSON.stringify(root))
        }
    }

    const runResolve = (resolve: string) => {
        let fn = getFn("resolve")
        if (fn != null) {
            fn(resolve)
        }
    }
    const uploadImage = (name: string, image: Uint8Array) => {
        let fn = getFn("uploadImage")
        if (fn != null) {
            fn(name, image)
        }
    }

    useImperativeHandle(ref, () => ({
        updateTheme,
        updateRoot,
        runResolve,
        uploadImage,
    }))

    let params = new URLSearchParams()
    params.set("company_name", props.company_name)
    params.set("company_id", props.company_id)
    params.set("vipir_backend_url", props.app_backend_url)

    let hasUploaded = useRef(false)

    return (
        <div className="relative">
            <iframe
                style={{
                    width: "100%",
                    aspectRatio: "9/19.5",
                    filter: `blur(${loading ? 20 : 0}px)`,
                }}
                title="App Preview"
                ref={iframeRef}
                src={`/app_preview/index.html?${params.toString()}`}
                onLoad={() => {
                    setFn("renderStart", () => {
                        console.log("render start")
                        if (!hasUploaded.current && props.template) {
                            updateRoot(props.template.root)
                        }
                        hasUploaded.current = true
                        setLoading(false)
                    })
                }}
            />
            {loading && (
                <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
                    <p className="text-primary">
                        {props.isDemo
                            ? "Building preview"
                            : t("common.building") + " " + t("common.preview")}
                    </p>
                    <Spinner />
                </div>
            )}
        </div>
    )
})

export default AppPreview

export { type AppPreviewRef }
