import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios"

// Models
import { OfferModel, OfferRespModel, ValidateOfferModel, ValidateOfferRespModel } from "models"

// Helpers
import { defaultHeaders, unwrapAxiosError } from "./helpers"

// Constants
import CONSTS from "constants/CONSTS"

interface CreatePostResponse {
    offer: OfferRespModel
    token: string
}

const getOffer = async (company: string, offerId: string): Promise<OfferModel> => {
    return axios({
        method: "GET",
        url: `/companies/${company}/offers/${offerId}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        validateStatus: (status) => {
            return status === 200
        },
        headers: defaultHeaders(true),
    })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
        .then((res: AxiosResponse) => {
            const data = {
                ...res.data,
                expiration: res.data.expiration ? new Date(res.data.expiration) : null,
            }
            const validateResult = ValidateOfferRespModel(data)
            if (validateResult.ok !== true) {
                return Promise.reject(`missing field ${validateResult.field}`)
            }
            return Promise.resolve(validateResult.value)
        })
}

const getOffers = async (company: string): Promise<any> => {
    return axios({
        method: "GET",
        baseURL: CONSTS.BACKEND_BASE_URL,
        url: `/companies/${company}/offers`,
        headers: defaultHeaders(true),
    }).then((res) => {
        return res.data
    })
}

const createOffer = async (offer: OfferModel): Promise<CreatePostResponse> => {
    return axios({
        method: "POST",
        url: `/companies/${offer.company_name}/offers`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
        data: offer,
        validateStatus: (status) => {
            return status === 201
        },
    }).then((res) => {
        return res.data
    })
}

const updateOffer = async (offer: OfferModel): Promise<OfferModel> => {
    return axios({
        method: "PATCH",
        url: `/companies/${offer.company_name}/offers/${offer.id}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
        data: offer,
        validateStatus: (status) => {
            return status === 200
        },
    }).then((res) => {
        return res.data
    })
}

// Delete newsletter
const deleteOffer = async (companyName: string, offerId: string): Promise<undefined> => {
    return axios({
        method: "DELETE",
        url: `companies/${companyName}/offers/${offerId}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        headers: defaultHeaders(true),
        withCredentials: true,
    }).then((res) => {
        return res.data
    })
}

export { getOffer, getOffers, createOffer, updateOffer, deleteOffer }
