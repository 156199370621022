import { TabbedViewComponent, ComponentModel } from "template/components"

const TabbedViewEditor = ({
    component,
    save,
}: {
    parent: ComponentModel
    component: TabbedViewComponent
    save: () => void
}) => {
    return (
        <form>
            <div className="grid grid-cols-2">
                <p>App Bar</p>
                <input
                    type={"checkbox"}
                    defaultChecked={component.app_bar}
                    onChange={(e) => {
                        component.app_bar = e.target.checked
                        save()
                    }}
                />
            </div>
        </form>
    )
}

export default TabbedViewEditor
