import React, { useState } from "react"
import axios, { AxiosError } from "axios"

// Api
import { deleteNotification, updateNotification, createNotification } from "api/notification"

// Providers
import { useCompanies } from "providers/company/CompaniesProvider"
import INPUT_CONSTANTS from "constants/INPUT"
import {
    NotificationModel,
    NotificationAction,
    NotificationCreateModel,
    NotificationData,
    NotificationV1Data,
    NotificationV2Data,
    NOTIFICATION_VERSION,
} from "models/Notification"
import { useTranslation } from "react-i18next"

// Components
import Paper, { PaperVariants } from "components/base/Paper"
import Input from "components/base/Input"
import style from "components/Notifications/BaseForm/BaseForm.module.css"
import ToolTip from "components/base/ToolTip"
import Button, { ButtonVariants } from "components/base/Button"
import Popup, { PopupVariants } from "components/base/Popup"
import Switch from "@mui/material/Switch"
import Form from "components/base/Form/Form"
import Datepicker from "components/base/Datepicker"

enum NotificationFormVariants {
    create,
    edit,
}

type PropTypes = {
    notification?: NotificationCreateModel
    variant: NotificationFormVariants
}

const BaseForm = React.forwardRef(({ notification, variant, ...rest }: PropTypes, ref) => {
    // Providers
    const { t } = useTranslation()
    const { currentCompany } = useCompanies({
        autoFetch: variant === NotificationFormVariants.create,
    })

    let notificationData: NotificationData | null = null

    // V1 Data
    let notificationPath: string = ""
    let notificationImageURL: string = ""

    // V2 Data
    let notificationActions: NotificationAction[] = []

    if (notification) {
        switch (notification.version) {
            case NOTIFICATION_VERSION["1.0.0"]:
                notificationData = notification.data as NotificationV1Data
                notificationPath = (notification.data as NotificationV1Data).path
                notificationImageURL = (notification.data as NotificationV1Data).image_url || ""
                break
            case NOTIFICATION_VERSION["2.0.0"]:
                notificationData = notification.data as NotificationV2Data
                notificationActions = (notificationData as NotificationV2Data).actions || []
                break
            default:
                throw new Error("Error in NotificationModal. Notification version not supported.")
        }
    }

    // State
    const [newTitle, setNewTitle] = useState<string>(notificationData?.title || "")
    const [newContent, setNewContent] = useState<string>(notificationData?.body || "")
    const [newLink, setNewLink] = useState<string>(notificationPath)
    const [id] = useState<string>(notification?.id || "")
    const [topic /*setTopic*/] = useState<string>("webview")
    const [version /*setVersion*/] = useState<NOTIFICATION_VERSION | null>(
        notification?.version || null
    )
    const [scheduled, setScheduled] = useState<boolean>(false)
    const [publishDate, setPublishDate] = useState<Date>(
        notification?.meta.scheduled_at || new Date()
    )
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)
    const [finalizeConfirmation, setFinalizeConfirmation] = useState<boolean>(false)
    const [successConfirmation, setSuccessConfirmation] = useState<boolean>(false)
    const [deleteSuccessConfirmation, setDeleteSuccessConfirmation] = useState<boolean>(false)
    const [errorConfirmation, setErrorConfirmation] = useState<boolean>(false)

    // Page states, used for user feedback
    const [loading, setLoading] = useState<boolean>(false)
    const [titleError, setTitleError] = useState<string | null>(null)
    const [linkError, setLinkError] = useState<string | null>(null)
    const [contentError, setContentError] = useState<string | null>(null)
    const [dateError, setDateError] = useState<string | null>(null)

    // Functions
    const errorMessage = (message: string | null) => {
        if (message) {
            return <div className={`text-danger text-sm`}>{message}</div>
        }
        return null
    }

    const validateFields = () => {
        let isValid = true
        let locLink = newLink
        if (newTitle.length <= 0 || newTitle.length > 65) {
            setTitleError(
                t("Title has to have between") +
                    INPUT_CONSTANTS.MIN_NOTIFICATION_TITLE_LENGTH +
                    t("and ") +
                    INPUT_CONSTANTS.MAX_NOTIFICATION_TITLE_LENGTH +
                    t(" chars.")
            )
            isValid = false
        }
        let url = currentCompany?.website.url ? currentCompany?.website.url : " "
        // incase url ends with '/'
        if (url.charAt(url.length - 1) === "/") {
            url = url.substr(0, url.length - 1)
        }

        if (locLink.match(`^${url}`)) {
            locLink = locLink.replace(url, "")
            setNewLink(locLink)
        }

        if (publishDate < new Date() && scheduled) {
            setDateError(t("The scheduled time must be in the future"))
            isValid = false
        }

        if (0 >= newContent.length || newContent.length > 110) {
            setContentError(t("The content has to have between 1 and 240 characters"))
            isValid = false
        }
        return isValid
    }

    const openFinalizeConfirmationModal = () => {
        if (validateFields()) {
            setFinalizeConfirmation(true)
        }
        return
    }

    const openDeleteConfirmationModal = () => {
        setDeleteConfirmation(true)
        return
    }

    const changeDate = (x: Date | string) => {
        if (x != null) {
            if (typeof x == "string") {
                let m = new Date(x)
                setPublishDate(m)
            } else {
                setPublishDate(x)
            }
        }
    }

    const company_name = () => {
        let l = currentCompany?.company_name
        if (l && l.length > 0) {
            return l
        } else {
            throw new ReferenceError("No company in config!")
        }
    }

    // Sends the form data
    const submitNotification = () => {
        if (loading) {
            return
        }

        //setLoading(true);

        let callFunction = () => {
            switch (variant) {
                case NotificationFormVariants.edit:
                    if (!notification || !version || !id) {
                        throw new Error(
                            "Editing notification does not exist or missing version or id"
                        )
                    }

                    // NotificationUpdateModel
                    let changedNotification: NotificationModel = {
                        version: version,
                        id: id,
                        meta: {
                            company_name: company_name(),
                            scheduled_at: publishDate,
                            created_at: notification?.meta.created_at,
                            created_by: notification?.meta.created_by,
                            sent_at: notification?.meta.sent_at,
                        },
                        data: {
                            topic: topic,
                            title: newTitle,
                            body: newContent,
                        },
                    }

                    switch (notification.version) {
                        case NOTIFICATION_VERSION["1.0.0"]:
                            changedNotification.data = {
                                ...changedNotification.data,
                                path: notificationPath,
                                image_url: notificationImageURL,
                            }
                            break
                        case NOTIFICATION_VERSION["2.0.0"]:
                            notificationData = notification.data as NotificationV2Data
                            changedNotification.data = {
                                ...changedNotification.data,
                                actions: notificationActions,
                            }
                            break
                        default:
                            throw new Error(
                                "Error in NotificationModal. Notification version not supported."
                            )
                    }

                    return updateNotification(changedNotification)
                case NotificationFormVariants.create:
                    // NotificationModel
                    let newNotification: NotificationCreateModel = {
                        version: NOTIFICATION_VERSION["1.0.0"],
                        meta: {
                            company_name: company_name(),
                            scheduled_at: publishDate,
                        },
                        data: {
                            topic: topic,
                            title: newTitle,
                            path: newLink,
                            body: newContent,
                        },
                    }

                    return createNotification(newNotification)
                default:
                    throw new Error("Error in NotificationModal")
            }
        }

        callFunction()
            .then((res: any) => {
                // Create Success Modal
                setDeleteSuccessConfirmation(true)
            })
            .catch((err: any) => {
                // setError("There was an error updating the notification")
                console.error(err)
                setErrorConfirmation(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // Sends the form data
    const submitDeleteNotification = () => {
        switch (variant) {
            case NotificationFormVariants.create:
                clearFields()
                break
            default:
                deleteNotification(company_name(), id)
                    .then((res: boolean | AxiosError<any>) => {
                        if (!res || axios.isAxiosError(res)) {
                            throw new Error("Deleting Notification failed")
                        } else {
                            setDeleteSuccessConfirmation(true)
                        }
                    })
                    .catch((err: any) => {
                        // setError("There was an error deleting the notification")
                        console.error(err)
                        setErrorConfirmation(true)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
        }
    }
    const clearFields = () => {
        setNewTitle("")
        setNewContent("")
        setNewLink("")
        setPublishDate(new Date())
    }

    const closeSuccessModal = () => {
        setSuccessConfirmation(false)
        setDeleteSuccessConfirmation(false)
        window.location.reload()
        return
    }

    return (
        <div>
            <Popup
                open={deleteConfirmation}
                setOpen={setDeleteConfirmation}
                variant={PopupVariants.deleteConfirmation}
                onConfirm={submitDeleteNotification}
                title={t("Delete Notification")}
                content={
                    variant === NotificationFormVariants.edit
                        ? t("Do you really want to delete the notification?")
                        : t("Do you really want to delete the draft?")
                }
                cancelButtonText={t("Cancel")}
                cofirmationsButtonText={t("Delete")}
            />
            <Popup
                open={deleteSuccessConfirmation}
                setOpen={setDeleteSuccessConfirmation}
                variant={PopupVariants.success}
                onConfirm={closeSuccessModal}
                title={t("Success")}
                content={
                    variant === NotificationFormVariants.edit
                        ? t("Notification updated")
                        : scheduled
                        ? t("notification.publishesAtDate", { date: publishDate.toLocaleString() })
                        : t("notification.publishes")
                }
                cofirmationsButtonText={t("Continue")}
            />

            <Popup
                open={finalizeConfirmation}
                setOpen={setFinalizeConfirmation}
                variant={PopupVariants.confirmation}
                onConfirm={submitNotification}
                title={variant === NotificationFormVariants.edit ? t("Update") : t("Publish")}
                content={
                    variant === NotificationFormVariants.edit
                        ? t("Do you want to update the notification?")
                        : t("Do you want to create the notification?")
                }
                cancelButtonText={t("Cancel")}
                cofirmationsButtonText={
                    variant === NotificationFormVariants.edit ? t("Update") : t("Create")
                }
            />
            <Popup
                open={successConfirmation}
                setOpen={setSuccessConfirmation}
                variant={PopupVariants.success}
                onConfirm={closeSuccessModal}
                title={t("common.success")}
                content={t("Notification created")}
                cofirmationsButtonText={t("Continue")}
            />
            <Popup
                open={errorConfirmation}
                setOpen={setErrorConfirmation}
                variant={PopupVariants.error}
                onConfirm={() => setErrorConfirmation(false)}
                title={t("common.failed")}
                content={t("notification.error.failedToSend")}
                cofirmationsButtonText={t("common.continue")}
            />

            <Form
                id="notificationForm"
                className="m-auto max-w-screen-lg "
                onSubmit={() => {
                    return false
                }}
            >
                <div className="mb-8">
                    <div>
                        <div className={""}>
                            <div>
                                <div className="text-xl font-body font-bold tracking-wide tracking-wide px-4 py-2 ">
                                    {t("common.notification")}
                                </div>
                                <div className={"grid grid-cols-2 gap-1"}>
                                    <div className={"p-4 "}>
                                        <label>
                                            <p className={`font-body text-sm ml-1`}>
                                                {t("common.title")}
                                            </p>
                                            <Input
                                                name="notificationTitle"
                                                value={newTitle}
                                                placeholder={t("commmon.title")}
                                                onChange={(e: any) => {
                                                    setNewTitle(e.target.value)
                                                    setTitleError(null)
                                                }}
                                                className={`
                                            w-full rounded-md mt-1 ${style["input"]}
                                            ${
                                                titleError
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }
                                            `}
                                            />
                                            {errorMessage(titleError)}
                                        </label>
                                    </div>
                                    <div className={"p-4"}>
                                        <label>
                                            <div
                                                className={`font-body text-sm ml-1 flex justify-between`}
                                            >
                                                <p>{t("Link")}</p>
                                                <ToolTip
                                                    className={``}
                                                    content={t("notification.edit.tooltip.link")}
                                                />
                                            </div>
                                            <Input
                                                name="notificationLink"
                                                value={newLink}
                                                placeholder={t("common.link")}
                                                onChange={(e) => {
                                                    setNewLink(e.target.value)
                                                    setLinkError(null)
                                                }}
                                                className={`w-full rounded-md mt-1 ${style["input"]}
                                            ${
                                                linkError
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                                            />
                                            {errorMessage(linkError)}
                                        </label>
                                    </div>
                                    <div className={`px-4 col-span-2`}>
                                        <label>
                                            <div
                                                className={`font-body text-sm ml-1 flex justify-between`}
                                            >
                                                <p className={`font-body text-sm`}>{t("Text")}</p>
                                                <ToolTip
                                                    content={t("notification.edit.tooltip.text")}
                                                />
                                            </div>
                                            <textarea
                                                name="notificationText"
                                                value={newContent}
                                                placeholder={t("notification.edit.tooltip.text")}
                                                onChange={(e) => {
                                                    setNewContent(e.target.value)
                                                    setContentError(null)
                                                }}
                                                className={`
                                            h-28 rounded-md mt-1 w-full ${style["textarea"]}
                                            ${
                                                contentError
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                                            />
                                            {errorMessage(contentError)}
                                        </label>
                                    </div>
                                </div>

                                {variant === NotificationFormVariants.create ? (
                                    <div className={"flex flex-row p-4"}>
                                        <Switch
                                            id="notificationScheduled"
                                            checked={scheduled}
                                            name="publicCheckbox"
                                            onChange={(e) => {
                                                setScheduled(e.target.checked)
                                            }}
                                        />
                                        <div className="font-body ml-1 mt-2 text-sm">
                                            {t("common.schedule")}
                                        </div>
                                    </div>
                                ) : null}
                                {scheduled || variant === NotificationFormVariants.edit ? (
                                    <div className={`p-4`}>
                                        <label>
                                            <div
                                                className={`font-body text-sm mb-2 ml-1 flex justify-between`}
                                            >
                                                <p className={`font-body text-sm`}>
                                                    {t("notification.publishingTime")}
                                                </p>
                                                <ToolTip
                                                    className={``}
                                                    content={t(
                                                        "notification.edit.tooltip.publishingTime"
                                                    )}
                                                />
                                            </div>
                                            <Datepicker
                                                value={publishDate}
                                                onChange={(e: any) => {
                                                    changeDate(e)
                                                    setDateError(null)
                                                }}
                                            />
                                            {errorMessage(dateError)}
                                        </label>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <Paper variant={PaperVariants.dark} className="h-20 mt-8 col-span-3 bg-primary">
                        <div className={`flex justify-between`}>
                            <div className={`flex justify-between`}>
                                <Button
                                    name="notificationDelete"
                                    variant={ButtonVariants.secondary}
                                    onClick={openDeleteConfirmationModal}
                                >
                                    {variant === NotificationFormVariants.edit
                                        ? t("notification.edit.delete")
                                        : t("notification.edit.deleteDraft")}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    name="notificationCreate"
                                    onClick={openFinalizeConfirmationModal}
                                    value="Notification"
                                >
                                    {variant === NotificationFormVariants.edit
                                        ? t("notification.edit.update")
                                        : t("notification.edit.create")}
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </Form>
        </div>
    )
})

export default BaseForm
export { NotificationFormVariants }
