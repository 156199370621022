const ErrorPage = ({ errorMessage }: { errorMessage: String }) => {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
                role="alert"
            >
                <strong className="font-bold">{errorMessage}</strong>
            </div>
        </div>
    )
}

export default ErrorPage
