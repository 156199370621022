import { InputHTMLAttributes } from "react"
import { ReactI18NextChild } from "react-i18next"
import ToolTip from "../ToolTip"

interface PropTypes extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    // tooltip is only shown if label is set as well
    tooltip?: string
    error?: boolean
    checked: boolean
}

const Checkbox = (props: PropTypes) => {
    const input = (
        <input
            {...props}
            id={props.id}
            type="checkbox"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            className={`rounded-md mt-1 border hover:border-gray-500 ${
                props.error ? "border-danger" : "border-hoverDark"
            } ${props.className}`}
            placeholder={props.placeholder}
            checked={props.checked}
        />
    )

    if (props.label && props.label !== "") {
        return (
            <CheckboxLabel label={props.label} tooltip={props.tooltip}>
                {input}
            </CheckboxLabel>
        )
    }

    return <>{input}</>
}

interface Props {
    label: string
    tooltip?: string
    children?: ReactI18NextChild | Iterable<ReactI18NextChild>
}

const CheckboxLabel = (props: Props) => {
    if (props.tooltip && props.tooltip !== "") {
        return (
            <label className="flex w-full">
                {props.children}
                <p className="flex-grow text-sm ml-1 mt-1">{props.label}</p>
                <ToolTip className={"content-end"} content={props.tooltip} />
            </label>
        )
    }
    return (
        <>
            {props.children}
            <label className="text-sm ml-1">{props.label}</label>
        </>
    )
}

export default Checkbox
