import React, {
    useEffect,
    useState,
    useRef,
    useContext,
    useCallback,
    MutableRefObject,
} from "react"

import Props from "providers/Props"

import { AxiosError } from "axios"

//import { useHistory } from 'react-router-dom';

// api
import { checkToken } from "api/user"

// Models
import UserClass from "classes/User"

type UserContextType = {
    user: UserClass | undefined
    setUser: (value: UserClass | undefined) => void
    userFetching: boolean
    setUserFetching: (value: boolean) => void
}

type UserPrivateContextType = {
    isUserFetching: MutableRefObject<boolean>
}

const UserContext = React.createContext<UserContextType | undefined>(undefined)
const UserPrivateContext = React.createContext<UserPrivateContextType | undefined>(undefined)

const UserProvider = ({ children }: Props) => {
    const [user, setUser] = useState<UserClass>()
    const [userFetching, setUserFetching] = useState<boolean>(true)
    const isUserFetching = useRef(false)

    return (
        <UserContext.Provider value={{ user, setUser, userFetching, setUserFetching }}>
            <UserPrivateContext.Provider value={{ isUserFetching }}>
                {children}
            </UserPrivateContext.Provider>
        </UserContext.Provider>
    )
}

const useUser = ({ autoFetch }: { autoFetch: boolean }) => {
    const { user, setUser, userFetching, setUserFetching } = useContext(UserContext)!
    const { isUserFetching } = useContext(UserPrivateContext)!

    const fetchUser = useCallback(async (): Promise<any> => {
        if (user !== undefined) {
            return
        }
        if (isUserFetching.current) {
            return
        }
        isUserFetching.current = true
        setUserFetching(true)

        return checkToken()
            .then((token: string | AxiosError<any>) => {
                try {
                    const usr = new UserClass(token as string)
                    setUser(usr)
                    isUserFetching.current = false
                    setUserFetching(false)
                    return Promise.resolve()
                } catch (e) {
                    isUserFetching.current = false
                    setUserFetching(false)
                    throw e
                }
            })
            .catch((err: any) => {
                isUserFetching.current = false
                setUserFetching(false)
                return Promise.reject(err)
            })
            .finally(() => {
                isUserFetching.current = false
                setUserFetching(false)
            })
    }, [setUser, user])
    useEffect(() => {
        if (user === undefined && !isUserFetching.current && autoFetch === true) {
            fetchUser()
        }
    }, [autoFetch, fetchUser, isUserFetching, user])

    return { user, setUser, userFetching, fetchUser, isUserFetching }
}

export { UserProvider, useUser, UserContext }
