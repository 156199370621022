import { Design04Component, ComponentModel } from "template/components"
import { Checkbox, Collapse } from "@mui/material"
import BackgroundEditor from "./BackgroundEditor"
import { Background, BackgroundTypes } from "../background"
import { ChangeEventHandler, useCallback, useState } from "react"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

// Define type with title and background
type bgeditorProps = {
    title: string
    background: Background | null
}

const Design04Editor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: Design04Component
    save: () => void
}) => {
    return (
        <div>
            <form>
                <div className={"grid grid-cols-2"}>
                    <TextInput
                        label={"Featured Offer ID"}
                        value={component.main_offer_id}
                        onChange={(e) => {
                            component.main_offer_id = e.target.value
                            save()
                        }}
                    />
                    <TextInput
                        label={"Featured Newsletter ID"}
                        value={component.main_newsletter_id}
                        onChange={(e) => {
                            component.main_newsletter_id = e.target.value
                            save()
                        }}
                    />
                </div>
                <BGEditor
                    title={"Main offer background"}
                    backgroundToEdit={component.main_offer_background}
                    onChange={(background) => {
                        component.main_offer_background = background
                        save()
                    }}
                />
                <BGEditor
                    title={"Offers carousel background"}
                    backgroundToEdit={component.offers_background}
                    onChange={(background) => {
                        component.offers_background = background
                        save()
                    }}
                />
                <BGEditor
                    title={"Newsletter background"}
                    backgroundToEdit={component.newsletter_background}
                    onChange={(background) => {
                        component.newsletter_background = background
                        save()
                    }}
                />
            </form>
        </div>
    )
}

const TextInput = ({
    label,
    value,
    onChange,
}: {
    label: string
    value: string
    onChange: ChangeEventHandler<HTMLInputElement> | undefined
}) => {
    return (
        <>
            <p>{label}</p>
            <input
                type={"text"}
                className="border-solid border-2 border-grey-400"
                value={value}
                onChange={onChange}
            />
        </>
    )
}

const BGEditor = ({
    title,
    backgroundToEdit,
    onChange,
}: {
    title: string
    backgroundToEdit: Background | null
    onChange: (background: Background | null) => void
}) => {
    const [open, setOpen] = useState(true)

    const toggleOpen = useCallback((event) => {
        event.stopPropagation()
        setOpen((prev) => !prev)
    }, [])

    return (
        <div className={"flex border-b cursor-pointer"}>
            <div className={"flex-grow"}>
                <div className="flex items-center" onClick={toggleOpen}>
                    <Checkbox
                        checked={backgroundToEdit != null}
                        onChange={(e) => {
                            setOpen(true)
                            if (e.target.checked) {
                                onChange({ type: BackgroundTypes.Gradient })
                            } else {
                                onChange(null)
                            }
                        }}
                    />
                    <p className="flex-grow">{title}</p>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </div>

                <Collapse in={backgroundToEdit != null && open}>
                    <div className="m-3">
                        {backgroundToEdit && (
                            <BackgroundEditor background={backgroundToEdit} onChange={onChange} />
                        )}
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default Design04Editor
