import { useState } from "react"
import { Collapse } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { InsetsModel } from "../structs"

const InsetEditor = ({
    name,
    inset,
    save,
}: {
    name: string
    inset: InsetsModel
    save: () => void
}) => {
    const [show, setShow] = useState<boolean>(false)
    return (
        <div>
            <div
                className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                onClick={() => setShow(!show)}
            >
                <p className="m-2">{name}: </p>
                <div className="flex-grow" />
                <div className="m-3">{show ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
            <Collapse in={show}>
                <div className="grid grid-cols-2">
                    <p>top:</p>
                    <input
                        type={"number"}
                        className="border-solid border-2 border-grey-400"
                        value={inset.top}
                        onChange={(e) => {
                            if (inset == null) {
                                return
                            }
                            inset.top = parseInt(e.target.value)
                            save()
                        }}
                    />

                    <p>right:</p>
                    <input
                        type={"number"}
                        className="border-solid border-2 border-grey-400"
                        value={inset.right}
                        onChange={(e) => {
                            if (inset == null) {
                                return
                            }
                            inset.right = parseInt(e.target.value)
                            save()
                        }}
                    />

                    <p>bottom:</p>
                    <input
                        type={"number"}
                        className="border-solid border-2 border-grey-400"
                        value={inset.bottom}
                        onChange={(e) => {
                            if (inset == null) {
                                return
                            }
                            inset.bottom = parseInt(e.target.value)
                            save()
                        }}
                    />

                    <p>left:</p>
                    <input
                        type={"number"}
                        className="border-solid border-2 border-grey-400"
                        value={inset.left}
                        onChange={(e) => {
                            if (inset == null) {
                                return
                            }
                            inset.left = parseInt(e.target.value)
                            save()
                        }}
                    />
                </div>
            </Collapse>
        </div>
    )
}

export default InsetEditor
