import Template from "template/components"

type ExpectedCmp = {
    type: string
    [index: string]: any
    /**
     * The subcomponents to expect.
     *
     * if [components] is null then the comparer will return true and ignore any components in actual
     */
    components?: ExpectedCmp[]
    /**
     * if [\_\_oneof] is true, then the components in [components] are viewed as same position optionals
     */
    __oneof?: boolean
    /**
     * __optional tells checkExpectedComponents that missing this component is allowed. it will then
     * check if the next one matches
     */
    __optional?: boolean
    /**
     * if the component is not [\_\_optional] then [\_\_default] can be used to insert component
     */
    __default?: boolean
    __default_component?: Cmp
}

/*
__oneof should have default
currently __default inserts itself.
*/

type Cmp = {
    type: string
    [index: string]: any
    components: Cmp[]
}

const expectedCmpToCmp = (expected: ExpectedCmp): Cmp => {
    let components: Cmp[] = []
    if (expected.components) {
        for (let comp of expected.components) {
            components.push(expectedCmpToCmp(comp))
        }
    }

    return Object.keys(expected)
        .filter((key) => {
            if (key === "components") {
                return false
            } else if (key.startsWith("__")) {
                return false
            }
            return true
        })
        .reduce(
            (obj, key: string) => {
                return Object.assign(obj, { [key]: expected[key] })
            },
            { type: expected.type, components: components }
        )
}

const verifyTheme = (template: Template): boolean => {
    return false
}

const checkExpectedComponents = (actual?: Cmp, expected?: ExpectedCmp): boolean => {
    // console.log("comparing\n:", actual, "\n", expected)
    return wrapper(actual, expected)
}

const wrapper = (actual?: Cmp, expected?: ExpectedCmp): boolean => {
    if (!actual || !expected) {
        console.error("missing actual or expected")
        return false
    }
    if (expected.__oneof) {
        return expectOneof(actual, expected)
    }

    if (actual.type != expected.type) {
        return false
    }

    let expectedFields = Object.keys(expected)

    for (let fieldName of expectedFields) {
        if (fieldName === "components") {
            continue
        }
        if (fieldName.startsWith("__")) {
            continue
        }
        if (!actual[fieldName]) {
            console.error("missing field: ", fieldName)
            return false
        }
        const expectedValue = expected[fieldName]

        if (expectedValue instanceof Object) {
        }

        if (actual[fieldName] != expected[fieldName]) {
            console.error("invalid field: ", fieldName)
            return false
        }
    }

    // can pass null components to skip check
    if (!expected.components) {
        return true
    }

    let actualIdx = 0
    let expectedIdx = 0
    while (expectedIdx < expected.components.length) {
        let actualComponent = actual.components[actualIdx]
        let expectedComponent = expected.components[expectedIdx]

        if (!checkExpectedComponents(actualComponent, expectedComponent)) {
            if (expectedComponent.__optional) {
                // skip this optional expected component
                expectedIdx += 1
                continue
            } else if (expectedComponent.__default) {
                if (expectedComponent.__default_component) {
                    actual.components.splice(actualIdx, 0, expectedComponent.__default_component)
                } else {
                    actual.components.splice(actualIdx, 0, expectedCmpToCmp(expectedComponent))
                }
            } else {
                return false
            }
        }

        expectedIdx += 1
        actualIdx += 1
    }

    if (actualIdx !== actual.components.length) {
        return false
    }
    return true
}

const expectOneof = (required: Cmp, expected: ExpectedCmp): boolean => {
    if (!expected.components) {
        return false
    }

    let defaultComponent: ExpectedCmp | null = null

    let match = 0

    let expectedIdx = 0
    while (expectedIdx < expected.components.length) {
        let expectedComponent = expected.components[expectedIdx]

        if (checkExpectedComponents(required, expectedComponent)) {
            match += 1
        }
        if (expectedComponent.__default) {
            if (defaultComponent !== null) {
                console.error("multiple defaults in oneof")
                return false
            }
            defaultComponent = expectedComponent
        }

        expectedIdx += 1
    }

    if (match > 1) {
        return false
    } else if (match < 1) {
        if (!defaultComponent) {
            return false
        }
        required.components = [expectedCmpToCmp(defaultComponent)]
    }
    return true
}

export { verifyTheme, checkExpectedComponents, type ExpectedCmp, type Cmp }
