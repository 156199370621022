/*######################################################*/
/*                                                      */
/*                                                      */
/*            GENERATED FILE, DO NOT MODIFY             */
/*                                                      */
/*                                                      */
/*######################################################*/

export interface Background {
    type: BackgroundTypes
}

export enum BackgroundTypes {
    Image = "image",
    Gradient = "gradient",
}

export interface ImageBackground extends Background {
    url: string
}

export interface GradientBackground extends Background {
    colors: Array<{ a: number; r: number; g: number; b: number }>
    angle: number
}

export interface ImageBackground extends Background {
    url: string
    // TODO: might want additional parameters here
}

export const typeFromString = (type: string) => {
    switch (type) {
        case "image":
            return BackgroundTypes.Image
        case "gradient":
            return BackgroundTypes.Gradient
        default:
            throw `bad background type ${type}`
    }
}
