import { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import { RGBColor } from "react-color"

import TemplateModel from "template/components"

import ColorPicker from "components/ColorPicker"
import { Collapse } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

type Ref = {
    getTheme: () => { [index: string]: any }
} | null

type Props = {
    template: TemplateModel
    onChange: (template: { [index: string]: any }) => void
}

const ThemeEditor = forwardRef<Ref, Props>((props, ref) => {
    let [theme, setTheme] = useState(props.template.theme)

    useImperativeHandle(ref, () => ({
        getTheme() {
            return theme
        },
    }))

    useEffect(() => {
        props.onChange(theme)
    }, [theme])

    if (!theme) {
        return <div>Missing theme</div>
    }

    const colorSelections = ["primary", "secondary", "tertiary"]

    return (
        <div>
            <p className="m-2 text-lg text-primary">Edit Theme</p>
            {colorSelections.map((c) => {
                return (
                    <ThemeColorPicker
                        theme={theme}
                        index={c}
                        onChange={(color) => {
                            theme[c] = color
                            setTheme({ ...theme })
                        }}
                    />
                )
            })}
        </div>
    )
})

const ThemeColorPicker = ({
    theme,
    index,
    onChange,
}: {
    theme: { [index: string]: any }
    index: string
    onChange: (color: RGBColor) => void
}) => {
    const [show, setShow] = useState<boolean>()

    return (
        <div>
            <div
                className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                onClick={() => setShow(!show)}
            >
                <p className="m-2">{index} color</p>
                <div className="flex-grow" />
                <div className="m-3">{show ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
            <Collapse in={show}>
                <ColorPicker
                    activeValue={theme[index]}
                    onChange={onChange}
                    // onChange={(rgb) => {
                    //     theme[index] = {
                    //         a: rgb.a ?? 255,
                    //         r: rgb.r,
                    //         g: rgb.g,
                    //         b: rgb.b,
                    //     }
                    // }}
                />
            </Collapse>
        </div>
    )
}

export default ThemeEditor
export { type Ref as ThemeEditorRef }
