import { useTranslation } from "react-i18next"

const Help = () => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="text-xl font-body font-bold tracking-wide tracking-wide px-4 py-2">
                {t("Help")}
            </div>
            <div className="flex justify-center text-xl font-body tracking-wide tracking-wide px-4 py-2 mt-20">
                {t("If you have any issues with Vipir please write us:")}
            </div>
            <div className="flex justify-center text-xl font-body tracking-wide tracking-wide px-4 py-2 mt-2">
                <a href={"mailto:support@vipir.no"}>support@vipir.no</a>
            </div>
        </div>
    )
}

export default Help
