import { RouteComponent, ComponentModel } from "template/components"

const RouteEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: RouteComponent
    save: () => void
}) => {
    return (
        <form>
            <div className="grid grid-cols-2">
                <p>Route</p>
                <input
                    type={"text"}
                    className="border-solid border-2 border-grey-400"
                    value={component.route}
                    onChange={(e) => {
                        component.route = e.target.value
                        save()
                    }}
                />
            </div>
        </form>
    )
}

export default RouteEditor
