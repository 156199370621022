import style from "../CreationModal.module.css"

// Components
import { Link } from "models/Newsletter"
import Dropdown, { DropdownVariants } from "components/base/Dropdown/Dropdown"
import MoreVertIcon from "@mui/icons-material/MoreVert"

type PropTypes = {
    links: Link[]
    onChange?: (links: Link[]) => void
}

const Links = ({ links, onChange = () => {} }: PropTypes) => {
    const handleDeleteLink = (index: number) => {
        const newLinks = [...links]
        newLinks.splice(index, 1)
        onChange(newLinks)
    }

    return (
        <div>
            {links?.map((link, index) => {
                return (
                    <div
                        key={index}
                        className={`bg-blueBackground rounded flex justify-between mt-3`}
                    >
                        <div className={"p-2 overflow-auto"}>
                            <div className={`w-28 text-sm truncate font-body`}>{link.title}</div>
                            <div
                                className={`${style["newsLetterPreviewText"]} text-xs font-body leading-none overflow-ellipsis overflow-scroll mb-2`}
                            >
                                {link.content}
                            </div>
                            <a
                                href={link.url}
                                className={
                                    "border-b-2 border-transparent hover:border-blue-500 text-blue-500"
                                }
                            >
                                {link.url}
                            </a>
                        </div>
                        <div>
                            <Dropdown
                                variant={DropdownVariants.verticalDots}
                                className={`mt-1`}
                                menu={[
                                    {
                                        text: "Fjern",
                                        onClick: () => handleDeleteLink(index),
                                    },
                                ]}
                            >
                                <MoreVertIcon
                                    className="hover:text-primary cursor-pointer"
                                    aria-hidden="true"
                                />
                            </Dropdown>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Links
