import React, { InputHTMLAttributes } from "react"
import styles from "./Button.module.css"

import Spinner from "../Spinner/Spinner"

enum ButtonVariants {
    primary,
    smallPrimary,
    secondary,
    smallSecondary,
    success,
    danger,
    icon,
    contained,
    outlined,
    text,
    close,
}

interface PropTypes extends InputHTMLAttributes<HTMLButtonElement> {
    name?: string
    variant?: ButtonVariants
    disableElevation?: boolean
    loading?: boolean
    spinnerColor?: string
    value?: string
    type?: any
}

const Button = (props: PropTypes) => {
    const variant = props.variant || ButtonVariants.primary

    let baseStyle = "font-body transition duration-25 ease-in-out"
    let btnStyles = "select-none "

    let primaryStyle = `hover:bg-secondary ${baseStyle} bg-primary text-white border-secondary border`
    let secondaryStyle = `border-darkBorder bg-light text-black hover:bg-hoverDark border `
    let sizeSmall = `px-1.5 py-0.5 rounded text-sm`
    let sizeNormal = `px-4 py-2 rounded-md`

    if (props.disabled) {
        btnStyles +=
            props.variant !== ButtonVariants.icon
                ? `${baseStyle} cursor-not-allowed opacity-50 ${
                      props.variant !== ButtonVariants.text ? "bg-gray-400" : ""
                  } `
                : "cursor-not-allowed opacity-50 p-2"
    } else {
        switch (variant) {
            case ButtonVariants.primary: {
                btnStyles += `${primaryStyle} ${sizeNormal} ${styles["btn-contained"]} font-bold`
                break
            }

            case ButtonVariants.smallPrimary: {
                btnStyles += `${baseStyle} ${primaryStyle} ${sizeSmall} ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.secondary: {
                btnStyles += `${baseStyle} ${secondaryStyle} ${sizeNormal} ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.smallSecondary: {
                btnStyles += `${baseStyle} ${secondaryStyle} ${sizeSmall} ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.success: {
                btnStyles += `${baseStyle} ${sizeNormal} bg-green-100 text-black border-success hover:bg-hoverSuccess ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.danger: {
                btnStyles += `${baseStyle} ${sizeNormal} bg-red-100 text-black border-danger hover:bg-hoverDanger ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.contained: {
                btnStyles += `${baseStyle} bg-${props.color} hover:bg-${props.color}-dark text-${
                    props.color
                }-text ${props.disableElevation ? "" : "shadow-md"} ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.outlined: {
                btnStyles += `${baseStyle} border-solid border-${props.color} border text-${props.color} hover:bg-gray-100 active:bg-${props.color} active:text-${props.color}-text`
                break
            }

            case ButtonVariants.text: {
                btnStyles += `${baseStyle} ${sizeNormal} rounded-md bg-transparent text-${props.color} hover:bg-gray-200 ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.icon: {
                btnStyles += `bg-transparent text-${props.color} rounded-full p-2 hover:bg-gray-100 ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.close: {
                btnStyles += `bg-transparent text-gray-500 rounded-full p-2 hover:bg-gray-100 ${styles["btn-contained"]}`
                break
            }

            default:
        }
    }

    const colorOfSpinner =
        props.disabled && !props.spinnerColor ? "secondary" : props.spinnerColor || "gray-400"

    return (
        <button
            {...props}
            className={`${styles.btn} ${btnStyles} flex items-center justify-center ${props.className}`}
            type={props.type ? props.type : "button"}
            value={props.value}
        >
            {props.loading && <Spinner className="ml-0 mr-3" size="xs" color={colorOfSpinner} />}
            {props.children}
        </button>
    )
}

export default Button
export { ButtonVariants }
