import { Design02Component, ComponentModel } from "template/components"

const Design02Editor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: Design02Component
    save: () => void
}) => {
    return (
        <div>
            <form>
                <div className={"grid grid-cols-2"}>
                    <p>Heading</p>
                    <input
                        type={"text"}
                        className="border-solid border-2 border-grey-400"
                        value={component.header}
                        onChange={(e) => {
                            component.header = e.target.value
                            save()
                        }}
                    />
                    <p>Header image</p>
                    <input
                        type={"text"}
                        className="border-solid border-2 border-grey-400"
                        value={component.header_image_url}
                        onChange={(e) => {
                            component.header_image_url = e.target.value
                            save()
                        }}
                    />
                </div>
            </form>
        </div>
    )
}

export default Design02Editor
