import { BoxComponent, ComponentModel } from "template/components"
import { Checkbox } from "@mui/material"
import InsetEditor from "./InsetEditor"
import ActionEditor from "./ActionEditor"
import { TemplateActionSystems, TemplateActionNavigationGoto } from "../actions"
import BackgroundEditor from "./BackgroundEditor"
import { BackgroundTypes } from "../background"

const BoxEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: BoxComponent
    save: () => void
}) => {
    return (
        <form>
            <div className="grid grid-cols-3">
                <p>Height:</p>
                <input
                    type={"checkbox"}
                    checked={component.height != null}
                    onChange={(e) => {
                        if (e.target.checked) {
                            component.height = 0
                        } else {
                            component.height = null
                        }
                        save()
                    }}
                />
                {component.height == null ? (
                    <div></div>
                ) : (
                    <input
                        type={"number"}
                        className="border-solid border-2 border-grey-400"
                        value={component.height}
                        onChange={(e) => {
                            component.height = parseInt(e.target.value)
                            save()
                        }}
                    />
                )}

                <p>Width:</p>
                <input
                    type={"checkbox"}
                    checked={component.width != null}
                    onChange={(e) => {
                        if (e.target.checked) {
                            component.width = 0
                        } else {
                            component.width = null
                        }
                        save()
                    }}
                />
                {component.width == null ? (
                    <div></div>
                ) : (
                    <input
                        type={"number"}
                        className="border-solid border-2 border-grey-400"
                        value={component.width}
                        onChange={(e) => {
                            component.width = parseInt(e.target.value)
                            save()
                        }}
                    />
                )}
            </div>
            <div className="grid grid-cols-2">
                <p>Layout</p>
                <select
                    value={component.layout}
                    onChange={(e) => {
                        component.layout = e.target.value
                        save()
                    }}
                >
                    <option value="column">Column</option>
                    <option value="row">Row</option>
                    <option value="stack">Stack</option>
                </select>
            </div>
            <div className="flex items-center text-center">
                <p className="pr-2">Round corners</p>
                <Checkbox
                    defaultChecked={component.rounded}
                    onChange={(e) => {
                        component.rounded = e.target.checked
                        save()
                    }}
                />
            </div>
            <div className="flex items-center text-center">
                <p className="pr-2">Shadow</p>
                <Checkbox
                    defaultChecked={component.shadow}
                    onChange={(e) => {
                        component.shadow = e.target.checked
                        save()
                    }}
                />
            </div>
            <div>
                <div className="flex items-center">
                    <p>Background</p>
                    <Checkbox
                        checked={component.background != null}
                        onChange={(e) => {
                            if (e.target.checked) {
                                component.background = {
                                    type: BackgroundTypes.Gradient,
                                }
                            } else {
                                component.background = null
                            }
                            save()
                        }}
                    />
                </div>
                {component.background && (
                    <BackgroundEditor
                        background={component.background}
                        onChange={(background) => {
                            component.background = background
                            save()
                        }}
                    />
                )}
            </div>

            <p>On Press:</p>
            <Checkbox
                checked={component.pressed != null}
                onChange={(e) => {
                    if (e.target.checked) {
                        component.pressed = {
                            system: TemplateActionSystems.Navigation,
                            method: "goto",
                            url: "",
                            data: {},
                        } as TemplateActionNavigationGoto
                    } else {
                        component.pressed = null
                    }
                    save()
                }}
            />
            {component.pressed == null ? (
                <div></div>
            ) : (
                <ActionEditor action={component.pressed} save={save} />
            )}

            <InsetEditor name={"Padding"} inset={component.padding} save={save} />
            <InsetEditor name={"Margin"} inset={component.margin} save={save} />
        </form>
    )
}

export default BoxEditor
