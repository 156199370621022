import React, { useRef } from "react"
import InputValidator from "helper/InputValidator.ts"
import "./Form.module.css"

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
    onValidation?: (validation: Validation) => void
    validators?: {
        [index: string]:
            | ((input: string) => string | undefined)
            | "default"
            | "none"
            | null
            | undefined
    }
}

type Validation = {
    [inputName: string]: {
        valid: boolean
        errorString: string | null
        input: string | null
    }
}
const Form = (props: FormProps) => {
    const { onValidation, validators, ...formProps } = props

    const formRef = useRef<HTMLFormElement>(null)

    const keyDownHandler = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === "Enter" && document.activeElement?.tagName === "INPUT") {
            // Handle focus next input or submit if last input
            const inputs: HTMLElement[] = []
            const addChildren = (element: HTMLElement) => {
                if (element.children.length > 0) {
                    Array.from(element.children).forEach((child: HTMLElement | any) => {
                        if (child.tagName === "INPUT") inputs.push(child)
                        if (child.tagName !== "FORM") addChildren(child)
                    })
                }
            }
            addChildren(e.currentTarget)
            const currentIndex = inputs.findIndex((input) => input === document.activeElement)
            const isLastInput = currentIndex === inputs.length - 1

            if (!isLastInput) {
                inputs[currentIndex + 1].focus()
                return
            }

            // Start validation
            if (!formRef.current || !validators) return

            // Create validation output containing all inputNames in validator. INPUTNAMES NOT IN VALIDATOR WILL BE IGNORED FOR NOW.
            let validation: Validation = {}
            Object.keys(validators).forEach((inputName) => {
                validation[inputName] = {
                    valid: true,
                    errorString: null,
                    input: null,
                }
            })

            // For each input: Use custom validator if exists else use default
            let isValidForm = true
            Array.from(formRef.current).forEach((childElement: Element) => {
                if (!validators) return // Dunno why typescript demands this
                if (childElement.tagName !== "INPUT") return
                const input = childElement as HTMLInputElement

                const inputName = input.getAttribute("name")
                const inputType = input.getAttribute("type")
                if (!inputName || !inputType) return

                if (!Object.keys(validators).includes(inputName)) {
                    console.warn(
                        `Input name: ${inputName} with type ${inputType} does not have a validator. Skipping validation. Please add a validator.`
                    )
                    return
                }

                // Get validation type
                let customValidator = validators[inputName]
                if (!customValidator || customValidator === "none") return

                let defaultValidator = InputValidator[inputType || ""]
                let validator = customValidator === "default" ? defaultValidator : customValidator
                if (!validator) {
                    console.error(
                        `Failed to validate input name: ${inputName}. No validator found for input type: ${inputType}. Please add a custom or default validator.`
                    )
                    return
                }

                // Validate input
                let errorString = validator(input.value)
                validation[inputName].input = input.value

                // Validation passed
                if (!errorString) return

                // Validation failed
                isValidForm = false
                validation[inputName] = {
                    valid: false,
                    errorString: errorString,
                    input: input.value,
                }
            })

            if (onValidation) onValidation(validation)
            if (isValidForm && isLastInput) if (props.onSubmit) props.onSubmit(e)
        }
    }

    return (
        <form ref={formRef} {...formProps} onKeyDown={keyDownHandler}>
            {props.children}
        </form>
    )
}

export default Form
export { type Validation }
