import Flags from "country-flag-icons/react/3x2"

export interface Lang {
    nativeName: string
    flag: JSX.Element
}

const lngs: { [key: string]: Lang } = {
    "en-GB": { nativeName: "English", flag: <Flags.GB title="English" className="w-6" /> },
    "de-DE": { nativeName: "Deutsch", flag: <Flags.DE title="Deutschland" className="w-6" /> },
    "no-NO": { nativeName: "Norsk (Bokmål)", flag: <Flags.NO title="Norge" className="w-6" /> },
}

export { lngs }
