import axios, { AxiosResponse, AxiosError } from "axios"
import CONSTS from "constants/CONSTS"

import { unwrapAxiosError, IAPIError, defaultHeaders } from "api/helpers"

export interface UploadImageResponse {
    [index: string]: string
}

const uploadSizes = [
    {
        width: 320,
        height: 0,
        ident: "low",
    },
    {
        width: 640,
        height: 0,
        ident: "med",
    },
]

const uploadImage = async (
    image: File,
    customWidth?: number
): Promise<IAPIError | UploadImageResponse> => {
    let formData = new FormData()
    formData.append("image", image)
    if (customWidth) uploadSizes.push({ width: customWidth, height: 0, ident: "custom" })
    formData.append("imageSizes", JSON.stringify(uploadSizes))

    return axios({
        method: "POST",
        url: "",
        baseURL: CONSTS.IUP_BASE_URL,
        headers: {
            ...defaultHeaders(true),
            "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        data: formData,
        validateStatus: validateStatusCode,
    })
        .then(async (res: AxiosResponse<UploadImageResponse>) => {
            return Promise.resolve(res.data)
        })
        .catch(async (err: AxiosError<any>) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const validateStatusCode = (code: number): boolean => {
    return 200 <= code && 400 > code
}

export { uploadImage }
