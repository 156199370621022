import React, { useEffect, useState } from "react"
import { DesignProps } from "../HomeTab"

// Template
import { Design02Component, DefaultDesign02Component } from "template/components"

// Components
import Input from "components/base/Input"
import ImageUpload from "components/base/ImageUpload"
import Form from "components/base/Form"
import TextArea from "components/base/TextArea"
import { useTranslation } from "react-i18next"

const Design02Editor = (props: DesignProps) => {
    const { t } = useTranslation()
    let component: Design02Component
    if (props.component.type !== "design02") {
        component = DefaultDesign02Component()
    } else {
        component = props.component as Design02Component
    }

    const [header, setHeader] = useState(component.header)
    const [headerImage, setHeaderImage] = useState(component.header_image_url)
    const [footerRedirectPath, setFooterRedirectPath] = useState(component.footer_redirect_path)

    useEffect(() => {
        props.onChange(getDesign())
    }, [header, headerImage, footerRedirectPath])

    const getDesign = (): Design02Component => {
        return {
            type: "design02",
            components: [],
            header: header,
            header_image_url: headerImage,
            footer_redirect_path: footerRedirectPath,
        }
    }

    return (
        <Form>
            <div className="mb-4">
                <TextArea
                    type={"text"}
                    label={t("appTheme.design02.header")}
                    labelClass={"ml-0 text-primary text-lg"}
                    className="w-full"
                    onChange={(e) => setHeader(e.target.value)}
                    value={header}
                    error={props.validationErrors?.has("header")}
                    errorMessage={props.validationErrors?.get("header")}
                />
            </div>
            <div className="mb-4">
                <h2 className="text-primary text-xl">{t("appTheme.design02.image")}</h2>
                <ImageUpload
                    disableTranslations={props.isDemo}
                    handleUpload={(image: File) => {
                        props
                            .uploadImage(image)
                            .then(() => setHeaderImage("mem:///template/images/" + image.name))
                    }}
                    imagePreviewWidth="3/4"
                />
            </div>
            <Input
                type={"text"}
                label={t("appTheme.design02.headerImagePath")}
                labelClass="text-primary text-lg"
                className={"w-full mb-4"}
                onChange={(e) => setHeaderImage(e.target.value)}
                value={headerImage}
                error={props.validationErrors?.has("header_image_url")}
                errorMessage={props.validationErrors?.get("header_image_url")}
            />
            <Input
                type={"text"}
                label={t("appTheme.design02.footerRedirectPath")}
                labelClass="text-primary text-lg"
                className={"w-full"}
                onChange={(e) => setFooterRedirectPath(e.target.value)}
                value={footerRedirectPath}
                error={props.validationErrors?.has("footer_redirect_path")}
                errorMessage={props.validationErrors?.get("footer_redirect_path")}
            />
        </Form>
    )
}

export default Design02Editor
