import CompaniesList from "./components/CompaniesList"
import CompanyCreate from "./components/CompanyCreate"
import CreateInvite from "./components/CreateInvite"

import Paper from "components/base/Paper"
import Frame from "components/base/Frame"

const AdminCompanies = () => {
    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        <Paper>
                            <CompaniesList />
                        </Paper>
                        <CompanyCreate />
                        <Paper>
                            <CreateInvite />
                        </Paper>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default AdminCompanies
