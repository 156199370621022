import { useState } from "react"
import { ComponentModel } from "template/components"

import ComponentsEditor from "./ComponentsEditor"

import { CopyCutDataContext, CopyCutData, DefaultCopyCutData } from "./SharedContext"

const cutAndPaste = (
    root: ComponentModel,
    component: ComponentModel,
    oldParent: ComponentModel,
    newParent: ComponentModel
): ComponentModel => {
    // TODO: use deepCopy?
    const newRoot = JSON.parse(JSON.stringify(root))
    const pasteComponent = JSON.parse(JSON.stringify(component))

    if (oldParent === newParent) {
        return newRoot
    }

    // traverse tree in root and newRoot at the same time
    const traverseAndUpdate = (current: ComponentModel, currentNew: ComponentModel) => {
        if (!currentNew) {
            return
        }
        // we compare by reference equality, should probably not
        if (current === oldParent) {
            // the cut part is in currentNew.components
            let newComponents = []
            for (let i = 0; i < oldParent.components.length; i++) {
                if (oldParent.components[i] === component) continue
                newComponents.push(currentNew.components[i])
            }
            currentNew.components = newComponents
        } else if (current === newParent) {
            // the paste part is in currentNew.components
            const newComponents = [pasteComponent, ...currentNew.components]
            currentNew.components = newComponents
        }
        for (let i = 0; i < current.components.length; i++) {
            traverseAndUpdate(current.components[i], currentNew.components[i])
        }
    }
    traverseAndUpdate(root, newRoot)
    return newRoot
}

// copyAndPaste copies 'component' to the subtree of 'copyTo'
const copyAndPaste = (
    root: ComponentModel,
    component: ComponentModel,
    copyTo: ComponentModel
): ComponentModel => {
    const newRoot = JSON.parse(JSON.stringify(root))
    const pasteComponent = JSON.parse(JSON.stringify(component))

    // we use the references as ids such that we keep track of position in the tree
    const traverseAndUpdate = (current: ComponentModel, currentNew: ComponentModel) => {
        if (current === copyTo) {
            const newComponents = [pasteComponent, ...currentNew.components]
            currentNew.components = newComponents
        }
        for (let i = 0; i < current.components.length; i++) {
            traverseAndUpdate(current.components[i], currentNew.components[i])
        }
    }
    traverseAndUpdate(root, newRoot)
    return newRoot
}

const RootComponentsEditor = ({
    parent,
    component,
    save,
    onSave,
}: {
    parent: ComponentModel
    component: ComponentModel
    save: () => void
    onSave: (root: ComponentModel) => void
}) => {
    const [copyCutData, setCopyCutData] = useState<CopyCutData>(DefaultCopyCutData)
    const handleCopyOrCut = (
        oldRoot: ComponentModel,
        data: ComponentModel,
        action: "copy" | "cut"
    ) => {
        setCopyCutData({ parent: oldRoot, component: data, action: action })
    }

    const resetData = () => {
        setCopyCutData(DefaultCopyCutData)
    }

    const handlePaste = (newParent: ComponentModel) => {
        if (!copyCutData.component || !copyCutData.parent) return

        if (copyCutData.action === "cut") {
            const newRoot = cutAndPaste(
                component,
                copyCutData.component,
                copyCutData.parent,
                newParent
            )
            onSave(newRoot)
            resetData()
        } else if (copyCutData.action === "copy") {
            const newRoot = copyAndPaste(component, copyCutData.component, newParent)
            onSave(newRoot)
            resetData()
        } else {
            resetData()
        }
    }

    return (
        <CopyCutDataContext.Provider
            value={{
                copyCutData,
                setCopyCutData,
                onCopyOrCut: handleCopyOrCut,
                onPaste: handlePaste,
                onCancel: resetData,
            }}
        >
            <ComponentsEditor parent={parent} component={component} save={save} />
        </CopyCutDataContext.Provider>
    )
}

export default RootComponentsEditor
