import React, { useEffect } from "react"
import { useCompanies } from "providers/company/CompaniesProvider"
import { getOffers } from "api/offers"
import { useCompanyPosts } from "providers/company/CompanyPosts"

// Components
import CreationModal from "components/CreationModal"

const Company = ({ match }: any) => {
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const { posts, setPosts } = useCompanyPosts()!

    useEffect(() => {
        if (currentCompany === null) {
            return
        }

        getOffers(currentCompany.company_name)
            .then((posts) => {
                setPosts(posts)
            })
            .catch((err) => {
                console.error("failed to get posts", err)
            })
    }, [currentCompany, match.params.company, setPosts])

    return (
        <div>
            <div className="m-auto max-w-screen-lg justify-between flex items-center">
                <div>
                    {currentCompany ? (
                        <div>
                            <CreationModal />
                            <p>----</p>
                            {posts !== undefined ? (
                                posts.length > 0 ? (
                                    posts.map((post) => {
                                        return <div>{post.title}</div>
                                    })
                                ) : (
                                    <div>no posts</div>
                                )
                            ) : (
                                <div>get posts failed</div>
                            )}
                        </div>
                    ) : (
                        <div>You do not have permissions to view this page</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Company
