import { useEffect } from "react"

// Models
import { NewsletterModel } from "models/Newsletter"

// Providers
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/company/CompaniesProvider"
import { useCompanyPosts } from "providers/company/CompanyPosts"
import { useCompanyNewsletters } from "providers/company/CompanyNewsletters"
import { useAlertContext } from "providers/AlertProvider"
import { useCompanyOffers } from "providers/company/CompanyOffers"

// Components
import Button from "components/base/Button"
import Frame from "components/base/Frame"
import AddIcon from "@mui/icons-material/Add"
import Paper from "components/base/Paper"
import BaseTable from "components/base/Table/Table"

// Constants
import { Paths } from "constants/ROUTES"
import HeadCell from "components/base/Table/HeadCell"
import { OfferRespModel } from "~/models"

const Posts = () => {
    // Providers
    const { t } = useTranslation()
    const { currentCompany } = useCompanies({ autoFetch: false })!
    const { fetchPosts, loadingPostsError } = useCompanyPosts()
    const { offers, fetchOffers, loadingOffersError } = useCompanyOffers()
    const { newsletters, fetchNewsletters, loadingNewslettersError } = useCompanyNewsletters()
    const { company } = useParams()
    const navigate = useNavigate()
    const alert = useAlertContext()

    // Methods
    const handlePostClick = (post: NewsletterModel) => {
        navigate(Paths.editNewsletter + "/" + post.id)
    }

    // Effects
    useEffect(() => {
        if (currentCompany === null) {
            return
        }

        fetchPosts()
        fetchNewsletters()
        fetchOffers()
    }, [currentCompany, company, fetchNewsletters, fetchPosts, fetchOffers])

    useEffect(() => {
        if (loadingNewslettersError || loadingPostsError) {
            alert.setAlert({
                alertSeverity: "error",
                alertMessage:
                    loadingPostsError?.response?.data.message ||
                    loadingNewslettersError?.response?.data.message ||
                    t("error.fetchingPosts"),
            })
        }
    }, [alert, loadingNewslettersError, loadingPostsError, loadingOffersError, t])

    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        <Paper>
                            <div className="flex flex-row justify-between items-center">
                                <div className="justify-between flex-wrap p-2 ">
                                    <h1 className="text-bold text-2xl font-body">
                                        {t("common.posts")}
                                    </h1>
                                </div>
                                <Button
                                    name="posts:create_button"
                                    onClick={() => {
                                        navigate(Paths.editNewsletter)
                                    }}
                                    className="bg-secondary text-white rounded-md active:bg-secondary hover:bg-primary h-fit py-2"
                                >
                                    <AddIcon className="mr-2" /> {t("post.newPost")}
                                </Button>
                            </div>
                        </Paper>

                        <Paper>
                            <div className=" bg-light p-4 rounded-xl shadow-none box-border">
                                <BaseTable
                                    data={newsletters}
                                    onEditClick={(offer) =>
                                        navigate(Paths.editNewsletter + "/" + offer.id)
                                    }
                                >
                                    <HeadCell
                                        id="title"
                                        numeric={false}
                                        disablePadding={true}
                                        label={t("common.title")}
                                    />
                                    <HeadCell
                                        id="publish_time"
                                        numeric={true}
                                        disablePadding={false}
                                        label={t("post.publishTime")}
                                    />
                                    <HeadCell
                                        id="expiration"
                                        numeric={true}
                                        disablePadding={false}
                                        label={t("post.expirationTime")}
                                    />
                                </BaseTable>
                            </div>
                        </Paper>

                        <Paper>
                            <div className="flex flex-row justify-between items-center">
                                <div className="justify-between flex-wrap p-2 ">
                                    <h1 className="text-bold text-2xl font-body">
                                        {t("common.offers")}
                                    </h1>
                                </div>
                                <Button
                                    onClick={() => {
                                        navigate(Paths.editOffer)
                                    }}
                                    className="bg-secondary text-white rounded-md active:bg-secondary hover:bg-primary h-fit py-2"
                                >
                                    <AddIcon className="mr-2" /> {t("post.newOffer")}
                                </Button>
                            </div>
                        </Paper>

                        <Paper>
                            <div className=" bg-light p-4 rounded-xl shadow-none box-border">
                                <BaseTable
                                    data={offers}
                                    onEditClick={(offer) =>
                                        navigate(Paths.editOffer + "/" + offer.id)
                                    }
                                >
                                    <HeadCell
                                        id="title"
                                        numeric={false}
                                        disablePadding={true}
                                        label={t("common.title")}
                                    />
                                    <HeadCell
                                        id="publish_time"
                                        numeric={true}
                                        disablePadding={false}
                                        label={t("post.publishTime")}
                                    />
                                    <HeadCell
                                        id="expiration"
                                        numeric={true}
                                        disablePadding={false}
                                        label={t("post.expirationTime")}
                                    />
                                </BaseTable>
                            </div>
                        </Paper>

                        {/* DONT DELETE THIS. PHILIP'S WORK IN PROGRESS. */}
                        {/* <div className=" bg-light p-4 rounded-xl shadow box-border ">
                            <div className={"flex justify-center"}>
                                <div className="grid grid-cols-3 gap-4 ml-5 w-full">
                                    <div className="col-span-1 flex items-top justify-center">
                                        <div className="flex items-top justify-between relative top-1.5">
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="14"
                                                    height="14"
                                                    rx="7"
                                                    fill="#02A287"
                                                />
                                            </svg>
                                        </div>
                                        <div className="flex items-top justify-between relative left-1.5 ">
                                            9706
                                        </div>
                                        <div className="flex items-top justify-between relative right-7 top-6 text-gray-400 text-xs">
                                            Visninger
                                        </div>
                                    </div>
                                    <div className="col-span-1 flex items-top justify-center ">
                                        <div className="flex items-top justify-between relative top-1.5">
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                            >
                                                <path d="M7 14L0 0L14 0L7 14Z" fill="#FCD468" />
                                            </svg>
                                        </div>
                                        <div className="flex items-top justify-between relative left-1.5 ">
                                            9706
                                        </div>
                                        <div className="flex items-top justify-between relative right-7 top-6 text-gray-400 text-xs">
                                            Klikk til nettbutikken
                                        </div>
                                    </div>
                                    <div className="col-span-1 flex items-top justify-center ">
                                        <div className="flex items-top justify-between relative top-1.5">
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect width="14" height="14" fill="#0094D8" />
                                            </svg>
                                        </div>
                                        <div className="flex items-top justify-between relative left-1.5 ">
                                            9706
                                        </div>
                                        <div className="flex items-top justify-between relative right-7 top-6 text-gray-400 text-xs">
                                            Innlegg
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" bg-light p-4 rounded-xl shadow-none box-border flex justify-center"></div>
                        </div> */}
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default Posts
