import axios, { AxiosError, AxiosResponse } from "axios"

import CONSTS from "constants/CONSTS"

import { defaultHeaders, unwrapAxiosError } from "./helpers"

/**
Use the google auth code to authorize with the server. If the user is not yet registered a valid, 
unused inviteCode must be provided

@param code   The Authorization code
@param invite The invite code
*/
const googleAuth = async (code: string, invite: string) => {
    return axios({
        method: "GET",
        url: "/v1/auth/google/callback",
        baseURL: CONSTS.BACKEND_HOST,
        params: {
            code: code,
            invite: invite,
        },
        validateStatus: (status) => {
            return status === 200
        },
        headers: defaultHeaders(false),
    })
        .then((res: AxiosResponse) => {
            localStorage.setItem("userToken", res.data.token)
            return res.data.token
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

export { googleAuth }
