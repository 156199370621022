import Input from "components/base/Input/Input"
import Button, { ButtonVariants } from "components/base/Button/Button"
import Paper, { PaperVariants } from "components/base/Paper"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { CRMModel, Stage1Model } from "models/CRM"
import ImageUpload from "components/base/ImageUpload"
import { useNavigate } from "react-router-dom"
import { AdminPaths } from "constants/ROUTES"
import TagPicker from "components/base/TagPicker"
import ISO6391 from "iso-639-1"
import { updateCustomerRelation } from "api/crm"
import { ArrowBack } from "@mui/icons-material"
import { uploadImage } from "api/image"
import ToolTip from "components/base/ToolTip"

const Stage1 = ({
    customerRelation,
    setCustomerRelation,
    update,
    goBack,
    admin = false,
}: {
    customerRelation: CRMModel
    setCustomerRelation: Function
    update: Function
    goBack?: Function
    admin?: boolean
}) => {
    const { t } = useTranslation()

    const [errorMessage, setErrorMessage] = useState<string>("")
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [appIcon, setAppIcon] = useState<File | null>(null)
    const [iconChanged, setIconChanged] = useState<boolean>(false)

    const [imageWidth, setImageWidth] = useState<number>(0)
    const languages = ISO6391.getAllNames().sort()

    const empty = (value: string | undefined) => {
        return value === null || value === "" || value === undefined
    }

    const displayWebviewInputs = () => {
        if (customerRelation.stage_1) {
            if (
                empty(customerRelation.stage_1?.test_user_name) &&
                empty(customerRelation.stage_1?.test_user_password) &&
                empty(customerRelation.stage_1?.webview_url)
            )
                return false
        }
        return true
    }
    const [showWebViewInputs, setShowWebViewInputs] = useState<boolean>(displayWebviewInputs())

    useEffect(() => {
        if (appIcon !== null) {
            let img = new Image()
            img.src = window.URL.createObjectURL(appIcon)
            img.decode().then(() => setImageWidth(img.width))
        }
    }, [appIcon])

    const getImageURL = async (): Promise<string> => {
        if (appIcon) {
            return uploadImage(appIcon, imageWidth)
                .then((res: any) => {
                    if (res["custom"]) {
                        return res["custom"]
                    } else return ""
                })
                .catch((err) => {
                    setErrorMessage(t("Could not save image."))
                    return ""
                })
        } else return ""
    }

    const save = async () => {
        let updated = {
            ...customerRelation,
        }
        if (iconChanged)
            updated = {
                ...customerRelation,
                stage_1: {
                    ...customerRelation.stage_1,
                    app_icon: await getImageURL(),
                },
            }

        if (admin) {
            if (customerRelation.stage_1?.pending) {
                updated = {
                    ...customerRelation,
                    stage_1: {
                        ...updated.stage_1,
                        pending: false,
                        changes_requested: true,
                    },
                }
            }
        }

        updateCustomerRelation(updated, customerRelation.id).then((res) => {})
    }

    const submit = async () => {
        if (!validate()) return
        let updated = { ...customerRelation }
        if (iconChanged)
            updated = {
                ...customerRelation,
                stage_1: {
                    ...customerRelation.stage_1,
                    app_icon: await getImageURL(),
                },
            }
        update(updated)
        if (goBack) goBack()
    }

    const validate = () => {
        setSuccessMessage("")

        if (empty(customerRelation.stage_1?.app_name)) {
            setErrorMessage(t("App name cannot be empty."))
            return false
        }
        if (empty(customerRelation.stage_1?.primary_language)) {
            setErrorMessage(t("Primary language cannot be empty."))
            return false
        }
        if (appIcon === null) {
            setErrorMessage(t("An app icon needs to be uploaded."))
            return false
        }
        if (empty(customerRelation.stage_1?.app_description)) {
            setErrorMessage(t("App description cannot be empty."))
            return false
        }
        if (empty(customerRelation.stage_1?.promotional_text)) {
            setErrorMessage(t("Promotional text cannot be empty."))
            return false
        }
        if (checkArrayUndefined(customerRelation.stage_1?.keywords).length === 0) {
            setErrorMessage(t("Keywords cannot be empty."))
            return false
        }
        if (empty(customerRelation.stage_1?.copyright)) {
            setErrorMessage(t("Copyright cannot be empty."))
            return false
        }
        if (empty(customerRelation.stage_1?.privacy_policy_url)) {
            setErrorMessage(t("Privacy policy URL cannot be empty."))
            return false
        }
        if (checkArrayUndefined(customerRelation.stage_1?.regions).length === 0) {
            setErrorMessage(t("You need to add atleast one region."))
            return false
        }

        if (showWebViewInputs) {
            if (empty(customerRelation.stage_1?.test_user_name)) {
                setErrorMessage(t("Test username cannot be empty."))
                return false
            }
            if (empty(customerRelation.stage_1?.test_user_password)) {
                setErrorMessage(t("Test password cannot be empty."))
                return false
            }
            if (empty(customerRelation.stage_1?.webview_url)) {
                setErrorMessage(t("Webview URL cannot be empty."))
                return false
            }
        }

        return true
    }

    const checkArrayUndefined = (array: undefined | null | string[]) => {
        if (array === undefined || array == null) return []
        else return array
    }

    return (
        <form
            className="m-auto max-w-screen-lg "
            onSubmit={() => {
                return false
            }}
        >
            <div className="mb-8">
                <div>
                    <div>
                        <div>
                            <div className="flex items-center">
                                {goBack && (
                                    <div
                                        className={
                                            "hover:bg-hoverDark cursor-pointer h-12 w-12 rounded-xl box-border flex items-center justify-center"
                                        }
                                        onClick={() => {
                                            if (goBack) goBack()
                                        }}
                                    >
                                        <ArrowBack />
                                    </div>
                                )}
                                <div className="text-xl font-body font-bold tracking-wide tracking-wide pl-1 flex">
                                    {t(`First stage`)}
                                    <p className="text-danger ml-1">
                                        {t(
                                            admin && customerRelation.stage_1?.pending
                                                ? "(pending)"
                                                : ""
                                        )}
                                    </p>
                                </div>
                            </div>
                            {!admin && !empty(customerRelation.stage_1?.notes) && (
                                <div className="p-4 border-2">
                                    <p>{t("Feedback on submission:")}</p>
                                    <p className="text-danger text-sm mt-2">
                                        {customerRelation.stage_1?.notes}
                                    </p>
                                </div>
                            )}
                            <div className={"grid grid-cols-2 gap-1"}>
                                <div className={"p-4"}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p className={`font-body text-sm ml-1`}>
                                                {t("App name")}
                                            </p>
                                            <ToolTip
                                                content={t("customerRelation.stage1.tooltips.name")}
                                            />
                                        </div>
                                        <Input
                                            name="appName"
                                            placeholder={"App name"}
                                            value={customerRelation.stage_1?.app_name}
                                            onChange={(e: any) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        app_name: e.target.value,
                                                    },
                                                })
                                            }}
                                            className={`w-full rounded-md mt-1 border border-hoverDark`}
                                        />
                                    </label>
                                </div>

                                <div className={"p-4"}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p>{t("Primary language")}</p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.language"
                                                )}
                                            />
                                        </div>
                                        <select
                                            className="flex justify-between ml-1 p-1 mt-1"
                                            id="language"
                                            onChange={(e) =>
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        primary_language: ISO6391.getCode(
                                                            e.target.value
                                                        ),
                                                    },
                                                })
                                            }
                                            defaultValue={
                                                customerRelation.stage_1?.primary_language !==
                                                undefined
                                                    ? ISO6391.getName(
                                                          customerRelation.stage_1.primary_language
                                                      )
                                                    : ""
                                            }
                                        >
                                            <option value="">{t("Choose language")}</option>
                                            {languages.map((val, index) => (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>

                                <div className="px-4 col-span-2 mb-4">
                                    <div
                                        className={`font-body text-sm ml-1 flex justify-between mb-1`}
                                    >
                                        <p className={`font-body text-sm`}>{t("App icon")}</p>
                                        <ToolTip
                                            content={t("customerRelation.stage1.tooltips.icon")}
                                        />
                                    </div>
                                    <ImageUpload
                                        handleUpload={(file: File) => {
                                            setErrorMessage("")
                                            setSuccessMessage("Image has been uploaded.")
                                            setAppIcon(file)
                                            setIconChanged(true)
                                        }}
                                        handleRemove={() => {
                                            setAppIcon(null)
                                            setErrorMessage("")
                                            setIconChanged(true)
                                        }}
                                        imageSpecifications={{}}
                                        initialImageURL={customerRelation.stage_1?.app_icon ?? ""}
                                    />
                                </div>

                                <div className={`px-4 col-span-2`}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p className={`font-body text-sm`}>
                                                {t("Description")}
                                            </p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.description"
                                                )}
                                            />
                                        </div>
                                        <textarea
                                            name="description"
                                            value={customerRelation.stage_1?.app_description}
                                            placeholder={"Description"}
                                            onChange={(e: any) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        app_description: e.target.value,
                                                    },
                                                })
                                            }}
                                            className={`
                                            h-20 rounded-md mt-1 w-full border border-hoverDark
                                           `}
                                        />
                                    </label>
                                </div>
                                <div className={`px-4 col-span-2`}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p className={`font-body text-sm`}>
                                                {t("Promotional text")}
                                            </p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.promo"
                                                )}
                                            />
                                        </div>
                                        <textarea
                                            name="promotionalText"
                                            value={customerRelation.stage_1?.promotional_text}
                                            placeholder={"Promotional text"}
                                            onChange={(e: any) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        promotional_text: e.target.value,
                                                    },
                                                })
                                            }}
                                            className={`
                                            h-20 rounded-md mt-1 w-full border border-hoverDark
                                           `}
                                        />
                                    </label>
                                </div>

                                <div className={`px-4 col-span-2`}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p>{t("Keywords")}</p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.keywords"
                                                )}
                                            />
                                        </div>

                                        <TagPicker
                                            value={checkArrayUndefined(
                                                customerRelation.stage_1?.keywords
                                            )}
                                            categories={[]}
                                            onCategoriesChange={(categories) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        keywords: categories,
                                                    },
                                                })
                                            }}
                                            placeholder={t("Add keywords with Enter")}
                                        />
                                    </label>
                                </div>

                                <div className={"p-4"}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p>{t("Copyright")}</p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.copyright"
                                                )}
                                            />
                                        </div>
                                        <Input
                                            name="copyright"
                                            value={customerRelation.stage_1?.copyright}
                                            placeholder={"Copyright"}
                                            onChange={(e: any) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        copyright: e.target.value,
                                                    },
                                                })
                                            }}
                                            className={`w-full rounded-md mt-1 border border-hoverDark`}
                                        />
                                    </label>
                                </div>

                                <div className={"p-4"}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p>{t("Privacy policy URL")}</p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.privacyPolicy"
                                                )}
                                            />
                                        </div>
                                        <Input
                                            name="privacyPolicy"
                                            value={customerRelation.stage_1?.privacy_policy_url}
                                            placeholder={"Privacy policy URL"}
                                            onChange={(e: any) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        privacy_policy_url: e.target.value,
                                                    },
                                                })
                                            }}
                                            className={`w-full rounded-md mt-1 border border-hoverDark`}
                                        />
                                    </label>
                                </div>
                                <div className={"p-4"}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p>{t("Age restrictions")}</p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.ageRestrictions"
                                                )}
                                            />
                                        </div>
                                        <TagPicker
                                            value={checkArrayUndefined(
                                                customerRelation.stage_1?.age_restricted
                                            )}
                                            categories={[
                                                "Language",
                                                "Violence",
                                                "Sexuality",
                                                "Controlled substances",
                                                "Promotion of age restricted products",
                                            ]}
                                            onCategoriesChange={(categories) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        age_restricted: categories,
                                                    },
                                                })
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className={"p-4"}>
                                    <label>
                                        <div
                                            className={`font-body text-sm ml-1 flex justify-between`}
                                        >
                                            <p>{t("Regions")}</p>
                                            <ToolTip
                                                content={t(
                                                    "customerRelation.stage1.tooltips.regions"
                                                )}
                                            />
                                        </div>
                                        <TagPicker
                                            value={checkArrayUndefined(
                                                customerRelation.stage_1?.regions
                                            )}
                                            categories={["Norway", "Germany"]}
                                            onCategoriesChange={(categories) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    stage_1: {
                                                        ...customerRelation.stage_1,
                                                        regions: categories,
                                                    },
                                                })
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="flex items-center p-4">
                                <input
                                    onChange={(e) => setShowWebViewInputs(!showWebViewInputs)}
                                    type={"checkbox"}
                                    className="w-4 h-4 mr-4 pt-2"
                                    checked={showWebViewInputs}
                                />
                                <div className="text-md font-body font-bold tracking-wide tracking-wide">
                                    {t("Do you want a web-view?")}
                                </div>
                            </div>
                            {showWebViewInputs && (
                                <div className="grid grid-cols-2 gap-1">
                                    <div className={"p-4"}>
                                        <label>
                                            <div
                                                className={`font-body text-sm ml-1 flex justify-between`}
                                            >
                                                <p>{t("Test username")}</p>
                                                <ToolTip
                                                    content={t(
                                                        "customerRelation.stage1.tooltips.testUsername"
                                                    )}
                                                />
                                            </div>
                                            <Input
                                                name="testUsername"
                                                value={customerRelation.stage_1?.test_user_name}
                                                placeholder={"Test username"}
                                                onChange={(e: any) => {
                                                    setCustomerRelation({
                                                        ...customerRelation,
                                                        stage_1: {
                                                            ...customerRelation.stage_1,
                                                            test_user_name: e.target.value,
                                                        },
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </label>
                                    </div>
                                    <div className={"p-4"}>
                                        <label>
                                            <div
                                                className={`font-body text-sm ml-1 flex justify-between`}
                                            >
                                                <p>{t("Test password")}</p>
                                                <ToolTip
                                                    content={t(
                                                        "customerRelation.stage1.tooltips.testPassword"
                                                    )}
                                                />
                                            </div>
                                            <Input
                                                name="userPassword"
                                                value={customerRelation.stage_1?.test_user_password}
                                                placeholder={"Test password"}
                                                onChange={(e: any) => {
                                                    setCustomerRelation({
                                                        ...customerRelation,
                                                        stage_1: {
                                                            ...customerRelation.stage_1,
                                                            test_user_password: e.target.value,
                                                        },
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </label>
                                    </div>
                                    <div className={"p-4"}>
                                        <label>
                                            <div
                                                className={`font-body text-sm ml-1 flex justify-between`}
                                            >
                                                <p>{t("Webview URL")}</p>
                                                <ToolTip
                                                    content={t(
                                                        "customerRelation.stage1.tooltips.webview"
                                                    )}
                                                />
                                            </div>
                                            <Input
                                                name="webviewUrl"
                                                value={customerRelation.stage_1?.webview_url}
                                                placeholder={"Webview URL"}
                                                onChange={(e: any) => {
                                                    setCustomerRelation({
                                                        ...customerRelation,
                                                        stage_1: {
                                                            ...customerRelation.stage_1,
                                                            webview_url: e.target.value,
                                                        },
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                        {admin && customerRelation.stage_1?.pending && (
                            <div className={`px-4 col-span-2`}>
                                <label>
                                    <div className={`font-body text-sm ml-1 flex justify-between`}>
                                        <p className={`font-body text-sm`}>
                                            {t("Feedback to customer")}
                                        </p>
                                        <ToolTip
                                            content={t("customerRelation.stage1.tooltips.feedback")}
                                        />
                                    </div>
                                    <textarea
                                        name="notes"
                                        value={customerRelation.stage_1?.notes}
                                        placeholder={"Notes"}
                                        onChange={(e: any) => {
                                            setCustomerRelation({
                                                ...customerRelation,
                                                stage_1: {
                                                    ...customerRelation.stage_1,
                                                    notes: e.target.value,
                                                },
                                            })
                                        }}
                                        className={`
                                            h-20 rounded-md mt-1 w-full border border-hoverDark
                                           `}
                                    />
                                </label>
                            </div>
                        )}
                        <div className={`text-danger text-sm ml-4 mt-4`}>{errorMessage}</div>
                        <div className={`text-green-800 text-sm ml-4 mt-4`}>{successMessage}</div>
                    </div>
                </div>
                <Paper variant={PaperVariants.dark} className="h-20 mt-8 col-span-3 bg-primary">
                    <div className={`flex justify-between`}>
                        <div className={`flex justify-between`}>
                            <Button
                                onClick={() => {
                                    submit()
                                }}
                                className="mr-5"
                            >
                                {t("Next stage")}
                            </Button>
                            <Button
                                onClick={() => {
                                    save()
                                    setErrorMessage("")
                                    setSuccessMessage("Information saved.")
                                }}
                                variant={ButtonVariants.secondary}
                            >
                                {t("Save")}
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </form>
    )
}

export default Stage1
