/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { DesignProps } from "../HomeTab"

import { Design01Component, DefaultDesign01Component } from "template/components"

// Components
import Input from "components/base/Input"
import Form from "components/base/Form"
import { useTranslation } from "react-i18next"

const Design01Editor = (props: DesignProps) => {
    const { t } = useTranslation()
    let component: Design01Component
    if (props.component.type !== "design01") {
        component = DefaultDesign01Component()
    } else {
        component = props.component as Design01Component
    }

    const [title, setTitle] = useState(component.title)
    const [subtitle, setSubtitle] = useState(component.subtitle)
    const [link1Text, setLink1Text] = useState<string>(component.link1_text)
    const [link1Path, setLink1Path] = useState<string>(component.link1_path)
    const [link2Text, setLink2Text] = useState<string>(component.link2_text)
    const [link2Path, setLink2Path] = useState<string>(component.link1_path)

    const getDesign = (): Design01Component => {
        return {
            type: "design01",
            components: [],
            title: title,
            subtitle: subtitle,
            link1_path: link1Path,
            link1_text: link1Text,
            link2_path: link2Path,
            link2_text: link2Text,
        }
    }

    // config.website.url + path

    useEffect(() => {
        props.onChange(getDesign())
    }, [title, subtitle, link1Text, link1Path, link2Text, link2Path])

    return (
        <Form>
            <Input
                type={"text"}
                label={t("appTheme.design01.title")}
                className={"w-full"}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                error={props.validationErrors?.has("title")}
                errorMessage={props.validationErrors?.get("title")}
            />
            <Input
                type={"text"}
                label={t("appTheme.design01.subtitle")}
                className={"w-full"}
                onChange={(e) => setSubtitle(e.target.value)}
                value={subtitle}
                error={props.validationErrors?.has("subtitle")}
                errorMessage={props.validationErrors?.get("subtitle")}
            />
            <Input
                type="text"
                label={t("appTheme.design01.link1.textLabel")}
                className={"w-full"}
                onChange={(e) => setLink1Text(e.target.value)}
                value={link1Text}
                error={props.validationErrors?.has("link1_text")}
                errorMessage={props.validationErrors?.get("link1_text")}
            />
            <Input
                type="text"
                label={t("appTheme.design01.link1.pathLabel")}
                className={"w-full"}
                onChange={(e) => setLink1Path(e.target.value)}
                value={link1Path}
                error={props.validationErrors?.has("link1_path")}
                errorMessage={props.validationErrors?.get("link1_path")}
            />
            <Input
                type="text"
                label={t("appTheme.design01.link2.textLabel")}
                className={"w-full"}
                onChange={(e) => setLink2Text(e.target.value)}
                value={link2Text}
                error={props.validationErrors?.has("link2_text")}
                errorMessage={props.validationErrors?.get("link2_text")}
            />
            <Input
                type="text"
                label={t("appTheme.design01.link2.pathLabel")}
                className={"w-full"}
                onChange={(e) => setLink2Path(e.target.value)}
                value={link2Path}
                error={props.validationErrors?.has("link2_path")}
                errorMessage={props.validationErrors?.get("link2_path")}
            />
        </Form>
    )
}

export default Design01Editor
