import { Design01Component, Design02Component } from "~/template/components"

const MAX_TITLE_LENGTH = 30

function isValidURL(url: string): boolean {
    try {
        new URL(url)
    } catch (_) {
        return false
    }
    return true
}

function isValidImageURL(url: string): boolean {
    const imageUrlRegex = /\.(jpeg|jpg|gif|png|svg)$/i
    return (isValidURL(url) || url.startsWith("mem://")) && imageUrlRegex.test(url)
}

function validateDesign01Component(
    component: Design01Component
): Map<keyof Design01Component, string> {
    // TODO: replace with i18n values after refactor
    const errors: Map<keyof Design01Component, string> = new Map()

    if (component.title.length > MAX_TITLE_LENGTH) {
        errors.set("title", "Title must be less than 30 characters")
    }

    if (component.subtitle.length > MAX_TITLE_LENGTH) {
        errors.set("subtitle", "Subtitle must be less than 30 characters")
    }

    if (component.link1_text.length > MAX_TITLE_LENGTH) {
        errors.set("link1_text", "Link 1 text must be less than 30 characters")
    }

    if (!isValidURL(component.link1_path)) {
        errors.set("link1_path", "Link 1 path must be a valid URL")
    }

    if (component.link2_text.length > MAX_TITLE_LENGTH) {
        errors.set("link2_text", "Link 2 text must be less than 20 characters")
    }

    if (!isValidURL(component.link2_path)) {
        errors.set("link2_path", "Link 2 path must be a valid URL")
    }

    return errors
}

function validateDesign02Component(
    component: Design02Component
): Map<keyof Design02Component, string> {
    // TODO: replace with i18n values after refactor
    const errors: Map<keyof Design02Component, string> = new Map()

    if (component.header.length > MAX_TITLE_LENGTH) {
        errors.set("header", "Title must be less than 20 characters")
    }

    if (!isValidImageURL(component.header_image_url)) {
        errors.set("header_image_url", "Header image URL must be a valid URL to an image file")
    }

    return errors
}

function getDesignValidatorFromType(type: string): (component: any) => Map<string, string> {
    switch (type) {
        case "design01":
            return validateDesign01Component
        case "design02":
            return validateDesign02Component
        default:
            throw new Error("Unknown design type")
    }
}

export { validateDesign01Component, validateDesign02Component, getDesignValidatorFromType }
