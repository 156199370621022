import { useEffect, useState } from "react"
import { getCustomerRelation, updateCustomerRelation } from "api/crm"
import { useParams } from "react-router-dom"
import Paper from "components/base/Paper/Paper"
import Stage1 from "./components/Stage1"

import { CRMModel, Stage1Model } from "models/CRM"
import { useNavigate } from "react-router-dom"
import Stage2 from "./components/Stage2"
import { AdminPaths } from "constants/ROUTES"

const TransitionCR = () => {
    const [customerRelation, setCustomerRelation] = useState<CRMModel>()
    let { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            getCustomerRelation(id).then((cr) => {
                setCustomerRelation(cr)
            })
        }
    }, [id])

    const update = (updatedCustomerRelation: CRMModel) => {
        const updated: CRMModel = {
            ...updatedCustomerRelation,
            stage: updatedCustomerRelation.stage + 1,
            stage_1: {
                ...updatedCustomerRelation.stage_1,
                pending: false,
            },
        }

        updateCustomerRelation(updated, updated.id).then((res) => {})
    }

    const transitionForm = () => {
        switch (customerRelation?.stage) {
            case 0:
                return (
                    <Stage1
                        customerRelation={customerRelation}
                        setCustomerRelation={setCustomerRelation}
                        update={update}
                        goBack={() => navigate(AdminPaths.crm)}
                        admin={true}
                    />
                )

            case 1:
                return (
                    <Stage2
                        customerRelation={customerRelation}
                        setCustomerRelation={setCustomerRelation}
                        update={update}
                        goBack={() => navigate(AdminPaths.crm)}
                    />
                )
        }
    }

    return (
        <div className={"flex justify-center mt-5"}>
            <Paper className={"w-2/3"}>{transitionForm()}</Paper>
        </div>
    )
}

export default TransitionCR
