import CreationModal from "components/CreationModal"
import Frame from "components/base/Frame"

const Newsletter = () => {
    return (
        <Frame>
            <div className="m-auto max-w-screen-lg justify-between flex items-center">
                <CreationModal />
            </div>
        </Frame>
    )
}

export default Newsletter
