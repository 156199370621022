import { ProviderGuardComponent, ComponentModel } from "template/components"

const ProviderGuardEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ProviderGuardComponent
    save: () => void
}) => {
    return (
        <form>
            <div className="grid grid-cols-2">
                <p>Provider</p>
                <input
                    type={"text"}
                    className="border-solid border-2 border-grey-400"
                    value={component.provider as string}
                    onChange={(e) => {
                        component.provider = e.target.value
                        save()
                    }}
                />
            </div>
        </form>
    )
}

export default ProviderGuardEditor
