import { useState } from "react"

// Services
import { createNotification } from "api/notification"

// Models
import {
    NotificationCreateModel,
    NotificationV1Data,
    NotificationV2Data,
    NOTIFICATION_VERSION,
} from "models/Notification"

// Hooks
import { useTranslation } from "react-i18next"
import { useAlertContext } from "providers/AlertProvider"
import { useCompanies } from "providers/company/CompaniesProvider"

// Components
import Input from "components/base/Input"
import TextArea from "components/base/TextArea"
import Button, { ButtonVariants } from "components/base/Button"
import Form from "components/base/Form"
import Datepicker from "components/base/Datepicker"
import AddIcon from "@mui/icons-material/Add"
import Select from "components/base/Select"
import LoadingCover from "components/base/LoadingCover/LoadingCover"

const DefaultAction = "open"
const RECOMMNDED_SCHEDULE_DAYS = 30

const CreateNotification = () => {
    const { t } = useTranslation()
    const { currentCompany } = useCompanies({ autoFetch: false })
    const alert = useAlertContext()

    const [action, setAction] = useState(DefaultAction)
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")
    const [url, setUrl] = useState("")
    const [scheduledAt, setScheduledAt] = useState<any | null>(null)
    const [loading, setLoading] = useState(false)

    // Methods
    const scheduleWarningDate = () => {
        // Warn user if they set a schedule date too far in the future
        var date = new Date()
        date.setDate(date.getDate() + RECOMMNDED_SCHEDULE_DAYS)
        return date
    }

    const scheduledDaysFromNow = () => {
        if (scheduledAt) {
            var date = new Date()
            var diff = Math.abs(scheduledAt.getTime() - date.getTime())
            return Math.ceil(diff / (1000 * 3600 * 24))
        }
        return 0
    }

    const clearForm = () => {
        setAction(DefaultAction)
        setTitle("")
        setText("")
        setScheduledAt(null)
    }

    const submit = () => {
        if (currentCompany === null) return

        setLoading(true)

        let notification: NotificationCreateModel | null = null

        // Add action to notification
        if (action === "url") {
            // create notification v1
            let data: NotificationV1Data = {
                topic: "webview",
                title: title,
                body: text,
                path: url,
            }

            notification = {
                version: NOTIFICATION_VERSION["1.0.0"],
                meta: {
                    scheduled_at: scheduledAt || new Date(),
                    company_name: currentCompany.company_name,
                },
                data: data,
            }
        } else {
            // create notification v2
            let data: NotificationV2Data = {
                topic: "webview",
                title: title,
                body: text,
                actions: [],
            }

            notification = {
                version: NOTIFICATION_VERSION["2.0.0"],
                meta: {
                    scheduled_at: scheduledAt || new Date(),
                    company_name: currentCompany.company_name,
                },
                data: data,
            }
        }

        if (!notification) {
            alert.setAlert({
                alertMessage: t("notification.sendFail"),
                alertSeverity: "error",
            })
            console.error("Failed to create notification object")
            return
        }

        createNotification(notification)
            .then((res) => {
                alert.setAlert({
                    alertMessage: t("notification.sendSuccess"),
                    alertSeverity: "success",
                })
            })
            .catch((err) => {
                alert.setAlert({
                    alertMessage: t("notification.sendFail"),
                    alertSeverity: "error",
                })
                console.error("failed to create notification: ", err)
            })
            .finally(() => {
                clearForm()
                setLoading(false)
            })
    }

    // Subcomponents
    const actionInfo = () => {
        switch (action) {
            case "url":
                return (
                    <Input
                        type={"text"}
                        label={t("notification.actions.openURL.title")}
                        tooltip={t("notification.actions.openURL.desc")}
                        className="mb-3"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                )
            case "open":
                return <></>
        }
    }

    // Render
    return (
        <div>
            <LoadingCover show={loading} />
            <Form>
                <div className="flex flex-wrap">
                    <div className="w-full mb-3">
                        <Input
                            label={t("Title")}
                            name={"notificationTitle"}
                            tooltip={t("notification.titleDescription")}
                            className="w-full"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            type={"text"}
                        />
                    </div>

                    <div className="w-full mb-3">
                        <TextArea
                            name={"notificationText"}
                            label={t("Text")}
                            tooltip={t("notification.textDescription")}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            type={"text"}
                        />
                    </div>

                    <div className="w-full mb-3 mt-3">
                        {scheduledAt ? (
                            <div className="flex flex-row">
                                <Datepicker
                                    value={scheduledAt}
                                    label={t("notification.publishingTime")}
                                    minDate={new Date()}
                                    warnAfter={scheduleWarningDate()}
                                    warnMessage={t("notification.warning.scheduleWarning", {
                                        days: scheduledDaysFromNow(),
                                    })}
                                    onChange={(date) => {
                                        if (date) setScheduledAt(date)
                                    }}
                                />
                                <Button
                                    variant={ButtonVariants.danger}
                                    className="ml-4 self-start"
                                    onClick={() => setScheduledAt(null)}
                                >
                                    {t("notification.removeSchedule")}
                                </Button>
                            </div>
                        ) : (
                            <Button onClick={() => setScheduledAt(new Date())}>
                                <AddIcon className="mr-2" />
                                {t("notification.addSchedule")}
                            </Button>
                        )}
                    </div>

                    <div className="w-full mb-3 hidden">
                        <Select
                            defaultValue={action}
                            label={t("notification.action")}
                            tooltip={t("notification.actionDescription")}
                            onChange={(e) => setAction(e.target.value)}
                        >
                            <option value="url">{t("notification.actions.openURL.select")}</option>
                            <option value="open">
                                {t("notification.actions.openApplication.select")}
                            </option>
                        </Select>
                    </div>

                    <div className="w-full mb-3 hidden">
                        <Select
                            defaultValue={action}
                            label={t("notification.action")}
                            tooltip={t("notification.actionDescription")}
                            onChange={(e) => setAction(e.target.value)}
                        >
                            <option value="url">{t("notification.actions.openURL.select")}</option>
                            <option value="open">
                                {t("notification.actions.openApplication.select")}
                            </option>
                        </Select>
                    </div>

                    {actionInfo()}

                    <div className="flex w-full justify-between mb-3">
                        <Button
                            name="notificationDeleteDraft"
                            type="button"
                            variant={ButtonVariants.secondary}
                            onClick={clearForm}
                        >
                            {t("common.deleteDraft")}
                        </Button>

                        <Button
                            name="notificationCreate"
                            type="button"
                            loading={loading}
                            variant={ButtonVariants.primary}
                            onClick={submit}
                        >
                            {t("notification.create")}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default CreateNotification
