import React from "react"

enum Variants {
    headline1,
    headline2,
    parahraph,
}

type PropTypes = {
    className?: string
    variant?: Variants
    children: React.ReactNode
}

const Typography = ({ className = "", variant = Variants.headline1, children }: PropTypes) => {
    let baseStyles = ""

    switch (variant) {
        case Variants.headline1: {
            baseStyles = `text-2xl font-body pb-3 font-body`

            return <h1 className={`${baseStyles} ${className}`}>{children}</h1>
        }

        case Variants.headline2: {
            baseStyles = `pb-3 font-body`
            return <h2 className={`${baseStyles} ${className}`}>{children}</h2>
        }
    }

    // when variant is "paragraph"
    baseStyles = `pb-3 font-body`
    return <p className={`${baseStyles} ${className}`}>{children}</p>
}

export default Typography
export { Variants }
