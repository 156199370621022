import axios, { AxiosError, AxiosResponse } from "axios"

import CONSTS from "constants/CONSTS"

import ApplicationTemplateModel from "template/components"
import CompanyModel from "models/Company"

import { defaultHeaders, IAPIError, unwrapAxiosError } from "./helpers"

const getApplicationTemplate = async (templateID: string) => {
    return axios({
        method: "GET",
        url: `/v1/admin/templates/${templateID}`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const setApplicationTemplateCurrent = async (companyID: string, templateID: string) => {
    return axios({
        method: "PATCH",
        url: `/v1/admin/companies/${companyID}/template/${templateID}`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        withCredentials: true,
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const getApplicationTemplateCurrent = async (
    companyID: string
): Promise<ApplicationTemplateModel | IAPIError> => {
    return axios({
        method: "GET",
        url: `/v1/admin/companies/${companyID}/template`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const updateApplicationTemplate = async (
    template: ApplicationTemplateModel
): Promise<ApplicationTemplateModel | IAPIError> => {
    return axios({
        method: "PATCH",
        url: `/v1/admin/templates/${template.id}`,
        baseURL: CONSTS.BACKEND_HOST,
        data: template,
        headers: defaultHeaders(true),
        withCredentials: true,
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const getPublicTemplates = async () => {
    return axios({
        method: "GET",
        url: "/v1/admin/templates",
        baseURL: CONSTS.BACKEND_HOST,
        params: {
            public: "true",
        },
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
        .then((res: AxiosResponse) => {
            // TODO: validate
            return Promise.resolve(res.data)
        })
}

const applicationTemplateGetManyByCompany = async (company: CompanyModel) => {
    return axios({
        method: "GET",
        url: `/v1/admin/companies/${company.id}/templates`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const deleteApplicationTemplate = async (templateId: string) => {
    return axios({
        method: "DELETE",
        url: `/v1/admin/templates/${templateId}`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    }).catch((err: AxiosError) => {
        return Promise.reject(unwrapAxiosError(err))
    })
}

const cloneApplicationTemplate = async (company: CompanyModel, templateId: string) => {
    return createApplicationTemplate(company.id!, {
        source_type: "template",
        source: templateId,
    })
}

const newDefaultApplicationTemplate = async (
    company: CompanyModel
): Promise<ApplicationTemplateModel> => {
    return createApplicationTemplate(company.id!, {
        source_type: "default",
    })
}

interface TemplateSource {
    source_type: "default" | "template"
    source?: string
}

const createApplicationTemplate = async (companyId: string, source: TemplateSource) => {
    return axios({
        method: "POST",
        url: `/v1/admin/companies/${companyId}/templates`,
        baseURL: CONSTS.BACKEND_HOST,
        data: source,
        headers: defaultHeaders(true),
        withCredentials: true,
        validateStatus: (status) => {
            return status === 201
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

export {
    getApplicationTemplate,
    getApplicationTemplateCurrent,
    getPublicTemplates,
    updateApplicationTemplate,
    applicationTemplateGetManyByCompany,
    newDefaultApplicationTemplate,
    cloneApplicationTemplate,
    setApplicationTemplateCurrent,
    deleteApplicationTemplate,
}
