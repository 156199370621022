import i18n, { Resource } from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

// Get all translation files and add them to the resources object
// All translation files must be in the translations folder and have the .translation.json extension
const resources: Resource = {}
const translationFiles = require.context("translations", true, /\.translation\.json$/)
translationFiles.keys().forEach((key) => {
    const translation = translationFiles(key)
    const namespace = key.split("/")[1].split(".")[0]
    const languages = Object.keys(translation)
    languages.forEach((language: any) => {
        if (!resources[language]) {
            resources[language] = {
                translation: {},
            }
        }
        const lanTranslation = resources[language].translation
        if (typeof lanTranslation === "object") {
            lanTranslation[namespace] = translation[language]
        }
    })
})

// Register the resources and the language detector
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        fallbackLng: "en",
        interpolation: { escapeValue: false },
    })

export default i18n
