import base64url from "base64url"

import { validate } from "models/helpers"

enum UserRole {
    Invalid = "invalid",
    CompanyAdmin = "company_admin",
    SystemAdmin = "system_admin",
}

// Used by the API for login
interface UserLoginModel {
    email: string
    password: string
}

// Used by the API for ChangePassword
interface UserChangePasswordModel {
    email: string
    new_password: string
    old_password: string
}

class UserModel {
    email: string
    ID: string
    role: UserRole

    constructor(token) {
        const parts = token.split(".")
        const claims = base64url.decode(parts[1])

        // TODO: this needs a verifier
        this.email = claims.email
        this.ID = claims.id
        this.role = claims.role
        if (Object.values(UserRole).includes(claims.role)) {
            this.role = claims.role as UserRole
        } else {
            this.role = UserRole.Invalid
        }
    }

    canAddCompanies(): bool {
        return this.role === UserRole.SystemAdmin
    }
}

const userChangePasswordConstraints = [
    { field: "email", type: "string" },
    { field: "new_password", type: "string" },
    { field: "old_password", type: "string" },
]

const ValidateUserChangePasswordModel = (n: any): ModelValidateResult<UserChangePasswordModel> => {
    return validate<UserChangePasswordModel>(n, userChangePasswordConstraints)
}

export {
    UserRole,
    UserModel,
    UserLoginModel,
    UserChangePasswordModel,
    ValidateUserChangePasswordModel,
}
