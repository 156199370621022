import { useState } from "react"

// Components
import Button from "components/base/Button"
import { TabComponent, ComponentModel } from "template/components"

const TabEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: TabComponent
    save: () => void
}) => {
    const [langcode, setLangcode] = useState("")

    return (
        <form>
            <div className="grid grid-cols-1">
                <p>Title</p>

                {Object.entries(component.title).map(([lang, value]) => {
                    return (
                        <div className={"grid grid-cols-2"}>
                            <p>{lang}</p>
                            <input
                                type={"text"}
                                className="border-solid border-2 border-grey-400"
                                value={value}
                                onChange={(e) => {
                                    component.title[lang] = e.target.value
                                    save()
                                }}
                            />
                        </div>
                    )
                })}
                <div className="grid grid-cols-3">
                    <p>Add language</p>
                    <input
                        type={"text"}
                        value={langcode}
                        onChange={(e) => setLangcode(e.target.value)}
                    />
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            component.title[langcode] = ""
                            save()
                        }}
                    >
                        Add
                    </Button>
                </div>
                <p>Icon</p>
                <div className="grid grid-cols-2">
                    <p>Source</p>
                    <input
                        type="text"
                        value={component.icon.source}
                        onChange={(e) => {
                            component.icon.source = e.target.value
                            save()
                        }}
                    />
                    <p>Name</p>
                    <input
                        type="text"
                        value={component.icon.name}
                        onChange={(e) => {
                            component.icon.name = e.target.value
                            save()
                        }}
                    />
                </div>
            </div>
        </form>
    )
}

export default TabEditor
