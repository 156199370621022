import { ComponentModel, PageComponent, RootComponent } from "template/components"

import Input from "components/base/Input"

const PageEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: PageComponent
    save: () => void
}) => {
    let navEnabled = false
    if (parent.type == "root") {
        navEnabled = (parent as RootComponent).navigation_enabled
    }
    return (
        <div>
            {navEnabled ? (
                <div>
                    <form>
                        <div className="grid grid-cols-2">
                            <p>Navigation Title</p>
                            {/* <Input */}
                            {/*     type="text" */}
                            {/*     value={component.navigation.title as string} */}
                            {/*     onChange={(e: any) => { */}
                            {/*         component.navigation.title = e.target.value */}
                            {/*         save() */}
                            {/*     }} */}
                            {/* /> */}
                        </div>
                    </form>
                </div>
            ) : (
                <div>Navigation not enabled</div>
            )}
        </div>
    )
}

export default PageEditor
