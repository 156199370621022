import { useRef, useState, ClipboardEvent, ChangeEvent, useEffect } from "react"
import { Done } from "@mui/icons-material"
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from "react-image-crop"
import { useTranslation } from "react-i18next"
import "react-image-crop/dist/ReactCrop.css"

import Button, { ButtonVariants } from "./Button"
import Input from "./Input"

type ImageSpecifications = {
    exactHeight?: number
    exactWidth?: number
    minHeight?: number
    minWidth?: number
    maxHeight?: number
    maxWidth?: number
    imageType?: "png" | "jpeg"
}

type ValidateSpecification = {
    comparison: boolean
    errorMessage: string
}

type PreviewWidth = "1/2" | "2/3" | "3/4" | "full"

const ImageUpload = ({
    handleUpload,
    handleRemove,
    imageSpecifications,
    initialImageURL,
    imagePreviewWidth = "1/2",
    className = "",
    disableTranslations = false,
}: {
    handleUpload: Function
    handleRemove?: Function
    imageSpecifications?: ImageSpecifications
    initialImageURL?: string
    imagePreviewWidth?: PreviewWidth
    className?: string
    disableTranslations?: boolean
}) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null)
    const [crop, setCrop] = useState<Crop>()
    const [aspectRatio, setAspectRatio] = useState<number | undefined>(undefined)
    const [aspectHeight, setAspectHeight] = useState<number | undefined>(undefined)
    const [aspectWidth, setAspectWidth] = useState<number | undefined>(undefined)
    const [error, setError] = useState<string>("")
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
    const fileRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (initialImageURL) fetchImage(initialImageURL)
    }, [])

    const fetchImage = async (imageURL: string) => {
        if (imageURL !== "") {
            fetch(imageURL)
                .then(async (res) => {
                    if (res.ok) {
                        const imageBlob = await res.blob()
                        const file = new File([imageBlob], "fetchedImage")

                        if (file != null) {
                            if (fileRef.current) fileRef.current.value = ""
                            updateImage(file)
                        } else {
                            setError(
                                disableTranslations
                                    ? "Error parsing image"
                                    : t("imageUpload.fileError")
                            )
                        }
                    }
                })
                .catch(() => {
                    setError(
                        disableTranslations
                            ? "Could not get image from URL"
                            : t("imageUpload.urlError")
                    )
                })
        }
    }

    const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null) updateImage(e.target.files[0])
        else setError(disableTranslations ? "Error updating image" : t("imageUpload.fileError"))
    }

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        if (e.clipboardData.getData("Text") !== "") {
            fetchImage(e.clipboardData.getData("Text"))
        } else if (e.clipboardData.files.length) {
            if (fileRef.current) fileRef.current.value = ""
            const fileObject = e.clipboardData.files[0]
            updateImage(fileObject)
        } else setError(disableTranslations ? "No image to paste" : t("imageUpload.emptyError"))
    }

    const updateImage = (image: File) => {
        setAspectRatio(undefined)
        setCrop(undefined)
        setError("")
        setUploadSuccess(false)
        setSelectedImage(image)
    }

    const checkImageSpecifications = async (image: File): Promise<boolean> => {
        if (imageSpecifications) {
            let img = new Image()
            img.src = window.URL.createObjectURL(image)
            await img.decode()

            setError("")
            const spec = imageSpecifications
            const type = "image/" + spec.imageType

            const validate: ValidateSpecification[] = [
                {
                    comparison: spec.imageType !== undefined && type !== selectedImage?.type,
                    errorMessage: `Image type must be ${spec.imageType}.`,
                },
                {
                    comparison: spec.exactWidth !== undefined && spec.exactWidth !== img.width,
                    errorMessage: `Image must have the exact width ${spec.exactWidth}px. This image is ${img.width}px.`,
                },
                {
                    comparison: spec.exactHeight !== undefined && spec.exactHeight !== img.height,
                    errorMessage: `Image must have the exact height ${spec.exactHeight}px. This image is ${img.height}px.`,
                },
                {
                    comparison: spec.minWidth !== undefined && spec.minWidth > img.width,
                    errorMessage: `Image must have a minimum width of ${spec.minWidth}px. This image is ${img.width}px.`,
                },
                {
                    comparison: spec.minHeight !== undefined && spec.minHeight > img.height,
                    errorMessage: `Image must have a minimum height of ${spec.minHeight}px. This image is ${img.height}px.`,
                },
                {
                    comparison: spec.maxWidth !== undefined && spec.maxWidth < img.width,
                    errorMessage: `Image must have a maximum width of ${spec.maxWidth}px. This image is ${img.width}px.`,
                },
                {
                    comparison: spec.maxHeight !== undefined && spec.maxHeight < img.height,
                    errorMessage: `Image must have a maximum height of ${spec.maxHeight}px. This image is ${img.height}px.`,
                },
            ]

            for (let v of validate) {
                if (v.comparison) {
                    setError(t(v.errorMessage))
                    return false
                }
            }
        }

        return true
    }

    const cropImage = async (): Promise<File | null> => {
        if (crop === undefined && selectedImage !== null) {
            let valid = await checkImageSpecifications(selectedImage)
            if (!valid) return null

            return selectedImage
        }

        const canvas = document.createElement("canvas")
        const imgElement: HTMLImageElement = document.getElementById("image") as HTMLImageElement
        if (imgElement) cropCanvas(imgElement, canvas, crop as PixelCrop)
        const blob = await toBlob(canvas)

        if (blob != null) {
            const file = new File([blob], "blobUpload")

            let valid = await checkImageSpecifications(file)
            if (!valid) return null

            return file
        }
        return null
    }

    const cropCanvas = (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) => {
        const ctx = canvas.getContext("2d")
        if (!ctx) throw new Error("No 2d context")

        canvas.width = image.naturalWidth * (crop.width / 100)
        canvas.height = image.naturalHeight * (crop.height / 100)

        ctx.save()
        ctx.translate(-(crop.x / 100) * image.naturalWidth, -(crop.y / 100) * image.naturalHeight)
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight)
        ctx.restore()
    }

    const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 80,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        )
    }

    const toBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
        return new Promise((resolve) => {
            canvas.toBlob(resolve)
        })
    }

    const upload = async () => {
        const file: File | null = await cropImage()
        if (file != null) {
            setUploadSuccess(true)
            handleUpload(file)
        }
    }

    const remove = () => {
        if (fileRef.current) fileRef.current.value = ""
        setUploadSuccess(false)
        setSelectedImage(null)
        setError("")
        if (handleRemove) handleRemove()
    }

    const updateAspectRatio = (e: ChangeEvent<HTMLInputElement>, dimension: "W" | "H") => {
        if (e.target.value === undefined) setAspectRatio(undefined)
        else {
            const img = document.getElementById("image") as HTMLImageElement
            if (dimension === "W") {
                if (e.target.value !== "") {
                    setAspectWidth(parseFloat(e.target.value))

                    if (aspectHeight !== undefined) {
                        const newAspectRatio = parseFloat(e.target.value) / aspectHeight
                        setAspectRatio(newAspectRatio)
                        setCrop(centerAspectCrop(img.clientWidth, img.clientHeight, newAspectRatio))
                    }
                } else {
                    setAspectRatio(undefined)
                    setAspectWidth(undefined)
                }
            } else if (dimension === "H") {
                if (e.target.value !== "") {
                    setAspectHeight(parseFloat(e.target.value))

                    if (aspectWidth !== undefined) {
                        const newAspectRatio = aspectWidth / parseFloat(e.target.value)
                        setAspectRatio(newAspectRatio)
                        setCrop(centerAspectCrop(img.clientWidth, img.clientHeight, newAspectRatio))
                    }
                } else {
                    setAspectRatio(undefined)
                    setAspectHeight(undefined)
                }
            }
        }
    }

    return (
        <div className={className}>
            <div
                onPaste={handlePaste}
                tabIndex={0}
                className="border-dashed border-2 border-gray-400 py-6 flex flex-col justify-center items-center focus:border-primary focus:bg-blue-100"
            >
                <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                    {disableTranslations
                        ? "Paste your image file/URL here or"
                        : t("imageUpload.pasteArea")}
                </p>

                <label
                    htmlFor="myImage"
                    className="rounded-md px-3 py-1 border border-gray-400 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none cursor-pointer"
                >
                    <p>{disableTranslations ? "Upload image" : t("common.uploadImage")}</p>
                    <input
                        type="file"
                        name="myImage"
                        id="myImage"
                        className="hidden w-full"
                        onChange={uploadFile}
                        ref={fileRef}
                    />
                </label>
            </div>
            {error !== "" && (
                <div className={`text-danger text-sm ml-4 my-2 m-auto flex text-center`}>
                    {error}
                </div>
            )}
            {selectedImage && (
                <>
                    <div className="place-items-center grid my-2">
                        <ReactCrop
                            crop={crop}
                            onChange={(_, c) => setCrop(c)}
                            className={`w-${imagePreviewWidth}`}
                            aspect={aspectRatio}
                        >
                            <img
                                id="image"
                                alt={t("imageUpload.notFoundError")}
                                width={"100%"}
                                className="flex"
                                src={URL.createObjectURL(selectedImage)}
                            />
                        </ReactCrop>
                    </div>
                    <div className="flex">
                        <Button
                            className="mr-2"
                            onClick={upload}
                            variant={
                                uploadSuccess ? ButtonVariants.success : ButtonVariants.primary
                            }
                        >
                            {disableTranslations ? "Upload" : t("common.upload")}
                            {uploadSuccess && <Done className="ml-2" />}
                        </Button>
                        <Button onClick={remove} variant={ButtonVariants.danger}>
                            {disableTranslations ? "Remove" : t("common.remove")}
                        </Button>
                        <div className="ml-auto flex justify-center">
                            <div className="justify-center m-auto">
                                <p className="text-md font-body font-bold tracking-wide px-2 align-middle">
                                    AR:
                                </p>
                            </div>
                            <div className="ml-auto w-16 mr-1 my-auto">
                                <Input
                                    type={"number"}
                                    min={1}
                                    placeholder="W"
                                    className="px-2 py-1 w-full"
                                    onChange={(e) => updateAspectRatio(e, "W")}
                                />
                            </div>
                            <div className="ml-auto w-16 my-auto">
                                <Input
                                    type={"number"}
                                    min={1}
                                    placeholder="H"
                                    className="px-2 py-1 w-full"
                                    onChange={(e) => updateAspectRatio(e, "H")}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ImageUpload
