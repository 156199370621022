import { BannerComponent, ComponentModel } from "template/components"

const BannerEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: BannerComponent
    save: () => void
}) => {
    return (
        <form>
            <div className="grid grid-cols-2">
                <p>Title</p>
                <input
                    type={"text"}
                    className="border-solid border-2 border-grey-400"
                    value={component.title as string}
                    onChange={(e) => {
                        component.title = e.target.value
                        save()
                    }}
                />
            </div>
        </form>
    )
}

export default BannerEditor
