import {
    useEffect,
    useState,
    useCallback,
    useContext,
    MutableRefObject,
    createContext,
    useRef,
} from "react"
import Props from "providers/Props"

import { useCompanies } from "providers/company/CompaniesProvider"
// Store
import { applicationTemplateGetManyByCompany } from "api/template"
import TemplateModel from "~/template/components"

import { useUser } from "providers/UserProvider"
import { IAPIError } from "~/api/helpers"

type TemplatesContextType = {
    currentTemplate: TemplateModel | null
    setCurrentTemplate: (value: TemplateModel | null) => void
    templates: Array<TemplateModel>
    setTemplates: (value: Array<TemplateModel>) => void
    templatesFetching: boolean
    setTemplatesFetching: (value: boolean) => void
}

type TemplatesPrivateContextType = {
    isFetchingTemplates: MutableRefObject<boolean>
}

const TemplatesContext = createContext<TemplatesContextType | undefined>(undefined)
const TemplatesPrivateContext = createContext<TemplatesPrivateContextType | undefined>(undefined)

const TemplatesProvider = ({ children }: Props) => {
    const [currentTemplate, setCurrentTemplate] = useState<TemplateModel | null>(null)
    const [templates, setTemplates] = useState<Array<TemplateModel>>([])
    const [templatesFetching, setTemplatesFetching] = useState<boolean>(false)
    const isFetchingTemplates = useRef(false)

    return (
        <TemplatesContext.Provider
            value={{
                currentTemplate,
                setCurrentTemplate,
                templates,
                setTemplates,
                templatesFetching,
                setTemplatesFetching,
            }}
        >
            <TemplatesPrivateContext.Provider value={{ isFetchingTemplates }}>
                {children}
            </TemplatesPrivateContext.Provider>
        </TemplatesContext.Provider>
    )
}

const useTemplates = ({ autoFetch }: { autoFetch: boolean }) => {
    const {
        currentTemplate,
        setCurrentTemplate,
        templates,
        setTemplates,
        templatesFetching,
        setTemplatesFetching,
    } = useContext(TemplatesContext)!
    const { isFetchingTemplates } = useContext(TemplatesPrivateContext)!

    const { currentCompany } = useCompanies({ autoFetch: true })!

    const { user } = useUser({ autoFetch: true })!

    const fetchTemplates = useCallback(() => {
        if (!user || !currentCompany) {
            return
        }
        if (isFetchingTemplates.current) {
            return
        }
        isFetchingTemplates.current = true
        setTemplatesFetching(true)

        applicationTemplateGetManyByCompany(currentCompany)
            .then((c: Array<TemplateModel> | IAPIError) => {
                c = c as Array<TemplateModel>
                setTemplates(c)
            })
            .catch((err: any) => {
                console.error("failed to get companies: ", err)
                setTemplates([])
                setCurrentTemplate(null)
            })
            .finally(() => {
                setTemplatesFetching(false)
                isFetchingTemplates.current = false
            })
    }, [setTemplates, user, currentCompany])

    const addTemplate = useCallback(
        (template: TemplateModel) => {
            templates.push(template)
            setTemplates([...templates])
        },
        [setTemplates, templates]
    )

    useEffect(() => {
        if (autoFetch === true) {
            fetchTemplates()
        }
    }, [autoFetch, fetchTemplates, user])

    return {
        currentTemplate,
        setCurrentTemplate,
        templates,
        setTemplates,
        fetchTemplates,
        templatesFetching,
        addTemplate,
    }
}

export { TemplatesProvider, useTemplates, TemplatesContext }
