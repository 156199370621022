import { useState, useEffect, useMemo } from "react"

import { t } from "i18next"

// components
import Frame from "components/base/Frame"
import Paper from "components/base/Paper"
import Button from "components/base/Button"
import Spinner from "components/base/Spinner"
import TemplateModel from "template/components"
import { AddCircle } from "@mui/icons-material"

// Providers
import { useCompanies } from "providers/company/CompaniesProvider"
import { useTemplates } from "providers/TemplatesProvider"
import { useAlertContext } from "providers/AlertProvider"

// api
import {
    newDefaultApplicationTemplate,
    updateApplicationTemplate,
    getPublicTemplates,
    cloneApplicationTemplate,
    deleteApplicationTemplate,
    setApplicationTemplateCurrent,
} from "api/template"
import { IAPIError } from "api/helpers"

import TemplatePreviewCard, { TemplateCardActions } from "./components/TemplatePreviewCard"

const AppTemplates = () => {
    const { currentCompany, fetchCompanies } = useCompanies({ autoFetch: false })!
    const { templates, setTemplates, fetchTemplates, templatesFetching } = useTemplates({
        autoFetch: true,
    })!
    const { setAlert } = useAlertContext()

    const [publicTemplates, setPublicTemplates] = useState<TemplateModel[]>([])

    useEffect(() => {
        getPublicTemplates().then((templates) => {
            setPublicTemplates(templates)
        })
    }, [currentCompany])

    const createTemplate = () => {
        if (currentCompany === null) {
            return
        }
        newDefaultApplicationTemplate(currentCompany)
            .then((res) => {
                setTemplates([...templates, res])
            })
            .catch((err) => {
                console.log("failed to create template: ", err)
            })
    }

    const cloneTemplate = (template: TemplateModel) => {
        if (currentCompany === null) {
            return
        }
        cloneApplicationTemplate(currentCompany, template.id)
            .then((res) => {
                setTemplates([...templates, res])
                setAlert({
                    alertMessage: t("templates.clonedPublicTemplate"),
                    alertSeverity: "info",
                })
            })
            .catch((err) => {
                console.log("failed to create template: ", err)
            })
    }

    const publishTemplate = (template: TemplateModel) => {
        if (template.public) {
            return
        }

        template.public = true
        updateApplicationTemplate(template).then((res: TemplateModel | IAPIError) => {
            setPublicTemplates([...publicTemplates, res as TemplateModel])
        })
    }

    const deleteTemplate = (templateID: string) => {
        deleteApplicationTemplate(templateID)
            .then(() => fetchTemplates())
            .catch(() => {
                setAlert({
                    alertMessage: t("templates.cannotDeleteActiveTemplate"),
                    alertSeverity: "error",
                })
            })
    }

    const setCurrent = (companyID: string, templateID: string) => {
        setApplicationTemplateCurrent(companyID, templateID).then(() => {
            fetchCompanies()
            fetchTemplates()
        })
    }

    const publicTemplatesCards = useMemo(() => {
        if (!currentCompany || currentCompany.id === undefined) {
            return []
        }

        const companyID = currentCompany.id

        const isOwnedByCompany = (t: TemplateModel) => {
            return currentCompany.id === t.owned_by
        }

        return publicTemplates.map((t) => {
            return TemplatePreviewCard({
                isCurrent: t.id === currentCompany.application.template_id,
                companyID,
                template: t,
                copy: cloneTemplate,
                deleteTemplate: isOwnedByCompany(t) ? deleteTemplate : undefined,
                bottomActions: isOwnedByCompany(t)
                    ? [TemplateCardActions.copy, TemplateCardActions.delete]
                    : [TemplateCardActions.copy],
                publish: publishTemplate,
            })
        })
    }, [publicTemplates, currentCompany, publishTemplate, cloneTemplate])

    const myTemplates = useMemo(() => {
        if (!currentCompany || currentCompany.id === undefined) {
            return []
        }
        const companyID = currentCompany.id

        return templates.map((t) =>
            TemplatePreviewCard({
                isCurrent: t.id === currentCompany.application.template_id,
                companyID,
                template: t,
                deleteTemplate: deleteTemplate,
                setCurrent: setCurrent,
                publish: publishTemplate,
                bottomActions: [
                    TemplateCardActions.current,
                    TemplateCardActions.edit,
                    TemplateCardActions.delete,
                    TemplateCardActions.publish,
                ],
            })
        )
    }, [templates, currentCompany, publishTemplate])

    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        <Paper>
                            <div className={`m-auto mb-2`}>
                                <div className="justify-between flex-wrap ">
                                    <h1 className="text-bold text-2xl font-body">
                                        Application Designs
                                    </h1>
                                </div>
                            </div>

                            {templatesFetching && (
                                <div className="h-screen flex items-center justify-center">
                                    <Spinner />
                                </div>
                            )}

                            {!templatesFetching && (
                                <>
                                    <div className="w-full">
                                        <h1 className="text-lg">Available Templates</h1>
                                        <div className="grid grid-cols-4 gap-4 mb-4">
                                            {publicTemplatesCards}
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <h1 className="text-lg">Your Designs</h1>
                                    </div>

                                    <div className="grid grid-cols-4 gap-4">
                                        <div
                                            onClick={createTemplate}
                                            className="w-full bg-gray-200 h-60 rounded transition duration-200 shadow-sm hover:shadow-lg cursor-pointer"
                                        >
                                            <div className="h-full flex items-center justify-center">
                                                <AddCircle fontSize="large" />
                                            </div>
                                        </div>

                                        {myTemplates}
                                    </div>
                                </>
                            )}
                        </Paper>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default AppTemplates
