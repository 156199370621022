import {
    useEffect,
    useState,
    useCallback,
    useContext,
    MutableRefObject,
    createContext,
    useRef,
} from "react"
import Props from "providers/Props"

// Store
import { getCompanies } from "api/company"
import { updateTokenToCompanyToken } from "api/user"
import UserClass from "classes/User"

import CompanyModel from "~/models/Company"

import { useUser } from "providers/UserProvider"
import { IAPIError } from "~/api/helpers"

// api
type CompaniesContextType = {
    currentCompany: CompanyModel | null
    setCurrentCompany: (value: CompanyModel | null) => void
    companies: Array<CompanyModel>
    setCompanies: (value: Array<CompanyModel>) => void
    companiesFetching: boolean
    setCompaniesFetching: (value: boolean) => void
}

type CompaniesPrivateContextType = {
    isFetchingCompanies: MutableRefObject<boolean>
}

const CompaniesContext = createContext<CompaniesContextType | undefined>(undefined)
const CompaniesPrivateContext = createContext<CompaniesPrivateContextType | undefined>(undefined)

const CompaniesProvider = ({ children }: Props) => {
    const [currentCompany, setCurrentCompany] = useState<CompanyModel | null>(null)
    const [companies, setCompanies] = useState<Array<CompanyModel>>([])
    const [companiesFetching, setCompaniesFetching] = useState<boolean>(false)
    const isFetchingCompanies = useRef(false)

    return (
        <CompaniesContext.Provider
            value={{
                currentCompany,
                setCurrentCompany,
                companies,
                setCompanies,
                companiesFetching,
                setCompaniesFetching,
            }}
        >
            <CompaniesPrivateContext.Provider value={{ isFetchingCompanies }}>
                {children}
            </CompaniesPrivateContext.Provider>
        </CompaniesContext.Provider>
    )
}

const useCompanies = ({ autoFetch }: { autoFetch: boolean }) => {
    const {
        currentCompany,
        setCurrentCompany,
        companies,
        setCompanies,
        companiesFetching,
        setCompaniesFetching,
    } = useContext(CompaniesContext)!
    const { isFetchingCompanies } = useContext(CompaniesPrivateContext)!

    const { user, setUser } = useUser({ autoFetch: false })!

    const updateCurrentCompany = useCallback(
        (company: CompanyModel | null) => {
            // have async update token
            updateTokenToCompanyToken(company?.company_name)
                .then((token: string) => {
                    if (company != null) {
                        localStorage.setItem("active_company", company.company_name)
                    } else {
                        localStorage.removeItem("active_company")
                    }
                    const usr = new UserClass(token)
                    setUser(usr)
                    setCurrentCompany(company)
                })
                .catch((err) => {
                    return Promise.reject(err)
                })
        },
        [setCurrentCompany, setUser]
    )

    const fetchCompanies = useCallback(() => {
        if (!user) {
            return
        }
        if (isFetchingCompanies.current) {
            return
        }
        isFetchingCompanies.current = true
        setCompaniesFetching(true)

        getCompanies()
            .then((c: Array<CompanyModel> | IAPIError) => {
                c = c as Array<CompanyModel>
                setCompanies(c)
                let company_name =
                    currentCompany?.company_name ?? localStorage.getItem("active_company") ?? ""
                let company = c.find((el) => el.company_name === company_name) ?? c[0]
                updateCurrentCompany(company)
            })
            .catch((err: any) => {
                console.error("failed to get companies: ", err)
                setCompanies([])
                updateCurrentCompany(null)
            })
            .finally(() => {
                setCompaniesFetching(false)
                isFetchingCompanies.current = false
            })
    }, [setCompanies])

    const addCompany = useCallback(
        (company: CompanyModel) => {
            companies.push(company)
            setCompanies([...companies])
        },
        [setCompanies, companies]
    )

    useEffect(() => {
        if (autoFetch === true) {
            fetchCompanies()
        }
    }, [autoFetch])

    return {
        currentCompany,
        setCurrentCompany,
        updateCurrentCompany,
        companies,
        setCompanies,
        fetchCompanies,
        companiesFetching,
        addCompany,
    }
}

export { CompaniesProvider, useCompanies, CompaniesContext }
