/* eslint-disable react-hooks/exhaustive-deps */
// Store
import { useUser } from "providers/UserProvider"

// models
import logo_text_white_bg from "assets/logo_text_white_bg.svg"
import { useAlertContext } from "providers/AlertProvider"
import { useNavigate } from "react-router"
import { useEffect } from "react"

// Compoanents
import Nav from "components/Menubar"
import Sidebar from "components/Sidebar"
import AlertPopup from "./AlertPopup/AlertPopup"
import Spinner from "./base/Spinner"

// Consts
import { Paths } from "constants/ROUTES"

const ContentWrapper = (props: any) => {
    // Providers
    const { user, userFetching } = useUser({ autoFetch: true })!
    const alert = useAlertContext()
    const navigate = useNavigate()

    // Reset alerts whenever navigating to a new page
    useEffect(() => {
        alert.resetAlerts()
    }, [navigate])

    // Render
    if (userFetching) {
        return (
            <div className="h-screen flex items-center justify-center">
                <Spinner />
            </div>
        )
    }
    return (
        <div className="bg-gray-200 grid grid-rows-main grid-cols-main grid-flow-col min-h-screen">
            <div className="z-40 col-start-1 col-span-2 row-start-1 row-span-1 grid grid-cols-main bg-light shadow">
                <div
                    className="flex col-start-1 col-span-1 row-start-1 row-span-1 cursor-pointer"
                    onClick={() =>
                        window.location.pathname === Paths.dashboard
                            ? window.location.reload()
                            : navigate(Paths.dashboard)
                    }
                >
                    <div
                        style={{
                            backgroundImage: `url(${logo_text_white_bg})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        className={"w-20 h-18 bg-blue-200"}
                    ></div>
                    <span className="inline-block text-primary text-xl my-auto">Admin</span>
                </div>

                <div className="col-start-2 col-span row-start-0 row-span-1">
                    <Nav className="text-primary" />
                </div>
            </div>

            {user !== undefined ? (
                <div className="grid col-start-1 row-start-2 grid-cols-main col-span-10">
                    <div className="col-start-1 col-span-1 row-start-1 row-span-8 bg-primary text-text-light h-max max-w-fit">
                        <Sidebar />
                    </div>
                    <div className="col-start-2 col-span-8 row-start-1 row-span-8 h-max px-4 overflow-y-auto">
                        <AlertPopup />
                        {props.children}
                    </div>
                </div>
            ) : (
                <div className="col-start-1 col-span-9 row-start-2 row-span-8 h-max px-4 overflow-y-auto">
                    <AlertPopup />
                    {props.children}
                </div>
            )}
            <div className="col-start-1 col-span-10 row-span-1 bg-gray-600"></div>
        </div>
    )
}

export default ContentWrapper
