import { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

// Services
import { createInvite } from "api/invite"

// Models
import { UserRole } from "models/User.d"
import { InviteReq } from "models/Invite"

// Components
import CloseIcon from "@mui/icons-material/Close"
import { Alert, AlertTitle, MenuItem, Select } from "@mui/material"
import Button from "components/base/Button"
import Modal from "components/base/Modal/Modal"
import Paper from "components/base/Paper"
import Form from "components/base/Form"
import Input from "components/base/Input"

type CreateInviteModalProps = {
    show: boolean
    setShow: (showInviteModal: boolean) => void
    inviteCompanyName: string | null
}

const CreateInviteModal = ({
    show: showInviteModal,
    setShow: setShowInviteModal,
    inviteCompanyName,
}: CreateInviteModalProps) => {
    const [useInviteEmail, setUseInviteEmail] = useState<boolean>(false)
    const [inviteRole, setInviteRole] = useState<string>(UserRole.CompanyAdmin)

    const [inviteLink, setInviteLink] = useState<string | null>(null)
    const [inviteError, setInviteError] = useState<string | null>(null)

    const { t } = useTranslation()

    // Methods
    const clearInvite = () => {
        setInviteLink(null)
        setInviteError(null)
        setUseInviteEmail(false)
    }

    const _createInvite = (e: FormEvent) => {
        e.preventDefault()
        setInviteLink(null)
        setInviteError(null)
        if (!inviteCompanyName) return

        const inviteReq: InviteReq = {
            company_name: inviteCompanyName,
            role: inviteRole,
        }

        const emailInput = document.getElementById("invite-email-input")
        if (useInviteEmail && emailInput instanceof HTMLInputElement) {
            inviteReq.email = emailInput.value
        }

        createInvite(inviteReq)
            .then((res: { invite: string }) => {
                setInviteLink(
                    `${window.location.protocol}//${window.location.host}/user/invite/${res.invite}`
                )
            })
            .catch((err: any) => {
                setInviteError(err.raw.message)
            })
    }

    const onCopyInviteLink = () => {
        if (!inviteLink) return
        const inviteSuccessAlert = document.getElementById("invite-success-alert")
        if (inviteSuccessAlert) {
            inviteSuccessAlert.classList.remove("cursor-pointer")
            document.getElementById("invite-success-alert-copy-text")!.innerHTML =
                t("common.copied")
            navigator.clipboard.writeText(inviteLink)
        }
    }

    return (
        <Modal
            show={showInviteModal}
            onClose={() => {
                clearInvite()
                setShowInviteModal(false)
            }}
        >
            <Paper className="relative">
                <button className="absolute top-0 right-0 m-2">
                    <CloseIcon className="text-red-500" onClick={() => setShowInviteModal(false)} />
                </button>
                <Form onSubmit={_createInvite}>
                    <h3 className="text-lg font-body tracking-wide mb-4">
                        {t("profile.information.createInvite")}
                    </h3>
                    <h4 className="mb-4">
                        {t("common.company")}: {inviteCompanyName}
                    </h4>
                    <div className="mb-4">
                        <label className="mr-2">{t("profile.information.role")}:</label>
                        <Select
                            label="Role"
                            onChange={(v) => setInviteRole(v.target.value)}
                            value={inviteRole}
                        >
                            <MenuItem value={UserRole.CompanyAdmin}>Company Admin</MenuItem>
                            <MenuItem value={UserRole.SystemAdmin}>System Admin</MenuItem>
                        </Select>
                    </div>
                    <div className="mb-4">
                        <Input
                            type="checkbox"
                            checked={Boolean(useInviteEmail)}
                            className="ml-1 mr-2"
                            style={{ scale: "1.5" }}
                            onChange={(e) => setUseInviteEmail(e.target.checked)}
                        />
                        <label>{t("profile.information.inviteSpecificUser")}</label>
                    </div>
                    {useInviteEmail && (
                        <div className="mb-4">
                            <label className="mr-2">{t("profile.information.email")}:</label>
                            <Input
                                id="invite-email-input"
                                type="email"
                                placeholder={t("profile.information.email")}
                            />
                            {inviteError && <p className="text-danger">{inviteError}</p>}
                        </div>
                    )}
                    <Button type="submit" className="mb-4">
                        {t("profile.information.createInviteLink")}
                    </Button>
                    {inviteLink && (
                        <Alert
                            id="invite-success-alert"
                            className="cursor-pointer"
                            severity="success"
                            onClick={onCopyInviteLink}
                        >
                            <AlertTitle>
                                <div className="flex flex-row justify-between items-center">
                                    <h4>{t("profile.information.linkCreated")}</h4>
                                    <span id="invite-success-alert-copy-text" className="font-bold">
                                        {t("common.clickToCopy")}
                                    </span>
                                </div>
                            </AlertTitle>
                            <p>{inviteLink}</p>
                        </Alert>
                    )}
                </Form>
            </Paper>
        </Modal>
    )
}
export default CreateInviteModal
