import { useState } from "react"
import { useUser } from "providers/UserProvider"
import { UserRole } from "models/User.d"
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/company/CompaniesProvider"
import Button from "components/base/Button"
import CreateInviteModal from "./components/CreateInviteModal"

const Information = () => {
    const [showInviteModal, setShowInviteModal] = useState<boolean>(false)
    const [inviteCompanyName, setInviteCompanyName] = useState<string | null>(null)

    const { companies } = useCompanies({ autoFetch: false })!
    const { user } = useUser({ autoFetch: false })!
    const { t } = useTranslation()

    const userRole = () => {
        if (user) {
            switch (user.role) {
                case UserRole.CompanyAdmin:
                    return "Company admin"
                case UserRole.SystemAdmin:
                    return "System admin"
            }
        }
    }

    // Render
    if (!user) {
        return (
            <div className="text-xl font-body font-bold tracking-wide px-4 py-2">
                {t("error.noUser")}
            </div>
        )
    }

    return (
        <div>
            <CreateInviteModal
                show={showInviteModal}
                setShow={setShowInviteModal}
                inviteCompanyName={inviteCompanyName}
            />
            <div className="text-xl font-body font-bold tracking-wide px-4 py-2">
                {t("profile.sidebar.information")}
            </div>
            <div className="grid grid-cols-2">
                <div className="flex justify-center text-base font-body tracking-wide px-4 py-2">
                    <p className="font-bold mr-2">{t("profile.information.email")}:</p>
                    {user.email}
                </div>
                <div className="flex justify-center text-base font-body tracking-wide px-4 py-2">
                    <p className="font-bold mr-2">{t("profile.information.role")}:</p>
                    {userRole()}
                </div>
            </div>
            <div className="text-base font-body tracking-wide px-4 mt-4">
                <p className="font-bold mr-2 border-b border-gray-500">
                    {t("profile.information.companies")}
                </p>
                {!companies && (
                    <p className="font-bold text-center mt-4">
                        {t("profile.information.noCompanies")}
                    </p>
                )}
                {companies?.map((company) => (
                    <div
                        key={company.id}
                        className="flex flex-row items-center justify-between mt-4"
                    >
                        <h3 className="text-lg">{company.company_name}</h3>
                        <Button
                            onClick={() => {
                                setInviteCompanyName(company.company_name)
                                setShowInviteModal(true)
                            }}
                        >
                            {t("profile.information.createInvite")}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Information
