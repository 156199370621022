// provider
import { useUser } from "providers/UserProvider"

// Base components
import Wrapper from "components/base/Wrapper"
import Paper from "components/base/Paper"

// Local Components
import Invites from "./components/Invites"
import Security from "./components/Security"
import Button, { ButtonVariants } from "components/base/Button"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useParams } from "react-router-dom"
import Information from "./components/Information"

enum ProfileVariants {
    information,
    invite,
    security,
    help,
}

const Profile = () => {
    const { t } = useTranslation()
    const { user } = useUser({ autoFetch: false })!
    const { variant } = useParams<{ variant: string; code: string }>()

    const getInitialVariant = (): ProfileVariants => {
        if (variant) {
            if (variant === "info") return ProfileVariants.information
            else if (variant === "invite") return ProfileVariants.invite
            else if (variant === "security") return ProfileVariants.security
        }

        return ProfileVariants.information
    }

    const [state, setState] = useState<ProfileVariants>(getInitialVariant())

    const renderSwitch = (param: ProfileVariants) => {
        switch (param) {
            case ProfileVariants.information:
                return <Information />
            case ProfileVariants.invite:
                return <Invites />
            case ProfileVariants.security:
                return <Security />
            default:
                return ""
        }
    }

    return (
        <div className="m-auto max-w-screen-lg justify-between flex items-center mt-6">
            <div className="m-auto w-full">
                <Paper className={"grid grid-cols-4 gap-2"}>
                    <div className="text-sm lg:flex-grow border-r-2 content-between">
                        <div className="text-lg py-4 px-2 font-body font-bold border-b-2">
                            {user?.email}
                        </div>
                        <Button
                            className={`w-full mt-2 mb-1 text-base text-black`}
                            onClick={() => setState(ProfileVariants.information)}
                            variant={ButtonVariants.text}
                        >
                            {t("profile.sidebar.information")}
                        </Button>

                        <Button
                            className={`w-full mt-2 mb-1 text-base text-black`}
                            onClick={() => setState(ProfileVariants.invite)}
                            variant={ButtonVariants.text}
                        >
                            {t("profile.sidebar.invites")}
                        </Button>
                        <Button
                            className={`w-full mt-2 mb-1 text-base text-black`}
                            onClick={() => setState(ProfileVariants.security)}
                            variant={ButtonVariants.text}
                        >
                            {t("profile.sidebar.security")}
                        </Button>
                    </div>
                    <div className={"col-span-3"}>{renderSwitch(state)}</div>
                </Paper>
            </div>
        </div>
    )
}

export default Profile
