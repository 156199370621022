const INPUT_CONSTANTS = {
    MIN_PASSWORD_LENGTH: 8,
    MAX_PASSWORD_LENGTH: 40,
    PASSWORD_SECURITY_REGEX: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
    MIN_NOTIFICATION_TITLE_LENGTH: 1,
    MAX_NOTIFICATION_TITLE_LENGTH: 65,
    MIN_NOTIFICATION_CONTENT_LENGTH: 1,
    MAX_NOTIFICATION_CONTENT_LENGTH: 240,
}

export default INPUT_CONSTANTS
