import { Link } from "react-router-dom"
import { Check, Edit, Delete, ContentCopy, Publish } from "@mui/icons-material"
import TemplateModel from "template/components"

import nature from "assets/nature.png"

// api
import { setApplicationTemplateCurrent, deleteApplicationTemplate } from "api/template"

enum TemplateCardActions {
    edit,
    current,
    delete,
    copy,
    publish,
}

interface TemplateCardOpts {
    isCurrent: boolean
    companyID: string
    template: TemplateModel
    bottomActions: TemplateCardActions[]

    deleteTemplate?: (templateID: string) => void
    publish?: (template: TemplateModel) => void
    copy?: (template: TemplateModel) => void
    setCurrent?: (companyID: string, templateID: string) => void
}

const TemplatePreviewCard = (opts: TemplateCardOpts) => {
    const mapAction = (action: TemplateCardActions) => {
        switch (action) {
            case TemplateCardActions.edit:
                return <EditButton key="edit_button" templateID={opts.template.id} />
            case TemplateCardActions.delete:
                return (
                    <DeleteButton
                        key="delete_button"
                        deleteTemplate={opts.deleteTemplate}
                        templateID={opts.template.id}
                    />
                )
            case TemplateCardActions.current:
                return (
                    <CurrentButton
                        key="current_button"
                        templateID={opts.template.id}
                        companyID={opts.companyID}
                        current={opts.isCurrent}
                        setCurrentTemplate={opts.setCurrent!}
                    />
                )
            case TemplateCardActions.publish:
                if (!opts.publish) {
                    console.error(
                        "[TemplatePreviewCard] requested publish button but no publish method provided"
                    )
                    return
                }
                return (
                    <PublishButton
                        key="publish_button"
                        templateID={opts.template.id}
                        publish={opts.publish}
                        template={opts.template}
                        isPublic={opts.template.public}
                    />
                )
            case TemplateCardActions.copy:
                if (!opts.copy) {
                    console.error(
                        "[TemplatePreviewCard] requested copy button but no copy method provided"
                    )
                    return
                }
                return (
                    <CopyButton
                        key="copy_button"
                        templateID={opts.template.id}
                        copy={opts.copy}
                        template={opts.template}
                    />
                )
        }
    }

    return (
        <div
            key={opts.template.id}
            className="bg-gray-200 h-60 rounded transition duration-200 shadow-sm hover:shadow-lg"
            style={{ minWidth: "fit-content " }}
        >
            <Link to={`/app/template/${opts.template.id}`} className="">
                <div className="h-5/6 w-full overflow-hidden">
                    <img alt="template" src={nature} className="w-full" />{" "}
                </div>
            </Link>

            <div className="h-1/6 rounded-b flex px-3 gap-2 text-white align-bottom bg-primary justify-center items-center min-w-8 min-w-8">
                {opts.bottomActions.map(mapAction)}
            </div>
        </div>
    )
}

const DeleteButton = ({
    templateID,
    deleteTemplate,
    isPublic,
}: {
    templateID: string
    deleteTemplate?: (templateID: string) => void
    isPublic?: boolean
}) => {
    if (isPublic) {
        // TODO(jorgenbele): add confirm button here
        return (
            <div
                title="Delete"
                className="rounded-full bg-white p-1 cursor-pointer text-button-error"
                onClick={() => deleteTemplate!(templateID)}
            >
                <Delete />
            </div>
        )
    }

    return (
        <div
            title="Delete"
            className="rounded-full bg-white text-primary p-1 cursor-pointer text-button-error"
            onClick={() => deleteTemplate!(templateID)}
        >
            <Delete />
        </div>
    )
}

const EditButton = ({ templateID }: { templateID: string }) => {
    return (
        <Link
            to={`/app/template/${templateID}`}
            title="Edit"
            className="rounded-full bg-white p-1 cursor-pointer text-primary"
        >
            <Edit />
        </Link>
    )
}

const CopyButton = ({
    templateID,
    copy,
    template,
}: {
    templateID: string
    copy: (template: TemplateModel) => void
    template: TemplateModel
}) => {
    return (
        <div
            onClick={() => {
                return copy(template)
            }}
            className="rounded-full bg-white p-1 cursor-pointer text-primary"
        >
            <ContentCopy />
        </div>
    )
}

const PublishButton = ({
    templateID,
    publish,
    template,
    isPublic,
}: {
    templateID: string
    publish: (template: TemplateModel) => void
    template: TemplateModel
    isPublic?: boolean
}) => {
    const textColor = isPublic ? "text-success" : "text-primary"
    const className = `rounded-full bg-white p-1 cursor-pointer ${textColor}`

    const onClick = isPublic ? undefined : () => publish(template)

    return (
        <div onClick={onClick} className={className}>
            <Publish />
        </div>
    )
}

/**
 * Check icon showing if the template is the currently enabled one
 * Updates the currently active template on click
 */
const CurrentButton = ({
    templateID,
    companyID,
    current,
    setCurrentTemplate,
}: {
    templateID: string
    companyID: string
    current: boolean
    setCurrentTemplate: (companyID: string, templateID: string) => void
}) => {
    if (current) {
        return (
            <div className="rounded-full bg-white p-1">
                <Check className="text-success" />
            </div>
        )
    } else {
        return (
            <div
                className="rounded-full bg-white p-1 cursor-pointer text-primary"
                onClick={() => setCurrentTemplate(companyID, templateID)}
            >
                <Check />
            </div>
        )
    }
}

export default TemplatePreviewCard
export { TemplateCardActions }
