import { ComponentModel, ConditionComponent } from "template/components"

import { FormEvent } from "react"

const ConditionEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ConditionComponent
    save: () => void
}) => {
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    return (
        <form onSubmit={submit}>
            <div className="grid grid-cols-2">
                <p>Switch condition</p>
                <input
                    type={"text"}
                    className="border-solid border-2 border-grey-400"
                    value={component.condition}
                    onChange={(e) => {
                        component.condition = e.target.value
                        save()
                    }}
                />
            </div>
        </form>
    )
}

export default ConditionEditor
