import React from "react"

type PropTypes = {
    className?: string
    children?: React.ReactNode
}

const Frame = React.forwardRef(({ className = "", children, ...rest }: PropTypes, ref) => {
    let baseStyles = "mb-10"

    return (
        <div
            className={`
            ${baseStyles} 
            ${className}`}
            {...rest}
        >
            {children}
        </div>
    )
})

export default Frame
