/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Login.module.css"
import { useState, useEffect, useMemo } from "react"
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import { Base64 } from "js-base64"

// API
import { googleAuth } from "api/auth"

// Components
import { useUser } from "providers/UserProvider"
import Button, { ButtonVariants } from "components/base/Button"
import LoginForm from "./components/LoginForm"
import RegisterForm from "./components/RegisterForm"
import Paper from "components/base/Paper"
import Popup, { PopupVariants } from "components/base/Popup"
import { Alert, AlertTitle, CircularProgress, AlertColor } from "@mui/material"

// translation
import { useTranslation } from "react-i18next"

interface loginNotice {
    severity: AlertColor
    message: string
    title: string
}

enum LoginViews {
    Register,
    Login,
}

const Login = () => {
    // Hooks
    const { t } = useTranslation()
    const location = useLocation()
    const queryParams = useQuery()
    const navigate = useNavigate()
    const [, setSearchParams] = useSearchParams()

    // form state
    const [errorLogin, setErrorLogin] = useState<boolean>(false)

    // page state
    const { user, userFetching, fetchUser } = useUser({ autoFetch: false })!
    const [loading, setLoading] = useState<boolean>(false)
    const [view, setView] = useState<LoginViews>(LoginViews.Login)
    const [enableRegistration, setEnableRegistration] = useState<boolean>(false)
    const [loginMessage, setLoginMessage] = useState<loginNotice | null>(null)

    // TODO: Display errors somewhere
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [failed, setFailed] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>(null)

    // Functions
    function useQuery() {
        const { search } = useLocation()
        return useMemo(() => new URLSearchParams(search), [search])
    }

    const loginErrorHandler = (error: string) => {
        setLoginMessage({
            severity: "error",
            title: "login.error.loginFailed",
            message: "login.error.wrongUsernameOrPassword",
        })
    }

    // This is where most of the action occurs
    useEffect(() => {
        // for some reason queryParams may not be fully loaded when useEffect starts so we wait for it
        // to match the window location. Could use window location instead, but you know.... react and
        // stuff
        if (window.location.search !== location.search) return
        setLoading(true)
        setLoginMessage(null)

        let redirect = queryParams.get("redirect") === "true"

        if (redirect) {
            let redirectState = getRedirectState()
            let redirectLocation = getRedirectLocation(redirectState)

            if (user === undefined) {
                setEnableRegistration(false)
                console.log("no user, redirectState = ", redirectState)
                switch (redirectState) {
                    case "google_oauth":
                        googleOauthRedirect()
                            .then((location: string) => {
                                fetchUser().then(() => {
                                    navigate(location)
                                })
                            })
                            .catch((err) => {
                                console.error(t("login.error.failedGoogleLogin", { error: err }))
                            })
                        break
                    case "accept_invite":
                        setLoginMessage({
                            severity: "info",
                            message: "login.loginOrRegisterToAcceptInvitation",
                            title: "",
                        })
                        setEnableRegistration(true)
                        break
                    case "signout":
                        setLoginMessage({
                            severity: "success",
                            message: "Signed Out",
                            title: "",
                        })
                        break
                    case "oauth_failed": {
                        let reason = (JSON.parse(Base64.decode(queryParams.get("state")!)) as any)[
                            "message"
                        ]
                        setLoginMessage({
                            severity: "error",
                            message: reason,
                            title: "login.error.loginFailed",
                        })
                        break
                    }
                    case "error": {
                        let state = JSON.parse(Base64.decode(queryParams.get("state")!))
                        setLoginMessage({
                            severity: "error",
                            message: state.message,
                            title: state.title,
                        })
                        break
                    }
                    default:
                        setLoginMessage({
                            severity: "error",
                            message: "login.error.unknownRedirectState",
                            title: "",
                        })
                        break
                }
                setLoading(false)
            } else {
                //user is defined, just redirect
                switch (redirectState) {
                    case "accept_invite":
                        navigate(redirectLocation)
                        break
                    case "google_oauth":
                        navigate(redirectLocation)
                        break
                    case "signout":
                        // if the user is defined and state is signedout then it's either an error
                        // or the user signed out and then back in, follow default login flow
                        navigate("/dashboard")
                        break
                    default:
                        setLoginMessage({
                            severity: "error",
                            message: "login.error.unknownRedirectState",
                            title: "",
                        })
                        break
                }
                setLoading(false)
            }
        } else {
            // no redirect specified, if the user is signedin move them to dashboard
            if (user !== undefined) {
                setLoading(false)
                navigate("/dashboard")
            } else {
                setLoading(false)
            }
        }
    }, [user, navigate, location.search, queryParams])

    const inviteCodeFromAuthState = (state: any): string => {
        if (state.hasOwnProperty("came_from")) {
            const cameFrom = state["came_from"] as string
            if (cameFrom.match(/^\/user\/invite\/accept/)) {
                return cameFrom.substring(cameFrom.lastIndexOf("/") + 1)
            }
        }
        return ""
    }

    const googleOauthRedirect = async (): Promise<string> => {
        // let authError = queryParams.get("error")
        let authCode = queryParams.get("code")
        let stateb64 = queryParams.get("state")

        const inviteCode = inviteCodeFromAuthState(JSON.parse(Base64.decode(stateb64 || "")))
        let location: string
        if (authCode != null) {
            let state: any
            if (stateb64 != null) {
                let statejson = Base64.decode(stateb64)
                state = JSON.parse(statejson)
                if (state.came_from !== "") {
                    location = state.came_from
                }
            }

            return googleAuth(authCode, inviteCode)
                .then((res) => {
                    // set came_from query param for redirectLocation()
                    return Promise.resolve(location)
                })
                .catch((err) => {
                    queryParams.delete("code")
                    queryParams.delete("came_from")
                    queryParams.delete("redirect_state")
                    queryParams.delete("scope")
                    queryParams.delete("hd")
                    queryParams.delete("authuser")
                    queryParams.delete("prompt")
                    queryParams.set("came_from", state.came_from)
                    // queryParams.set("redirect_state", state.redirect_state)
                    queryParams.set("redirect_state", "oauth_failed")
                    queryParams.set(
                        "state",
                        Base64.encodeURL(
                            JSON.stringify({
                                came_from: state.came_from,
                                message: err["raw"]["message"],
                            })
                        )
                    )
                    setSearchParams(queryParams)

                    setFailed(true)
                    setError(err["raw"]["message"])

                    return Promise.reject(err.raw.message)
                })
        }
        return Promise.reject("missing oauth state")
    }

    const getRedirectState = (): string => {
        let redirectState = queryParams.get("redirect_state")
        if (redirectState === null) {
            console.warn("redirected without state")
            redirectState = ""
        }
        return redirectState
    }

    const getRedirectLocation = (redirectState: string | null): string => {
        if (redirectState === null) {
            redirectState = getRedirectState()
        }

        let redirectLocation
        switch (redirectState) {
            case "google_oauth":
                let stateb64 = queryParams.get("state")
                if (stateb64 != null) {
                    let statejson = Base64.decode(stateb64)
                    let state = JSON.parse(statejson)
                    if (state.came_from !== "") {
                        redirectLocation = state.came_from
                    }
                }
                break
            default:
                redirectLocation = queryParams.get("came_from")
                break
        }

        if (redirectLocation === null || redirectLocation === "") {
            redirectLocation = "/dashboard"
        }

        return redirectLocation
    }

    const renderView = () => {
        let comp = null
        switch (view) {
            case LoginViews.Login:
                comp = <LoginForm onLoginError={loginErrorHandler} />
                break
            case LoginViews.Register:
                comp = <RegisterForm />
                break
        }
        if (enableRegistration) {
            return (
                <div>
                    {comp}
                    {view === LoginViews.Login ? (
                        <Button
                            variant={ButtonVariants.text}
                            className="w-full justify-self-end mt-2"
                            onClick={(_e: any) => {
                                setView(LoginViews.Register)
                            }}
                        >
                            {t("login.noUserSignUp")}
                        </Button>
                    ) : (
                        <Button
                            variant={ButtonVariants.text}
                            className="w-full justify-self-end mt-2"
                            onClick={(_e: any) => {
                                setView(LoginViews.Login)
                            }}
                        >
                            {t("login.alreadyHaveUser")}
                        </Button>
                    )}
                </div>
            )
        }
        return comp
    }

    return (
        <div>
            <Popup
                open={errorLogin}
                setOpen={setErrorLogin}
                variant={PopupVariants.error}
                onConfirm={() => setErrorLogin(false)}
                title={t("login.error.loginFailed")}
                content={t("login.error.wrongUsernameOrPassword")}
                cofirmationsButtonText={t("common.continue")}
            />

            <Paper
                className={`m-auto my-40 w-1/3 flex items-center justify-center ${styles.paper}`}
            >
                {loading || userFetching ? (
                    <div>
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        {loginMessage && (
                            <Alert severity={loginMessage.severity}>
                                <AlertTitle>{t(loginMessage.title)}</AlertTitle>
                                {t(loginMessage.message)}
                            </Alert>
                        )}
                        <div>{renderView()}</div>
                    </div>
                )}
            </Paper>
        </div>
    )
}

export default Login
