import { ComponentModel, NewDefaultComponent } from "template/components"

// const providerbase = "http://192.168.38.122:5000/client/companies/vipir_test"

const removeComponent = (component: ComponentModel, index: number) => {
    component.components?.splice(index, 1)
}

const addComponent = (component: ComponentModel, componentType: string) => {
    let components = component.components ?? []
    let newComp = NewDefaultComponent(componentType)
    if (newComp !== undefined) {
        components.push(newComp)
    } else {
        console.error("unsupported component type")
    }
    component.components = components
}

export { addComponent, removeComponent }
