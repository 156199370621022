import React from "react"
import AppTheme from "./AppTheme/src/AppTheme"

import { useEffect, useCallback, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useAlertContext } from "providers/AlertProvider"

import CONSTS from "constants/CONSTS"

// Services
import { useCompanies } from "providers/company/CompaniesProvider"
import { uploadImage as apiUploadImage, UploadImageResponse } from "api/image"

// Template
import { getApplicationTemplateCurrent, updateApplicationTemplate } from "api/template"
import Template, { ComponentModel } from "template/components"

// Components
import LoadingCover from "components/base/LoadingCover/LoadingCover"
import Paper from "components/base/Paper"
import Frame from "components/base/Frame"

// Helpers
import { exportJSON } from "helper/export"

const AppDesigner = () => {
    const { currentCompany } = useCompanies({ autoFetch: false })!
    const { t } = useTranslation()

    const alert = useAlertContext()

    const [template, setTemplate] = useState<Template | null>(null)
    const [templateLoading, setTemplateLoading] = useState(false)

    useEffect(() => {
        if (!currentCompany) {
            return
        }
        setTemplateLoading(true)
        getApplicationTemplateCurrent(currentCompany?.id!)
            .then((res) => {
                let template = res as Template
                setTemplate(template)
            })
            .finally(() => {
                setTemplateLoading(false)
            })
    }, [currentCompany])

    /**
     * If the [url] follows the memory scheme the image is uploaded from [memoryImages] and
     * the url is replaced with the storage URL.
     *
     * used primarily as the [mod] for findValueMatch
     */
    const uploadAndReplaceMemoryImages = async (
        url: string,
        images: { [index: string]: any }
    ): Promise<string> => {
        const exec = /^mem:\/\/\/template\/images\/([A-Za-z0-9.]+)/.exec(url)
        if (!exec || exec.length < 2) {
            return Promise.reject(`${url} fit mem:// scheme but not app memory image scheme`)
        }
        let name = exec[1]
        if (!images[name]) {
            return Promise.reject(`image ${name} not in memoryImages`)
        }

        return await apiUploadImage(images[name])
            .then((res) => {
                return Promise.resolve((res as UploadImageResponse).med)
            })
            .catch((err) => {
                return Promise.reject(`image upload failed: ${err}`)
            })
    }
    // modify object in-place
    const findValueMatch = async (
        obj: { [index: string]: any },
        match: RegExp,
        modifier: (key: string, values: {}) => Promise<string>,
        values: {}
    ): Promise<{ [index: string]: any }> => {
        for (const key in obj) {
            const value = obj[key]

            if (typeof value === "string") {
                if (match.test(value)) {
                    obj[key] = await modifier(value, values)
                }
            } else if (value instanceof Object) {
                obj[key] = await findValueMatch(value, match, modifier, values)
            }
        }
        return obj
    }

    const onsubmit = async (template: Template, memoryImages: { [index: string]: File }) => {
        findValueMatch(template.root, /^mem:\/\//, uploadAndReplaceMemoryImages, memoryImages)
            .then(async (templateRoot) => {
                template.root = templateRoot as ComponentModel
                // exportJSON(template, "defaultTemplate.json") // Export template for landing page
                return await updateApplicationTemplate(template)
                    .then((res) => {
                        alert.setAlert({
                            alertMessage: t("appTheme.templateUpdated"),
                            alertSeverity: "success",
                        })
                    })
                    .catch((err) => {
                        alert.setAlert({
                            alertMessage: t("notification.error.templateUpdated", { error: err }),
                            alertSeverity: "error",
                        })
                    })
            })
            .catch((err) => {
                console.error("failed to update template: ", err)
            })
    }

    if (!currentCompany || templateLoading || !template) {
        return <LoadingCover />
    }
    return (
        <Frame>
            <div className="m-auto max-w-screen-xl my-6">
                <Paper>
                    <AppTheme template={template} onsubmit={onsubmit} company={currentCompany} />
                </Paper>
            </div>
        </Frame>
    )
}

export default AppDesigner
