import { useState } from "react"
import { Collapse } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Position } from "../structs"

const PositionEditor = ({ position, save }: { position: Position; save: () => void }) => {
    const [show, setShow] = useState<boolean>(false)
    return (
        <div>
            <div
                className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                onClick={() => setShow(!show)}
            >
                <p className="m-2">Position: </p>
                <div className="flex-grow" />
                <div className="m-3">{show ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
            <Collapse in={show}>
                <div className="grid grid-cols-3">
                    <p>top:</p>
                    <input
                        type={"checkbox"}
                        checked={position.top != null}
                        onChange={(e) => {
                            if (e.target.checked) {
                                position.top = 0
                            } else {
                                position.top = null
                            }
                            save()
                        }}
                    />
                    {position.top == null ? (
                        <div></div>
                    ) : (
                        <input
                            type={"number"}
                            className="border-solid border-2 border-grey-400"
                            value={position.top}
                            onChange={(e) => {
                                if (position == null) {
                                    return
                                }
                                position.top = parseFloat(e.target.value)
                                save()
                            }}
                        />
                    )}
                    <p>right:</p>
                    <input
                        type={"checkbox"}
                        checked={position.right != null}
                        onChange={(e) => {
                            if (e.target.checked) {
                                position.right = 0
                            } else {
                                position.right = null
                            }
                            save()
                        }}
                    />
                    {position.right == null ? (
                        <div></div>
                    ) : (
                        <input
                            type={"number"}
                            className="border-solid border-2 border-grey-400"
                            value={position.right}
                            onChange={(e) => {
                                if (position == null) {
                                    return
                                }
                                position.right = parseInt(e.target.value)
                                save()
                            }}
                        />
                    )}
                    <p>bottom:</p>
                    <input
                        type={"checkbox"}
                        checked={position.bottom != null}
                        onChange={(e) => {
                            if (e.target.checked) {
                                position.bottom = 0
                            } else {
                                position.bottom = null
                            }
                            save()
                        }}
                    />
                    {position.bottom == null ? (
                        <div></div>
                    ) : (
                        <input
                            type={"number"}
                            className="border-solid border-2 border-grey-400"
                            value={position.bottom}
                            onChange={(e) => {
                                if (position == null) {
                                    return
                                }
                                position.bottom = parseInt(e.target.value)
                                save()
                            }}
                        />
                    )}

                    <p>left:</p>
                    <input
                        type={"checkbox"}
                        checked={position.left != null}
                        onChange={(e) => {
                            if (e.target.checked) {
                                position.left = 0
                            } else {
                                position.left = null
                            }
                            save()
                        }}
                    />
                    {position.left == null ? (
                        <div></div>
                    ) : (
                        <input
                            type={"number"}
                            className="border-solid border-2 border-grey-400"
                            value={position.left}
                            onChange={(e) => {
                                if (position == null) {
                                    return
                                }
                                position.left = parseInt(e.target.value)
                                save()
                            }}
                        />
                    )}
                </div>
            </Collapse>
        </div>
    )
}

export default PositionEditor
