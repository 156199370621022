import { validate, ValidationConstraint, ModelValidateResult } from "./helpers"
import { ScaledImage } from "./ScaledImage"
import { ContentState } from "./ContentState"

export interface OfferModel {
    id?: string
    company_name: string

    title: string
    content: string

    images: ScaledImage[]

    expiration?: Date | null
    state: ContentState
    categories: string[]

    display_price?: boolean
    display_discount?: boolean
    display_new_price?: boolean

    price: number
    discount: number
    new_price: number
}

// response from backend
export interface OfferRespModel extends OfferModel {
    id: string
}

const offerConstraints: ValidationConstraint[] = [
    { field: "company_name", type: "string" },
    { field: "title", type: "string" },
    { field: "content", type: "string" },
    { field: "images", type: "array" },
    { field: "expiration", type: "ISOdate", optional: true },
    { field: "state", type: "string" },
    { field: "display_price", type: "boolean", optional: true },
    { field: "display_discount", type: "boolean", optional: true },
    { field: "display_new_price", type: "boolean", optional: true },
]

const offerRespConstraints: ValidationConstraint[] = [
    ...offerConstraints,
    { field: "id", type: "string" },
]

const ValidateOfferModel = (n: any): ModelValidateResult<OfferModel> => {
    return validate<OfferModel>(n, offerConstraints)
}
const ValidateOfferRespModel = (n: any): ModelValidateResult<OfferRespModel> => {
    return validate<OfferRespModel>(n, offerRespConstraints)
}

export { ValidateOfferModel, ValidateOfferRespModel }
