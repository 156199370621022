import style from "../CreationModal.module.css"

// Providers
import { useTranslation } from "react-i18next"

// Components
import Paper from "components/base/Paper/Paper"
import TagPicker from "components/base/TagPicker"
import Input from "components/base/Input"
import Form from "components/base/Form"
import TextArea from "components/base/TextArea"

// Models
import { NewsletterModel } from "models/Newsletter"
import ToolTip from "components/base/ToolTip"

type PropTypes = {
    newsletter: NewsletterModel
    onTitleChange?: (title: string) => void
    onContentChange?: (content: string) => void
    onCategoriesChange?: (category: any) => void
}

const NewsletterForm = ({
    newsletter,
    onTitleChange = () => {},
    onContentChange = () => {},
    onCategoriesChange = () => {},
}: PropTypes) => {
    const { t } = useTranslation()

    return (
        <Paper className="col-span-2">
            <Form>
                <div className="grid grid-cols-2 gap-2 flex-grow">
                    <div className="text-xl font-body">{t("common.content")}</div>
                    <div />
                    <div>
                        <Input
                            label={t("common.title")}
                            tooltip={t("post.tooltip.title")}
                            name="post:title"
                            value={newsletter?.title || ""}
                            onChange={(e) => onTitleChange(e.target.value)}
                            className={`border-hoverDark border-solid border rounded-md mt-1 w-11/12`}
                        />
                    </div>
                    <div>
                        <label>
                            <div className="flex justify-between">
                                <p className={`font-body text-sm ml-1`}>{t("common.category")}</p>
                                <ToolTip content={t("post.tooltip.category")} />
                            </div>
                            <TagPicker
                                value={newsletter?.categories || []}
                                categories={["Klær", "Elektronikk", "Planter"]}
                                onCategoriesChange={onCategoriesChange}
                            />
                        </label>
                    </div>
                    <div className={`col-span-2`}>
                        <TextArea
                            name="post:content"
                            label={t("common.description")}
                            tooltip={t("post.tooltip.description")}
                            value={newsletter?.content || ""}
                            onChange={(e) => onContentChange(e.target.value)}
                            className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                        />
                    </div>
                </div>
            </Form>
        </Paper>
    )
}

export default NewsletterForm
