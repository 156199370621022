import { ButtonComponent, ComponentModel } from "template/components"
import ActionEditor from "./ActionEditor"
import ColorPicker from "components/ColorPicker"

import { Collapse } from "@mui/material"
import { useState } from "react"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import PositionEditor from "./Position"
import { RGBColor } from "react-color"
import { rgbColorToStrictRGB } from "helper/colorConversions"

const ButtonEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ButtonComponent
    save: () => void
}) => {
    const [showBGColor, setShowBGColor] = useState<boolean>(false)
    const [showFGColor, setShowFGColor] = useState<boolean>(false)
    return (
        <form>
            <p>Text</p>
            <input
                type={"text"}
                className="border-solid border-2 border-grey-400"
                value={component.text}
                onChange={(e) => {
                    component.text = e.target.value
                    save()
                }}
            />
            <div>
                <div
                    className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                    onClick={() => setShowFGColor(!showFGColor)}
                >
                    <p className="m-2">Foreground color: </p>
                    <div className="flex-grow" />
                    <div className="m-3">{showFGColor ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
                {component.foreground_color === null ? (
                    <div></div>
                ) : (
                    <Collapse in={showFGColor}>
                        <ColorPicker
                            activeValue={component.foreground_color}
                            onChange={(color: RGBColor) => {
                                component.foreground_color = rgbColorToStrictRGB(color)
                                save()
                            }}
                        />
                    </Collapse>
                )}
            </div>

            <div>
                <div
                    className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                    onClick={() => setShowBGColor(!showBGColor)}
                >
                    <p className="m-2">Background color: </p>
                    <div className="flex-grow" />
                    <div className="m-3">{showBGColor ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
                {component.background_color === null ? (
                    <div></div>
                ) : (
                    <Collapse in={showBGColor}>
                        <ColorPicker
                            activeValue={component.background_color}
                            onChange={(color) => {
                                component.background_color = rgbColorToStrictRGB(color)
                                save()
                            }}
                        />
                    </Collapse>
                )}
            </div>

            <PositionEditor position={component.position} save={save} />

            <p>On press</p>
            <ActionEditor action={component.pressed} save={save} />
        </form>
    )
}

export default ButtonEditor
