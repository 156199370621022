// import BaseForm, { NotificationFormVariants } from "components/Notifications/BaseForm"
import Paper from "components/base/Paper"

import NotificationCreate from "./components/Create"

const CreateNotifications = () => {
    return (
        <div className={"flex justify-center mt-10"}>
            <Paper className={"lg:w-1/2 md:w-2/3 sm:w-full"}>
                {/* <BaseForm variant={NotificationFormVariants.create} /> */}
                <NotificationCreate />
            </Paper>
        </div>
    )
}

export default CreateNotifications
