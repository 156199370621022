import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { convertRGBToHex } from "helper/colorConversions"
import { SketchPicker, ColorResult, RGBColor } from "react-color"

type ThemeRef = {
    getTheme: () => { [index: string]: any }
} | null

type ThemeProps = {
    isDemo?: boolean
    theme: { [index: string]: any }
    onChange: (theme: { [index: string]: any }) => void
}

export enum ThemeColorType {
    Background = "background",
    Primary = "primary",
    Secondary = "secondary",
    Tertiary = "tertiary",
}

const ColorTheme = forwardRef<ThemeRef, ThemeProps>(({ isDemo = false, theme, onChange }, ref) => {
    const { t } = useTranslation()

    // State
    const [background, setBackground] = useState<RGBColor>(
        theme["background"] ?? { r: 0, g: 0, b: 0, a: 0 }
    )
    const [primary, setPrimary] = useState<RGBColor>(theme["primary"] ?? { r: 0, g: 0, b: 0, a: 0 })
    const [secondary, setSecondary] = useState<RGBColor>(
        theme["secondary"] ?? { r: 0, g: 0, b: 0, a: 0 }
    )
    const [tertiary, setTertiary] = useState<RGBColor>(
        theme["tertiary"] ?? { r: 0, g: 0, b: 0, a: 0 }
    )

    const [selectedThemeType, setSelectedThemeType] = useState<ThemeColorType>(
        ThemeColorType.Background
    )

    const selectedColorGetter = {
        [ThemeColorType.Background]: background,
        [ThemeColorType.Primary]: primary,
        [ThemeColorType.Secondary]: secondary,
        [ThemeColorType.Tertiary]: tertiary,
    }

    const selectedColorSetter = {
        [ThemeColorType.Background]: setBackground,
        [ThemeColorType.Primary]: setPrimary,
        [ThemeColorType.Secondary]: setSecondary,
        [ThemeColorType.Tertiary]: setTertiary,
    }

    // Methods
    const setSelectedColor = (color: RGBColor) => {
        const setter = selectedColorSetter[selectedThemeType]
        if (!setter) return
        setter(color)
    }

    const getTheme = () => {
        return {
            type: "custom",
            background: background,
            primary: primary,
            secondary: secondary,
            tertiary: tertiary,
        }
    }

    useImperativeHandle(ref, () => ({
        getTheme,
    }))

    useEffect(() => {
        onChange(getTheme())
    }, [background, primary, secondary, tertiary])

    return (
        <div className="flex flex-col items-center mt-4">
            <p className="w-full text-lg text-primary">Choose colors:</p>
            <div className={"w-full grid grid-cols-4 gap-2"}>
                <ColorDisplay
                    activeValue={background}
                    onClick={() => setSelectedThemeType(ThemeColorType.Background)}
                    isActive={selectedThemeType === ThemeColorType.Background}
                    text={t("appTheme.background")}
                />

                <ColorDisplay
                    activeValue={primary}
                    onClick={() => setSelectedThemeType(ThemeColorType.Primary)}
                    isActive={selectedThemeType === ThemeColorType.Primary}
                    text={t("appTheme.primary")}
                />

                <ColorDisplay
                    activeValue={secondary}
                    onClick={() => setSelectedThemeType(ThemeColorType.Secondary)}
                    isActive={selectedThemeType === ThemeColorType.Secondary}
                    text={t("appTheme.secondary")}
                />

                <ColorDisplay
                    activeValue={tertiary}
                    onClick={() => setSelectedThemeType(ThemeColorType.Tertiary)}
                    isActive={selectedThemeType === ThemeColorType.Tertiary}
                    text={t("appTheme.tertiary")}
                />
            </div>
            <p className="w-full mb-4 mt-2 text-sm">
                {isDemo
                    ? "Mark the color you wish to change before you click the color picker"
                    : t("appTheme.markTheColor")}
            </p>
            <SketchPicker
                styles={{
                    default: { picker: { boxShadow: "none", width: "100%", maxWidth: "80%" } },
                }}
                color={selectedColorGetter[selectedThemeType]}
                onChange={(value: ColorResult) => {
                    setSelectedColor(value.rgb)
                }}
                onChangeComplete={(value: ColorResult) => {
                    setSelectedColor(value.rgb)
                }}
            />
        </div>
    )
})

const ColorDisplay = ({
    text,
    activeValue,
    isActive,
    onClick = () => {},
}: {
    text: string
    activeValue: RGBColor
    isActive: boolean
    onClick?: () => void
}) => {
    return (
        <div className={"cursor-pointer"} onClick={onClick}>
            <div className="w-full flex flex-col items-center my-4">
                <p className="text-primary">{text}</p>
                <div className="relative mt-2 p-2">
                    {isActive && (
                        <div className="absolute top-0 left-0 h-full w-full border-4 border-primary" />
                    )}
                    <div
                        className={"h-12 w-12 border border-gray-200"}
                        style={{ background: convertRGBToHex(activeValue) }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ColorTheme
export { type ThemeRef }
