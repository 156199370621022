import Paper from "@mui/material/Paper"
import MenuList from "@mui/material/MenuList"
import MenuItem from "@mui/material/MenuItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import ContentCut from "@mui/icons-material/ContentCut"
import ContentClear from "@mui/icons-material/Clear"
import ContentCopy from "@mui/icons-material/ContentCopy"
import ContentPaste from "@mui/icons-material/ContentPaste"

import { useTranslation } from "react-i18next"

const CopyCutMenu = ({
    currentAction,
    isSelected,
    onCopy,
    onCut,
    onPaste,
    onCancel,
}: {
    isSelected: boolean
    currentAction: "cut" | "copy" | "none"
    onCopy: () => void
    onCut: () => void
    onPaste: () => void
    onCancel: () => void
}) => {
    const { t } = useTranslation()

    return (
        <Paper sx={{ width: 320, maxWidth: "100%" }}>
            <MenuList>
                <MenuItem disabled={currentAction === "none"} onClick={onCancel}>
                    <ListItemIcon>
                        <ContentClear fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("templates.copyCutPasteActions.cancel")}</ListItemText>
                </MenuItem>
                <MenuItem disabled={currentAction !== "none" || isSelected} onClick={onCut}>
                    <ListItemIcon>
                        <ContentCut fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("templates.copyCutPasteActions.cut")}</ListItemText>
                </MenuItem>
                <MenuItem disabled={currentAction !== "none" || isSelected} onClick={onCopy}>
                    <ListItemIcon>
                        <ContentCopy fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("templates.copyCutPasteActions.copy")}</ListItemText>
                </MenuItem>
                <MenuItem disabled={isSelected} onClick={onPaste}>
                    <ListItemIcon>
                        <ContentPaste fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("templates.copyCutPasteActions.paste")}</ListItemText>
                </MenuItem>
            </MenuList>
        </Paper>
    )
}

export default CopyCutMenu
