import ProviderConfig, { DefaultProviderConfig } from "./model"

import Button, { ButtonVariants } from "components/base/Button"
import { Delete, ExpandLess, ExpandMore } from "@mui/icons-material"
import { useState } from "react"
import { Collapse } from "@mui/material"

const ProviderEditor = ({ provider, save }: { provider: ProviderConfig; save: () => void }) => {
    return (
        <div className={"py-4"}>
            <form>
                <div>
                    <label className="form-label inline-block mb-2 text-gray-700">
                        Provider Name
                    </label>
                    <input
                        type={"text"}
                        className="form-control
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
                        placeholder="Provider Name"
                        value={provider.name as string}
                        onChange={(e) => {
                            provider.name = e.target.value
                            save()
                        }}
                    />

                    <label className="form-label inline-block mb-2 text-gray-700">Endpoint</label>
                    <input
                        type={"text"}
                        placeholder="Endpoint"
                        className="form-control
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
                        value={provider.url as string}
                        onChange={(e) => {
                            provider.url = e.target.value
                            save()
                        }}
                    />

                    <div className="form-group form-check text-left">
                        <label className={"form-check-label inline-block text-gray-800"}>
                            Multi
                        </label>
                        <input
                            type={"checkbox"}
                            className="border-solid border-grey-400 h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                            checked={provider.multi}
                            onChange={(e) => {
                                provider.multi = e.target.checked
                                save()
                            }}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

const ProvidersEditor = ({
    providers,
    save,
}: {
    providers: Array<ProviderConfig>
    save: () => void
}) => {
    const [show, setShow] = useState(false)
    return (
        <div>
            <div
                className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                onClick={() => setShow(!show)}
            >
                <p className="m-2 text-lg text-primary">Edit Providers</p>
                <div className="flex-grow" />
                <div className="m-3">{show ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
            <Collapse in={show}>
                {providers.map((provider) => (
                    <div
                        className="
                      p-1 mb-2
                      border border-solid border-gray-300
                      hover:bg-gray-200 transition duration-200"
                    >
                        <ProviderEditor provider={provider} save={save} />
                        <Button
                            variant={ButtonVariants.danger}
                            onClick={() => {
                                providers.splice(providers.indexOf(provider), 1)
                                save()
                            }}
                        >
                            <Delete />
                        </Button>
                    </div>
                ))}
                <Button
                    onClick={() => {
                        providers.push(DefaultProviderConfig())
                        save()
                    }}
                >
                    Add Provider
                </Button>
            </Collapse>
        </div>
    )
}

export default ProviderEditor
export { ProvidersEditor }
