import { ComponentModel } from "template/components"

import Button, { ButtonVariants } from "components/base/Button"
import { Delete } from "@mui/icons-material"
import { removeComponent } from "../utils"

const RemoveComponent = ({
    parent,
    component,
    index,
    save,
}: {
    parent: ComponentModel
    component: ComponentModel
    index: number
    save: () => void
}) => {
    return (
        <Button
            disabled={parent === component ? true : false}
            variant={ButtonVariants.danger}
            onClick={() => {
                removeComponent(parent, index)
                save()
            }}
        >
            <Delete />
        </Button>
    )
}

export default RemoveComponent
