/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import CheckIcon from "@mui/icons-material/Check"
import Tooltip from "@mui/material/Tooltip"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

enum ToolTipVariants {
    info,
    error,
}

type PropTypes = {
    content: string
    className?: string
    cancelButtonText?: string
    cofirmationsButtonText?: string
    variant?: ToolTipVariants
}

const ToolTip = ({
    className = "",
    variant,
    content,
    cancelButtonText,
    cofirmationsButtonText,
}: PropTypes) => {
    let Icon = () => {
        return (
            <div>
                <HelpOutlineIcon fontSize="small" color="inherit" />
            </div>
        )
    }

    switch (variant) {
        case ToolTipVariants.info: {
            break
        }
        case ToolTipVariants.error: {
            Icon = () => {
                return (
                    <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10`}
                    >
                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                )
            }
            break
        }
    }

    return (
        <div className={`text-gray-400 hover:text-primary cursor-pointer`}>
            <Tooltip
                title={content ? content : ""}
                followCursor
                placement={"top"}
                className={`${className}`}
            >
                {Icon()}
            </Tooltip>
        </div>
    )
}

export default ToolTip
export { ToolTipVariants }
