import axios, { AxiosError, AxiosResponse } from "axios"

import CONSTS from "constants/CONSTS"

import {
    UserLoginModel,
    UserChangePasswordModel,
    ValidateUserChangePasswordModel,
} from "models/User.d"
import { unwrapAxiosError, IAPIError, defaultHeaders } from "api/helpers"

const enum HTTP_METHOD {
    Post = "POST",
    Get = "GET",
    Put = "PUT",
}

const signin = async (credentials: UserLoginModel): Promise<string | AxiosError> => {
    return axios({
        method: HTTP_METHOD.Post,
        url: "/signin",
        baseURL: CONSTS.BACKEND_BASE_URL,
        data: credentials,
        validateStatus: (status) => {
            return status === 200
        },
        headers: defaultHeaders(true),
        withCredentials: true,
    })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
        .then((res: AxiosResponse) => {
            return res.data.token
        })
}

const signup = async (
    credentials: UserLoginModel,
    inviteCode: string
): Promise<string | IAPIError> => {
    return axios({
        method: HTTP_METHOD.Post,
        url: "/v1/user/register",
        baseURL: CONSTS.BACKEND_HOST,
        data: {
            credentials: credentials,
            invite: inviteCode,
        },
        validateStatus: (status) => {
            return status === 201
        },
        headers: defaultHeaders(true),
        withCredentials: true,
    })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
        .then((res: AxiosResponse) => {
            return res.data
        })
}

const checkToken = async (): Promise<string | AxiosError> => {
    return axios({
        method: HTTP_METHOD.Get,
        url: "/verifytoken",
        baseURL: CONSTS.BACKEND_BASE_URL,
        validateStatus: (status) => {
            return status === 200
        },
        headers: defaultHeaders(true),
        withCredentials: true,
    }).then((res) => {
        return res.data.token
    })
}

// endpoint to update the usertoken to contain company selected.
// can be expanded to include other claims.
const updateTokenToCompanyToken = async (companyName: string | undefined): Promise<string> => {
    return axios({
        method: HTTP_METHOD.Put,
        url: `/modifytoken?company=${companyName ?? ""}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        validateStatus: (status) => status === 200,
        headers: defaultHeaders(true),
        withCredentials: true,
    }).then((res) => res.data.token)
}

const signout = async () => {
    localStorage.removeItem("userToken")
    return true
}

// @NOTE - not sure if this is all the funcitonality that is needed for this
const changePassword = (credentials: UserChangePasswordModel): Promise<void | IAPIError> => {
    return new Promise<void | IAPIError>((resolve, reject) => {
        let valid = ValidateUserChangePasswordModel(credentials)

        if (true !== valid.ok) {
            return reject({
                raisedBy: "changePassword",
                error: `The ${valid.field} of is invalid`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            })
        }

        return axios({
            method: HTTP_METHOD.Post,
            url: "/user/changePassword", // @TODO - this should be moved to its own struct
            baseURL: CONSTS.BACKEND_BASE_URL,
            data: credentials,
            withCredentials: true,
            headers: defaultHeaders(true),
            validateStatus: (status: number) => {
                return status === 200
            },
        })
            .then(() => resolve())
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

export { signin, checkToken, signout, changePassword, signup, updateTokenToCompanyToken }
