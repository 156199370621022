import Input from "components/base/Input/Input"
import Button, { ButtonVariants } from "components/base/Button/Button"
import Paper, { PaperVariants } from "components/base/Paper"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { CRMModel, Stage1Model } from "models/CRM"
import ImageUpload from "components/base/ImageUpload"
import { ArrowBack } from "@mui/icons-material"

const Stage2 = ({
    customerRelation,
    setCustomerRelation,
    update,
    goBack,
}: {
    customerRelation: CRMModel
    setCustomerRelation: Function
    update: Function
    goBack?: Function
}) => {
    const { t } = useTranslation()

    const [showWebViewInputs, setShowWebViewInputs] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [successMessage, setSuccessMessage] = useState<string>("")

    const save = () => {
        update(customerRelation)
    }

    const submit = () => {
        if (!validate()) return
        let updated: CRMModel = {
            ...customerRelation,
            stage: customerRelation.stage,
        }

        update(updated)
    }

    const validate = () => {
        setSuccessMessage("")

        if (empty(customerRelation.stage_1?.app_name)) {
            setErrorMessage("App name cannot be empty.")
            return false
        }

        return true
    }

    const empty = (value: string | undefined) => {
        return value === null || value === "" || value === undefined
    }

    return (
        <form
            className="m-auto max-w-screen-lg "
            onSubmit={() => {
                return false
            }}
        >
            <div className="mb-8">
                <div>
                    <div>
                        <div>
                            <div className="flex items-center">
                                {goBack && (
                                    <div
                                        className={
                                            "hover:bg-hoverDark cursor-pointer h-12 w-12 rounded-xl box-border flex items-center justify-center"
                                        }
                                        onClick={() => {
                                            if (goBack) goBack()
                                        }}
                                    >
                                        <ArrowBack />
                                    </div>
                                )}
                                <div className="text-xl font-body font-bold tracking-wide tracking-wide pl-1">
                                    {t("Second stage")}
                                </div>
                            </div>
                            <div className={"grid grid-cols-2 gap-1"}>
                                <div className={"p-4"}>
                                    <label>
                                        <p className={`font-body text-sm ml-1`}>
                                            {t("Company name")}
                                        </p>
                                        <Input
                                            name="companyName"
                                            placeholder={"Company name"}
                                            value={customerRelation.company_name}
                                            onChange={(e: any) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    company_name: e.target.value,
                                                })
                                            }}
                                            className={`w-full rounded-md mt-1 border border-hoverDark`}
                                        />
                                    </label>
                                </div>
                                <div className={"p-4"}>
                                    <label>
                                        <p className={`font-body text-sm ml-1`}>
                                            {t("Phone number")}
                                        </p>
                                        <Input
                                            name="appName"
                                            placeholder={"Phone"}
                                            value={customerRelation.phone}
                                            onChange={(e: any) => {
                                                setCustomerRelation({
                                                    ...customerRelation,
                                                    phone: e.target.value,
                                                })
                                            }}
                                            className={`w-full rounded-md mt-1 border border-hoverDark`}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={`text-danger text-sm ml-4 mt-4`}>{errorMessage}</div>
                        <div className={`text-green-800 text-sm ml-4 mt-4`}>{successMessage}</div>
                    </div>
                </div>
                <Paper variant={PaperVariants.dark} className="h-20 mt-8 col-span-3 bg-primary">
                    <div className={`flex justify-between`}>
                        <div className={`flex justify-between`}>
                            <Button
                                onClick={() => {
                                    submit()
                                }}
                                className="mr-5"
                            >
                                {t("Next stage")}
                            </Button>
                            <Button
                                onClick={() => {
                                    save()
                                    setErrorMessage("")
                                    setSuccessMessage("Information saved.")
                                }}
                                variant={ButtonVariants.secondary}
                            >
                                {t("Save")}
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </form>
    )
}

export default Stage2
