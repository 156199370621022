import { GradientBackground } from "template/background"
import { convertRGBToHex, rgbColorToStrictRGB } from "helper/colorConversions"
import ColorPicker from "../ColorPicker"
import ListPicker from "../ListPicker"
import { Input, Typography } from "@mui/material"

const GradientEditor = ({
    value,
    onChange,
}: {
    value: GradientBackground
    onChange: (g: GradientBackground) => void
}) => {
    return (
        <>
            <Preview value={value} />
            {value?.colors?.length > 1 && (
                <>
                    <Typography variant="body1">Angle: </Typography>
                    <Input
                        defaultValue={toDeg(value.angle ?? 0)}
                        type="number"
                        onChange={(e) => {
                            onChange({ ...value, angle: toRadians(parseFloat(e.target.value)) })
                        }}
                    />
                </>
            )}
            <ListPicker
                defaultValue={{ r: 255, g: 0, b: 0, a: 1 }}
                currentValue={value.colors ?? []}
                onResultChange={(colors) => {
                    onChange({
                        ...value,
                        colors: colors.map((color) => rgbColorToStrictRGB(color)),
                    })
                }}
                ValuePicker={ColorPicker}
            />
        </>
    )
}

const Preview = ({ value }: { value: GradientBackground }) => {
    const getGradientString = () => {
        if (!value.colors || value.colors.length === 0) {
            return "red"
        }

        if (value.colors.length > 1) {
            return `linear-gradient(${value.angle ?? 0}rad, ${(value.colors ?? [])
                .map((color) => convertRGBToHex(color))
                .join(", ")})`
        } else {
            return convertRGBToHex(value.colors[0])
        }
    }

    return (
        <div
            style={{
                width: "10rem",
                height: "10rem",
                background: getGradientString(),
            }}
        />
    )
}

const toRadians = (angle: number): number => {
    return ((angle * Math.PI) / 180) % (2 * Math.PI)
}

const toDeg = (angle: number): number => {
    return (angle * 180) / Math.PI
}

export default GradientEditor
