import React, { InputHTMLAttributes } from "react"
import InputLabel from "../InputLabel"

enum InputVariants {
    primary,
    smallPrimary,
    secondary,
    smallSecondary,
    danger,
    icon,
    contained,
    outlined,
    text,
}

interface PropTypes extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    labelClass?: string
    // tooltip is only shown if label is set as well
    tooltip?: string
    color?: string
    variant?: InputVariants
    disableElevation?: boolean
    spinnerColor?: string
    error?: boolean
    errorMessage?: string
}

const Input = (props: PropTypes) => {
    const { labelClass, error, errorMessage, ...inputProps } = props

    let style = {}

    if (props.type === "checkbox" || props.type === "radio") {
        style = { accentColor: props.color || "#2569E6" }
    }

    const input = (
        <input
            {...inputProps}
            id={props.id}
            type={props.type}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            className={`rounded-md mt-1 border hover:border-gray-500 ${
                props.error ? "border-danger" : "border-hoverDark"
            } ${props.className}`}
            style={{ ...style, ...props.style }}
            placeholder={props.placeholder}
            checked={props.checked}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        />
    )

    if (props.label && props.label !== "") {
        return (
            <InputLabel label={props.label} tooltip={props.tooltip} className={props.labelClass}>
                {input}
                {errorMessage && <div className="text-danger mb-1">{errorMessage}</div>}
            </InputLabel>
        )
    }

    return <>{input}</>
}

export default Input
export { InputVariants }
