import {
    TemplateAction,
    TemplateActionSystems,
    systemFromString,
    TemplateActionProviders,
    TemplateActionNavigations,
    TemplateActionProvider,
    TemplateActionNavigation,
    methodFromString,
    TemplateActionNavigationGoto,
    TemplateActionProviderReload,
} from "template/actions"

import Input from "components/base/Input"
import Button from "components/base/Button"
import { useState } from "react"
import { Delete } from "@mui/icons-material"

const ActionEditor = ({ action, save }: { action: TemplateAction; save: () => void }) => {
    return (
        <div>
            {selectSystem({ action, save })}
            {selectMethod({ action, save })}
            {inputParameters({ action, save })}
        </div>
    )
}

const selectSystem = ({ action, save }: { action: TemplateAction; save: () => void }) => {
    if (action == null) {
        return
    }
    if (action.system == null) {
        action.system = Object.values(TemplateActionSystems)[0]
    }
    return (
        <div>
            <select
                value={
                    action.system ||
                    systemFromString(Object.keys(TemplateActionSystems)[0].toLowerCase())
                }
                onChange={(e) => {
                    action.system = systemFromString(e.target.value)
                    action.method = null
                    save()
                }}
            >
                {Object.keys(TemplateActionSystems).map((k) => {
                    return <option value={k.toLowerCase()}>{k}</option>
                })}
            </select>
        </div>
    )
}

const selectMethod = ({ action, save }: { action: TemplateAction; save: () => void }) => {
    if (action == null) {
        return
    }
    if (!action.system) {
        return <div></div>
    }

    switch (action.system) {
        case TemplateActionSystems.Provider:
            let act = action as TemplateActionProvider

            if (!act.method) {
                act.method = TemplateActionProviders.Reload
            }

            return (
                <div>
                    <select
                        value={act.method}
                        onChange={(e) => {
                            act.method = methodFromString(
                                act.system,
                                e.target.value
                            ) as TemplateActionProviders
                            save()
                        }}
                    >
                        {Object.keys(TemplateActionProviders).map((k) => {
                            return <option value={k.toLowerCase()}>{k}</option>
                        })}
                    </select>
                </div>
            )
        case TemplateActionSystems.Navigation: {
            let act = action as TemplateActionNavigation

            if (!act.method) {
                // set the default method with default values
                let goto = act as TemplateActionNavigationGoto
                goto.method = TemplateActionNavigations.Goto
                goto.data = {}
                break
            }
            return (
                <div>
                    <select
                        value={act.method || Object.keys(TemplateActionNavigations)[0]}
                        onChange={(e) => {
                            let method = methodFromString(
                                act.system,
                                e.target.value
                            ) as TemplateActionNavigations

                            switch (method) {
                                case TemplateActionNavigations.Goto:
                                    let goto = act as TemplateActionNavigationGoto
                                    goto.method = method
                                    goto.data = {}
                                    break
                            }

                            save()
                        }}
                    >
                        {Object.keys(TemplateActionNavigations).map((k) => {
                            return <option value={k.toLowerCase()}>{k}</option>
                        })}
                    </select>
                </div>
            )
        }
        default:
            throw "bad"
    }
}

const inputParameters = ({ action, save }: { action: TemplateAction; save: () => void }) => {
    // get the system enum
    if (action == null) {
        return
    }
    if (!action.system) {
        return <div></div>
    }

    switch (action.system) {
        case TemplateActionSystems.Provider: {
            switch ((action as TemplateActionProvider).method) {
                case TemplateActionProviders.Reload:
                    return (
                        <TemplateActionProviderReloadEditor
                            action={action as TemplateActionProviderReload}
                            save={save}
                        />
                    )
                default:
            }
            break
        }
        case TemplateActionSystems.Navigation: {
            switch ((action as TemplateActionNavigation).method) {
                case TemplateActionNavigations.Goto: {
                    return (
                        <TemplateActionNavigationGotoEditor
                            action={action as TemplateActionNavigationGoto}
                            save={save}
                        />
                    )
                }
            }
            break
        }
        default:
            throw "bad"
    }
}

const TemplateActionProviderReloadEditor = ({
    action,
    save,
}: {
    action: TemplateActionProviderReload
    save: () => void
}) => {
    return (
        <div>
            <p>provider</p>
            <input
                type={"text"}
                value={action.provider_name}
                onChange={(e) => {
                    action.provider_name = e.target.value
                    save()
                }}
            />
        </div>
    )
}

const TemplateActionNavigationGotoEditor = ({
    action,
    save,
}: {
    action: TemplateActionNavigationGoto
    save: () => void
}) => {
    const [dataName, setDataName] = useState<string>("")
    return (
        <div>
            <p>Url</p>
            <input
                type={"text"}
                value={action.url}
                onChange={(e) => {
                    action.url = e.target.value
                    save()
                }}
            />
            <p>Pass data</p>
            <div className="grid" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                {Object.entries(action.data).map(([k, v], index) => {
                    return (
                        <>
                            <p>{k}</p>{" "}
                            <Input
                                value={v}
                                type={"text"}
                                onChange={(e) => {
                                    action.data[k] = e.target.value
                                    save()
                                }}
                            />
                            <Delete
                                onClick={() => {
                                    delete action.data[k]
                                    save()
                                }}
                            />
                        </>
                    )
                })}
            </div>
            <Input type="text" onChange={(e) => setDataName(e.target.value)} />
            <Button
                onClick={() => {
                    action.data[dataName] = ""
                    setDataName("")
                    save()
                }}
            >
                Add
            </Button>
            <Button
                onClick={() => {
                    action.data = {}
                    save()
                }}
            >
                Clear Data
            </Button>
        </div>
    )
}

export default ActionEditor
