import { ReactI18NextChild } from "react-i18next"
import ToolTip from "./ToolTip"

interface Props {
    label: string
    tooltip?: string
    className?: string
    children?: ReactI18NextChild | Iterable<ReactI18NextChild>
}

const InputLabel = (props: Props) => {
    if (props.tooltip && props.tooltip !== "") {
        return (
            <>
                <label className={`flex w-full ${props.className}`}>
                    <p className="flex-grow text-sm ml-1">{props.label}</p>
                    <ToolTip className={"content-end"} content={props.tooltip} />
                </label>

                {props.children}
            </>
        )
    }
    return (
        <>
            <label className={`text-sm ${props.className}`}>{props.label}</label>
            {props.children}
        </>
    )
}

export default InputLabel
