import * as React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"

import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"

import Row from "./Row"
import getComparator from "helper/Comparator"
import { getChildrenByType } from "helper/reactComponents"
import { ContentState } from "models/ContentState"

export type TableData = {
    title: string
    id: string
    state: ContentState
    publish_time?: Date | null
    expiration?: Date | null
}

export enum SortOrder {
    ASC = "asc",
    DESC = "desc",
}

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void
    order: SortOrder
    orderBy: string
    rowCount: number
    headCells: React.ReactElement[]
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const defaultOrder = SortOrder.ASC

    return (
        <TableHead>
            <TableRow>
                {props.headCells.map((HeadCell) => {
                    const ClonedHeadCell = React.cloneElement(HeadCell, {
                        order,
                        orderBy,
                        onRequestSort,
                        defaultOrder,
                    })
                    return ClonedHeadCell
                })}
            </TableRow>
        </TableHead>
    )
}

enum TableVariants {
    primary,
}

type PropTypes = {
    width?: number
    data: TableData[]
    onEditClick?: (rowData: TableData) => void
    children?: React.ReactNode
}

const rowsPerPage = 5

/**
 * Inverse the [order]
 */
const toggleSortOrder = (order: SortOrder): SortOrder => {
    if (order === SortOrder.ASC) {
        return SortOrder.DESC
    }
    return SortOrder.ASC
}

const BaseTable = ({ data, width, onEditClick, children }: PropTypes) => {
    const [order, setOrder] = useState<SortOrder>(SortOrder.ASC)
    const [orderBy, setOrderBy] = useState<keyof TableData>("title")
    const [selected] = useState<readonly string[]>([])
    const [page, setPage] = useState(0)

    const { t } = useTranslation()

    const dense = false

    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof TableData) => {
        setOrder(toggleSortOrder(order))
        setOrderBy(property)
    }

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

    // Get all HeadCell components from children
    const headCells = getChildrenByType(children, ["HeadCell"]) as React.ReactElement[]

    return (
        <div className={"break-words"}>
            <TableContainer>
                <Table
                    sx={{ width: width }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        headCells={headCells}
                    />
                    <TableBody>
                        {data
                            .sort(getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return <Row key={row.id} row={row} onEditClick={onEditClick} />
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}–${to} ${t("notification.table.paginationOf")} ${count}`
                }}
            />
        </div>
    )
}

export default BaseTable
export { TableVariants }
