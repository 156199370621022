import axios, { AxiosResponse, AxiosError } from "axios"

import CONSTS from "constants/CONSTS"
import { CreateCRMModel, CRMModel, ValidateCRMModel, ValidateCreateCRMModel } from "models/CRM"
import { Paths } from "constants/ROUTES"
import { defaultHeaders, IAPIError, unwrapAxiosError } from "./helpers"

const getCustomerRelations = async () => {
    let results: string
    return new Promise<CRMModel[]>((resolve, reject) => {
        return axios({
            method: "GET",
            url: `v1/admin/customer_relations`,
            baseURL: CONSTS.BACKEND_HOST,
            headers: defaultHeaders(true),
        })
            .then((res: AxiosResponse) => {
                results = res.data

                if (res.data != null) {
                    let invalid = res.data.some((r: any) => {
                        const validateResult = ValidateCRMModel(r)
                        if (!validateResult.ok) {
                            return true
                        }
                        return false
                    })

                    if (!invalid) return resolve(res.data)
                }

                return reject(`Could not validate CRM`)
            })
            .catch((err: AxiosError) => {
                console.log("Response was: " + JSON.stringify(results, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

const getCustomerRelation = async (id: string) => {
    let results: string
    return new Promise<CRMModel>((resolve, reject) => {
        return axios({
            method: "get",
            url: `v1/admin${Paths.CRM}/${id}`,
            baseURL: CONSTS.BACKEND_HOST,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
        })
            .then((res: AxiosResponse) => {
                if (res.data != null) {
                    const validateResult = ValidateCRMModel(res.data)

                    if (validateResult.ok) return resolve(res.data)
                }

                return reject(`Could not validate CRM`)
            })
            .catch((err: AxiosError) => {
                console.log("Response was: " + JSON.stringify(results, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

const getCustomerRelationByEmail = async (email: string) => {
    let results: string
    return new Promise<CRMModel>((resolve, reject) => {
        return axios({
            method: "get",
            url: `v1/admin${Paths.CRM}/email/${email}`,
            baseURL: CONSTS.BACKEND_HOST,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
        })
            .then((res: AxiosResponse) => {
                if (res.data != null) {
                    const validateResult = ValidateCRMModel(res.data)

                    if (validateResult.ok) return resolve(res.data)
                }

                return reject(`Could not validate CRM`)
            })
            .catch((err: AxiosError) => {
                console.log("Response was: " + JSON.stringify(results, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

const updateCustomerRelation = async (
    customerRelation: CRMModel,
    id: string
): Promise<CRMModel | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "PATCH",
            url: `/v1/admin/customer_relations/${id}`,
            baseURL: CONSTS.BACKEND_HOST,
            validateStatus: (status) => {
                return status === 200
            },
            data: customerRelation,
            headers: defaultHeaders(true),
            withCredentials: true,
        })
            .then((r: AxiosResponse) => {
                const valid = ValidateCRMModel(r.data)

                if (valid.ok !== true) {
                    return reject({
                        raisedBy: "updateCustomerRelation",
                        error: `The ${valid.field} of customer relation is ${valid.reason}`,
                        raw: `${valid.reason}`,
                        code: 0,
                    })
                }
                return resolve(valid.value)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const deleteCustomerRelation = async (id: string): Promise<boolean | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "DELETE",
            url: `v1/admin${Paths.CRM}/${id}`,
            baseURL: CONSTS.BACKEND_HOST,
            validateStatus: (status) => {
                return status === 200
            },
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
        })
            .then((r: AxiosResponse) => {
                return resolve(true)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const createCustomerRelation = async (
    customerRelation: CreateCRMModel
): Promise<CreateCRMModel | IAPIError> => {
    return new Promise<CreateCRMModel | IAPIError>((resolve, reject) => {
        let valid = ValidateCreateCRMModel(customerRelation)
        if (true !== valid.ok) {
            return reject({
                raisedBy: "input",
                error: `The ${valid.field} of customer relation is invalid`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            })
        }

        return axios({
            method: "POST",
            url: `v1/admin/customer_relations`,
            baseURL: CONSTS.BACKEND_HOST,
            headers: defaultHeaders(true),
            withCredentials: true,
            data: customerRelation,
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((r: AxiosResponse) => {
                const valid = ValidateCreateCRMModel(r.data)

                if (valid.ok !== true) {
                    return reject({
                        raisedBy: "createCustomerRelation",
                        error: `The ${valid.field} of customer relation is ${valid.reason}`,
                        raw: `${valid.reason}`,
                        code: 0,
                    })
                }
                return resolve(valid.value)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

export {
    getCustomerRelations,
    getCustomerRelationByEmail,
    updateCustomerRelation,
    createCustomerRelation,
    getCustomerRelation,
    deleteCustomerRelation,
}
