import { useState } from "react"
import { ComponentModel } from "template/components"

import MoreVertIcon from "@mui/icons-material/MoreVert"
import CopyCutPasteMenu from "./CopyCutPasteMenu"

import Popover from "@mui/material/Popover"
import IconButton from "@mui/material/IconButton"

import { CopyCutData } from "./SharedContext"

const CopyCutPasteComponent = ({
    parent,
    component,
    index,
    save,
    copyCutData,
    onCopyOrCut,
    onPaste,
    onCancel,
}: {
    parent: ComponentModel
    component: ComponentModel
    index: number
    save: () => void
    copyCutData: CopyCutData
    onCopyOrCut: (oldRoot: ComponentModel, data: ComponentModel, action: "cut" | "copy") => void
    onPaste: (newRoot: ComponentModel) => void
    onCancel: () => void
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    const thisIsCopyCutData = copyCutData?.component === component

    const handleCopy = () => {
        onCopyOrCut(parent, component, "copy")
        handleClose()
    }

    const handleCut = () => {
        onCopyOrCut(parent, component, "cut")
        handleClose()
    }

    const handlePaste = () => {
        onPaste(component)
        handleClose()
    }

    const handleCancel = () => {
        onCancel()
        handleClose()
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <CopyCutPasteMenu
                    isSelected={thisIsCopyCutData}
                    currentAction={copyCutData.action}
                    onCopy={handleCopy}
                    onCut={handleCut}
                    onPaste={handlePaste}
                    onCancel={handleCancel}
                />
            </Popover>
        </div>
    )
}

export default CopyCutPasteComponent
