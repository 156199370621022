import { IconComponent, ComponentModel } from "template/components"
import ColorPicker from "components/ColorPicker"

import { Collapse } from "@mui/material"
import { useState } from "react"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { rgbColorToStrictRGB } from "helper/colorConversions"

const IconEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: IconComponent
    save: () => void
}) => {
    const [showFGColor, setShowFGColor] = useState<boolean>(false)
    return (
        <form>
            <p>Icon</p>
            <input
                type={"text"}
                className="border-solid border-2 border-grey-400"
                value={component.icon}
                onChange={(e) => {
                    component.icon = e.target.value
                    save()
                }}
            />

            <div>
                <div
                    className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                    onClick={() => setShowFGColor(!showFGColor)}
                >
                    <p className="m-2">Foreground color: </p>
                    <div className="flex-grow" />
                    <div className="m-3">{showFGColor ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
                <Collapse in={showFGColor}>
                    <ColorPicker
                        activeValue={component.foreground_color}
                        onChange={(color) => {
                            component.foreground_color = rgbColorToStrictRGB(color)
                            save()
                        }}
                    />
                </Collapse>
            </div>
        </form>
    )
}

export default IconEditor
