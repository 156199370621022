/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"

// Services
import { getOffer } from "api/offers"

// Providers
import { useCompanies } from "providers/company/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"

// Models
import { OfferModel } from "models"

// Components
import LoadingCover from "components/base/LoadingCover/LoadingCover"
import OfferForm from "components/CreationModal/components/OfferForm"
import Paper from "components/base/Paper"

// Utils
import { dateFromToday } from "helper/DateUtils"

// Constants
import { Paths } from "constants/ROUTES"
import SettingsForm from "components/CreationModal/components/SettingsForm"
const DEFAULT_EXPIRATION_DAYS = 30

interface DefaultOfferModel extends Omit<OfferModel, "id" | "company_name"> {}

const defaultOffer: DefaultOfferModel = {
    title: "",
    content: "",
    state: "draft",
    categories: [],
    images: [],
    expiration: dateFromToday(DEFAULT_EXPIRATION_DAYS),
    display_price: true,
    display_discount: true,
    display_new_price: true,
    price: 0,
    discount: 0,
    new_price: 0,
}

const OfferEditor = () => {
    // State
    const [loading, setLoading] = useState(true)
    const [editingOffer, setEditingOffer] = useState<OfferModel | null>(null)

    // Refs
    const offerFormRef = useRef<HTMLFormElement>(null)

    // Hooks
    const { t } = useTranslation()
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const alert = useAlertContext()
    const { offerId } = useParams()
    const navigate = useNavigate()

    // Effects
    useEffect(() => {
        if (!currentCompany?.id) {
            setLoading(false)
            return
        }

        if (!offerId) {
            setEditingOffer({ ...defaultOffer, company_name: currentCompany.company_name })
            setLoading(false)
            return
        }

        getOffer(currentCompany.id, offerId)
            .then(setEditingOffer)
            .catch((error) => {
                alert.setAlert({
                    alertSeverity: "error",
                    alertMessage: t("post.error.fetchingOffer", { error }),
                })
            })
            .finally(() => setLoading(false))
    }, [currentCompany?.id, offerId])

    // Methods
    const handleOnOfferCreate = (offer: OfferModel) => {
        navigate(Paths.posts + "/" + currentCompany?.company_name)
    }

    const handleOnOfferUpdate = (offer: OfferModel) => {
        alert.setAlert({
            alertSeverity: "success",
            alertMessage: t("common.xUpdated", { type: t("common.offer") }),
        })
    }

    const handleOnDeleteOffer = (offerId: string) => {
        navigate(Paths.posts + "/" + currentCompany?.company_name)
    }

    // Render
    if (!editingOffer) {
        return <div>{t("error.state", { state: `editingOffer: ${Boolean(editingOffer)}` })}</div>
    }
    return (
        <div className="py-6">
            <LoadingCover show={loading} />
            <div className="grid grid-cols-2 gap-4">
                <Paper>
                    <OfferForm
                        ref={offerFormRef}
                        editOffer={editingOffer}
                        onLoadingChange={setLoading}
                        onOfferCreate={handleOnOfferCreate}
                        onOfferUpdate={handleOnOfferUpdate}
                        onOfferDelete={handleOnDeleteOffer}
                        alwaysShowForm={true}
                    />
                </Paper>
                <SettingsForm
                    data={{ ...editingOffer, show_countdown: true }}
                    showStateToggle={true}
                    onStateToggle={(state) => {
                        setEditingOffer({ ...editingOffer, state })
                    }}
                    onExpirationChange={(expiration) => {
                        setEditingOffer({ ...editingOffer, expiration })
                    }}
                />
            </div>
        </div>
    )
}

export default OfferEditor
