import Paper from "components/base/Paper"
import { CRMModel } from "models/CRM"
import { useEffect, useState } from "react"
import { getCustomerRelationByEmail, updateCustomerRelation } from "api/crm"
import Stage1 from "../admin/CRM/components/Stage1"
import StagesList from "./components/StagesList"
import { useUser } from "providers/UserProvider"
import Stage2 from "../admin/CRM/components/Stage2"
import { useTranslation } from "react-i18next"

const AppData = () => {
    const [customerRelation, setCustomerRelation] = useState<CRMModel>()
    const [showForm, setShowForm] = useState<boolean>(false)
    const { user, setUser } = useUser({ autoFetch: false })!
    const { t } = useTranslation()

    const update = (updatedCustomerRelation: CRMModel) => {
        const updated: CRMModel = {
            ...updatedCustomerRelation,
            stage_1: {
                ...updatedCustomerRelation.stage_1,
                pending: true,
                changes_requested: false,
            },
        }

        setCustomerRelation(updated)
        updateCustomerRelation(updated, updated.id).then((res) => {})
    }

    useEffect(() => {
        if (user?.email)
            getCustomerRelationByEmail(user.email).then((cr) => {
                setCustomerRelation(cr)
            })
    }, [showForm])

    const transitionForm = () => {
        switch (customerRelation?.stage) {
            case 0:
                return (
                    <Stage1
                        customerRelation={customerRelation}
                        setCustomerRelation={setCustomerRelation}
                        update={update}
                        goBack={() => setShowForm(false)}
                    />
                )
            case 1:
                return (
                    <Stage2
                        customerRelation={customerRelation}
                        setCustomerRelation={setCustomerRelation}
                        update={update}
                        goBack={() => setShowForm(false)}
                    />
                )
        }
    }
    return (
        <div className={"flex justify-center mt-5"}>
            <Paper className="w-2/3">
                {customerRelation !== undefined ? (
                    showForm ? (
                        transitionForm()
                    ) : (
                        <StagesList
                            currentStage={customerRelation?.stage ? customerRelation?.stage : 0}
                            pending={
                                customerRelation?.stage_1?.pending
                                    ? customerRelation?.stage_1.pending
                                    : false
                            }
                            changesRequested={
                                customerRelation?.stage_1?.changes_requested
                                    ? customerRelation?.stage_1.changes_requested
                                    : false
                            }
                            setShowForm={setShowForm}
                        />
                    )
                ) : (
                    <div className="flex items-center w-full my-10 justify-center">
                        <p className="justify-center">{t("Waiting for app to be ready...")}</p>
                    </div>
                )}
            </Paper>
        </div>
    )
}

export default AppData
