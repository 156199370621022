import { AxiosResponse, AxiosError, AxiosRequestHeaders } from "axios"

export interface IAPIError {
    raisedBy: string
    error: string
    code: number
    raw: any
}

const validateContentType = (ct: string, target: string): boolean => {
    let vars = ct.split(";")
    for (let v of vars) {
        v = v.replaceAll(" ", "")
        if (v === target) {
            return true
        }
    }
    return false
}

const unwrapAxiosError = (error: AxiosError): IAPIError => {
    if (error.response && error.response.data) {
        console.log("response: ", error)
        let res = error.response as AxiosResponse
        return {
            raisedBy: "Server",
            error: "The server did not approve the request",
            code: res.status,
            raw: res.data,
        }
    } else if (error.request && error.request.data) {
        return {
            raisedBy: "Request",
            error: "No response received from the server",
            code: 0,
            raw: error.request,
        }
    } else {
        return {
            raisedBy: "Client",
            error: "Failed to make the request",
            raw: error.message,
            code: 0,
        }
    }
}

const defaultHeaders = (withAuth: boolean): AxiosRequestHeaders => {
    if (withAuth === true) {
        return {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    } else {
        return {
            Accept: "application/json",
        }
    }
}

export { validateContentType, unwrapAxiosError, defaultHeaders }
