import Paper, { PaperVariants } from "components/base/Paper"
import Input from "components/base/Input"
import TextArea from "components/base/TextArea"
import Button from "components/base/Button"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { CustomerRelationTableData } from "./components/CustomerRelationTable"
import { CreateCRMModel } from "models/CRM"
import { createCustomerRelation } from "api/crm"
import { useNavigate } from "react-router-dom"
import { AdminPaths } from "constants/ROUTES"
import Frame from "components/base/Frame"
import ToolTip from "components/base/ToolTip"

const CreateCR = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [newCustomerRelation, setNewCustomerRelation] = useState<CustomerRelationTableData>({
        id: "",
        companyName: "",
        email: "",
        phone: "",
        address: "",
        contactPerson: "",
        stage: 0,
        schemaVersion: "",
        notes: "",
        companyID: "",
        website: "",
        createdAt: "",
        changedAt: "",
    })

    const [errorMessage, setErrorMessage] = useState<string>("")

    const create = () => {
        if (!isValid()) return

        let customerRelation: CreateCRMModel = {
            company_name: newCustomerRelation.companyName,
            email: newCustomerRelation.email,
            phone: newCustomerRelation.phone,
            address: newCustomerRelation.address,
            contact_person: newCustomerRelation.contactPerson,
            stage: newCustomerRelation.stage,
            notes: newCustomerRelation.notes,
            website: newCustomerRelation.website,
        }

        createCustomerRelation(customerRelation).then(() => {
            navigate(AdminPaths.crm)
        })
    }

    const isValid = (): boolean => {
        let re = /\S+@\S+\.\S+/
        if (!re.test(newCustomerRelation.email)) {
            setErrorMessage("Email is not valid.")
            return false
        }

        if (newCustomerRelation.phone && newCustomerRelation.phone !== "") {
            re = /^\d{8}$/
            if (!re.test(newCustomerRelation.phone)) {
                setErrorMessage("Phone number is not valid.")
                return false
            }
        }
        if (newCustomerRelation.companyName === "") {
            setErrorMessage("Company name cannot be empty.")
            return false
        }

        return true
    }

    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-xl justify-between block flex items-center my-6">
                <div className="w-full items-center space-y-6">
                    <Paper>
                        <form
                            className="m-auto max-w-screen-lg "
                            onSubmit={() => {
                                return false
                            }}
                        >
                            <div className="mb-8">
                                <div>
                                    <div className="text-xl font-body font-bold tracking-wide px-4 py-2 ">
                                        {t("Create customer relation")}
                                    </div>
                                    <div className={"grid grid-cols-2 gap-1"}>
                                        <div className={"p-4"}>
                                            <Input
                                                label={t("common.company")}
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.company"
                                                )}
                                                name="companyName"
                                                placeholder={t("common.company")}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        companyName: e.target.value,
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </div>
                                        <div className={"p-4"}>
                                            <Input
                                                label={t("common.emailAddress")}
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.email"
                                                )}
                                                name="email"
                                                type={"email"}
                                                placeholder={t("common.emailAddress")}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        email: e.target.value,
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </div>
                                        <div className={"p-4"}>
                                            <Input
                                                label={
                                                    t("customerRelation.createCR.contactPerson") +
                                                    " (optional)"
                                                }
                                                name="contactPerson"
                                                placeholder={t(
                                                    "customerRelation.createCR.contactPerson"
                                                )}
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.contactPerson"
                                                )}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        contactPerson: e.target.value,
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </div>
                                        <div className={"p-4"}>
                                            <Input
                                                label={
                                                    t("customerRelation.createCR.address") +
                                                    " (optional)"
                                                }
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.address"
                                                )}
                                                name="address"
                                                placeholder={"Address"}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        address: e.target.value,
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </div>
                                        <div className={"p-4"}>
                                            <Input
                                                name="stage"
                                                type={"number"}
                                                label={
                                                    t("customerRelation.createCR.stage") +
                                                    " (optional)"
                                                }
                                                placeholder={t("customerRelation.createCR.stage")}
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.stage"
                                                )}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        stage: parseInt(e.target.value),
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </div>
                                        <div className={"p-4"}>
                                            <Input
                                                label={
                                                    t("customerRelation.createCR.website") +
                                                    " (optional)"
                                                }
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.website"
                                                )}
                                                name="website"
                                                placeholder={t("customerRelation.createCR.website")}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        website: e.target.value,
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </div>
                                        <div className={"p-4"}>
                                            <Input
                                                name="phone"
                                                label={
                                                    t("customerRelation.createCR.phone") +
                                                    " (optional)"
                                                }
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.phone"
                                                )}
                                                placeholder={t("customerRelation.createCR.phone")}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        phone: e.target.value,
                                                    })
                                                }}
                                                className={`w-full rounded-md mt-1 border border-hoverDark`}
                                            />
                                        </div>
                                        <div className={`px-4 col-span-2`}>
                                            <TextArea
                                                label={
                                                    t("customerRelation.createCR.notes") +
                                                    " (optional)"
                                                }
                                                tooltip={t(
                                                    "customerRelation.createCR.tooltips.notes"
                                                )}
                                                name="text"
                                                placeholder={t("customerRelation.createCR.notes")}
                                                onChange={(e: any) => {
                                                    setNewCustomerRelation({
                                                        ...newCustomerRelation,
                                                        notes: e.target.value,
                                                    })
                                                }}
                                                className={`h-28 rounded-md mt-1 w-full border border-hoverDark `}
                                            />
                                        </div>
                                    </div>
                                    <div className={`text-danger text-sm ml-4 mt-4`}>
                                        {errorMessage}
                                    </div>
                                </div>
                            </div>
                            <Paper
                                variant={PaperVariants.dark}
                                className="h-20 mt-8 col-span-3 bg-primary"
                            >
                                <div className={`flex justify-between`}>
                                    <div className={`flex justify-between`}>
                                        <Button
                                            onClick={() => {
                                                create()
                                            }}
                                        >
                                            {t("Create")}
                                        </Button>
                                    </div>
                                </div>
                            </Paper>
                        </form>
                    </Paper>
                </div>
            </div>
        </Frame>
    )
}

export default CreateCR
