import * as React from "react"
import { useState } from "react"
import TableCell from "@mui/material/TableCell"
import EditIcon from "@mui/icons-material/Edit"
import TableRow from "@mui/material/TableRow"
import truncate from "helper/Truncate"
import { CustomerRelationTableData } from "./CustomerRelationTable"
import Popup, { PopupVariants } from "components/base/Popup"
import EditCustomerRelation from "./EditCustomerRelation"
import { ArrowForward } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { AdminPaths, ADMINROUTES } from "constants/ROUTES"

type PropTypes = {
    r: CustomerRelationTableData
    maxCharsInColumn?: number
}

const STATUS: { [stage: string]: string } = {
    "-1": "Unsubscribed",
    "0": "First stage",
    "1": "Second stage",
    "2": "Third stage",
    "3": "Fourth stage",
    "4": "Deployed",
}

const CustomerRelationRow = ({ r, maxCharsInColumn = 65, ...rest }: PropTypes) => {
    const [editModal, setEditModal] = useState<boolean>(false)
    const navigate = useNavigate()

    let localDate = new Date(r.changedAt)
    return (
        <React.Fragment>
            <Popup
                open={editModal}
                setOpen={setEditModal}
                variant={PopupVariants.editForm}
                noIcon
                closeButton
                onConfirm={() => {
                    setEditModal(false)
                }}
                content={<EditCustomerRelation customerRelation={r} />}
            />
            <TableRow hover tabIndex={-1} key={r.companyName}>
                <TableCell align="center" className={`flex flex-col break-words `}>
                    <div>{truncate(r.companyName, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={`flex flex-col break-words `}>
                    <div>{truncate(r.email, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={`flex flex-col break-words`}>
                    <div>{truncate(r.website, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={`flex flex-col break-words`}>
                    <div>{truncate(STATUS[r.stage.toString()], maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={`flex flex-col break-words`}>
                    <div>{truncate(localDate.toLocaleString(), maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={`flex flex-col break-words`}>
                    <div>{truncate(r.notes, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={`flex flex-col break-words`}>
                    <div className="flex flex-row">
                        <div
                            className={
                                "hover:bg-hoverDark cursor-pointer h-12 w-12 rounded-xl box-border flex items-center justify-center "
                            }
                            onClick={() => {
                                setEditModal(!editModal)
                            }}
                        >
                            <EditIcon />
                        </div>
                        {r.stage < Object.keys(STATUS).length - 2 && (
                            <div
                                className={
                                    "hover:bg-hoverDark cursor-pointer h-12 w-12 rounded-xl box-border flex items-center justify-center "
                                }
                                onClick={() => {
                                    navigate(AdminPaths.transitionCR + "/" + r.id)
                                }}
                            >
                                <ArrowForward />
                            </div>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default CustomerRelationRow
