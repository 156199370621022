import { ModelValidateResult } from "models/ValidateResult"
import { validate } from "models/helpers"

interface InviteModel {
    company_name: string
    type: string
    role: string
    inviter_id: string
    invited_id: string
    valid_until: string
}

interface InviteReq {
    company_name: string
    role: string
    email?: string | null
}

const inviteModelConstraints = [
    { field: "company_name", type: "string" },
    // { field: "type", type: "string" },
    { field: "role", type: "string" },
    { field: "inviter_id", type: "string" },
    { field: "invited_id", type: "string", optional: true },
    { field: "valid_until", type: "string" },
]

const ValidateInviteModel = (n: any): ModelValidateResult<InviteModel> => {
    return validate<InviteModel>(n, inviteModelConstraints)
}

export default InviteModel
export { ValidateInviteModel, InviteReq }
