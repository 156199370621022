import React, { MouseEvent, useEffect } from "react"

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    show?: boolean
    clickAway?: boolean
    onClose?: () => void
}

const Modal = ({ show, clickAway = true, ...props }: ModalProps) => {
    useEffect(() => {
        const modalContent = document.getElementById("vipir-modal-content")
        if (modalContent) {
            modalContent.style.scale = show ? "100%" : "0%"
        }
    }, [show])

    const handleClickAway = (e: MouseEvent) => {
        if (e.target === document.getElementById("vipir-modal-content") && clickAway) {
            props.onClose?.()
        }
    }

    return (
        <div
            className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-40 ${props.className}`}
            onClick={handleClickAway}
            style={{ pointerEvents: show ? "auto" : "none", scale: show ? "100%" : "0%" }}
            {...props}
        >
            <div
                id="vipir-modal-content"
                className="w-full h-full flex flex-col items-center justify-center bg-gray-500 bg-opacity-50 transition-all duration-300 z-50"
            >
                {props.children}
            </div>
        </div>
    )
}

export default Modal
