import { useEffect, useState, forwardRef, useRef } from "react"

import TemplateModel, { ComponentModel, NewDefaultComponent } from "template/components"

import Button from "components/base/Button"

import ComponentsEditor from "./ComponentsEditor"
import RootComponentsEditor from "./RootComponentsEditor"

import { updateApplicationTemplate } from "api/template"
import { useCompanies } from "providers/company/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"
import { t } from "i18next"
import { ProvidersEditor } from "template/provider/editor"
import AppPreview, { AppPreviewRef } from "components/AppPreview"
import ThemeEditor, { ThemeEditorRef } from "./ThemeEditor"

import Spinner from "components/base/Spinner"
import CONSTS from "constants/CONSTS"

const EditApplicationTemplate = ({
    applicationTemplate,
}: {
    applicationTemplate: TemplateModel
}) => {
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const [template, setTemplate] = useState<TemplateModel>(applicationTemplate)

    const [submitLoading, setSubmitLoading] = useState<boolean>(false)

    const [rootType, setRootType] = useState<string>("")
    const alert = useAlertContext()

    const themeEditorRef = useRef<ThemeEditorRef>(null)
    const appPreviewRef = useRef<AppPreviewRef>(null)

    useEffect(() => {}, [template])

    const setPage = () => {
        setTemplate({ ...template })
    }

    const onSave = (component: ComponentModel) => {
        const newTemplate = {
            ...template,
            root: component,
        }
        setTemplate(newTemplate)
    }

    const updateTemplate = () => {
        if (!currentCompany) {
            return
        }
        if (submitLoading) {
            return
        }
        setSubmitLoading(true)

        template.theme = themeEditorRef.current!.getTheme()

        setTimeout(() => {
            updateApplicationTemplate(template)
                .then((res: any) => {
                    // setFeedback({ severity: "success", message: "Template updated" })
                    alert.setAlert({
                        alertMessage: t("Template updated"),
                        alertSeverity: "success",
                    })
                })
                .catch((err: any) => {
                    console.error("failed to update: ", err)
                    alert.setAlert({
                        alertMessage: t("Failed to update template: ") + err,
                        alertSeverity: "error",
                    })
                })
                .finally(() => {
                    setSubmitLoading(false)
                })
        }, 1000)
    }

    if (!template.providers) {
        template.providers = []
    }

    const rootChanged = (root: ComponentModel) => {
        template.root = root
        appPreviewRef.current!.updateRoot(root)
    }

    const themeChanged = (theme: { [index: string]: any }) => {
        template.theme = theme
        appPreviewRef.current!.updateTheme(theme)
    }

    return (
        <div>
            <ProvidersEditor providers={template.providers} save={setPage} />
            <ThemeEditor template={template} onChange={themeChanged} ref={themeEditorRef} />
            <p className="m-2 text-lg text-primary">Edit Providers</p>
            <RootComponentsEditor
                parent={template.root}
                component={template.root}
                onSave={onSave}
                save={setPage}
            />

            <div className="grid grid-cols-2">
                <Button
                    className="my-4"
                    onClick={() => {
                        updateTemplate()
                    }}
                    loading={submitLoading}
                >
                    Update
                </Button>

                <div>
                    <p>Change Root type</p>
                    <select value={rootType} onChange={(e) => setRootType(e.target.value)}>
                        <option value={"tabbed_view"}>Tabbed view</option>
                        <option value={"root"}>root</option>
                    </select>

                    <div className="fixed bottom-0 right-0 m-8">
                        <AppPreview
                            template={template}
                            ref={appPreviewRef}
                            company_name={currentCompany?.company_name ?? ""}
                            company_id={currentCompany?.id ?? ""}
                            app_backend_url={CONSTS.BACKEND_HOST}
                        />
                        {submitLoading ? (
                            <div className="w-24 flex justify-center">
                                <Spinner />
                            </div>
                        ) : (
                            <Button
                                className="text-2xl"
                                onClick={() => {
                                    updateTemplate()
                                }}
                            >
                                {t("common.save")}
                            </Button>
                        )}
                    </div>

                    <Button
                        onClick={() => {
                            let root = NewDefaultComponent(rootType)
                            if (root != undefined) {
                                template.root = root
                            } else {
                                console.error("unsupported component type: ", rootType)
                            }
                            setPage()
                        }}
                    >
                        Change
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EditApplicationTemplate
