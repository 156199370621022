interface ProviderConfig {
    url: string
    name: string
    multi: boolean
    schema: { [index: string]: any }
}

const DefaultProviderConfig = () => {
    return {
        url: "",
        name: "",
        multi: false,
        schema: {},
    }
}

export default ProviderConfig
export { DefaultProviderConfig }
