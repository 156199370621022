import { useEffect, useState } from "react"
import { ComponentList, ComponentModel } from "template/components"
import { addComponent } from "../utils"

const AddComponent = ({
    component,
    save,
    componentFilter = [],
    add = addComponent,
    title,
}: {
    component: ComponentModel
    save: () => void
    componentFilter?: string[]
    add?: (component: ComponentModel, type: string) => void
    title?: string
}) => {
    const [componentType, setComponentType] = useState<any>("")

    useEffect(() => {
        // Set the value to the first component type if there is only one choice
        if (componentFilter.length === 1) {
            setComponentType(componentFilter[0])
        }
    }, [componentFilter])

    const onSubmit = (e: any) => {
        e.preventDefault()
        add(component, componentType)
        save()
    }

    return (
        <div>
            <form>
                <p className="font-bold">{title || `Add component to ${component.type}:`}</p>
                <div className={"grid grid-cols-2 gap-4"}>
                    <select
                        //defaultValue={ComponentList[0]}
                        value={componentType}
                        onChange={(e) => setComponentType(e.target.value)}
                    >
                        {ComponentList.filter((component) => {
                            if (componentFilter.length <= 0) {
                                return true
                            }
                            return componentFilter.includes(component)
                        }).map((type, index) => {
                            return (
                                <option key={index} value={type}>
                                    {type}
                                </option>
                            )
                        })}
                    </select>
                    <input type="button" onClick={onSubmit} value="add"></input>
                </div>
            </form>
        </div>
    )
}

export default AddComponent
