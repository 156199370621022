import { TextComponent, ComponentModel } from "template/components"
import ColorPicker from "components/ColorPicker"

import { Collapse } from "@mui/material"
import { useState } from "react"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import PositionEditor from "./Position"
import { RGBColor } from "react-color"
import { rgbColorToStrictRGB } from "helper/colorConversions"

const TextEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: TextComponent
    save: () => void
}) => {
    const [showBGColor, setShowBGColor] = useState<boolean>(false)
    const [showFGColor, setShowFGColor] = useState<boolean>(false)
    return (
        <form>
            <p>Text</p>
            <input
                type={"text"}
                className="border-solid border-2 border-grey-400"
                value={component.text}
                onChange={(e) => {
                    component.text = e.target.value
                    save()
                }}
            />

            <div className={"grid grid-cols-3"}>
                <p>Font size</p>
                <input
                    type={"checkbox"}
                    checked={component.font_size != null}
                    onChange={(e) => {
                        if (e.target.checked) {
                            component.font_size = 0
                        } else {
                            component.font_size = null
                        }
                        save()
                    }}
                />
                {component.font_size == null ? (
                    <div></div>
                ) : (
                    <input
                        type={"number"}
                        className="border-solid border-2 border-grey-400"
                        value={component.font_size}
                        onChange={(e) => {
                            component.font_size = parseInt(e.target.value)
                            save()
                        }}
                    />
                )}
            </div>

            <div>
                <div
                    className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                    onClick={() => setShowFGColor(!showFGColor)}
                >
                    <p className="m-2">Foreground color: </p>
                    <div className="flex-grow" />
                    <div className="m-3">{showFGColor ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
                <Collapse in={showFGColor}>
                    <ColorPicker
                        activeValue={component.foreground_color}
                        onChange={(color: RGBColor) => {
                            component.foreground_color = rgbColorToStrictRGB(color)
                            save()
                        }}
                    />
                </Collapse>
            </div>

            <div>
                <div
                    className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                    onClick={() => setShowBGColor(!showBGColor)}
                >
                    <p className="m-2">Background color: </p>
                    <div className="flex-grow" />
                    <div className="m-3">{showBGColor ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
                <Collapse in={showBGColor}>
                    <ColorPicker
                        activeValue={component.background_color}
                        onChange={(color) => {
                            component.background_color = rgbColorToStrictRGB(color)
                            save()
                        }}
                    />
                </Collapse>
            </div>

            <div className="grid grid-cols-2">
                <p>Enable Position:</p>
                <input
                    type={"checkbox"}
                    checked={component.position != null}
                    onChange={(e) => {
                        if (e.target.checked) {
                            component.position = {
                                top: null,
                                right: null,
                                bottom: null,
                                left: null,
                            }
                        } else {
                            component.position = null
                        }
                        save()
                    }}
                />
            </div>
            {component.position == null ? (
                <div></div>
            ) : (
                <PositionEditor position={component.position} save={save} />
            )}
        </form>
    )
}

export default TextEditor
