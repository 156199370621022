/*######################################################*/
/*                                                      */
/*                                                      */
/*            GENERATED FILE, DO NOT MODIFY             */
/*                                                      */
/*                                                      */
/*######################################################*/

import {
    ComponentModel,
    RootComponent,
    PageComponent,
    RouterComponent,
    RouteComponent,
    TabbedViewComponent,
    TabComponent,
    BoxComponent,
    NewsletterFeedComponent,
    NewsletterSingleComponent,
    OfferSingleComponent,
    WebviewComponent,
    BannerComponent,
    ProviderGuardComponent,
    ProviderGuardMultiComponent,
    ListComponent,
    ProfileComponent,
    ButtonComponent,
    CarouselComponent,
    TextComponent,
    IconComponent,
    SwitchComponent,
    ConditionComponent,
    Design01Component,
    Design02Component,
    Design04Component,
    NewsletterPageComponent,
} from "./components"

import RootEditor from "./editors/RootEditor"
import PageEditor from "./editors/PageEditor"
import RouterEditor from "./editors/RouterEditor"
import RouteEditor from "./editors/RouteEditor"
import TabbedViewEditor from "./editors/TabbedViewEditor"
import TabEditor from "./editors/TabEditor"
import BoxEditor from "./editors/BoxEditor"
import NewsletterFeedEditor from "./editors/NewsletterFeedEditor"
import NewsletterSingleEditor from "./editors/NewsletterSingleEditor"
import OfferSingleEditor from "./editors/OfferSingleEditor"
import WebviewEditor from "./editors/WebviewEditor"
import BannerEditor from "./editors/BannerEditor"
import ProviderGuardEditor from "./editors/ProviderGuardEditor"
import ProviderGuardMultiEditor from "./editors/ProviderGuardMultiEditor"
import ListEditor from "./editors/ListEditor"
import ProfileEditor from "./editors/ProfileEditor"
import ButtonEditor from "./editors/ButtonEditor"
import CarouselEditor from "./editors/CarouselEditor"
import TextEditor from "./editors/TextEditor"
import IconEditor from "./editors/IconEditor"
import SwitchEditor from "./editors/SwitchEditor"
import ConditionEditor from "./editors/ConditionEditor"
import Design01Editor from "./editors/Design01Editor"
import Design02Editor from "./editors/Design02Editor"
import Design04Editor from "./editors/Design04Editor"
import NewsletterPageEditor from "./editors/NewsletterPageEditor"

import NotImplementedEditor from "./editors/NotImplementedEditor"

const GetEditor = (parent: ComponentModel, component: ComponentModel, save: () => void) => {
    switch (component.type) {
        case "root":
            return <RootEditor parent={parent} component={component as RootComponent} save={save} />
        case "page":
            return <PageEditor parent={parent} component={component as PageComponent} save={save} />
        case "router":
            return (
                <RouterEditor
                    parent={parent}
                    component={component as RouterComponent}
                    save={save}
                />
            )
        case "route":
            return (
                <RouteEditor parent={parent} component={component as RouteComponent} save={save} />
            )
        case "tabbed_view":
            return (
                <TabbedViewEditor
                    parent={parent}
                    component={component as TabbedViewComponent}
                    save={save}
                />
            )
        case "tab":
            return <TabEditor parent={parent} component={component as TabComponent} save={save} />
        case "box":
            return <BoxEditor parent={parent} component={component as BoxComponent} save={save} />
        case "newsletter_feed":
            return (
                <NewsletterFeedEditor
                    parent={parent}
                    component={component as NewsletterFeedComponent}
                    save={save}
                />
            )
        case "newsletter_single":
            return (
                <NewsletterSingleEditor
                    parent={parent}
                    component={component as NewsletterSingleComponent}
                    save={save}
                />
            )
        case "offer_single":
            return (
                <OfferSingleEditor
                    parent={parent}
                    component={component as OfferSingleComponent}
                    save={save}
                />
            )
        case "webview":
            return (
                <WebviewEditor
                    parent={parent}
                    component={component as WebviewComponent}
                    save={save}
                />
            )
        case "banner":
            return (
                <BannerEditor
                    parent={parent}
                    component={component as BannerComponent}
                    save={save}
                />
            )
        case "provider_guard":
            return (
                <ProviderGuardEditor
                    parent={parent}
                    component={component as ProviderGuardComponent}
                    save={save}
                />
            )
        case "provider_guard_multi":
            return (
                <ProviderGuardMultiEditor
                    parent={parent}
                    component={component as ProviderGuardMultiComponent}
                    save={save}
                />
            )
        case "list":
            return <ListEditor parent={parent} component={component as ListComponent} save={save} />
        case "profile":
            return (
                <ProfileEditor
                    parent={parent}
                    component={component as ProfileComponent}
                    save={save}
                />
            )
        case "button":
            return (
                <ButtonEditor
                    parent={parent}
                    component={component as ButtonComponent}
                    save={save}
                />
            )
        case "carousel":
            return (
                <CarouselEditor
                    parent={parent}
                    component={component as CarouselComponent}
                    save={save}
                />
            )
        case "text":
            return <TextEditor parent={parent} component={component as TextComponent} save={save} />
        case "icon":
            return <IconEditor parent={parent} component={component as IconComponent} save={save} />
        case "switch":
            return (
                <SwitchEditor
                    parent={parent}
                    component={component as SwitchComponent}
                    save={save}
                />
            )
        case "condition":
            return (
                <ConditionEditor
                    parent={parent}
                    component={component as ConditionComponent}
                    save={save}
                />
            )
        case "design01":
            return (
                <Design01Editor
                    parent={parent}
                    component={component as Design01Component}
                    save={save}
                />
            )
        case "design02":
            return (
                <Design02Editor
                    parent={parent}
                    component={component as Design02Component}
                    save={save}
                />
            )
        case "design04":
            return (
                <Design04Editor
                    parent={parent}
                    component={component as Design04Component}
                    save={save}
                />
            )
        case "newsletter_page":
            return (
                <NewsletterPageEditor
                    parent={parent}
                    component={component as NewsletterPageComponent}
                    save={save}
                />
            )
        default:
            return <NotImplementedEditor parent={parent} component={component} save={save} />
    }
}

export default GetEditor
