import axios, { AxiosResponse, AxiosError } from "axios"

import CONSTS from "constants/CONSTS"

//Models
import {
    NotificationCreateModel,
    NotificationModel,
    ValidateNotificationCreateModel,
    ValidateNotificationModel,
} from "models/Notification"

// helpers
import { validateContentType, unwrapAxiosError, IAPIError, defaultHeaders } from "api/helpers"

interface CreateNotificationResponse {
    token: string
    data: any
}

export enum NotificationState {
    scheduled,
    sent,
}

export interface GetNotificationFilter {
    state: NotificationState
}

// Initialize the structure in the database without any posts/links attached
const createNotification = async (
    notification: NotificationCreateModel
): Promise<NotificationCreateModel | IAPIError> => {
    return new Promise<NotificationCreateModel | IAPIError>((resolve, reject) => {
        let valid = ValidateNotificationCreateModel(notification)
        if (true !== valid.ok) {
            return reject({
                raisedBy: "input",
                error: `The ${valid.field} of notification is ${valid.reason}`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            } as IAPIError)
        }

        return axios({
            method: "POST",
            url: `/companies/${notification.meta.company_name}/notifications`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: defaultHeaders(true),
            withCredentials: true,
            data: notification,
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse<CreateNotificationResponse>) => {
                // verify returned content-type
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                return resolve(res.data.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const updateNotification = async (
    updatedNotification: NotificationModel
): Promise<NotificationCreateModel | IAPIError> => {
    return new Promise<NotificationCreateModel | IAPIError>((resolve, reject) => {
        let valid = ValidateNotificationModel(updatedNotification)
        if (true !== valid.ok) {
            //TODO: generic error from a "error class"
            return reject({
                raisedBy: "input",
                error: `The ${valid.field} of notification is invalid`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            })
        }

        return axios({
            method: "PATCH",
            url: `/companies/${updatedNotification.meta.company_name}/notifications/${updatedNotification.id}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: defaultHeaders(true),
            withCredentials: true,
            data: {
                version: updatedNotification.version,
                meta: updatedNotification.meta,
                data: updatedNotification.data,
            },
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse<CreateNotificationResponse>) => {
                // verify returned content-type
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                return resolve(res.data.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const getNotifications = async (company: string, filter: GetNotificationFilter) => {
    let results: string

    return new Promise<NotificationModel[] | AxiosError>((resolve, reject) => {
        if (!company) {
            return reject("missing company")
        }

        let url = `/companies/${company}/notifications/`
        switch (filter.state) {
            case NotificationState.sent:
                url += "sent"
                break
            case NotificationState.scheduled:
                url += "scheduled"
                break
            default:
                return reject(`getNotifications unsupported state ${filter.state}`)
        }

        return axios({
            method: "GET",
            url: url,
            baseURL: CONSTS.BACKEND_BASE_URL,
            withCredentials: true,
            headers: defaultHeaders(true),
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse) => {
                results = res.data.results
                let invalid = res.data.results.some((r: any) => {
                    const validateResult = ValidateNotificationModel(r)
                    if (!validateResult.ok) {
                        console.error(
                            `notification validate failed: field ${validateResult.field} is ${validateResult.reason}`,
                            r
                        )
                        return true
                    }
                    return false
                })
                if (invalid) {
                    return reject(`Could not validate notifications`)
                } else {
                    return resolve(res.data.results)
                }
            })
            .catch((err: AxiosError) => {
                console.log("Response was: " + JSON.stringify(results, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

const deleteNotification = async (company: string, id: string) => {
    return new Promise<boolean | AxiosError>((resolve, reject) => {
        console.log("ID IS " + id)
        return axios({
            method: "DELETE",
            url: `/companies/${company}/notifications/${id}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: defaultHeaders(true),
            withCredentials: true,
            validateStatus: (status) => {
                return status === 200
            },
        })
            .then((res: AxiosResponse) => {
                return resolve(true)
            })
            .catch((err: AxiosError) => {
                console.log("Error deleting Notification" + JSON.stringify(err, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

export { createNotification, updateNotification, getNotifications, deleteNotification }
