export type ModelValidateResult<T> =
    | { ok: true; value: T }
    | { ok: false; field: string; reason: string }

interface Condition {
    field: string
    is: any
}

export interface ValidationConstraint {
    field: string
    type: "string" | "array" | "boolean" | "date" | "number" | "ISOdate" | "object"
    conditions?: [Condition]
    optional?: boolean
}

function validate<T>(n: any, constraints: ValidationConstraint[]): ModelValidateResult<T> {
    if (!n) {
        return { ok: false, field: "self", reason: "missing" }
    }
    for (let c of constraints) {
        if (c.conditions) {
            let skip = false
            for (let condition of c.conditions) {
                if (n[condition.field] !== condition.is) {
                    skip = true
                    break
                }
            }
            if (skip) {
                continue
            }
        }

        if (!n.hasOwnProperty(c.field) || n[c.field] === null) {
            if (c.optional === true) {
                continue
            }
            return { ok: false, field: c.field, reason: "missing" }
        }

        let v = n[c.field]
        switch (c.type) {
            case "string":
                if (typeof v !== "string") {
                    return { ok: false, field: c.field, reason: "invalid" }
                }
                break
            case "array":
                if (!(v instanceof Array)) {
                    return { ok: false, field: c.field, reason: "invalid" }
                }
                break
            case "boolean":
                if (typeof v !== "boolean") {
                    return { ok: false, field: c.field, reason: "invalid" }
                }
                break
            case "date":
                if (!(v instanceof Date)) {
                    return { ok: false, field: c.field, reason: "expected" }
                }
                break
            case "number":
                if (typeof v !== "number") {
                    return {
                        ok: false,
                        field: c.field,
                        reason: `expected 'number' but got ${typeof v}`,
                    }
                }
                break
            case "object":
                if (typeof v !== "object") {
                    return {
                        ok: false,
                        field: c.field,
                        reason: `expected 'object' but got ${typeof v}`,
                    }
                }
                break
            case "ISOdate":
                // TODO: a godo way of validating this?
                break
            default:
                return { ok: false, field: c.field, reason: "unsupported" }
        }
    }
    return { ok: true, value: n }
}

export { validate }
