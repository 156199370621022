import { validate, ValidationConstraint, ModelValidateResult } from "models/helpers"

// response from backend
export interface CRMModel {
    id: string
    company_name: string
    email: string
    phone: string
    address: string
    contact_person: string
    stage: number
    schema_version: string
    notes: string
    company_id: string
    website: string
    created_at: string
    changed_at: string
    stage_1?: Stage1Model
}

export interface CreateCRMModel {
    company_name?: string
    email: string
    phone?: string
    address?: string
    contact_person?: string
    stage?: number
    schema_version?: string
    notes?: string
    company_id?: string
    website?: string
    created_at?: string
    changed_at?: string
}

export interface Stage1Model {
    app_name?: string
    app_icon?: string
    app_description?: string
    promotional_text?: string
    keywords?: string[]
    copyright?: string
    primary_language?: string
    regions?: string[]
    age_restricted?: string[]
    webview_url?: string
    privacy_policy_url?: string
    test_user_name?: string
    test_user_password?: string
    pending?: boolean
    changes_requested?: boolean
    notes?: string
}

const crmConstraints: ValidationConstraint[] = [{ field: "email", type: "string" }]

const ValidateCRMModel = (n: any): ModelValidateResult<CRMModel> => {
    return validate<CRMModel>(n, crmConstraints)
}

const ValidateCreateCRMModel = (n: any): ModelValidateResult<CreateCRMModel> => {
    return validate<CRMModel>(n, crmConstraints)
}

export { ValidateCRMModel, ValidateCreateCRMModel }
