import {
    Background,
    BackgroundTypes,
    GradientBackground,
    ImageBackground,
    typeFromString,
} from "template/background"
import GradientEditor from "components/GradientEditor"

const BackgroundEditor = ({
    background,
    onChange,
}: {
    background: Background
    onChange: (background: Background | null) => void
}) => {
    return (
        <div>
            {selectSystem({ background, onChange })}
            {inputParameters({ background, onChange })}
        </div>
    )
}

const selectSystem = ({
    background,
    onChange,
}: {
    background: Background
    onChange: (background: Background | null) => void
}) => {
    if (background == null) {
        return
    }
    if (background.type == null) {
        background.type = Object.values(BackgroundTypes)[0]
    }
    return (
        <div>
            <select
                value={
                    background.type || typeFromString(Object.keys(BackgroundTypes)[0].toLowerCase())
                }
                onChange={(e) => {
                    background.type = typeFromString(e.target.value)
                    onChange(background)
                }}
            >
                {Object.keys(BackgroundTypes).map((k) => {
                    return <option value={k.toLowerCase()}>{k}</option>
                })}
            </select>
        </div>
    )
}

const inputParameters = ({
    background,
    onChange,
}: {
    background: Background
    onChange: (background: Background | null) => void
}) => {
    // get the system enum
    if (background == null) {
        return
    }
    if (!background.type) {
        return <div></div>
    }

    switch (background.type) {
        case BackgroundTypes.Gradient:
            const bgGrad = background as GradientBackground
            return (
                <GradientEditor
                    value={bgGrad}
                    onChange={(g) => {
                        bgGrad.angle = g.angle ?? 0
                        bgGrad.colors = g.colors
                        onChange(bgGrad)
                    }}
                />
            )
        case BackgroundTypes.Image:
            const bgImage = background as ImageBackground
            return (
                <div>
                    <p>Url</p>
                    <input
                        type={"text"}
                        value={bgImage.url}
                        onChange={(e) => {
                            bgImage.url = e.target.value
                            onChange(bgImage)
                        }}
                    />
                </div>
            )
        default:
            throw "bad"
    }
}

export default BackgroundEditor
