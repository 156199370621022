import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useCompanies } from "providers/company/CompaniesProvider"

// components
import Company from "./Company"
import Snippet from "./Snippet"
import Help from "./Help"
import Subscription from "./Subscription"

import Paper from "components/base/Paper"
import Spinner from "components/base/Spinner"
import Button, { ButtonVariants } from "components/base/Button"

enum SettingVariants {
    company,
    subscription,
    snippet,
    help,
}

const ProfileOverview = () => {
    const { currentCompany, companiesFetching } = useCompanies({ autoFetch: true })!

    const { t } = useTranslation()
    const [state, setState] = useState<SettingVariants>(SettingVariants.company)

    const renderSwitch = (param: SettingVariants) => {
        switch (param) {
            case SettingVariants.company:
                return <Company />
            case SettingVariants.snippet:
                return <Snippet company={currentCompany} />
            case SettingVariants.help:
                return <Help />
            case SettingVariants.subscription:
                return <Subscription />
            default:
                return ""
        }
    }

    return (
        <div className="m-auto max-w-screen-lg justify-between flex items-center mt-6">
            <div className="m-auto w-full">
                {companiesFetching ? (
                    <Spinner size="lg" />
                ) : (
                    <Paper className={"grid grid-cols-4 gap-2"}>
                        <div className="text-sm lg:flex-grow border-r-2 content-between">
                            <div className="text-lg py-4 px-2 font-body font-bold border-b-2">
                                {currentCompany?.company_name}
                            </div>

                            <Button
                                className={`w-full mt-2 mb-1 text-base text-black`}
                                onClick={() => setState(SettingVariants.company)}
                                variant={ButtonVariants.text}
                            >
                                {t("settings.sidebar.company")}
                            </Button>
                            <Button
                                className={`w-full mt-2 mb-1 text-base text-black`}
                                onClick={() => setState(SettingVariants.subscription)}
                                variant={ButtonVariants.text}
                            >
                                {t("settings.sidebar.subscription")}
                            </Button>
                            <Button
                                className={`w-full mt-2 mb-1 text-base text-black`}
                                onClick={() => setState(SettingVariants.snippet)}
                                variant={ButtonVariants.text}
                            >
                                {t("settings.sidebar.htmlSnippet")}
                            </Button>
                            <div className="border-b-2"></div>
                            <Button
                                className={`w-full mt-2 mb-1 text-base text-black`}
                                onClick={() => setState(SettingVariants.help)}
                                variant={ButtonVariants.text}
                            >
                                {t("settings.sidebar.help")}
                            </Button>
                        </div>
                        <div className={"col-span-3"}>{renderSwitch(state)}</div>
                    </Paper>
                )}
            </div>
        </div>
    )
}

export default ProfileOverview
