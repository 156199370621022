import React, { useState, MouseEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styles from "./Snippet.module.css"

// Services
import { getDownloadEmbed } from "../../../api/company"

// Models
import CompanyModel from "~/models/Company"

// Consts
import CONSTS from "constants/CONSTS"

// Component
import Button from "../../../components/base/Button"

// Types
enum CopyType {
    Embed,
    Source,
}

type PropTypes = {
    company: CompanyModel | null
}

const Snippet = (props: PropTypes) => {
    // Hooks
    const { t } = useTranslation()

    // State
    const [copyEmbedSuccess, setCopyEmbedSuccess] = useState(false)
    const [copySourceSuccess, setCopySourceSuccess] = useState(false)
    const [embedCode, setEmbedCode] = useState<string | null>(null)
    const [loadingPopup, setLoadingPopup] = useState<boolean>(false)

    // Data
    const snippetLink = `${CONSTS.BACKEND_HOST}/client/companies/${props.company?.company_name}/downloadSnippet`
    const embedString = `<script src="${snippetLink}"></script>`

    // Functions
    const copyToKeyboard = async (e: MouseEvent<any> | null, type: CopyType) => {
        const isEmbed = type === CopyType.Embed

        let textToCopy = isEmbed ? embedString : embedCode
        if (textToCopy) navigator.clipboard.writeText(textToCopy)

        const setSuccessFunction = isEmbed ? setCopyEmbedSuccess : setCopySourceSuccess
        setSuccessFunction(true)
        setTimeout(() => {
            setSuccessFunction(false)
        }, 1000)
    }

    const runDemo = () => {
        if (!embedCode) return alert("No embed code")

        const vipirModal = document.getElementById("vipirModal")
        if (vipirModal) {
            const modalContent = document.getElementById("modalContent")
            if (modalContent) modalContent.classList.remove("fadeoutClass")
            vipirModal.style.display = "block"
            return
        }

        const scriptTag = document.createElement("script")
        scriptTag.type = "text/javascript"
        scriptTag.src = snippetLink
        scriptTag.async = true
        document.body.appendChild(scriptTag)
        setLoadingPopup(true)
    }

    // Mounted
    useEffect(() => {
        if (props.company) {
            getDownloadEmbed(props.company.company_name).then((embedCode) => {
                if (typeof embedCode == "string") {
                    setEmbedCode(embedCode)
                }
            })
        }
    }, [props.company])

    // Render
    if (!props.company) return <></>
    return (
        <div className="flex">
            <div>
                <h3 className="text-xl font-body font-bold tracking-wide tracking-wide mb-6">
                    {t("settings.snippet.title")}
                </h3>
                {embedCode ? (
                    <React.Fragment>
                        <p className="mb-6">{t("settings.snippet.instructions")}</p>
                        <div className="mb-8">
                            <h4>{t("settings.snippet.embededCode")}</h4>
                            <div
                                className={styles.code}
                                onMouseDown={(e) => copyToKeyboard(e, CopyType.Embed)}
                            >
                                {embedString}
                            </div>
                            {copyEmbedSuccess && (
                                <span className={"absolute text-success font-bold"}>
                                    {t("settings.snippet.copied")}
                                </span>
                            )}
                        </div>

                        <div className="mb-8">
                            <h4>{t("settings.snippet.sourceCode")}</h4>
                            <textarea
                                rows={10}
                                readOnly
                                className={styles.code}
                                onMouseDown={(e) => copyToKeyboard(null, CopyType.Source)}
                                value={embedCode || ""}
                            />
                            {copySourceSuccess && (
                                <span className={"absolute text-success font-bold"}>
                                    {t("settings.snippet.copied")}
                                </span>
                            )}
                        </div>
                        <div className="flex items-center">
                            <Button className="mr-2" onClick={() => runDemo()}>
                                {t("settings.snippet.demoButton")}
                            </Button>
                            {loadingPopup &&
                                t("settings.snippet.popupDelay") +
                                    ": ~1 " +
                                    t("settings.snippet.second")}
                        </div>
                    </React.Fragment>
                ) : (
                    <p>{t("settings.snippet.noSnippet")}</p>
                )}
            </div>
        </div>
    )
}

export default Snippet
