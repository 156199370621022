import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
// components
import Frame from "components/base/Frame"
import Paper from "components/base/Paper"
import Button from "components/base/Button"
import EditApplicationTemplate from "./components/Edit"
import TemplateModel from "template/components"

import { useCompanies } from "providers/company/CompaniesProvider"
import { getApplicationTemplate, setApplicationTemplateCurrent } from "api/template"

const AppTemplate = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [template, setTemplate] = useState<TemplateModel | null>(null)

    const { currentCompany } = useCompanies({ autoFetch: false })!

    const { template_id } = useParams()!

    useEffect(() => {
        if (!template_id) {
            return
        }
        setLoading(true)
        getApplicationTemplate(template_id)
            .then((res) => setTemplate(res))
            .catch((err) => {
                console.error("failed to get template", err)
            })
            .finally(() => setLoading(false))
    }, [template_id])

    const setCurrentTemplate = () => {
        if (!template_id || currentCompany === null || currentCompany.id === undefined) {
            return
        }
        setApplicationTemplateCurrent(currentCompany.id, template_id)
            .then((res) => {
                console.log("updated current template")
            })
            .catch((err) => {
                console.error("failed to set curret template", err)
            })
    }

    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        <Paper>
                            <div className={`m-auto`}>
                                <div className="justify-between flex-wrap p-2 ">
                                    <h1 className="text-bold text-2xl font-body">
                                        Application Template
                                    </h1>
                                </div>
                                <div>
                                    {loading === true ? (
                                        <p>loading</p>
                                    ) : (
                                        <div>
                                            {template === null ? (
                                                <div>Error getting template</div>
                                            ) : (
                                                <div>
                                                    <Button
                                                        onClick={(e: any) => setCurrentTemplate()}
                                                    >
                                                        Set as current
                                                    </Button>

                                                    <EditApplicationTemplate
                                                        applicationTemplate={template}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default AppTemplate
