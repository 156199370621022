import { CarouselComponent, ComponentModel } from "template/components"

const CarousellEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: CarouselComponent
    save: () => void
}) => {
    return (
        <form>
            <div className="grid grid-cols-2">
                <p>Data</p>
                <input
                    type={"text"}
                    className="border-solid border-2 border-grey-400"
                    value={component.carousel_data as string}
                    onChange={(e) => {
                        component.carousel_data = e.target.value
                        save()
                    }}
                />
            </div>
            <div>
                <p>Height</p>
                <input
                    type={"number"}
                    className="border-solid border-2 border-grey-400"
                    defaultValue={component.height || 200}
                    onChange={(e) => {
                        component.height = parseInt(e.target.value)
                        save()
                    }}
                />
            </div>
        </form>
    )
}

export default CarousellEditor
