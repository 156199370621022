import Input from "components/base/Input"
import { ComponentModel, ProfileComponent } from "template/components"
import { useMemo, useState } from "react"
import { Collapse } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import ListPicker from "components/ListPicker"
import ColorPicker from "components/ColorPicker"
import { convertHexToRGB, convertRGBToHex } from "helper/colorConversions"

const ProfileEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ProfileComponent
    save: () => void
}) => {
    const [showGradientPicker, setShowGradientPicker] = useState(false)

    const gradientRGBList = useMemo(() => {
        return component.gradient.map((hex) => convertHexToRGB(hex))
    }, [component.gradient])

    return (
        <div className="border-t border-b mt-2 mb-2">
            <h1>
                <b>Profile component</b>
            </h1>
            <form>
                <div className="flex flex-col">
                    <div className="mb-2">
                        <p>Title</p>
                        <Input
                            type={"text"}
                            className="grow bg-gray-200 pt pb mt-2 mb-2"
                            value={component.title as string}
                            onChange={(value) => {
                                component.title = value.target.value
                                save()
                            }}
                            placeholder="My Profile"
                        />
                    </div>
                    <div className="mb-2">
                        <p>Notification title</p>
                        <Input
                            type={"text"}
                            className="grow bg-gray-200 pt pb mt-2 mb-2"
                            value={component.notification_title as string}
                            onChange={(element) => {
                                component.notification_title = element.target.value
                                save()
                            }}
                            placeholder="Notifications"
                        />
                    </div>
                    <div className="mb-2">
                        <p>App update title</p>
                        <Input
                            type={"text"}
                            className="grow bg-gray-200 pt pb mt-2 mb-2"
                            value={component.app_update_title as string}
                            onChange={(element) => {
                                component.app_update_title = element.target.value
                                save()
                            }}
                            placeholder="Update"
                        />
                    </div>

                    <div>
                        <div
                            className="cursor-pointer flex flex-row mt-2 mb-2 items-center border-t"
                            onClick={() => setShowGradientPicker(!showGradientPicker)}
                        >
                            <p className="m-2">Gradient colors for component</p>
                            <div className="flex-grow" />
                            <div className="m-3">
                                {showGradientPicker ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </div>
                        <Collapse in={showGradientPicker}>
                            <ListPicker
                                defaultValue={{ r: 255, g: 0, b: 0, a: 1 }}
                                currentValue={gradientRGBList ?? []}
                                onResultChange={(gradients) => {
                                    component.gradient = gradients.map((color) =>
                                        convertRGBToHex(color)
                                    )
                                    save()
                                }}
                                ValuePicker={ColorPicker}
                            />
                        </Collapse>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ProfileEditor
