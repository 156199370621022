import { ComponentModel, NewDefaultComponent, SwitchComponent } from "template/components"

import { FormEvent } from "react"
import AddComponent from "views/AppTemplate/components/AddComponent"
import ComponentsEditor from "views/AppTemplate/components/ComponentsEditor"

const SwitchEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: SwitchComponent
    save: () => void
}) => {
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    return (
        <form onSubmit={submit}>
            <div className="grid grid-cols-2">
                <p>Switch data</p>
                <input
                    type={"text"}
                    className="border-solid border-2 border-grey-400"
                    value={component.input}
                    onChange={(e) => {
                        component.input = e.target.value
                        save()
                    }}
                />
            </div>
            <div className="">
                <b>Default components:</b>
                <div className="border-t-2 py-2">
                    <div className={"pl-2 "}>
                        {component.components
                            .filter((component) => component.type !== "condition")
                            ?.map((c, i) => (
                                <div key={i}>
                                    <ComponentsEditor
                                        parent={component}
                                        component={c}
                                        save={save}
                                    />
                                </div>
                            ))}
                    </div>
                    <AddComponent
                        title="Add default components to switch"
                        component={component}
                        save={save}
                    />
                </div>
            </div>
        </form>
    )
}

export default SwitchEditor
