import * as React from "react"

import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

import EditIcon from "@mui/icons-material/Edit"

import truncate from "helper/Truncate"
import { localizedDate } from "helper/DateUtils"

import { TableData } from "./Table"
import { newsletterStateString } from "models"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

type PropTypes = {
    row: TableData
    maxCharsInColumn?: number
    onEditClick?: (rowData: TableData) => void
}

const Row = ({ row, maxCharsInColumn = 65, onEditClick }: PropTypes) => {
    const { t } = useTranslation()
    const cellClassName = `flex flex-col break-words}`

    return (
        <React.Fragment>
            <TableRow hover tabIndex={-1} key={row.title}>
                <TableCell align="center" className={cellClassName}>
                    <div className="cursor-pointer" onClick={() => onEditClick?.(row)}>
                        <div>{truncate(row.title, maxCharsInColumn)}</div>
                        <div
                            className={"rounded text-sm " + StateColor(newsletterStateString(row))}
                        >
                            {t(`post.${newsletterStateString(row)}`)}
                        </div>
                    </div>
                </TableCell>

                {row.publish_time && (
                    <TableCell align={"center"} className={cellClassName}>
                        <div>{localizedDate(new Date(row.publish_time))}</div>
                    </TableCell>
                )}

                <TableCell align={"center"} className={cellClassName}>
                    {row.expiration ? <div>{localizedDate(new Date(row.expiration))}</div> : "N/A"}
                </TableCell>

                <TableCell className={`break-words}`}>
                    <div className={"flex justify-center"}></div>
                </TableCell>

                <TableCell className={`break-words}`}>
                    <div className={"flex justify-center"}>
                        {/* <Link to={`/newsletter/edit/${row.id}`}>
                            <EditIcon />
                        </Link> */}
                        <div className="cursor-pointer" onClick={() => onEditClick?.(row)}>
                            <EditIcon />
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const StateColor = (state: string) => {
    switch (state) {
        case "public":
            return "bg-success"
        case "expired":
            return "bg-warning"
        case "deleted":
            return "bg-error"
        case "draft":
            return "bg-info"
    }
}
export default Row
