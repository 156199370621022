import { useState, FormEvent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { AxiosError } from "axios"

// Services
import { signin } from "api/user"

// Providers
import { useUser } from "providers/UserProvider"

// Components
import Button, { ButtonVariants } from "components/base/Button"
import Form from "components/base/Form/Form"

// Types
import UserClass from "classes/User"

type PropTypes = {
    onLoginError?: (error: string) => void
}

const LoginForm = ({ onLoginError = () => {} }: PropTypes) => {
    // Hooks
    const { t } = useTranslation()
    const queryParams = useQuery()

    // State
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    // TODO: Display this somewhere
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorLogin, setErrorLogin] = useState<boolean>(false)

    // Providers
    const { setUser } = useUser({ autoFetch: false })!

    // Functions
    function useQuery() {
        const { search } = useLocation()

        return useMemo(() => new URLSearchParams(search), [search])
    }

    const handleLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!email || !password) {
            setErrorLogin(true)
            return
        }

        const usercredentials = {
            email: email,
            password: password,
        }

        signin(usercredentials)
            .then((token: string | AxiosError<any>) => {
                try {
                    console.log("token: ", token)
                    const usr = new UserClass(token as string)
                    setUser(usr)
                } catch (e) {
                    console.error("decode user failed: ", e)
                    setErrorLogin(true)
                    setUser(undefined)
                }
            })
            .catch((err) => {
                setErrorLogin(true)
                console.error("signin failed: ", err)
                onLoginError(err.toString())
            })
    }

    /** Move to separate file */
    const google_oauth_link = (): string => {
        const base_url = "https://accounts.google.com/o/oauth2/v2/auth"
        const scopes = ["profile", "email"]
        const access_type = "offline"
        const response_type = "code"
        const redirect_uri = `${window.location.protocol}//${window.location.host}/login?redirect=true&redirect_state=google_oauth`
        const client_id = "9828210299-iab3sukltvbar49hn536htap5giv23ht.apps.googleusercontent.com"

        //TODO: add xsrf/csrf token to state

        let state: any = {
            came_from: getRedirectLocation(null),
        }

        const params_data = {
            scope: scopes.join(" "),
            access_type: access_type,
            response_type: response_type,
            redirect_uri: redirect_uri,
            include_granted_scopes: "true",
            client_id: client_id,
            state: btoa(JSON.stringify(state)),
        }
        let params = new URLSearchParams(params_data)

        return base_url + "?" + params.toString()
    }

    const getRedirectState = (): string => {
        let redirectState = queryParams.get("redirect_state")
        if (redirectState === null) {
            console.warn("redirected without state")
            redirectState = ""
        }
        return redirectState
    }

    const getRedirectLocation = (redirectState: string | null): string => {
        if (redirectState === null) {
            redirectState = getRedirectState()
        }

        let redirectLocation
        switch (redirectState) {
            case "google_oauth":
                let stateb64 = queryParams.get("state")
                if (stateb64 != null) {
                    let statejson = atob(stateb64)
                    let state = JSON.parse(statejson)
                    if (state.came_from !== "") {
                        redirectLocation = state.came_from
                    }
                }
                break
            default:
                redirectLocation = queryParams.get("came_from")
                break
        }

        if (redirectLocation === null || redirectLocation === "") {
            redirectLocation = "/dashboard"
        }

        return redirectLocation
    }

    //TODO: combine Login & Register Form.
    return (
        <div>
            <Form id="loginForm" onSubmit={(e) => handleLogin(e)}>
                <h1 className="text-center text-3xl text-#353535 py-3">{t("login.login")}</h1>
                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                        id="email"
                        value={email}
                        placeholder={t("login.identifier")}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                        id="password"
                        type="password"
                        placeholder={t("login.password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="">
                    <Button
                        variant={ButtonVariants.primary}
                        className="font-bold w-full justify-self-end"
                        type={"submit"}
                    >
                        {t("login.login")}
                    </Button>
                </div>
            </Form>

            <Button
                className={"mt-4 mb-6 w-full justify-self-end"}
                variant={ButtonVariants.secondary}
                onClick={() => {
                    window.location.replace(google_oauth_link())
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="24px"
                    height="24px"
                >
                    <path
                        fill="#FFC107"
                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                        fill="#FF3D00"
                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                        fill="#4CAF50"
                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                        fill="#1976D2"
                        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                </svg>
                <span className="ml-2">{t("login.signinWithX", { type: "Google" })}</span>
            </Button>
        </div>
    )
}

export default LoginForm
