import { ArrowForward, Done, HourglassEmpty, Pending, Schedule } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

const StagesList = ({
    currentStage = 0,
    pending = false,
    changesRequested = false,
    setShowForm,
}: {
    currentStage: number
    pending: boolean
    changesRequested: boolean
    setShowForm: Function
}) => {
    const stages = ["First stage", "Second stage", "Third stage", "Fourth stage"]
    const { t } = useTranslation()

    const getStatus = (stage: number) => {
        let status = ""

        if (stage === currentStage) {
            if (changesRequested) status = t("Changes requested")
            else if (!pending) status = t("In progress")
            else status = t("Pending")
        } else if (stage > currentStage) status = t("Waiting")
        else if (stage < currentStage) status = t("Completed")

        return status
    }

    return (
        <div>
            <h1 className="text-bold text-3xl font-body mb-5 p-2">{t("App stages")}</h1>
            {stages.map((stage, index) => {
                return (
                    <div key={stage} className="flex items-center justify-center w-full m-auto p-2">
                        <p className={`font-body text-lg ml-1`}>{t(stage)}</p>
                        <div className="ml-auto flex items-center">
                            <p className={`font-body text-lg mr-2`}>{getStatus(index)}</p>
                            {index < currentStage && (
                                <div
                                    className={
                                        "h-12 w-12 rounded-xl box-border flex items-center justify-center"
                                    }
                                >
                                    <Done />
                                </div>
                            )}
                            {index === currentStage && (!pending || changesRequested) && (
                                <div
                                    className={
                                        "hover:bg-hoverDark cursor-pointer h-12 w-12 rounded-xl box-border flex items-center justify-center"
                                    }
                                    onClick={() => {
                                        setShowForm(true)
                                    }}
                                >
                                    <ArrowForward />
                                </div>
                            )}
                            {index === currentStage && pending && (
                                <div
                                    className={
                                        "h-12 w-12 rounded-xl box-border flex items-center justify-center"
                                    }
                                >
                                    <HourglassEmpty />
                                </div>
                            )}
                            {index > currentStage && (
                                <div
                                    className={
                                        "h-12 w-12 rounded-xl box-border flex items-center justify-center"
                                    }
                                >
                                    <Schedule />
                                </div>
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default StagesList
