import React from "react"
import styles from "./Spinner.module.css"

type PropTypes = {
    className?: string
    size?: string
    color?: string
}

const Spinner = ({ className, size = "md", color = "primary" }: PropTypes) => {
    return (
        <div
            className={`${className || ""} ${styles.spinner} ${styles[size]} border-${color}`}
        ></div>
    )
}

export default Spinner
