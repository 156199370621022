import i18n from "i18n"

export function daysFromToday(date: Date | null | undefined): number | null | undefined {
    if (!date) return null

    const today = new Date()
    if (date.getTime() < today.getTime()) return 0
    const diffTime = Math.abs(date.getTime() - today.getTime())
    const dayMilliSeconds = 86400000 // 1000 * 60 * 60 * 24
    return Math.ceil(diffTime / dayMilliSeconds)
}

export function dateFromToday(days: number): Date | null {
    const date = new Date()
    date.setDate(date.getDate() + days)
    return date
}

export function localizedDate(date: Date | null | undefined): string | null | undefined {
    if (!date) return null
    return new Intl.DateTimeFormat(i18n.language, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    }).format(date)
}
