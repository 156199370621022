import Frame from "components/base/Frame"
// [Wrapper] is the base component used to wrap content displayed within the content wrapper.
const Wrapper = (props: any) => {
    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">{props.children}</div>
                </div>
            </div>
        </Frame>
    )
}

export default Wrapper
