import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import Button, { ButtonVariants } from "components/base/Button"
import Wrapper from "components/base/Wrapper"

import { useUser } from "providers/UserProvider"

import { getInvite, acceptInvite, declineInvite } from "api/invite"
import { useAlertContext } from "providers/AlertProvider"
import { Paths } from "constants/ROUTES"
import { Trans, useTranslation } from "react-i18next"
import LoadingCover from "./base/LoadingCover/LoadingCover"

const InviteAccept = ({ code }: { code: string }) => {
    const { t } = useTranslation()

    const { user } = useUser({ autoFetch: false })!
    const navigate = useNavigate()
    const alert = useAlertContext()

    const [invite, setInvite] = useState<any>(null)
    const [inviteLoading, setInviteLoading] = useState<boolean>(true)
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)

    // Hooks
    useEffect(() => {
        getInvite(code)
            .then((res) => {
                setInvite(res)
            })
            .catch((err) => {
                const message = err.raisedBy === "Server" ? err.raw.message : err
                alert.setAlert({
                    alertMessage: message,
                    alertSeverity: "error",
                })
            })
            .finally(() => {
                setInviteLoading(false)
            })
    }, [])

    const _acceptInvite = () => {
        if (!code) {
            alert.setAlert({
                alertMessage: t("invite.codeNotFound"),
                alertSeverity: "error",
            })

            return
        }
        setSubmitLoading(true)
        acceptInvite(code)
            .then(() => {
                alert.setAlert({
                    alertMessage: t("invite.accepted", { company: invite.company_name }),
                    alertSeverity: "success",
                })

                setTimeout(() => {
                    navigate(Paths.root)
                }, 500)
            })
            .catch((err) => {
                console.error("accept failed: ", err)
                const message = err.raisedBy === "Server" ? err.raw.message : err

                alert.setAlert({
                    alertMessage: message,
                    alertSeverity: "error",
                })
            })
            .finally(() => {
                setSubmitLoading(false)
            })
    }

    const _declineInvite = () => {
        if (!code) {
            alert.setAlert({
                alertMessage: t("invite.codeNotFound"),
                alertSeverity: "error",
            })
            return
        }
        setSubmitLoading(true)
        declineInvite(code)
            .then((res) => {
                alert.setAlert({
                    alertMessage: t("invite.declined"),
                    alertSeverity: "success",
                })

                setTimeout(() => {
                    navigate("/")
                }, 500)
            })
            .catch((err) => {
                const message = err.raisedBy === "Server" ? err.raw.message : err
                alert.setAlert({
                    alertMessage: message,
                    alertSeverity: "error",
                })
            })
            .finally(() => {
                setSubmitLoading(false)
            })
    }

    const InvitePrompt = () => {
        return (
            <div>
                <div className={"flex justify-center"}>
                    <p className="text-bold text-2xl font-body py-3">
                        <Trans
                            i18nKey="invite.invitedTo"
                            values={{ company: invite.company_name }}
                        />
                    </p>
                </div>
                <div className="my-6 flex justify-center">
                    <div className="flex mt-2 justify-between w-1/2">
                        <Button
                            className="mr-4"
                            loading={submitLoading}
                            onClick={() => _acceptInvite()}
                            variant={ButtonVariants.primary}
                        >
                            {t("common.accept")}
                        </Button>
                        <Button
                            className=""
                            loading={submitLoading}
                            onClick={() => _declineInvite()}
                            variant={ButtonVariants.danger}
                        >
                            {t("common.decline")}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const RedirectToLoginURL = () => {
        const params = {
            redirect: "true",
            came_from: "/user/invite/" + code,
            redirect_state: "accept_invite",
        }
        const urlParams = new URLSearchParams(params)
        let url = "/?" + urlParams.toString()
        navigate(url)
        return <Navigate to={url} />
    }

    if (!user) return <RedirectToLoginURL />

    return (
        <Wrapper>
            <LoadingCover show={inviteLoading} />
            {!inviteLoading && <InvitePrompt />}
        </Wrapper>
    )
}

export default InviteAccept
