import * as React from "react"
import { useAutocomplete, AutocompleteGetTagProps } from "@mui/base/AutocompleteUnstyled"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import { useState } from "react"

const Root = styled("div")(
    ({ theme }) => `
  color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
  font-size: 14px;
`
)

const InputWrapper = styled("div")(
    ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 6px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#2569E6"};

  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#2569E6"};
    border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#2569E6"};

  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
    height: 28px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
)

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
    label: string
    updateState: Function
}

function Tag(props: TagProps) {
    const { label, onDelete, updateState, ...other } = props
    return (
        <div {...other}>
            <span>{label}</span>
            <CloseIcon
                className={`hover:text-primary`}
                onClick={(event: any) => {
                    onDelete(event)
                    updateState()
                }}
            />
        </div>
    )
}

const StyledTag = styled(Tag)<TagProps>(
    ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"};
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
  }
`
)

const Listbox = styled("ul")(
    ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
)

type PropTypes = {
    value: string[]
    categories: Array<string>
    onCategoriesChange?: (categories: any[]) => void
    placeholder?: string
}

export default function CustomizedHook({ onCategoriesChange = () => {}, ...props }: PropTypes) {
    const {
        getRootProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        defaultValue: [],
        multiple: true,
        options: props.categories,
        getOptionLabel: (option) => option,
        isOptionEqualToValue: (option, value) => option === value,
    })

    const [inputValue, setInputValue] = useState<string>("")
    const { onChange } = getInputProps()

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            if (props.categories.length === 0 && inputValue !== "")
                if (!props.value.includes(inputValue))
                    onCategoriesChange([...props.value, inputValue])
            setInputValue("")
        }
    }

    return (
        <Root>
            <div {...getRootProps()} className={"mt-1"}>
                <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
                    {props.value.map((option: string, index: number) => (
                        <StyledTag
                            label={option}
                            {...getTagProps({ index })}
                            updateState={() => {
                                let filteredList = props.value.filter((v) => {
                                    return v !== option
                                })
                                onCategoriesChange(filteredList)
                            }}
                        />
                    ))}
                    <input
                        {...getInputProps()}
                        value={inputValue}
                        onChange={(e) => {
                            if (onChange) onChange(e)
                            setInputValue(e.target.value)
                        }}
                        onKeyDown={handleKeyPress}
                        placeholder={props.placeholder}
                    />
                </InputWrapper>
            </div>
            {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                    {(groupedOptions as typeof props.categories).map((option, index) => (
                        <div
                            className="cursor-pointer hover:bg-gray-100"
                            key={option}
                            onClick={() => {
                                if (!props.value.includes(option)) {
                                    onCategoriesChange([...props.value, option])
                                    setInputValue("")
                                }
                            }}
                        >
                            <li {...getOptionProps({ option, index })}>
                                <span>{option}</span>
                                <CheckIcon fontSize={"medium"} />
                            </li>
                        </div>
                    ))}
                </Listbox>
            ) : null}
        </Root>
    )
}
