// returns a splice of a given string with the last 3 chars before the maxLenght as "..."
const truncate = (s: string, maxLenght: number) => {
    if (!s) {
        return
    }
    if (s.length > maxLenght) {
        return s.slice(0, -(s.length - (maxLenght - 3))).concat("...")
    } else return s
}

export default truncate
