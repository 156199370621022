import { ComponentModel, RootComponent } from "template/components"

const RootEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: RootComponent
    save: () => void
}) => {
    return (
        <div>
            <div>
                Enable Navigation:{" "}
                <input
                    type="checkbox"
                    checked={component.navigation_enabled}
                    onChange={(e) => {
                        component.navigation_enabled = e.target.checked
                        save()
                    }}
                />
            </div>
        </div>
    )
}

export default RootEditor
