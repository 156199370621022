import React, { useCallback, useMemo, useState, createContext, useContext } from "react"
import { ComponentModel } from "template/components"

import GetEditor from "template/editors"
import Button, { ButtonVariants } from "components/base/Button"

import AddComponent from "./AddComponent"
import RemoveComponent from "./RemoveComponent"
import CopyCutPasteComponent from "./CopyCutPasteComponent"
import { Box, Collapse } from "@mui/material"
import { ArrowDownward, ArrowUpward, ExpandLess, ExpandMore } from "@mui/icons-material"

import { CopyCutDataContext } from "./SharedContext"

const ComponentsEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ComponentModel
    save: () => void
}) => {
    const { copyCutData, setCopyCutData, onCopyOrCut, onPaste, onCancel } =
        useContext(CopyCutDataContext)

    const editor = (component: ComponentModel) => {
        return GetEditor(parent, component, save)
    }

    const childComponentFilters = useMemo(() => {
        if (component.type === "switch") {
            return ["condition"]
        }

        return []
    }, [component.type])

    const [open, setOpen] = useState<boolean>(true)

    const swapComponentIndex = (from: number, to: number) => {
        const isOutsideRange = (index: number) => index < 0 || index >= parent.components.length

        if (isOutsideRange(from) || isOutsideRange(to)) {
            return
        }

        const pages = [...parent.components]
        let tmp = pages[to]
        pages[to] = pages[from]
        pages[from] = tmp
        parent.components = pages
    }

    const moveDown = () => {
        let index = getIndex()
        let newIndex = (index + 1) % parent.components.length
        swapComponentIndex(index, newIndex)
        save()
    }

    const moveUp = () => {
        let index = getIndex()
        const newIndex = (index - 1 + parent.components.length) % parent.components.length
        swapComponentIndex(index, newIndex)
        save()
    }

    const getIndex = () => {
        let index = -1
        for (let i = 0; i < parent.components.length; i++) {
            if (parent.components[i] === component) {
                index = i
                break
            }
        }
        return index
    }

    const toggleOpen = useCallback((event) => {
        event.stopPropagation()
        setOpen((prev) => !prev)
    }, [])

    if (!component) {
        return <div>no component</div>
    }

    return (
        <div className="border-solid border-l-2 border-t-2 border-b-2 border-black px-1 my-2">
            <div className="cursor-pointer py-2" onClick={toggleOpen}>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
                    <p className="font-bold">{component.type} Configuration</p>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <div
                        className="grid grid-cols-4 gap-4"
                        onClick={() => setOpen((prev) => !prev)}
                    >
                        <Button variant={ButtonVariants.smallPrimary} onClick={() => moveUp()}>
                            <ArrowUpward />
                        </Button>
                        <Button onClick={() => moveDown()}>
                            <ArrowDownward />
                        </Button>
                        <RemoveComponent
                            parent={parent}
                            component={component}
                            index={getIndex()}
                            save={save}
                        />
                        <CopyCutPasteComponent
                            parent={parent}
                            component={component}
                            index={getIndex()}
                            save={save}
                            copyCutData={copyCutData}
                            onCopyOrCut={onCopyOrCut}
                            onPaste={onPaste}
                            onCancel={onCancel}
                        />
                    </div>
                </Box>
            </div>
            <Collapse in={open}>
                <div className="border-t-2 py-2">
                    {editor(component)}
                    <p className="font-bold">{component.type ?? "unknown"} Components:</p>

                    <div className={"pl-2 "}>
                        {component.components
                            ?.filter(
                                (c) =>
                                    childComponentFilters.length === 0 ||
                                    childComponentFilters.includes(c.type)
                            )
                            .map((c, i) => (
                                <div key={i}>
                                    <ComponentsEditor
                                        parent={component}
                                        component={c}
                                        save={save}
                                    />
                                </div>
                            ))}
                    </div>

                    <AddComponent
                        component={component}
                        save={save}
                        componentFilter={childComponentFilters}
                    />
                </div>
            </Collapse>
        </div>
    )
}

export default ComponentsEditor
