import { InputHTMLAttributes } from "react"
import InputLabel from "./InputLabel"

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
    label?: string
    // tooltip is only shown if label is set as well
    tooltip?: string
}

const Select = (props: SelectProps) => {
    const select = (
        <div className="inline-block relative w-full">
            <select
                {...props}
                className="block appearance-none w-full bg-white border border-gray-300 focus:border-gray-500 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
            >
                {props.children}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
            </div>
        </div>
    )

    if (props.label && props.label !== "") {
        return (
            <InputLabel label={props.label} tooltip={props.tooltip}>
                {select}
            </InputLabel>
        )
    }
    return <>{select}</>
}

export default Select
