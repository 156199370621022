import React from "react"
import Spinner from "../Spinner"

const LoadingCover = ({ show = true }: { show?: boolean }) => {
    return (
        <React.Fragment>
            {show && (
                <div className="fixed top-0 left-0 h-screen w-screen z-50 flex items-center justify-center bg-gray-400 opacity-50">
                    <Spinner />
                </div>
            )}
        </React.Fragment>
    )
}

export default LoadingCover
