import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react"
import style from "../CreationModal.module.css"

// Api
import { uploadImage } from "api/image"
import { updateOffer, createOffer, getOffers, deleteOffer } from "api/offers"

// Providers
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/company/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"

// Models
import { NewsletterModel, OfferModel, ScaledImage } from "models"
import { ContentState } from "models/ContentState"

// Components
import Button, { ButtonVariants } from "components/base/Button/Button"
import TagPicker from "components/base/TagPicker/TagPicker"
import Switch from "components/base/Switch/Switch"
import AddIcon from "@mui/icons-material/Add"
import Close from "@mui/icons-material/Close"
import Input from "components/base/Input"
import TextArea from "components/base/TextArea"
import Form from "components/base/Form"
import ToolTip from "components/base/ToolTip"
import Dropdown, { DropdownMenu, DropdownVariants } from "components/base/Dropdown"

// accepted filetypes for image upload
const acceptedFileTypes = [".png", ".jpg"]

type PropTypes = {
    newsletter?: NewsletterModel
    editOffer?: OfferModel | null
    onOfferCreate?: (offer: OfferModel) => void
    onOfferUpdate?: (offer: OfferModel) => void
    onOfferDelete?: (offerId: string) => void
    onLoadingChange?: (isloading: boolean) => void
    alwaysShowForm?: boolean
}

const OfferForm = forwardRef(
    (
        {
            newsletter,
            editOffer,
            onOfferCreate = () => {},
            onOfferUpdate = () => {},
            onOfferDelete = () => {},
            onLoadingChange = () => {},
            alwaysShowForm = false,
        }: PropTypes,
        ref
    ) => {
        // Providers
        const { currentCompany } = useCompanies({ autoFetch: true })!
        const { t } = useTranslation()
        const alert = useAlertContext()

        // State
        const [title, setTitle] = useState(editOffer?.title || "")
        const [titleError, setTitleError] = useState<string | null>(null)
        const [content, setContent] = useState(editOffer?.content || "")
        const [contentError, setContentError] = useState<string | null>(null)
        const [categories, setCategories] = useState(editOffer?.categories || [])
        const [price, setPrice] = useState(editOffer?.price || 0)
        const [discount, setDiscount] = useState(editOffer?.discount || 0)
        const [newPrice, setNewPrice] = useState(editOffer?.new_price || 0)
        const [images, setImages] = useState(editOffer?.images || [])
        const [imageError, setImageError] = useState<string | null>(null)
        const [imageFiles, setImageFiles] = useState<File[]>([])

        const [usingDiscount, setUsingDiscount] = useState(true) // Controls whether use chooses discount or new price
        const [showForm, setShowForm] = useState(alwaysShowForm || false)
        const [previousOffers, setPreviousOffers] = useState<OfferModel[]>([])
        const [previousOffersId, setPreviousOffersId] = useState<string>("")

        const isEditing = Boolean(editOffer?.id)

        // Refs
        const fileInputRef = useRef<HTMLInputElement>(null)

        // Methods
        useImperativeHandle(ref, () => ({
            checkUnfinishedOffer() {
                if (!showForm) return false
                if (isEditing && editOffer) {
                    const currentOffer: any = createOfferRequest()
                    if (!currentOffer) return false
                    Object.keys(currentOffer).forEach((key) => {
                        if (!Object.hasOwn(editOffer, key)) return true
                        if (currentOffer[key] !== (editOffer as any)[key]) {
                            return true
                        }
                    })
                    return false
                }
                return title.length > 0 || content.length > 0 || price > 0
            },
        }))

        const handleCalculatePrice = (_price: number, _discount: number, _newPrice: number) => {
            const calculateNewPrice = (price: number, discount: number) => {
                return Math.round((price * (100 - discount)) / 100)
            }
            const calculateDiscountPercentage = (price: number, newPrice: number) => {
                return Math.round(((price - newPrice) / price) * 10000) / 100
            }
            if (usingDiscount) {
                setNewPrice(calculateNewPrice(_price, _discount))
            }
            if (!usingDiscount) {
                setDiscount(calculateDiscountPercentage(_price, _newPrice))
            }
        }

        const uploadImageFiles = async (): Promise<ScaledImage[]> => {
            const imageUploadPromises: Promise<any>[] = []
            imageFiles.forEach((imageFile) => {
                imageUploadPromises.push(uploadImage(imageFile))
            })
            return await Promise.all(imageUploadPromises)
        }

        const createOfferRequest = (options?: { draft: boolean }): OfferModel | null => {
            if (!currentCompany) return null

            // Default draft. If it is a newsletter-offer, use the newsletter state
            let state: ContentState = "draft"
            if (editOffer) state = editOffer.state
            if (newsletter) state = newsletter.state

            return {
                company_name: currentCompany.company_name,
                title,
                content,
                categories,
                price,
                discount,
                new_price: newPrice,
                images,
                state,
                expiration: editOffer?.expiration,
            }
        }

        /**
         * validate the settings in the form
         * If fields are invalid the appropriate errors are set
         * @returns `true` if the form is valid
         */
        const validateForm = (): boolean => {
            let valid = true
            // title
            if (title.length === 0) {
                setTitleError("post.error.noTitle")
                valid = false
            } else {
                setTitleError(null)
            }

            //content
            if (content.length === 0) {
                setContentError("post.error.noContent")
                valid = false
            } else {
                setContentError(null)
            }

            //images
            if (images.length < 1 && imageFiles.length < 1) {
                setImageError("post.error.noImage")
                valid = false
            } else {
                setImageError(null)
            }

            return valid
        }

        const handleCreateOffer = async () => {
            if (!currentCompany) return
            onLoadingChange(true)

            if (!validateForm()) {
                onLoadingChange(false)
                return
            }

            const offerReq = createOfferRequest()
            if (!offerReq) {
                return
            }

            if (imageFiles.length > 0) {
                const uploadedImages = await uploadImageFiles()
                    .catch((error) => {
                        alert.setAlert({
                            alertSeverity: "error",
                            alertMessage: error.response?.data.message || t("error.uploadImage"),
                        })
                    })
                    .finally(() => {
                        onLoadingChange(false)
                    })
                if (!uploadedImages) return
                offerReq.images = uploadedImages
            }

            const newOfferRes = await createOffer(offerReq).catch((error) => {
                alert.setAlert({
                    alertSeverity: "error",
                    alertMessage: error.response?.data.message || t("error"),
                })
                onLoadingChange(false)
            })

            if (!newOfferRes) return

            onLoadingChange(false)
            onOfferCreate(newOfferRes.offer)
            clearForm()
        }

        const handleUpdateOffer = async () => {
            if (!currentCompany || !editOffer) return
            onLoadingChange(true)

            if (!validateForm()) {
                onLoadingChange(false)
                return
            }

            const offerReq = createOfferRequest()
            if (!offerReq) return

            offerReq.id = editOffer.id

            if (imageFiles.length > 0) {
                const uploadedImages = await uploadImageFiles().catch((error) => {
                    alert.setAlert({
                        alertSeverity: "error",
                        alertMessage: error?.response?.data.message || t("error.uploadImage"),
                    })
                    onLoadingChange(false)
                    return
                })
                if (!uploadedImages) return
                offerReq.images = [...offerReq.images, ...uploadedImages]
            }

            const updatedOffer = await updateOffer(offerReq).catch((error) => {
                console.error(error)
                alert.setAlert({
                    alertSeverity: "error",
                    alertMessage: error.response.data.message,
                })
                onLoadingChange(false)
                return
            })

            onLoadingChange(false)
            if (updatedOffer) onOfferUpdate(updatedOffer)
            if (!alwaysShowForm) clearForm()
        }

        const handleDeleteOffer = async () => {
            if (!currentCompany?.id || !editOffer?.id) {
                alert.setAlert({
                    alertSeverity: "error",
                    alertMessage: t("error.state", {
                        state: `editOffer: ${Boolean(editOffer)}, currentCompany: ${Boolean(
                            currentCompany
                        )}`,
                    }),
                })
                return
            }

            onLoadingChange(true)

            deleteOffer(currentCompany.id, editOffer.id)
                .then(() => {
                    if (editOffer.id) onOfferDelete(editOffer.id)
                })
                .catch((error) => {
                    alert.setAlert({
                        alertSeverity: "error",
                        alertMessage: error.response.data.message,
                    })
                })
                .finally(() => {
                    onLoadingChange(false)
                })
        }

        const clearForm = () => {
            setTitle("")
            setContent("")
            setCategories([])
            setPrice(0)
            setDiscount(0)
            setImages([])
            setImageFiles([])
            setShowForm(false)
        }

        // Effects
        useEffect(() => {
            if (!editOffer) return
            setTitle(editOffer?.title || "")
            setContent(editOffer?.content || "")
            setCategories(editOffer?.categories || [])
            setPrice(editOffer?.price || 0)
            setDiscount(editOffer?.discount || 0)
            setNewPrice(editOffer?.new_price || 0)
            setImages(editOffer?.images || [])
            setImageFiles([])
            setShowForm(true)
        }, [editOffer])

        useEffect(() => {
            if (currentCompany)
                getOffers(currentCompany.company_name).then((offers) => {
                    setPreviousOffers(offers)
                })
        }, [])

        useEffect(() => {
            const offer = previousOffers.find((offer) => offer.id === previousOffersId)
            if (offer) {
                onOfferCreate(offer)
                setPreviousOffersId("")
            }
        }, [previousOffersId])

        // Render
        if (!showForm) {
            return (
                <div>
                    <div className="flex justify-between">
                        <h2 className="text-xl font-body mb-2">{t("common.offer")}</h2>
                        <ToolTip content={t("post.tooltip.offers")} />
                    </div>
                    <div className="flex">
                        <Button
                            name="post:offer_add"
                            variant={ButtonVariants.secondary}
                            onClick={() => setShowForm(true)}
                        >
                            <AddIcon className="mr-2" />
                            {t("common.addX", { type: t("common.offer") })}
                        </Button>
                        <div className="my-auto flex ml-4">
                            <Dropdown
                                title={t("post.existingOffers")}
                                menu={previousOffers.map((offer) => {
                                    const menuItem: DropdownMenu = {
                                        text: offer.title,
                                        onClick: () => {
                                            if (offer.id) setPreviousOffersId(offer.id)
                                        },
                                    }
                                    return menuItem
                                })}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Form onSubmit={() => (isEditing ? handleUpdateOffer() : handleCreateOffer())}>
                <div className="grid grid-cols-2 gap-2 flex-grow">
                    <div className="flex justify-between">
                        <h2 className="text-xl font-body">{t("common.offer")}</h2>
                        <ToolTip content={t("post.tooltip.offers")} />
                    </div>
                    <div />
                    <div>
                        <Input
                            value={title}
                            name="offer:title"
                            label={t("common.title")}
                            tooltip={t("post.tooltip.title")}
                            onChange={(e) => setTitle(e.target.value)}
                            className={`border-hoverDark border-solid border rounded-md mt-1 w-full`}
                        />
                        {titleError && <div className="text-danger">{t(titleError)}</div>}
                    </div>
                    <div>
                        <label>
                            <div className="flex justify-between">
                                <h4 className={`font-body text-sm ml-1`}>{t("common.category")}</h4>
                                <ToolTip content={t("post.tooltip.category")} />
                            </div>
                            <TagPicker
                                value={categories}
                                categories={["Klær", "Elektronikk", "Planter"]}
                                onCategoriesChange={(categories) => setCategories(categories)}
                            />
                        </label>
                    </div>
                    <div className={`col-span-2 mb-2`}>
                        <TextArea
                            label={t("common.text")}
                            tooltip={t("post.tooltip.description")}
                            name="offer:content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                        />
                        {contentError && <div className="text-danger">{t(contentError)}</div>}
                    </div>
                    <div className="col-span-2">
                        <div className="inline-block">
                            <h4 className={`font-body text-sm mt-2 ml-1`}>{t("common.price")}</h4>
                            <Switch
                                leftLabel={t("post.chooseDiscount")}
                                rightLabel={t("post.chooseNewPrice")}
                                onLeftClick={() => setUsingDiscount(true)}
                                onRightClick={() => setUsingDiscount(false)}
                            />
                        </div>
                    </div>
                    <div className={`col-span-2 flex flex-wrap`}>
                        <div className="w-32 mr-2">
                            <Input
                                name="price"
                                min={0}
                                label={t("post.originalPrice")}
                                value={Number(price).toString()}
                                type="number"
                                onChange={(e) => {
                                    let _price: number = Number(e.target.value)
                                    if (isNaN(parseInt(e.target.value))) return
                                    if (e.target.value === "") _price = 0
                                    if (isNaN(_price) || _price < 0) return
                                    setPrice(parseInt(e.target.value))
                                    handleCalculatePrice(_price, discount, newPrice)
                                }}
                                className={`w-full border-hoverDark border-solid border rounded-md mt-1`}
                            />
                        </div>
                        <div className={`w-32 mr-2`}>
                            <Input
                                label={t("post.discount") + "(%)"}
                                name="discount"
                                type="number"
                                max={100}
                                min={0}
                                disabled={!usingDiscount}
                                value={Number(discount).toString()}
                                onChange={(e) => {
                                    let _discount: number = Number(e.target.value)
                                    if (e.target.value === "") _discount = 0
                                    if (isNaN(_discount) || _discount < 0) return
                                    setDiscount(_discount)
                                    handleCalculatePrice(price, _discount, newPrice)
                                }}
                                className={`w-full border-hoverDark border-solid border rounded-md mt-1`}
                            />
                        </div>
                        <div className={`w-32`}>
                            <Input
                                label={t("post.newPrice")}
                                name="newPrice"
                                type="number"
                                disabled={usingDiscount}
                                max={100}
                                min={0}
                                value={Number(newPrice).toString()}
                                onChange={(e) => {
                                    let _newPrice: number = Number(e.target.value)
                                    if (e.target.value === "") _newPrice = 0
                                    if (isNaN(_newPrice) || _newPrice < 0) return
                                    setNewPrice(_newPrice)
                                    handleCalculatePrice(price, discount, _newPrice)
                                }}
                                className={`w-full border-hoverDark border-solid border rounded-md mt-1`}
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="flex justify-between">
                            <h4 className="mb-1 font-body text-sm">{t("common.images")}</h4>
                            <ToolTip content={t("post.tooltip.images")} />
                        </div>

                        <div className="flex flex-row flex-wrap mb-2">
                            {images.length > 0 &&
                                images.map((image, index) => (
                                    <div key={index} className="relative w-1/2">
                                        <img alt="offer" className="w-full" src={image.med} />
                                        <Close
                                            className="absolute top-0 right-0 m-2 text-red-500 bg-white rounded cursor-pointer opacity-75 hover:opacity-100"
                                            fontSize="large"
                                            onClick={() => {
                                                const newImages = [...images]
                                                newImages.splice(index, 1)
                                                setImages(newImages)
                                            }}
                                        />
                                    </div>
                                ))}
                            {imageFiles.length > 0 &&
                                imageFiles.map((imageFile, index) => (
                                    <div key={index} className="relative w-1/2">
                                        <img
                                            alt="offer"
                                            className="w-full"
                                            src={URL.createObjectURL(imageFile)}
                                        />
                                        <Close
                                            className="absolute top-0 right-0 m-2 text-red-500 bg-white rounded cursor-pointer opacity-75 hover:opacity-100"
                                            fontSize="large"
                                            onClick={() => {
                                                const newImageFiles = [...imageFiles]
                                                newImageFiles.splice(index, 1)
                                                setImageFiles(newImageFiles)
                                            }}
                                        />
                                    </div>
                                ))}
                        </div>
                        <div>
                            <React.Fragment>
                                <Button
                                    name="offer:add_image"
                                    variant={ButtonVariants.secondary}
                                    onClick={() => {
                                        if (fileInputRef.current) fileInputRef.current.click()
                                    }}
                                >
                                    <AddIcon className="mr-2" />
                                    {t("common.addX", { type: t("common.image") })}
                                </Button>
                                <input
                                    name="offer:image"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    type="file"
                                    accept={acceptedFileTypes.join(",")}
                                    multiple={true}
                                    onChange={(e) => {
                                        console.log("setting image files")
                                        if (e.target.files && e.target.files.length > 0) {
                                            setImageFiles([
                                                ...imageFiles,
                                                ...Array.from(e.target.files),
                                            ])
                                        }
                                    }}
                                />
                            </React.Fragment>
                            {imageError && <div className="text-danger">{t(imageError)}</div>}
                        </div>
                    </div>
                </div>
                <div className={`col-span-2 flex flex-row justify-end mt-2`}>
                    {!alwaysShowForm && (
                        <Button
                            variant={ButtonVariants.secondary}
                            className={"mr-2"}
                            onClick={() => {
                                clearForm()
                            }}
                        >
                            {t("common.cancel")}
                        </Button>
                    )}
                    {alwaysShowForm && (
                        <Button
                            variant={ButtonVariants.danger}
                            className="mr-2"
                            onClick={handleDeleteOffer}
                        >
                            {t("common.deleteX", { type: t("common.offer") })}
                        </Button>
                    )}
                    <Button
                        name="offer:submit"
                        onClick={() => {
                            isEditing ? handleUpdateOffer() : handleCreateOffer()
                        }}
                    >
                        {isEditing
                            ? t("common.updateX", { type: t("common.offer") })
                            : t("common.createX", { type: t("common.offer") })}
                    </Button>
                </div>
            </Form>
        )
    }
)

export default OfferForm
