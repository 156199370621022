/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import style from "./Company.module.css"
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/company/CompaniesProvider"

// Services
import { getWelcomeMessage, updateWelcomeMessage } from "api/config"

// Models
import { ApplicationWelcomeMessage } from "models/Company"

// Components
import Form from "components/base/Form"
import Button, { ButtonVariants } from "components/base/Button"
import Paper, { PaperVariants } from "components/base/Paper"
import Input from "components/base/Input"
import Checkbox from "components/base/Checkbox/Checkbox"
import Popup, { PopupVariants } from "components/base/Popup"
import ToolTip from "components/base/ToolTip"
import { Alert, AlertTitle, AlertProps } from "@mui/material"
import TextArea from "components/base/TextArea"

enum Data_Status {
    WAITING,
    FETCHED,
    ERROR,
}

const Company = () => {
    const { currentCompany } = useCompanies({ autoFetch: false })!
    const { t } = useTranslation()

    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [coupon, setCoupon] = useState<string>("")
    const [duration, setDuration] = useState<number>(60)
    const [enabled, setEnabled] = useState<boolean>(false)

    const [oldMessage, setOldMessage] = useState<ApplicationWelcomeMessage | undefined>(undefined) // Used if we want to reset the fields
    const [dataStatus, setDataStatus] = useState<Data_Status>(Data_Status.WAITING)

    const [alertMessage, setAlertMessage] = useState<(AlertProps & { message: string }) | null>(
        null
    )
    const [finalizeConfirmation, setFinalizeConfirmation] = useState<boolean>(false)

    // Page states, used for user feedback
    const [titleError, setTitleError] = useState<string | null>(null)
    const [durationError, setDurationError] = useState<string | null>(null)
    const [contentError, setContentError] = useState<string | null>(null)
    const [couponError, setCouponError] = useState<string | null>(null)

    useEffect(() => {
        if (currentCompany === null) {
            setDataStatus(Data_Status.ERROR)
            return
        }

        getWelcomeMessage(currentCompany.company_name)
            .then((oldMessage) => {
                setOldMessage(oldMessage)

                setDataStatus(Data_Status.FETCHED) // We only want to render the full page when we have fetched the data.
                setTitle(oldMessage.title || title)
                setContent(oldMessage.content || content)
                setCoupon(oldMessage.coupon || coupon)
                setDuration(oldMessage.time_to_live || duration)
                setEnabled(oldMessage.enabled || false)
            })
            .catch(() => {
                setDataStatus(Data_Status.ERROR)
            })
    }, [currentCompany])

    switch (dataStatus) {
        case Data_Status.ERROR: {
            return (
                <div className={"h-full grid grid-cols-3  content-center"}>
                    <div />
                    <p className={"text-center"}>
                        {t("settings.company.welcomeMessage.error.fetchingWelcomeMessage")}
                    </p>
                    <div />
                </div>
            )
        }

        case Data_Status.WAITING: {
            return (
                <div className={"h-full grid grid-cols-3  content-center"}>
                    <div />
                    <p className={"text-center"}>{t("Loading...")}</p>
                    <div />
                </div>
            )
        }

        case Data_Status.FETCHED: {
            // Data is fetched and we can do the normal rendering
            break
        }
    }

    const errorMessage = (message: string | null) => {
        if (message !== null) {
            return <div className={`text-danger text-sm`}>{message}</div>
        }
        return null
    }

    const openFinalizeConfirmationModal = () => {
        setAlertMessage(null)
        if (validateFields()) {
            setFinalizeConfirmation(true)
        }
        return
    }

    const submitWelcomeMessage = () => {
        if (currentCompany === null) {
            setDataStatus(Data_Status.ERROR)
            return
        }

        let newMessage: ApplicationWelcomeMessage = {
            enabled: enabled,
            title: title,
            content: content,
            time_to_live: duration,
            coupon: coupon,
        }
        updateWelcomeMessage(currentCompany.company_name, newMessage) // @TODO - FIXME!!!!!!
            .then((_res) => {
                setAlertMessage({
                    title: t("common.success"),
                    message: t("settings.company.welcomeMessage.updatedWelcomeMessage"),
                    severity: "success",
                })
            })
            .catch((error) => {
                setAlertMessage({
                    title: t("common.error"),
                    message: t(
                        "settings.company.welcomeMessage.error.failedUpdatingWelcomeMessage",
                        {
                            error: error,
                        }
                    ),
                    severity: "error",
                })
            })
    }

    const resetFields = () => {
        if (oldMessage === undefined) return

        setTitle(oldMessage.title)
        setContent(oldMessage.content)
        setCoupon(oldMessage.coupon)
        setDuration(oldMessage.time_to_live)
        setEnabled(oldMessage.enabled)

        return
    }

    const validateFields = () => {
        let isValid = true

        if (isNaN(duration)) {
            isValid = false
            setDurationError("Duration must be set")
        }

        if (duration < 0) {
            isValid = false
            setDurationError("Must be positive")
        }

        if (duration === 0) {
            isValid = false
            setDurationError("Duration must be longer than 0 minutes")
        }

        if (title.length === 0) {
            isValid = false
            setTitleError("Title must be set")
        }

        if (content.length === 0) {
            isValid = false
            setContentError("Title must be set")
        }

        if (coupon.length === 0) {
            isValid = false
            setCouponError("Title must be set")
        }

        return isValid
    }

    return (
        <div>
            <Popup
                open={finalizeConfirmation}
                setOpen={setFinalizeConfirmation}
                variant={PopupVariants.confirmation}
                onConfirm={submitWelcomeMessage}
                title={t("common.update")}
                content={t("settings.company.welcomeMessage.updateConfirmation")}
                cancelButtonText={t("common.cancel")}
                cofirmationsButtonText={t("common.save")}
            />
            <div>
                <div className="text-xl font-body font-bold tracking-wide px-4 py-2">
                    {t("common.company")}
                </div>

                <Form>
                    <div className={"grid grid-cols-2 gap-2"}>
                        <div className="text-l font-body font-bold tracking-wide px-4 py-2 flex justify-between p-4 w-5/6">
                            <p className="mr-5">{t("settings.company.welcomeMessage.header")}</p>
                            <ToolTip content={t("settings.company.welcomeMessage.description")} />
                        </div>
                        <div></div>
                        <div className={"p-4 w-5/6"}>
                            <Input
                                name="title"
                                value={title}
                                label={t("Title")}
                                tooltip={t("settings.company.welcomeMessage.titleDescription")}
                                placeholder={t("settings.company.welcomeMessage.title")}
                                onChange={(e: any) => {
                                    setTitle(e.target.value)
                                    setTitleError(null)
                                }}
                                error={titleError !== null}
                                className={"w-full rounded-md mt-1"}
                            />
                            {errorMessage(titleError)}
                        </div>
                        <div className={"p-4 w-5/6"}>
                            <Input
                                type="number"
                                name="duration"
                                value={duration}
                                label={t("settings.company.welcomeMessage.duration")}
                                tooltip={t("settings.company.welcomeMessage.durationDescription")}
                                placeholder={"60"}
                                onChange={(e) => {
                                    setDuration(parseInt(e.target.value, 10))
                                    setDurationError(null)
                                }}
                                error={durationError !== null}
                                className={"w-full  rounded-md mt-1"}
                            />
                            {errorMessage(durationError)}
                        </div>

                        <div className={`p-4 w-5/6`}>
                            <TextArea
                                name="text"
                                value={content}
                                label={t("settings.company.welcomeMessage.text")}
                                tooltip={t("settings.company.welcomeMessage.textDescription")}
                                placeholder={t("settings.company.welcomeMessage.textPlaceholder")}
                                onChange={(e) => {
                                    setContent(e.target.value)
                                    setContentError(null)
                                }}
                                error={contentError !== null}
                                className={"h-28 rounded-md mt-1 w-full"}
                            />
                            {errorMessage(contentError)}
                        </div>
                        <div className={`p-4 w-5/6`}>
                            <Input
                                name="coupon"
                                value={coupon}
                                label={t("settings.company.welcomeMessage.couponCode")}
                                tooltip={t("settings.company.welcomeMessage.couponCodeDescription")}
                                placeholder={t("settings.company.welcomeMessage.couponCode")}
                                onChange={(e: any) => {
                                    setCoupon(e.target.value)
                                    setCouponError(null)
                                }}
                                error={couponError !== null}
                                className={"w-full rounded-md mt-1"}
                            />
                            {errorMessage(contentError)}
                            <br />

                            <br />

                            <Checkbox
                                checked={enabled}
                                label={t("common.enabled")}
                                tooltip={t("settings.company.welcomeMessage.enabledDescription")}
                                onChange={(e) => {
                                    setEnabled(e.target.checked)
                                }}
                            />
                        </div>
                    </div>
                </Form>
            </div>

            {alertMessage && (
                <div className="mx-4">
                    <Alert severity={alertMessage.severity}>
                        <AlertTitle>{alertMessage.title}</AlertTitle>
                        {alertMessage.message}
                    </Alert>
                </div>
            )}

            <Paper
                variant={PaperVariants.dark}
                className="mt-8 col-span-3 bg-primary content-center"
            >
                <div className={`flex justify-between content-center`}>
                    <div className={`content-center`}>
                        <Button variant={ButtonVariants.secondary} onClick={resetFields}>
                            {t("common.reset")}
                        </Button>
                    </div>
                    <div>
                        <Button
                            name="companySave"
                            className={`font-bold`}
                            onClick={openFinalizeConfirmationModal}
                        >
                            {t("common.save")}
                        </Button>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default Company
