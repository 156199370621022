import * as React from "react"
import { useState } from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import TableData from "./TableData"

import { useTranslation } from "react-i18next"

import Row from "./TableRow"
import getComparator from "helper/Comparator"

enum SortOrder {
    ASC = "asc",
    DESC = "desc",
}

interface HeadCell {
    disablePadding: boolean
    id: keyof TableData
    label: string
    numeric: boolean
}

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void
    order: SortOrder
    orderBy: string
    rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }
    const { t } = useTranslation()
    const defaultOrder = SortOrder.ASC

    const headCells: readonly HeadCell[] = [
        {
            id: "title",
            numeric: false,
            disablePadding: true,
            label: t("notification.table.columns.title"),
        },
        {
            id: "content",
            numeric: true,
            disablePadding: false,
            label: t("notification.table.columns.content"),
        },
        {
            id: "url",
            numeric: true,
            disablePadding: false,
            label: t("notification.table.columns.url"),
        },
        {
            id: "date",
            numeric: true,
            disablePadding: false,
            label: t("notification.table.columns.date"),
        },
        {
            id: "edit",
            numeric: true,
            disablePadding: false,
            label: "",
        },
    ]

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : defaultOrder}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            <div className="text-left text-bold text-xl font-body text-blue-600">
                                {headCell.label}
                            </div>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

enum TableVariants {
    primary,
}

type PropTypes = {
    width?: number
    data: TableData[]
    notifications: any[]
}

const rowsPerPage = 5

/**
 * Inverse the [order]
 */
const toggleSortOrder = (order: SortOrder): SortOrder => {
    if (order === SortOrder.ASC) {
        return SortOrder.DESC
    }
    return SortOrder.ASC
}

const NotificationsTable = ({ data, notifications, width }: PropTypes) => {
    const [order, setOrder] = useState<SortOrder>(SortOrder.ASC)
    const [orderBy, setOrderBy] = useState<keyof TableData>("title")
    const [selected] = useState<readonly string[]>([])
    const [page, setPage] = useState(0)

    const { t } = useTranslation()

    const dense = false

    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof TableData) => {
        setOrder(toggleSortOrder(order))
        setOrderBy(property)
    }

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

    return (
        <div className={"break-words"}>
            <TableContainer>
                <Table
                    sx={{ width: width }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                        {data
                            .sort(getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return <Row key={row.id} row={row} notifications={notifications} />
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}–${to} ${t("notification.table.paginationOf")} ${count}`
                }}
            />
        </div>
    )
}

export default NotificationsTable
export { TableVariants }
