import { FormEvent, SetStateAction, useState } from "react"
import style from "../CreationModal.module.css"

// Providers
import { useTranslation } from "react-i18next"

// Components
import AddIcon from "@mui/icons-material/Add"
import { Link } from "models/Newsletter"
import Button, { ButtonVariants } from "components/base/Button/Button"
import Input from "components/base/Input"
import TextArea from "components/base/TextArea"
import Form, { Validation } from "components/base/Form"

type PropTypes = {
    onLinkCreate?: (link: Link) => void
}

const LinkForm = ({ onLinkCreate = () => {} }: PropTypes) => {
    // Providers
    const { t } = useTranslation()

    // State
    const [title, setTitle] = useState("")
    const [url, setUrl] = useState("")
    const [content, setContent] = useState("")
    const [showForm, setShowForm] = useState(false)

    // Errors
    const [titleError, setTitleError] = useState("")
    const [urlError, setUrlError] = useState("")
    const errorStates: { [inputName: string]: SetStateAction<any> } = {
        title: { set: setTitleError },
        url: { set: setUrlError },
    }

    const resetForm = () => {
        setTitle("")
        setUrl("")
        setContent("")
    }

    const createLink = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onLinkCreate({ title, url, content })
        resetForm()
        setShowForm(false)
    }

    const onValidation = (validation: Validation): void => {
        for (let inputName in validation) {
            errorStates[inputName].set(validation[inputName].errorString)
        }
    }

    const cancelCreateLink = () => {
        resetForm()
        setShowForm(false)
    }

    return showForm ? (
        <Form
            className="m-auto justify-between block flex-grow"
            onSubmit={createLink}
            onValidation={onValidation}
            validators={{
                title: (value: string) => {
                    if (value.length < 3) {
                        return "error.tooShort"
                    }
                    return
                },
                url: "default",
            }}
        >
            <div>
                <Input
                    type="text"
                    name="title"
                    label={t("common.title")}
                    className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["input"]}`}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <span className="text-danger text-xs">{t(titleError)}</span>
            </div>
            <div>
                <TextArea
                    label={t("common.text")}
                    name="content"
                    className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
            </div>
            <div>
                <Input
                    type="url"
                    name="url"
                    label={t("common.link")}
                    className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["input"]}`}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <span className="text-danger text-xs">{t(urlError)}</span>
            </div>

            <div className={`${style.gridCustomWidth} mt-5`}>
                <div>
                    <Button
                        variant={ButtonVariants.smallSecondary}
                        className={"px-1"}
                        onClick={cancelCreateLink}
                    >
                        {t("common.cancel")}
                    </Button>
                </div>
                <div>
                    <Button
                        type={"submit"}
                        variant={ButtonVariants.smallPrimary}
                        className={"px-1 w-full"}
                    >
                        {t("common.addX", { type: t("common.link") })}
                    </Button>
                </div>
            </div>
        </Form>
    ) : (
        <Button className={"w-full h-10 my-2"} onClick={() => setShowForm(true)}>
            <AddIcon className="mr-2" />
            {t("common.addX", { type: t("common.link") })}
        </Button>
    )
}

export default LinkForm
