import { useCallback, createContext, useState, useContext } from "react"
import Props from "providers/Props"

// Api
import { getOffers } from "api/offers"

// Models
import { OfferRespModel } from "models/Offer"

// Store
import { useCompanies } from "providers/company/CompaniesProvider"
import { AxiosError } from "axios"

type CompanyOffersContextType = {
    offers: OfferRespModel[]
    setOffers: (value: OfferRespModel[]) => void
}

const CompanyOfferContext = createContext<CompanyOffersContextType | undefined>(undefined)

export const CompanyOffersProvider = ({ children }: Props) => {
    const [offers, setOffers] = useState<OfferRespModel[]>([])

    return (
        <CompanyOfferContext.Provider value={{ offers, setOffers }}>
            {children}
        </CompanyOfferContext.Provider>
    )
}

export const useCompanyOffers = () => {
    const { offers, setOffers } = useContext(CompanyOfferContext)!
    const [isLoadingOffers, setIsloadingOffers] = useState(false)
    const [loadingOffersError, setLoadingOffersError] = useState<AxiosError<any> | null>(null)
    const { currentCompany } = useCompanies({ autoFetch: true })!

    const fetchOffers = useCallback(() => {
        setIsloadingOffers(true)
        if (currentCompany === null) {
            setIsloadingOffers(false)
            return
        }
        getOffers(currentCompany.company_name)
            .then((offers) => {
                setOffers(offers)
            })
            .catch((error) => {
                console.error("failed to get offers: ", error)
                setLoadingOffersError(error)
            })
            .finally(() => {
                setIsloadingOffers(false)
            })
    }, [setOffers, currentCompany])

    return {
        offers,
        setOffers,
        fetchOffers,
        isLoadingOffers,
        loadingOffersError,
    }
}
