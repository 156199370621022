import React from "react"
import TextField from "@mui/material/TextField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

type PropTypes = {
    label?: string
    onChange: (date: Date | null | undefined, keyboardInputValue?: string | undefined) => void
    name?: string
    value?: Date
    minDate?: Date
    maxDate?: Date
    warnAfter?: Date
    warnMessage?: string
    className?: string
}

const DatePicker = ({
    label,
    onChange,
    value,
    minDate,
    maxDate,
    warnAfter,
    warnMessage,
    className,
}: PropTypes) => {
    // Methods
    const checkWarn = () => {
        return warnAfter && value && value > warnAfter
    }

    return (
        <div className={className}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={label}
                    renderInput={(props) => <TextField {...props} />}
                    value={value}
                    ampm={false}
                    onChange={onChange}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </LocalizationProvider>
            {checkWarn() && <p className="text-warning">{warnMessage}</p>}
        </div>
    )
}

export default DatePicker
