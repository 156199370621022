import { useCallback, createContext, useState, useContext } from "react"
import Props from "providers/Props"
import { AxiosError } from "axios"

// Api
import { getOffers } from "api/offers"

// Models
import { NewsletterModel } from "models/Newsletter"

// Store
import { useCompanies } from "providers/company/CompaniesProvider"

type CompanyPostsContextType = {
    posts: NewsletterModel[]
    setPosts: (value: NewsletterModel[]) => void
}

const CompanyPostsContext = createContext<CompanyPostsContextType | undefined>(undefined)

export const CompanyPostsProvider = ({ children }: Props) => {
    const [posts, setPosts] = useState<NewsletterModel[]>([])

    return (
        <CompanyPostsContext.Provider value={{ posts, setPosts }}>
            {children}
        </CompanyPostsContext.Provider>
    )
}

export const useCompanyPosts = () => {
    const { posts, setPosts } = useContext(CompanyPostsContext)!
    const [loading, setLoading] = useState(false)
    const [loadingPostsError, setLoadingPostsError] = useState<AxiosError<any> | null>(null)
    const { currentCompany } = useCompanies({ autoFetch: true })!

    const fetchPosts = useCallback(() => {
        setLoading(true)
        if (currentCompany === null) {
            setLoadingPostsError(new AxiosError("No company selected"))
            setLoading(false)
            return
        }
        return getOffers(currentCompany.company_name)
            .then((posts) => {
                setPosts(posts)
            })
            .catch((err) => {
                console.error("failed to get posts: ", err)
                setLoadingPostsError(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [setPosts, currentCompany])

    return { posts, setPosts, fetchPosts, loading, loadingPostsError }
}
