import { ModelValidateResult } from "models/ValidateResult"

import { validate } from "models/helpers"

interface ApplicationConfigModel {
    enabled: boolean
    version: string
    application_name: string
    build: string
    welcome_message: ApplicationWelcomeMessage
    feature_set: {
        [index: string]: boolean
    }
    template_id?: string
    analytics_access_token: string

    privacy_policy_url: string
    //
    ios: {
        team_id: string
        apple_id: string
        bundle_id: string
        bundle_identifier: string
    }
    android: {
        package_name: string
    }
}

interface ApplicationWelcomeMessage {
    enabled: boolean
    title: string
    content: string
    time_to_live: number
    coupon: string
}

interface WebsiteConfigModel {
    enabled: boolean
    url: string
}

interface NotificationConfigModel {
    enabled: boolean
    topics: Array<String>
}

interface CompanyModel {
    id?: string
    company_name: string
    schema_version: string
    application: ApplicationConfigModel
    website: WebsiteConfigModel
    notification: NotificationConfigModel
}

const applicationWelcomeMessageConstraints = [
    { field: "enabled", type: "boolean" },
    {
        field: "title",
        type: "string",
        conditions: [{ field: "enabled", is: true }],
    },
    {
        field: "content",
        type: "string",
        conditions: [{ field: "enabled", is: true }],
    },
    {
        field: "time_to_live",
        type: "number",
        conditions: [{ field: "enabled", is: true }],
    },
    {
        field: "coupon",
        type: "string",
        conditions: [{ field: "enabled", is: true }],
    },
]

const ValidateApplicationWelcomeMessage = (
    n: any
): ModelValidateResult<ApplicationWelcomeMessage> => {
    return validate<ApplicationWelcomeMessage>(n, applicationWelcomeMessageConstraints)
}

const ValidateCompanyModel = (config: any): ModelValidateResult<CompanyModel> => {
    if (!config.company_name || typeof config.company_name !== "string") {
        return { ok: false, field: "company_name" }
    }
    if (!config.application || config.application.enabled === (undefined || null)) {
        return { ok: false, field: "application_config.enabled" }
    }
    return { ok: true, value: config }
}

export default CompanyModel

export { ApplicationWelcomeMessage, ValidateApplicationWelcomeMessage, ValidateCompanyModel }
