import { RGBColor } from "react-color"

const rgbColorToStrictRGB = (rgb: RGBColor): { r: number; g: number; b: number; a: number } => {
    return {
        r: rgb.r,
        g: rgb.g,
        b: rgb.b,
        a: rgb.a ?? 1,
    }
}
const convertHexToRGB = (hex: string): RGBColor => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return { r, g, b, a: 1 }
}

const convertRGBToHex = (rgb: RGBColor): string => {
    const r = rgb.r.toString(16)
    const g = rgb.g.toString(16)
    const b = rgb.b.toString(16)

    return "#" + padZero(r) + padZero(g) + padZero(b)
}

const padZero = (str: string, len = 2): string => {
    let zeros = new Array(len).join("0")
    return (zeros + str).slice(-len)
}

export { rgbColorToStrictRGB, convertHexToRGB, convertRGBToHex }
