/*######################################################*/
/*                                                      */
/*                                                      */
/*            GENERATED FILE, DO NOT MODIFY             */
/*                                                      */
/*                                                      */
/*######################################################*/

import { VIcon, InsetsModel, NavigationModel, Position } from "./structs"
import { TemplateAction, NoopAction } from "./actions"
import { Background } from "./background"
import ProviderConfig from "./provider/model"

export default interface Template {
    id: string
    owned_by: string
    root: ComponentModel

    theme: { [index: string]: any }
    providers: Array<ProviderConfig>

    public: boolean
    parent: string
    is_managed: boolean
}

/* Actual component list begin */

export interface ComponentModel {
    type: string
    components: Array<ComponentModel>
}

export interface RootComponent extends ComponentModel {
    navigation_enabled: boolean
}

export const DefaultRootComponent = (): RootComponent => {
    return {
        type: "root",
        components: [],
        navigation_enabled: false,
    }
}

export interface PageComponent extends ComponentModel {
    id: string
    navigation: NavigationModel
}

export const DefaultPageComponent = (): PageComponent => {
    return {
        type: "page",
        components: [],
        id: Math.random().toString(16).substring(2, 8),
        navigation: { title: "" },
    }
}

export interface RouterComponent extends ComponentModel {
    default_route: string
}

export const DefaultRouterComponent = (): RouterComponent => {
    return {
        type: "router",
        components: [],
        default_route: "/",
    }
}

export interface RouteComponent extends ComponentModel {
    route: string
}

export const DefaultRouteComponent = (): RouteComponent => {
    return {
        type: "route",
        components: [],
        route: "/",
    }
}

export interface TabbedViewComponent extends ComponentModel {
    app_bar: boolean
}

export const DefaultTabbedViewComponent = (): TabbedViewComponent => {
    return {
        type: "tabbed_view",
        components: [],
        app_bar: true,
    }
}

export interface TabComponent extends ComponentModel {
    id: string
    title: { [index: string]: string }
    icon: VIcon
}

export const DefaultTabComponent = (): TabComponent => {
    return {
        type: "tab",
        components: [],
        id: Math.random().toString(16).substring(2, 8),
        title: {},
        icon: { source: "", name: "" },
    }
}

export interface BoxComponent extends ComponentModel {
    padding: InsetsModel
    margin: InsetsModel
    height: number | null
    width: number | null
    layout: string
    rounded: boolean
    shadow: boolean
    background: Background | null
    pressed: TemplateAction | null
}

export const DefaultBoxComponent = (): BoxComponent => {
    return {
        type: "box",
        components: [],
        padding: { top: 0.0, right: 0.0, bottom: 0.0, left: 0.0 },
        margin: { top: 0.0, right: 0.0, bottom: 0.0, left: 0.0 },
        height: null,
        width: null,
        layout: "column",
        rounded: false,
        shadow: false,
        background: null,
        pressed: null,
    }
}

export interface NewsletterFeedComponent extends ComponentModel {
    provider: string
}

export const DefaultNewsletterFeedComponent = (): NewsletterFeedComponent => {
    return {
        type: "newsletter_feed",
        components: [],
        provider: "",
    }
}

export interface NewsletterSingleComponent extends ComponentModel {
    newsletter_id: string
}

export const DefaultNewsletterSingleComponent = (): NewsletterSingleComponent => {
    return {
        type: "newsletter_single",
        components: [],
        newsletter_id: "",
    }
}

export interface OfferSingleComponent extends ComponentModel {
    offer_id: string
}

export const DefaultOfferSingleComponent = (): OfferSingleComponent => {
    return {
        type: "offer_single",
        components: [],
        offer_id: "",
    }
}

export interface WebviewComponent extends ComponentModel {}

export const DefaultWebviewComponent = (): WebviewComponent => {
    return {
        type: "webview",
        components: [],
    }
}

export interface BannerComponent extends ComponentModel {
    title: string
}

export const DefaultBannerComponent = (): BannerComponent => {
    return {
        type: "banner",
        components: [],
        title: "Hello",
    }
}

export interface ProviderGuardComponent extends ComponentModel {
    provider: string
}

export const DefaultProviderGuardComponent = (): ProviderGuardComponent => {
    return {
        type: "provider_guard",
        components: [],
        provider: "",
    }
}

export interface ProviderGuardMultiComponent extends ComponentModel {
    providers: Array<string>
}

export const DefaultProviderGuardMultiComponent = (): ProviderGuardMultiComponent => {
    return {
        type: "provider_guard_multi",
        components: [],
        providers: [],
    }
}

export interface ListComponent extends ComponentModel {
    list_data: string
}

export const DefaultListComponent = (): ListComponent => {
    return {
        type: "list",
        components: [],
        list_data: "",
    }
}

export interface ProfileComponent extends ComponentModel {
    title: string
    notification_title: string
    app_update_title: string
    gradient: Array<string>
}

export const DefaultProfileComponent = (): ProfileComponent => {
    return {
        type: "profile",
        components: [],
        title: "Title",
        notification_title: "Notification title",
        app_update_title: "Update",
        gradient: ["#834B88", "#6E095D"],
    }
}

export interface ButtonComponent extends ComponentModel {
    icon: string
    text: string
    position: Position
    pressed: TemplateAction
    foreground_color: { a: number; r: number; g: number; b: number } | null
    background_color: { a: number; r: number; g: number; b: number } | null
}

export const DefaultButtonComponent = (): ButtonComponent => {
    return {
        type: "button",
        components: [],
        icon: "Link to icon image",
        text: "Click Me!",
        position: { top: null, right: null, bottom: null, left: null },
        pressed: NoopAction(),
        foreground_color: null,
        background_color: null,
    }
}

export interface CarouselComponent extends ComponentModel {
    height: number | null
    carousel_data: string
    infinite_scroll: boolean
    enlarge_center_page: boolean | null
    viewport_fraction: number | null
    bottom_navigation: boolean | null
    arrow_navigation: boolean | null
}

export const DefaultCarouselComponent = (): CarouselComponent => {
    return {
        type: "carousel",
        components: [],
        height: null,
        carousel_data: "",
        infinite_scroll: false,
        enlarge_center_page: null,
        viewport_fraction: null,
        bottom_navigation: null,
        arrow_navigation: null,
    }
}

export interface TextComponent extends ComponentModel {
    text: string
    font_size: number | null
    position: Position | null
    foreground_color: { a: number; r: number; g: number; b: number }
    background_color: { a: number; r: number; g: number; b: number }
}

export const DefaultTextComponent = (): TextComponent => {
    return {
        type: "text",
        components: [],
        text: "",
        font_size: null,
        position: null,
        foreground_color: { r: 0, g: 0, b: 0, a: 0 },
        background_color: { r: 0, g: 0, b: 0, a: 0 },
    }
}

export interface IconComponent extends ComponentModel {
    icon: string
    foreground_color: { a: number; r: number; g: number; b: number }
}

export const DefaultIconComponent = (): IconComponent => {
    return {
        type: "icon",
        components: [],
        icon: "",
        foreground_color: { r: 0, g: 0, b: 0, a: 0 },
    }
}

export interface SwitchComponent extends ComponentModel {
    input: string
}

export const DefaultSwitchComponent = (): SwitchComponent => {
    return {
        type: "switch",
        components: [],
        input: "",
    }
}

export interface ConditionComponent extends ComponentModel {
    condition: string
}

export const DefaultConditionComponent = (): ConditionComponent => {
    return {
        type: "condition",
        components: [],
        condition: "",
    }
}

export interface Design01Component extends ComponentModel {
    title: string
    subtitle: string
    link1_text: string
    link1_path: string
    link2_text: string
    link2_path: string
}

export const DefaultDesign01Component = (): Design01Component => {
    return {
        type: "design01",
        components: [],
        title: "Title",
        subtitle: "Template",
        link1_text: "Link Text",
        link1_path: "",
        link2_text: "Link text",
        link2_path: "",
    }
}

export interface Design02Component extends ComponentModel {
    header: string
    header_image_url: string
    footer_redirect_path: string
}

export const DefaultDesign02Component = (): Design02Component => {
    return {
        type: "design02",
        components: [],
        header: "Header",
        header_image_url: "",
        footer_redirect_path: "/",
    }
}

export interface Design04Component extends ComponentModel {
    main_newsletter_id: string
    main_offer_id: string
    main_offer_background: Background | null
    offers_background: Background | null
    newsletter_background: Background | null
}

export const DefaultDesign04Component = (): Design04Component => {
    return {
        type: "design04",
        components: [],
        main_newsletter_id: "",
        main_offer_id: "",
        main_offer_background: null,
        offers_background: null,
        newsletter_background: null,
    }
}

export interface NewsletterPageComponent extends ComponentModel {}

export const DefaultNewsletterPageComponent = (): NewsletterPageComponent => {
    return {
        type: "newsletter_page",
        components: [],
    }
}

export const NewDefaultComponent = (componentType: string): ComponentModel | undefined => {
    switch (componentType) {
        case "root":
            return DefaultRootComponent()

        case "page":
            return DefaultPageComponent()

        case "router":
            return DefaultRouterComponent()

        case "route":
            return DefaultRouteComponent()

        case "tabbed_view":
            return DefaultTabbedViewComponent()

        case "tab":
            return DefaultTabComponent()

        case "box":
            return DefaultBoxComponent()

        case "newsletter_feed":
            return DefaultNewsletterFeedComponent()

        case "newsletter_single":
            return DefaultNewsletterSingleComponent()

        case "offer_single":
            return DefaultOfferSingleComponent()

        case "webview":
            return DefaultWebviewComponent()

        case "banner":
            return DefaultBannerComponent()

        case "provider_guard":
            return DefaultProviderGuardComponent()

        case "provider_guard_multi":
            return DefaultProviderGuardMultiComponent()

        case "list":
            return DefaultListComponent()

        case "profile":
            return DefaultProfileComponent()

        case "button":
            return DefaultButtonComponent()

        case "carousel":
            return DefaultCarouselComponent()

        case "text":
            return DefaultTextComponent()

        case "icon":
            return DefaultIconComponent()

        case "switch":
            return DefaultSwitchComponent()

        case "condition":
            return DefaultConditionComponent()

        case "design01":
            return DefaultDesign01Component()

        case "design02":
            return DefaultDesign02Component()

        case "design04":
            return DefaultDesign04Component()

        case "newsletter_page":
            return DefaultNewsletterPageComponent()

        default:
            return undefined
    }
}

export const ComponentList = [
    "root",
    "page",
    "router",
    "route",
    "tabbed_view",
    "tab",
    "box",
    "newsletter_feed",
    "newsletter_single",
    "offer_single",
    "webview",
    "banner",
    "provider_guard",
    "provider_guard_multi",
    "list",
    "profile",
    "button",
    "carousel",
    "text",
    "icon",
    "switch",
    "condition",
    "design01",
    "design02",
    "design04",
    "newsletter_page",
]
