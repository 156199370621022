import React, { useRef } from "react"

// Providers
import { useTranslation } from "react-i18next"

// Models
import { ScaledImage } from "models"

// Components
import Paper from "components/base/Paper/Paper"
import Button, { ButtonVariants } from "components/base/Button"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import ToolTip from "components/base/ToolTip"

// Accepted filetypes for image upload
const acceptedFileTypes = [".png", ".jpg"]

type PropTypes = {
    newsletterImages?: ScaledImage[]
    onNewsletterImagesChange: (images: ScaledImage[]) => void
    images: File[]
    onImagesChange: (images: File[]) => void
}

const NewsletterImagesForm = ({
    newsletterImages,
    onNewsletterImagesChange = () => {},
    images,
    onImagesChange = () => {},
}: PropTypes) => {
    // Providers
    const { t } = useTranslation()

    // Refs
    const fileInputRef = useRef<HTMLInputElement>(null)

    // Methods
    const handleUploadImage = (files: File[]) => {
        const newImages = [...images, ...files]
        onImagesChange(newImages)
    }

    const handleDeleteNewsletterImage = (index: number) => {
        if (!newsletterImages) return
        const _newsletterImages = [...newsletterImages]
        _newsletterImages.splice(index, 1)
        onNewsletterImagesChange(_newsletterImages)
    }

    const handleDeleteImage = (index: number) => {
        const _images = [...images]
        _images.splice(index, 1)
        onImagesChange(_images)
    }

    return (
        <Paper className="col-span-2">
            <div>
                <div>
                    <div className="flex justify-between">
                        <h3 className="mb-2">{t("common.images")}</h3>
                        <ToolTip content={t("post.tooltip.images")} />
                    </div>
                    <React.Fragment>
                        <Button
                            variant={ButtonVariants.secondary}
                            className="mb-2"
                            onClick={() => {
                                if (fileInputRef.current) fileInputRef.current.click()
                            }}
                        >
                            <AddIcon className="mr-2" />
                            {t("common.addX", { type: t("common.image") })}
                        </Button>
                        <input
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            type="file"
                            accept={acceptedFileTypes.join(",")}
                            multiple={true}
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    handleUploadImage(Array.from(e.target.files))
                                }
                            }}
                        />
                    </React.Fragment>
                </div>
                <div className="flex flex-row flex-wrap">
                    {newsletterImages?.map((image, index) => {
                        return (
                            <div key={index} className="relative w-1/2">
                                <img alt="newsletter" className="w-full" src={image.med} />
                                <CloseIcon
                                    className="absolute top-0 right-0 m-1 cursor-pointer bg-white opacity-50 rounded text-red-500 hover:opacity-100"
                                    onClick={() => handleDeleteNewsletterImage(index)}
                                />
                            </div>
                        )
                    })}
                    {images?.map((f, index) => {
                        return (
                            <div key={index} className="relative w-1/2">
                                <img
                                    alt="newsletter"
                                    className="w-80"
                                    src={URL.createObjectURL(f)}
                                />
                                <CloseIcon
                                    className="absolute top-0 right-0 m-1 cursor-pointer bg-white opacity-50 rounded text-red-500 hover:opacity-100"
                                    onClick={() => handleDeleteImage(index)}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </Paper>
    )
}

export default NewsletterImagesForm
