import * as React from "react"
import { useState } from "react"

import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"

import EditIcon from "@mui/icons-material/Edit"

import TableRow from "@mui/material/TableRow"
import style from "./TableRow.module.css"
import { useTranslation } from "react-i18next"
import truncate from "helper/Truncate"

import BaseForm, { NotificationFormVariants } from "components/Notifications/BaseForm"
import Popup, { PopupVariants } from "components/base/Popup"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { localizedDate } from "helper/DateUtils"

type _Row = {
    date: Date
    id: string
    title: string
    content: string
    url: string
}

type PropTypes = {
    row: _Row
    maxCharsInColumn?: number
    notifications: any[]
}

const Row = ({ row, maxCharsInColumn = 65, notifications }: PropTypes) => {
    const [open, setOpen] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)

    const { t } = useTranslation()

    let scheduled = row.date > new Date()

    const getNotificationFromID = (id: string) => {
        //TODO: Error Handling
        let notification = notifications.find((n) => {
            return n.id === id
        })
        return notification
    }

    const RowClick = () => {
        if (!scheduled) {
            setOpen(!open)
        }
    }

    // Make the Rows expandable by adding another collapsed row.
    const ExpandedRow = () => {
        return
    }

    const cellClassName = `flex flex-col break-words ${style["div"]}`

    return (
        <React.Fragment>
            <Popup
                open={editModal}
                setOpen={setEditModal}
                variant={PopupVariants.editForm}
                noIcon
                closeButton
                onConfirm={() => {
                    setEditModal(false)
                }}
                content={
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BaseForm
                            variant={NotificationFormVariants.edit}
                            notification={getNotificationFromID(row.id)}
                        ></BaseForm>
                    </LocalizationProvider>
                }
            />
            <TableRow hover tabIndex={-1} key={row.title}>
                <TableCell align="center" className={cellClassName} onClick={RowClick}>
                    <div onClick={RowClick}>
                        <div>{truncate(row.title, maxCharsInColumn)}</div>
                        {scheduled ? (
                            <div className="text-black text-sm text-center rounded bg-warning font-body mt-1">
                                {t("notification.table.scheduled")}
                            </div>
                        ) : (
                            <div className="text-white text-sm text-center rounded bg-success font-body mt-1">
                                {t("notification.table.sent")}
                            </div>
                        )}
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={RowClick}
                        ></IconButton>
                    </div>
                </TableCell>
                <TableCell align="center" className={cellClassName} onClick={RowClick}>
                    <div>{truncate(row.content, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={cellClassName} onClick={RowClick}>
                    <div>{truncate(row.url, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell align="center" className={cellClassName} onClick={RowClick}>
                    <div>{localizedDate(row.date as Date | any)}</div>
                </TableCell>
                <TableCell className={`break-words ${style["div"]}`} onClick={RowClick}>
                    <div className={"flex justify-center"}>
                        {scheduled ? (
                            <div
                                className={
                                    "hover:bg-hoverDark cursor-pointer h-12 w-12 rounded-xl box-border flex items-center justify-center "
                                }
                                onClick={() => {
                                    if (scheduled) {
                                        setEditModal(!open)
                                    }
                                }}
                            >
                                <EditIcon />
                            </div>
                        ) : null}
                    </div>
                </TableCell>
            </TableRow>
            {ExpandedRow()}
        </React.Fragment>
    )
}

export default Row
