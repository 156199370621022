import { useEffect, useCallback, useState, useRef } from "react"
import { useTranslation } from "react-i18next"

import CONSTS from "constants/CONSTS"

// Services
import { uploadImage as apiUploadImage, UploadImageResponse } from "api/image"

// Template
import { checkExpectedComponents, ExpectedCmp } from "./verifyTheme"
import Template, {
    ComponentModel,
    DefaultTabComponent,
    DefaultWebviewComponent,
    DefaultProfileComponent,
    TabbedViewComponent,
} from "template/components"

// Components
import HomeTab, { getHomeTabComponent, HomeTabDesign, homeTabDesigns } from "../src/HomeTab"
import ColorTheme, { ThemeRef } from "./ThemeEditor"

import AppPreview, { AppPreviewRef } from "components/AppPreview"
import Button, { ButtonVariants } from "components/base/Button"
import Input from "components/base/Input"
import LoadingCover from "components/base/LoadingCover/LoadingCover"
import CompanyModel from "models/Company"
import { getDesignValidatorFromType } from "./DesignEditors/validation"

const WEBVIEW_TAB_IDX = 1

const expectedTemplate: ExpectedCmp = {
    type: "tabbed_view",
    components: [
        {
            type: "tab",
            components: [
                {
                    type: "oneofHolder",
                    __oneof: true,
                    components: Object.entries(homeTabDesigns).map(([name, design]) => {
                        if (name == Object.keys(homeTabDesigns)[0]) {
                            return { ...design.expected_components, __default: true }
                        }

                        return { ...design.expected_components }
                    }),
                },
            ],
        },
        { type: "tab", __optional: true, components: [{ type: "webview" }] },
        {
            type: "tab",
            components: [{ type: "profile", components: [] }],
            __default: true,
            __default_component: {
                ...DefaultTabComponent(),
                title: { en: "Profile" },
                icon: { source: "material", name: "web" },
                components: [DefaultProfileComponent()],
            },
        },
    ],
}

const AppTheme = ({
    template,
    onsubmit,
    company,
    isDemo = false,
}: {
    template: Template
    onsubmit: (template: Template, memImages: { [index: string]: File }) => Promise<void>
    company: CompanyModel
    isDemo?: boolean
}) => {
    const { t } = useTranslation()

    const [webviewEnabled, setWebviewEnabled] = useState(false)
    const [appBarEnabled, setAppBarEnabled] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const [designName, setDesignName] = useState<string | null>(null)
    const [designSettings, setDesignSettings] = useState<HomeTabDesign | null>(null)
    const [designComponent, setDesignComponent] = useState<ComponentModel | null>(null)
    const [memoryImages, setMemoryImages] = useState<{ [index: string]: File }>({})
    const [designValidationErrors, setDesignValidationErrors] = useState<Map<string, string>>(
        new Map()
    )

    // Hold unsaved component changes
    const [unsavedComponents, setUnsavedComponents] = useState<{
        [designName: string]: ComponentModel
    }>({})

    const themeRef = useRef<ThemeRef>(null)
    const appPreviewRef = useRef<AppPreviewRef>(null)

    useEffect(() => {
        if (!checkExpectedComponents(template.root, expectedTemplate)) {
            console.error("invalid template, expected: ", expectedTemplate)
        } else {
            setDesignComponent(template.root.components[0])
            const templateHomeTabDesign = currentDesign(template)
            if (templateHomeTabDesign) {
                const designName = templateHomeTabDesign.type
                setDesignName(designName)
                setDesignSettings(homeTabDesigns[designName])
            }
        }
    }, [template])

    // useEffect(() => {
    //       if (!company) {
    //           return
    //       }
    //       setTemplateLoading(true)
    //       getApplicationTemplateCurrent(company.id!)
    //           .then((res) => {
    //               let template = res as Template
    //               if (!checkExpectedComponents(template.root, expectedTemplate)) {
    //                   console.error("invalid template, expected: ", expectedTemplate)
    //                   setTemplate(null)
    //               } else {
    //                   setTemplate(template)
    //                   setDesignComponent(template.root.components[0])
    //                   const templateDesign = currentDesign(template)
    //                   if (templateDesign) {
    //                       const designName = templateDesign.type
    //                       setDesignName(designName)
    //                       setDesignSettings(homeTabDesigns[designName])
    //                   }
    //               }
    //           })
    //           .finally(() => {
    //               setTemplateLoading(false)
    //           })
    //   }, [currentCompany])

    // insert/remove the webview from the tabs when enable is toggled
    useEffect(() => {
        if (isWebviewEnabled(template) != webviewEnabled) {
            if (webviewEnabled) {
                // insert webview tab
                template.root.components.splice(WEBVIEW_TAB_IDX, 0, webviewTab())
            } else {
                // remove webview tab
                template.root.components.splice(WEBVIEW_TAB_IDX, 1)
            }
            updateRoot(template.root)
        }
    }, [webviewEnabled])

    // update root when appBar is toggled
    useEffect(() => {
        if (!template) {
            return
        }

        if (template.root.type == "tabbed_view") {
            ;(template.root as TabbedViewComponent).app_bar = appBarEnabled
        }
        updateRoot(template.root)
    }, [appBarEnabled])

    // Retrieve values from the template and update react states
    useEffect(() => {
        if (!template) {
            return
        }
        setWebviewEnabled(isWebviewEnabled(template))
        if (template.root.type == "tabbed_view") {
            if ((template.root as TabbedViewComponent).app_bar === true) {
                setAppBarEnabled(true)
            }
        }
    }, [template])

    const isWebviewEnabled = (template: Template): boolean => {
        return checkExpectedComponents(template.root, {
            type: "tabbed_view",
            components: [
                { type: "tab" },
                { type: "tab", components: [{ type: "webview" }] },
                { type: "tab" },
            ],
        })
    }

    // <<<<<<< HEAD:admin/src/views/AppTheme/src/AppTheme.tsx
    //     useEffect(() => {
    //         setWebviewEnabled(isWebviewEnabled(template))
    //     }, [template])

    // =======
    // >>>>>>> master:admin/src/views/AppTheme/AppTheme.tsx
    // Methods
    const saveTempDesign = (currentDesignName: string) => {
        if (!template || !currentDesignName || !designComponent) return
        setUnsavedComponents({ ...unsavedComponents, [currentDesignName]: designComponent })
    }

    const getCurrentParent = (template: Template): ComponentModel | null => {
        let expected_components = ["router", "route", "provider_guard_multi"]
        let curr_component = template.root.components[0]
        expected_components.every((ec) => {
            if (curr_component.components[0].type !== ec) {
                console.error("did not find component: ", ec, " in ", curr_component)
                return false
            }
            curr_component = curr_component.components[0]
            return true
        })

        return curr_component
    }

    const currentDesign = (template?: Template) => {
        // Check for unsaved design
        if (designName && unsavedComponents[designName]) {
            return unsavedComponents[designName]
        }

        if (!template) {
            return
        }

        let parent = getCurrentParent(template)
        if (!parent) {
            return
        }

        return parent.components[0]
    }

    const webviewTab = () => {
        return {
            ...DefaultTabComponent(),
            title: { en: "web" },
            icon: { source: "material", name: "web" },
            components: [DefaultWebviewComponent()],
        }
    }

    const submit = async () => {
        if (submitting) {
            console.error("Already submitting")
            return
        }

        if (!designName || !homeTabDesigns[designName]) {
            console.error("Invalid design name")
            return
        }

        const designValidationErrors = getDesignValidatorFromType(designName)(designComponent)

        if (designValidationErrors.size > 0) {
            setDesignValidationErrors(designValidationErrors)
            console.error("could not validate design")
            return
        }

        setSubmitting(true)

        // set providers
        template.providers = homeTabDesigns[designName].providers
        template.theme = themeRef.current!.getTheme()

        onsubmit(template, memoryImages).finally(() => {
            setSubmitting(false)
        })
    }

    const designChanged = (design: ComponentModel) => {
        if (!template) {
            return
        }

        let tab = template.root.components[0]
        if (tab.type !== "tab") {
            console.error(`expected 'tab' component got ${tab.type}`)
            return
        }

        tab.components = [design]
        updateRoot(template.root)
    }

    // short wrappers for AppPreview methods
    // const runResolve = () => appPreviewRef.current!.runResolve(resolveValue)  For testing
    const updateTheme = (theme: { [index: string]: any }) => {
        if (appPreviewRef.current) {
            appPreviewRef.current.updateTheme(theme)
        }
    }

    const updateRoot = useCallback(
        (root: ComponentModel) => {
            if (!appPreviewRef.current) {
                return
            }
            appPreviewRef.current.updateRoot(root)
        },
        [appPreviewRef]
    )

    // these are images that are only stored in the browser, they can be viewed in the preview but
    // are not accessible from anywhere else. Therefore we must upload them to our storage when the
    // template is submitted

    const addMemoryImage = useCallback(
        async (image: File) => {
            if (!appPreviewRef.current) {
                return
            }

            console.log("memory image name: ", image.name)

            memoryImages[image.name] = image
            setMemoryImages({ ...memoryImages })

            appPreviewRef.current.uploadImage(image.name, new Uint8Array(await image.arrayBuffer()))
        },
        [appPreviewRef]
    )

    /**
     * If the [url] follows the memory scheme the image is uploaded from [memoryImages] and
     * the url is replaced with the storage URL.
     *
     * used primarily as the [mod] for findValueMatch
     */
    const uploadAndReplaceMemoryImages = async (url: string): Promise<string> => {
        const exec = /^mem:\/\/\/template\/images\/([A-Za-z0-9._-]+)/.exec(url)
        if (!exec || exec.length < 2) {
            return Promise.reject(`${url} fit mem:// scheme but not app memory image scheme`)
        }
        let name = exec[1]
        if (!memoryImages[name]) {
            return Promise.reject(`image ${name} not in memoryImages`)
        }

        return await apiUploadImage(memoryImages[name])
            .then((res) => {
                return Promise.resolve((res as UploadImageResponse).med)
            })
            .catch((err) => {
                return Promise.reject(`image upload failed: ${err}`)
            })
    }

    // modify object in-place
    const findValueMatch = async (
        obj: { [index: string]: any },
        match: RegExp,
        mod: (key: string) => Promise<string>
    ): Promise<{ [index: string]: any }> => {
        for (const key in obj) {
            const value = obj[key]

            if (typeof value === "string") {
                if (match.test(value)) {
                    obj[key] = await mod(value)
                }
            } else if (value instanceof Object) {
                obj[key] = await findValueMatch(value, match, mod)
            }
        }
        return obj
    }

    const onTabSelection = (selectedDesignName: string) => {
        if (!designName) {
            console.warn("no design name")
            return
        }
        if (designName === selectedDesignName) {
            console.warn(
                `designName(${designName}) not equal to selected design name(${selectedDesignName})`
            )
            return
        }
        saveTempDesign(designName)

        setDesignName(selectedDesignName)
        setDesignSettings(homeTabDesigns[selectedDesignName])

        // Load unsaved design if it exists
        const unsavedComponent = unsavedComponents[selectedDesignName]
        if (unsavedComponent) {
            setDesignComponent(unsavedComponent)
        }
    }

    // Render
    if (!designSettings || !designComponent || !designName) {
        return <LoadingCover />
    }

    return (
        <div>
            <LoadingCover show={submitting} />
            <div className=" justify-between flex items-center">
                <div className="h-full bg-white">
                    <div className="grid grid-cols-3">
                        {/* Theme and design editor */}
                        <div>
                            <designSettings.editor
                                isDemo={isDemo}
                                component={currentDesign(template)!}
                                onChange={(component) => {
                                    if (designValidationErrors.size > 0) {
                                        setDesignValidationErrors(
                                            getDesignValidatorFromType(designName)(designComponent)
                                        )
                                    }
                                    designChanged(getHomeTabComponent(designName, component))
                                    setDesignComponent(component)
                                }}
                                uploadImage={addMemoryImage}
                                validationErrors={designValidationErrors}
                            />
                            <ColorTheme
                                isDemo={isDemo}
                                theme={template.theme}
                                ref={themeRef}
                                onChange={updateTheme}
                            />
                        </div>

                        {/* App Preview */}
                        <div className="w-full flex flex-col items-center px-2 bg-white">
                            <AppPreview
                                isDemo={isDemo}
                                template={template!}
                                ref={appPreviewRef}
                                company_name={company.company_name ?? ""}
                                company_id={company.id ?? ""}
                                app_backend_url={CONSTS.BACKEND_HOST}
                            />
                        </div>

                        {/* Home Tab Selection */}
                        <div className="flex flex-col justify-between">
                            <div>
                                <h3 className="text-xl text-primary mb-6 ml-4">
                                    {isDemo ? "Select theme" : t("common.selection")}:
                                </h3>
                                <HomeTab
                                    uploadImage={addMemoryImage}
                                    component={currentDesign(template)!}
                                    onChange={(componentModel) => designChanged(componentModel)}
                                    onDesignChange={onTabSelection}
                                />
                            </div>
                            <div>
                                {!isDemo && (
                                    <>
                                        <div className={"flex flex-row my-4 items-center"}>
                                            <Input
                                                id="appBarEnableCheckbox"
                                                type="checkbox"
                                                name="appBarEnableCheckbox"
                                                checked={Boolean(appBarEnabled)}
                                                onChange={(e) => setAppBarEnabled(e.target.checked)}
                                                className="m-1 w-6 h-6"
                                            />
                                            <label
                                                htmlFor="appBarEnableCheckbox"
                                                className="font-body ml-1 text-lg"
                                            >
                                                {t("appTheme.appBarEnabled")}
                                            </label>
                                        </div>
                                        <div className={"flex flex-row my-4 items-center"}>
                                            <Input
                                                id="publicCheckbox"
                                                type="checkbox"
                                                name="publicCheckbox"
                                                checked={Boolean(webviewEnabled)}
                                                onChange={(e) =>
                                                    setWebviewEnabled(e.target.checked)
                                                }
                                                className="m-1 w-6 h-6"
                                            />
                                            <label
                                                htmlFor="publicCheckbox"
                                                className="font-body ml-1 text-lg"
                                            >
                                                {t("appTheme.webviewEnabled")}
                                            </label>
                                        </div>
                                    </>
                                )}
                                {isDemo && (
                                    <div className="mb-4">
                                        <p>
                                            Do you like what you see? Continue with your design and
                                            make it your own app!
                                        </p>
                                    </div>
                                )}
                                <Button
                                    variant={ButtonVariants.outlined}
                                    className="w-full border-primary rounded-lg p-2 text-primary text-lg flex items-center"
                                    onClick={submit}
                                >
                                    <span className="mr-2">
                                        {isDemo ? "Continue" : t("common.save")}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppTheme
