import axios, { AxiosError, AxiosResponse } from "axios"
import CONSTS from "constants/CONSTS"

import CompanyModel, { ValidateCompanyModel } from "models/Company.d"

import { defaultHeaders, IAPIError, unwrapAxiosError } from "./helpers"

const getCompany = async (companyName: string): Promise<CompanyModel> => {
    return axios({
        method: "GET",
        url: `/companies/${companyName}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        validateStatus: (status) => {
            return status === 200
        },
        headers: defaultHeaders(true),
    })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err)) // IAPIError
        })
        .then((res) => {
            const validateResult = ValidateCompanyModel(res.data)
            if (validateResult.ok !== true) {
                return Promise.reject(`missing field ${validateResult.field}`)
            }
            return validateResult.value as CompanyModel
        })
}

const updateCompany = async (config: CompanyModel): Promise<CompanyModel | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "PATCH",
            url: `/companies/${config.company_name}`,
            baseURL: CONSTS.BACKEND_BASE_URL, //Change admin api in the backend
            validateStatus: (status) => {
                return status === 200
            },
            data: config,
            withCredentials: true,
            headers: defaultHeaders(true),
        })
            .then((res: AxiosResponse) => {
                const valid = ValidateCompanyModel(res.data)
                if (valid.ok !== true) {
                    return reject({
                        raisedBy: "updateCompany",
                        error: `The ${valid.field} of config is ${valid.reason}`,
                        raw: `${valid.reason}`,
                        code: 0,
                    })
                }
                return resolve(valid.value)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const getCompanies = async (): Promise<Array<CompanyModel> | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "GET",
            url: "/companies",
            baseURL: CONSTS.BACKEND_BASE_URL,
            validateStatus: (status) => {
                return status === 200
            },
            headers: defaultHeaders(true),
        })
            .then((res: AxiosResponse) => {
                if (Object.prototype.toString.call(res.data) !== "[object Array]") {
                    return reject("expected response to be array")
                }

                for (let company of res.data) {
                    const validateResult = ValidateCompanyModel(company)
                    if (!validateResult.ok) {
                        return reject(
                            `invalid response from server: missing field ${validateResult.field}`
                        )
                    }
                }

                return resolve(res.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const addCompany = async (name: string): Promise<CompanyModel | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "POST",
            baseURL: CONSTS.BACKEND_BASE_URL,
            url: "/companies",
            data: {
                name: name,
            },
            withCredentials: true,
            headers: defaultHeaders(true),
        })
            .then((res: AxiosResponse) => {
                const validateResult = ValidateCompanyModel(res.data)
                if (validateResult.ok !== true) {
                    return reject("Invalid response from server")
                }
                return resolve(validateResult.value)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const enableAnalytics = async (name: string): Promise<void | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "POST",
            baseURL: CONSTS.BACKEND_BASE_URL,
            url: `/companies/${name}/analytics`,
            headers: defaultHeaders(true),
            withCredentials: true,
        })
            .then(() => {
                return resolve()
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const checkCompanyNameAvailability = async (name: string): Promise<boolean | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "GET",
            baseURL: CONSTS.BACKEND_BASE_URL,
            url: `/companies/${name}`,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
        })
            .then((res: AxiosResponse) => {
                const validateResult = ValidateCompanyModel(res.data)
                if (validateResult.ok == true) {
                    return resolve(false)
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    // TODO: This should be its own call instead of expecting a 404
                    return resolve(true)
                } else {
                    console.log("Error checking companyName")
                    return resolve(false)
                }
            })
    })
}

const deleteCompany = () => {}

const createAppBundle = (companyName: string) => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "POST",
            baseURL: CONSTS.BACKEND_BASE_URL,
            url: `/companies/${companyName}/appbundle`,
            withCredentials: true,
            headers: defaultHeaders(true),
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse) => {
                const validateResult = ValidateCompanyModel(res.data)
                if (validateResult.ok == true) {
                    return resolve(false)
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    // TODO: This should be its own call instead of expecting a 404
                    return resolve(true)
                } else {
                    console.log("Error checking companyName")
                    return resolve(false)
                }
            })
    })
}

const companyAppleAutomation = (company: CompanyModel) => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "POST",
            baseURL: CONSTS.BACKEND_BASE_URL,
            url: `/companies/${company.company_name}/apple_automate`,
            headers: defaultHeaders(true),
            withCredentials: true,
            validateStatus: (status) => {
                return status === 200
            },
        })
            .then((res: AxiosResponse) => {})
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    // TODO: This should be its own call instead of expecting a 404
                    return resolve(true)
                } else {
                    console.log("Error checking companyName")
                    return resolve(false)
                }
            })
    })
}

const getDownloadEmbed = async (companyName: string): Promise<string | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "GET",
            baseURL: CONSTS.BACKEND_HOST,
            url: `/client/companies/${companyName}/downloadSnippet`,
            headers: {
                accept: "application/json",
            },
        })
            .then((res: AxiosResponse) => {
                return resolve(res.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

export {
    checkCompanyNameAvailability,
    companyAppleAutomation,
    createAppBundle,
    getCompany,
    updateCompany,
    getCompanies,
    addCompany,
    enableAnalytics,
    deleteCompany,
    getDownloadEmbed,
}
