import { useState } from "react"
import { useUser } from "providers/UserProvider"
import { useTranslation } from "react-i18next"

// Services
import { changePassword } from "api/user"

// Components
import Button, { ButtonVariants } from "components/base/Button"
import Paper, { PaperVariants } from "components/base/Paper"
import Input from "components/base/Input"
import Popup, { PopupVariants } from "components/base/Popup/Popup"
import { Alert, AlertProps, AlertTitle } from "@mui/material"
import Form from "components/base/Form"

// Utils
let passwordValidator = require("password-validator")

type Error = {
    lookup: string
    args?: {}
}

const Security = () => {
    const { user } = useUser({ autoFetch: false })!
    const { t } = useTranslation()

    // React States
    const [alertMessage, setAlertMessage] = useState<(AlertProps & { message: string }) | null>(
        null
    )
    const [finalizeConfirmation, setFinalizeConfirmation] = useState<boolean>(false)

    const [changePasswordConfirmationModal, setChangePasswordConfirmationModal] =
        useState<boolean>(false)

    const [currentPassword, setCurrentPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>("")

    // Page states, used for user feedback
    const [currentPasswordErrors, setCurrentPasswordErrors] = useState<Error[] | null>(null)
    const [newPasswordRepeatError, setNewPasswordRepeatError] = useState<Error[] | null>(null)
    const [newPasswordErrors, setNewPasswordErrors] = useState<Error[] | null>(null)

    let passwordScheme = new passwordValidator()
    passwordScheme
        .is()
        .min(8, "settings.security.errors.minLength") // Minimum length 8
        .is()
        .max(100, "settings.security.errors.maxLength") // Maximum length 100
        .has()
        .uppercase(1, "settings.security.errors.uppercaseLetter") // Must have uppercase letters
        .has()
        .lowercase(1, "settings.security.errors.lowercaseLetter") // Must have lowercase letters
        .has()
        .digits(1, "settings.security.errors.requireDigit") // Must have at least 1 digit
        .has()
        .not()
        .spaces(1, "settings.security.errors.noSpaces") // Should not have spaces
        .is()
        .not()
        .oneOf(["Passw0rd", "Password123"]) // Blacklist these values

    /**
     * Render error messages.
     *
     * @input messages `List` of `Error` that can be resolved by i18next
     */
    const errorMessages = (errors: Error[] | null) => {
        if (errors === null) {
            return
        }
        return (
            <div className="text-danger text-sm">
                {errors.map((error: Error) => (
                    <div>{t(error.lookup, error.args)}</div>
                ))}
            </div>
        )
    }

    const openFinalizeConfirmationModal = () => {
        setAlertMessage(null)
        if (validateFields()) {
            setChangePasswordConfirmationModal(true)
        }
        return
    }

    const reset = () => {
        setNewPassword("")
        setCurrentPassword("")
        setNewPasswordRepeat("")
        return
    }

    const submitChangePassword = () => {
        // this should only happen if there's a react state error
        if (!user) {
            return
        }

        changePassword({
            email: user?.email,
            new_password: newPassword,
            old_password: currentPassword,
        })
            .then((res) => {
                setFinalizeConfirmation(true)
            })
            .catch((err) => {
                console.log("Error changing password. " + JSON.stringify(err))
                setAlertMessage({
                    title: t("settings.security.errors.failedToChangePassword"),
                    message: err.raw.message,
                    severity: "error",
                })
            })
    }

    const closeSuccessModal = () => {
        setFinalizeConfirmation(false)
        window.location.reload()
        return
    }

    const validateFields = () => {
        let isValid = true

        if (currentPassword.length === 0) {
            setCurrentPasswordErrors([{ lookup: "settings.security.errors.currentPasswordEmpty" }])
            isValid = false
            return isValid
        }

        if (newPassword === currentPassword) {
            setNewPasswordErrors([
                { lookup: "settings.security.errors.newPasswordIsSameAsCurrent" },
            ])
            isValid = false
            return isValid
        }

        let validationErrors = passwordScheme.validate(newPassword, { details: true })
        if (validationErrors.length > 0) {
            let errs = validationErrors.map((error: any) => {
                return { lookup: error.message, args: { count: error.arguments } }
            })

            setNewPasswordErrors(errs)
            isValid = false
        }

        if (newPassword !== newPasswordRepeat || newPassword.length <= 0) {
            setNewPasswordRepeatError([{ lookup: "settings.security.errors.passwordMissmatch" }])
            isValid = false
            return isValid
        }

        return isValid
    }

    return (
        <div>
            <Popup
                open={finalizeConfirmation}
                setOpen={setFinalizeConfirmation}
                variant={PopupVariants.success}
                onConfirm={closeSuccessModal}
                title={t("common.success")}
                content={t("settings.security.passwordUpdated")}
                cofirmationsButtonText={t("common.continue")}
            />

            <Popup
                open={changePasswordConfirmationModal}
                setOpen={setChangePasswordConfirmationModal}
                variant={PopupVariants.confirmation}
                onConfirm={submitChangePassword}
                title={t("settings.security.changePassword")}
                content={t("settings.security.confirmPasswordUpdate")}
                cancelButtonText={t("common.cancel")}
                cofirmationsButtonText={t("common.confirm")}
            />
            <div>
                <div className="text-xl font-body font-bold tracking-wide px-4 py-2">
                    {t("common.security")}
                </div>
                <div className="text-l font-body font-bold tracking-wide px-4 py-2">
                    {t("settings.security.changePassword")}
                </div>
                <div className={"grid grid-cols-2 gap-2"}>
                    <Form>
                        <div className={"p-4 w-5/6"}>
                            <Input
                                type="password"
                                name={"currentPassword"}
                                label={t("settings.security.currentPassword")}
                                value={currentPassword}
                                onChange={(e: any) => {
                                    setCurrentPassword(e.target.value)
                                    setCurrentPasswordErrors(null)
                                }}
                                className={` w-full  rounded-md mt-1 
                                            ${
                                                currentPasswordErrors
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                            />
                            {errorMessages(currentPasswordErrors)}
                        </div>
                        <div className={"p-4 w-5/6"}>
                            <Input
                                name={"newPassword"}
                                type="password"
                                label={t("settings.security.newPassword")}
                                tooltip={t("settings.security.newPasswordTip")}
                                value={newPassword}
                                onChange={(e: any) => {
                                    setNewPassword(e.target.value)
                                    setNewPasswordErrors(null)
                                }}
                                className={` w-full  rounded-md mt-1 
                                            ${
                                                newPasswordErrors
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                            />
                            {errorMessages(newPasswordErrors)}
                        </div>
                        <div className={"p-4 w-5/6"}>
                            <Input
                                name={"newPasswordRepeat"}
                                type="password"
                                label={t("settings.security.newPasswordRepeat")}
                                value={newPasswordRepeat}
                                onChange={(e: any) => {
                                    setNewPasswordRepeat(e.target.value)
                                    setNewPasswordRepeatError(null)
                                }}
                                className={` w-full rounded-md mt-1 
                                            ${
                                                newPasswordRepeatError
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                            />
                            {errorMessages(newPasswordRepeatError)}
                        </div>
                    </Form>
                    {alertMessage && (
                        <div className="flex flex-col justify-end">
                            <Alert severity={alertMessage.severity}>
                                <AlertTitle>{alertMessage.title}</AlertTitle>
                                {alertMessage.message}
                            </Alert>
                        </div>
                    )}
                </div>
                <Paper
                    variant={PaperVariants.dark}
                    className="mt-8 col-span-3 flex justify-between"
                >
                    <Button variant={ButtonVariants.secondary} onClick={reset}>
                        {t("common.reset")}
                    </Button>
                    <Button name={"changePassword:submit"} onClick={openFinalizeConfirmationModal}>
                        {t("settings.security.changePassword")}
                    </Button>
                </Paper>
            </div>
        </div>
    )
}

export default Security
