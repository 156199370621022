import React, { useState, FormEvent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import UserClass from "classes/User"
import { useUser } from "providers/UserProvider"
import Button, { ButtonVariants } from "components/base/Button"
import Input from "components/base/Input"

import { signup } from "api/user"
import CONSTS from "constants/CONSTS"

const RegisterForm = () => {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [passwordRe, setPasswordRe] = useState<string>("")

    // TODO: Display this somewhere
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorLogin, setErrorLogin] = useState<boolean>(false)

    const { setUser } = useUser({ autoFetch: false })!
    const { t } = useTranslation()
    const queryParams = useQuery()

    function useQuery() {
        const { search } = useLocation()

        return useMemo(() => new URLSearchParams(search), [search])
    }

    const handleRegister = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!email || !password || !passwordRe) {
            setErrorLogin(true)
            return
        } else if (password !== passwordRe) {
            console.error("missmatch passwords")
            return
        }

        const usercredentials = {
            email: email,
            password: password,
        }

        let cameFrom = queryParams.get("came_from")
        if (!cameFrom || cameFrom === "") {
            return
        }
        let invite = cameFrom.substring(cameFrom.lastIndexOf("/") + 1)

        signup(usercredentials, invite)
            .then((res: any) => {
                console.log("registered", res)
                try {
                    const usr = new UserClass(res.token)
                    setUser(usr)
                } catch (e) {
                    console.error("failed to parse user token: ", e)
                }
            })
            .catch((err) => {
                console.error("register failed", err)
            })
    }

    /** Move to separate file */
    const google_oauth_link = (): string => {
        if (!CONSTS.GOOGLE_OAUTH_CLIENT_ID) {
            console.error("Missing google oauth client id")
            return ""
        }

        const base_url = "https://accounts.google.com/o/oauth2/v2/auth"
        const scopes = ["profile", "email"]
        const access_type = "offline"
        const response_type = "code"
        const redirect_uri = `${window.location.protocol}//${window.location.host}/login?redirect=true&redirect_state=google_oauth`
        const client_id = CONSTS.GOOGLE_OAUTH_CLIENT_ID

        //TODO: add xsrf/csrf token to state

        let state: any = {
            redirect: queryParams.get("redirect"),
            redirect_state: queryParams.get("redirect_state"),
            came_from: getRedirectLocation(null),
        }

        const params_data = {
            scope: scopes.join(" "),
            access_type: access_type,
            response_type: response_type,
            redirect_uri: redirect_uri,
            include_granted_scopes: "true",
            client_id: client_id,
            state: btoa(JSON.stringify(state)),
        }
        let params = new URLSearchParams(params_data)

        return base_url + "?" + params.toString()
    }

    const getRedirectState = (): string => {
        let redirectState = queryParams.get("redirect_state")
        if (redirectState === null) {
            console.warn("redirected without state")
            redirectState = ""
        }
        return redirectState
    }

    const getRedirectLocation = (redirectState: string | null): string => {
        if (redirectState === null) {
            redirectState = getRedirectState()
        }

        let redirectLocation
        switch (redirectState) {
            case "google_oauth":
                let stateb64 = queryParams.get("state")
                if (stateb64 != null) {
                    let statejson = atob(stateb64)
                    let state = JSON.parse(statejson)
                    if (state.came_from !== "") {
                        redirectLocation = state.came_from
                    }
                }
                break
            default:
                redirectLocation = queryParams.get("came_from")
                break
        }

        if (redirectLocation === null || redirectLocation === "") {
            redirectLocation = "/dashboard"
        }

        return redirectLocation
    }

    //TODO: combine Login & Register Form.
    return (
        <div>
            <form onSubmit={(e) => handleRegister(e)}>
                <h1 className="text-center text-3xl text-#353535 py-3">{t("Sign Up")}</h1>

                <div className="mb-4">
                    <Input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                        name="register_form:email"
                        value={email}
                        placeholder={t("signup.identifier")}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <Input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                        name="register_form:password"
                        type="password"
                        placeholder={t("Password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <Input
                        name="register_form:password_repeat"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                        type="password"
                        placeholder={t("Repeat Password")}
                        value={passwordRe}
                        onChange={(e) => setPasswordRe(e.target.value)}
                    />
                </div>

                <div className="grid grid-cols-1 justify-items-start">
                    <Button
                        name="register_form:submit"
                        variant={ButtonVariants.primary}
                        className="font-bold w-full justify-self-end"
                        type={"submit"}
                    >
                        {t("Sign Up")}
                    </Button>
                </div>
            </form>

            <Button
                className={"mt-4 mb-6 w-full justify-self-end"}
                variant={ButtonVariants.secondary}
                onClick={() => {
                    window.location.replace(google_oauth_link())
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="24px"
                    height="24px"
                >
                    <path
                        fill="#FFC107"
                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                        fill="#FF3D00"
                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                        fill="#4CAF50"
                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                        fill="#1976D2"
                        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                </svg>
                <span className="ml-2">Sign up with Google</span>
            </Button>
        </div>
    )
}

export default RegisterForm
