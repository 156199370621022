import { createContext } from "react"
import { ComponentModel } from "template/components"

export type CopyCutAction = "cut" | "copy" | "none"

export type CopyCutData = {
    action: CopyCutAction
    component: ComponentModel | undefined // what to copy
    parent: ComponentModel | undefined // new parent = where to cut/copy to
}

// We use a local context to share data between root and children
export type CopyCutDataContextType = {
    // Store cutData if we have something cut currently
    copyCutData: CopyCutData
    setCopyCutData: (copyCutdata: CopyCutData) => void

    onCopyOrCut: (parent: ComponentModel, data: ComponentModel, action: "cut" | "copy") => void
    onPaste: (parent: ComponentModel) => void
    onCancel: () => void
}

export const DefaultCopyCutData: CopyCutData = {
    action: "none",
    component: undefined,
    parent: undefined,
}
export const CopyCutDataContext = createContext<CopyCutDataContextType>({
    copyCutData: DefaultCopyCutData,
    setCopyCutData: () => {},
    onCopyOrCut: () => {},
    onPaste: () => {},
    onCancel: () => {},
})
