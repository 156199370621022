import { Navigate } from "react-router-dom"

// Providers
import { useUser } from "providers/UserProvider"

// Constants
import { ROUTES } from "constants/ROUTES"
import { UserRole } from "models/User"

type PropTypes = {
    path: string
    element: JSX.Element
    exact?: boolean
    userRequired?: boolean
    roleRequired?: UserRole[]
}

export const ProtectedRoute = (props: PropTypes) => {
    // Providers
    const { user } = useUser({ autoFetch: false })!

    // Constants
    const LoginNavigate = () => <Navigate to={ROUTES.login.path} />

    // Check user is logged in
    if (props.userRequired && !user) {
        return <LoginNavigate />
    }

    // Check user has required role
    if (props.roleRequired && user && !props.roleRequired.includes(user.role)) {
        return <LoginNavigate />
    }

    return props.element
}

export default ProtectedRoute
