// Components
import ProfileOverview from "components/ProfileOverview"
import Frame from "components/base/Frame"

const Settings = () => {
    return (
        <Frame>
            <ProfileOverview />
        </Frame>
    )
}

export default Settings
