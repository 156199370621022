/*######################################################*/
/*                                                      */
/*                                                      */
/*            GENERATED FILE, DO NOT MODIFY             */
/*                                                      */
/*                                                      */
/*######################################################*/

export interface TemplateAction {
    system: TemplateActionSystems
    method: any
}

export enum TemplateActionSystems {
    Noop = "noop",
    Provider = "provider",
    Navigation = "navigation",
    Webview = "webview",
}

// The available Methods for System <no value>

export enum TemplateActionNoops {
    Noop = "noop",
}

export interface TemplateActionNoop extends TemplateAction {
    method: TemplateActionNoops
}

// Method Classes
export interface TemplateActionNoopNoop extends TemplateActionNoop {}
export enum TemplateActionProviders {
    Reload = "reload",
}

export interface TemplateActionProvider extends TemplateAction {
    method: TemplateActionProviders
}

// Method Classes
export interface TemplateActionProviderReload extends TemplateActionProvider {
    provider_name: string
}
export enum TemplateActionNavigations {
    Goto = "goto",
    GotoTab = "goto_tab",
}

export interface TemplateActionNavigation extends TemplateAction {
    method: TemplateActionNavigations
}

// Method Classes
export interface TemplateActionNavigationGoto extends TemplateActionNavigation {
    url: string

    data: { [index: string]: string }
}
export interface TemplateActionNavigationGotoTab extends TemplateActionNavigation {
    tab_index: number
}
export enum TemplateActionWebviews {
    Goto = "goto",
}

export interface TemplateActionWebview extends TemplateAction {
    method: TemplateActionWebviews
}

// Method Classes
export interface TemplateActionWebviewGoto extends TemplateActionWebview {
    url: string
}

// Generated inputs

export const systemFromString = (system: string) => {
    switch (system) {
        case "noop":
            return TemplateActionSystems.Noop
        case "provider":
            return TemplateActionSystems.Provider
        case "navigation":
            return TemplateActionSystems.Navigation
        case "webview":
            return TemplateActionSystems.Webview
        default:
            throw `bad system ${system}`
    }
}

export const methodFromString = (system: TemplateActionSystems, method: string) => {
    switch (system) {
        case TemplateActionSystems.Noop:
            switch (method) {
                case "noop":
                    return TemplateActionNoops.Noop
                default:
                    throw `bad method ${method} for system ${system}`
            }
        case TemplateActionSystems.Provider:
            switch (method) {
                case "reload":
                    return TemplateActionProviders.Reload
                default:
                    throw `bad method ${method} for system ${system}`
            }
        case TemplateActionSystems.Navigation:
            switch (method) {
                case "goto":
                    return TemplateActionNavigations.Goto
                case "goto_tab":
                    return TemplateActionNavigations.GotoTab
                default:
                    throw `bad method ${method} for system ${system}`
            }
        case TemplateActionSystems.Webview:
            switch (method) {
                case "goto":
                    return TemplateActionWebviews.Goto
                default:
                    throw `bad method ${method} for system ${system}`
            }
        default:
            throw `bad system ${system}`
    }
}

export const NoopAction = () => {
    return {
        system: TemplateActionSystems.Noop,
        method: TemplateActionNoops.Noop,
    }
}

const SelectSystem = () => {}
