import { useState } from "react"
import "./Switch.scss"

type PropTypes = {
    leftLabel?: string
    rightLabel?: string
    onLeftClick?: () => void
    onRightClick?: () => void
    defaultOn?: "left" | "right"
    className?: string
}

const Switch = ({
    leftLabel,
    rightLabel,
    onLeftClick = () => {},
    onRightClick = () => {},
    defaultOn = "left",
    className = "",
}: PropTypes) => {
    const [leftOn, setLeftOn] = useState(defaultOn === "left")
    return (
        <div className="inline-block">
            <div className={"fedd-switch border border-gray-300 " + className}>
                <div
                    className={`switch-button ${leftOn ? "switch-button-on" : "hover:bg-gray-200"}`}
                    onClick={() => {
                        setLeftOn(true)
                        onLeftClick()
                    }}
                >
                    <span className="whitespace-nowrap px-4">{leftLabel}</span>
                </div>
                <div
                    className={`switch-button ${
                        !leftOn ? "switch-button-on" : "hover:bg-gray-200"
                    }`}
                    onClick={() => {
                        setLeftOn(false)
                        onRightClick()
                    }}
                >
                    <span className="whitespace-nowrap mx-4">{rightLabel}</span>
                </div>
            </div>
        </div>
    )
}

export default Switch
