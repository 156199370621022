import { validate, ValidationConstraint, ModelValidateResult } from "models/helpers"

export enum NOTIFICATION_VERSION {
    "1.0.0" = "1.0.0",
    "2.0.0" = "2.0.0",
}

export interface NotificationModel {
    version: NOTIFICATION_VERSION
    meta: NotificationMeta
    data: NotificationData
    id: string
}

// Only used when creating the notification as that's when we do not have the id
export interface NotificationCreateModel {
    version: NOTIFICATION_VERSION
    meta: NotificationMeta
    data: NotificationData
    // this is only here because BaseForm uses it. Remove when baseform is fixed
    id?: string
}

interface NotificationMeta {
    scheduled_at: Date
    company_name: string
    created_at?: string
    created_by?: string
    sent_at?: string
}

/******************************/
/* Notification Data Versions */
/******************************/

export type NotificationData = NotificationV1Data | NotificationV2Data

export interface NotificationV1Data {
    topic: string
    title: string
    body: string
    path: string
    image_url?: string
}

export interface NotificationV2Data {
    topic: string
    title: string
    body: string
    actions?: NotificationAction[]
}

export interface NotificationAction {
    // ActionType defines how the notification is processed
    type: string
    // Action defines the parameter for the specific action type. Values are bound by the ActionType
    parameters: { [index: string]: string }
}

/*********************************/
/* Notification Data Constraints */
/*********************************/

const NotificationDataSharedConstraints: ValidationConstraint[] = [
    { field: "title", type: "string" },
    { field: "topic", type: "string" },
    { field: "body", type: "string" },
]

const NotificationDataV1ModelConstraints: ValidationConstraint[] = [
    ...NotificationDataSharedConstraints,
    { field: "path", type: "string" },
]

const NotificationDataV2ModelConstraints: ValidationConstraint[] = [
    ...NotificationDataSharedConstraints,
    { field: "actions", type: "array" },
]

/****************************/
/* Notification Constraints */
/****************************/
const NotificationSharedConstraints: ValidationConstraint[] = [
    { field: "version", type: "string" },
    { field: "meta", type: "object" },
    { field: "data", type: "object" },
]
const NotificationConstraints: ValidationConstraint[] = [
    ...NotificationSharedConstraints,
    { field: "version", type: "string" },
]
const NotificationCreateConstraints: ValidationConstraint[] = [...NotificationSharedConstraints]

const ValidateNotificationCreateModel = (n: any): ModelValidateResult<NotificationCreateModel> => {
    let valid = validate<NotificationCreateModel>(n, NotificationCreateConstraints)
    if (!valid.ok) {
        return valid
    }
    var version = n["version"]

    let dataValid = validateData<NotificationCreateModel>(version, n["data"])
    if (!dataValid.ok) {
        dataValid.field = "data." + dataValid.field
    }
    return dataValid
}
const ValidateNotificationModel = (n: any): ModelValidateResult<NotificationModel> => {
    let valid = validate<NotificationModel>(n, NotificationConstraints)
    if (!valid.ok) {
        return valid
    }
    var version = n["version"]

    let dataValid = validateData<NotificationModel>(version, n["data"])
    if (!dataValid.ok) {
        dataValid.field = "data." + dataValid.field
    }
    return dataValid
}

const validateData = <T>(version: string, n: any): ModelValidateResult<T> => {
    // validate data
    switch (version.split(".")[0]) {
        case "1":
            return validate<T>(n, NotificationDataV1ModelConstraints)
        case "2":
            return validate<T>(n, NotificationDataV2ModelConstraints)
        default:
            return { ok: false, field: "version", reason: `unsupported version ${version}` }
    }
}

export { ValidateNotificationCreateModel, ValidateNotificationModel }
