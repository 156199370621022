import { useCallback, createContext, useState, useContext } from "react"
import Props from "providers/Props"
import { AxiosError } from "axios"

// Api
import { getNewsletters } from "api/newsletter"

// Models
import { NewsletterRespModel } from "models/Newsletter"

// Store
import { useCompanies } from "providers/company/CompaniesProvider"

type CompanyNewslettersContextType = {
    newsletters: NewsletterRespModel[]
    setNewsletters: (value: NewsletterRespModel[]) => void
}

const CompanyNewsletterContext = createContext<CompanyNewslettersContextType | undefined>(undefined)

export const CompanyNewslettersProvider = ({ children }: Props) => {
    const [newsletters, setNewsletters] = useState<NewsletterRespModel[]>([])

    return (
        <CompanyNewsletterContext.Provider value={{ newsletters, setNewsletters }}>
            {children}
        </CompanyNewsletterContext.Provider>
    )
}

export const useCompanyNewsletters = () => {
    const { newsletters, setNewsletters } = useContext(CompanyNewsletterContext)!
    const [isLoadingNewsletters, setIsloadingNewsletters] = useState(false)
    const [loadingNewslettersError, setLoadingNewslettersError] = useState<AxiosError<any> | null>(
        null
    )
    const { currentCompany } = useCompanies({ autoFetch: true })!

    const fetchNewsletters = useCallback(() => {
        setIsloadingNewsletters(true)
        if (currentCompany === null) {
            setIsloadingNewsletters(false)
            return
        }
        getNewsletters(currentCompany.company_name)
            .then((newsletters) => {
                setNewsletters(newsletters.data)
            })
            .catch((error) => {
                console.error("failed to get newsletters: ", error)
                setLoadingNewslettersError(error)
            })
            .finally(() => {
                setIsloadingNewsletters(false)
            })
    }, [setNewsletters, currentCompany])

    return {
        newsletters,
        setNewsletters,
        fetchNewsletters,
        isLoadingNewsletters,
        loadingNewslettersError,
    }
}
