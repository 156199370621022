/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import style from "./Notifications.module.css"

// Providers
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/company/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"
import { useUser } from "providers/UserProvider"

// Api
import { getNotifications, NotificationState } from "api/notification"

// Components
import Paper from "components/base/Paper"
import Button, { ButtonVariants } from "components/base/Button"
import Frame from "components/base/Frame"
import NotificationsTable from "components/Notifications/NotificationsTable"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { CircularProgress } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"

// Constants
import { ROUTES } from "constants/ROUTES"
import { NotificationV1Data } from "models/Notification"

const Notification = () => {
    // Providers
    const { t } = useTranslation()
    const navigate = useNavigate()
    const alert = useAlertContext()
    const { currentCompany, companiesFetching } = useCompanies({ autoFetch: true })!
    const { user } = useUser({ autoFetch: false })!

    // State
    const [scheduledTableData, setScheduledTableData] = useState<any[]>([])
    const [sentTableData, setSentTableData] = useState<any[]>([])
    const [scheduledNotifications, setScheduledNotifications] = useState<any[]>([])
    const [sentNotifications, setSentNotifications] = useState<any[]>([])
    const [scheduledNotificationsLoading, setScheduledNotificationsLoading] =
        useState<boolean>(false)
    const [sentNotificationsLoading, setSentNotificationsLoading] = useState<boolean>(false)
    const [tab, setTab] = React.useState("1")

    // Functions
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue)
    }

    const fetchScheduledNotifications = useCallback(() => {
        if (!currentCompany || !user) {
            return
        }
        if (scheduledNotificationsLoading) {
            return
        }
        setScheduledNotificationsLoading(true)
        getNotifications(currentCompany?.company_name, {
            state: NotificationState.scheduled,
        })
            .then((notifications) => {
                if (
                    Object.prototype.toString.call(notifications) !== "[object Array]" ||
                    axios.isAxiosError(notifications) ||
                    notifications === undefined
                ) {
                    setScheduledNotificationsLoading(false)
                    return Promise.reject("err")
                    // setTableData([emptyTableData])
                } else {
                    setScheduledNotifications(notifications)
                    // Divide Notification info into table friendly format
                    let x: any = []
                    notifications.forEach((n) => {
                        let data = n.data as NotificationV1Data
                        x = x.concat({
                            title: data.title,
                            content: data.body,
                            url: data.path,
                            date: new Date(n.meta.scheduled_at),
                            id: n.id,
                        })
                    })
                    setScheduledTableData(x)
                    setScheduledNotificationsLoading(false)
                    return Promise.resolve()
                }
            })
            .catch((err) => {
                console.log("Error fecthing Notifications: " + JSON.stringify(err))
                alert.setAlert({
                    alertMessage: t("Error fecthing Notifications: ") + JSON.stringify(err),
                    alertSeverity: "error",
                })
                setScheduledNotificationsLoading(false)
                setScheduledTableData([])
            })
    }, [currentCompany?.company_name])

    const fetchSentNotifications = useCallback(() => {
        if (!currentCompany || !localStorage.getItem("userToken")) {
            return
        }
        if (sentNotificationsLoading) {
            return
        }
        setSentNotificationsLoading(true)
        getNotifications(currentCompany?.company_name, { state: NotificationState.sent })
            .then((notifications) => {
                if (
                    Object.prototype.toString.call(notifications) !== "[object Array]" ||
                    axios.isAxiosError(notifications) ||
                    notifications === undefined
                ) {
                    setSentNotificationsLoading(false)
                    return Promise.reject("err")
                    // setTableData([emptyTableData])
                } else {
                    setSentNotifications(notifications)
                    // Divide Notification info into table friendly format
                    let x: any = []
                    notifications.forEach((n) => {
                        let data = n.data as NotificationV1Data
                        x = x.concat({
                            title: data.title,
                            content: data.body,
                            url: data.path,
                            date: new Date(n.meta.scheduled_at),
                            id: n.id,
                        })
                    })
                    setSentTableData(x)
                    setSentNotificationsLoading(false)
                    return Promise.resolve()
                }
            })
            .catch((err) => {
                alert.setAlert({
                    alertMessage: "Error fecthing Notifications: " + JSON.stringify(err),
                    alertSeverity: "error",
                })
                setSentNotificationsLoading(false)
                setSentTableData([])
            })
    }, [currentCompany?.company_name])

    // Effects
    useEffect(() => {
        if (!currentCompany) {
            return
        }
        fetchScheduledNotifications()
        fetchSentNotifications()
    }, [currentCompany])

    // Render
    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        {/* Top Banner  */}
                        <Paper>
                            <div className={`m-auto ${style.gridCustomWidth}`}>
                                <div className="justify-between flex-wrap p-2 ">
                                    <h1 className="text-bold text-2xl font-body">
                                        {t("common.notifications")}
                                    </h1>
                                </div>
                                <Button
                                    name="createNotification"
                                    variant={ButtonVariants.primary}
                                    onClick={() => {
                                        navigate(ROUTES.createNotification.path)
                                    }}
                                >
                                    <AddIcon className="mr-2" /> {t("common.notification")}
                                </Button>
                            </div>
                        </Paper>

                        {/* Notifications Table */}
                        <Paper>
                            <Box sx={{ width: "100%", typography: "body1" }}>
                                <TabContext value={tab}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList
                                            onChange={handleTabChange}
                                            aria-label="notifications-tab"
                                        >
                                            <Tab
                                                label={t("notification.table.tabs.scheduled")}
                                                value="1"
                                            />
                                            <Tab
                                                label={t("notification.table.tabs.sent")}
                                                value="2"
                                            />
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1">
                                        <div className=" bg-light p-4 rounded-xl shadow-none box-border">
                                            {scheduledNotificationsLoading || companiesFetching ? (
                                                <div className="grid w-full justify-center">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <NotificationsTable
                                                    data={scheduledTableData}
                                                    notifications={scheduledNotifications}
                                                />
                                            )}
                                        </div>
                                    </TabPanel>

                                    <TabPanel value="2">
                                        <div className=" bg-light p-4 rounded-xl shadow-none box-border">
                                            {sentNotificationsLoading || companiesFetching ? (
                                                <div className="grid w-full justify-center">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <NotificationsTable
                                                    data={sentTableData}
                                                    notifications={sentNotifications}
                                                />
                                            )}
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Paper>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default Notification
