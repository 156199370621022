import { useEffect, useRef, useState } from "react"

// Services
import { createNewsletter, getNewsletter, updateNewsletter, deleteNewsletter } from "api/newsletter"
import { uploadImage } from "api/image"
import { getOffer } from "api/offers"

// Providers
import { useParams, useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/company/CompaniesProvider"
import { useCompanyPosts } from "providers/company/CompanyPosts"
import { useAlertContext } from "providers/AlertProvider"

// Models
import { NewsletterModel, OfferModel } from "models"

// Components
import Button, { ButtonVariants } from "components/base/Button"
import Paper from "components/base/Paper"
import Popup, { PopupVariants } from "components/base/Popup"
import NewsletterForm from "./components/NewsletterForm"
import SettingsForm from "./components/SettingsForm"
import NewsletterImagesForm from "./components/NewsletterImagesForm"
import LinkForm from "./components/LinkForm"
import Links from "./components/Links"
import Offers from "./components/Offers"
import OfferForm from "./components/OfferForm"
import Spinner from "../base/Spinner"
import ToolTip from "components/base/ToolTip"

// Utils
import { dateFromToday } from "helper/DateUtils"

// Constants
import { Paths } from "constants/ROUTES"
import React from "react"

const defaultNewsletter: NewsletterModel = {
    company_name: "",
    title: "",
    content: "",
    images: [],
    publish_time: new Date(),
    state: "draft",
    show_countdown: false,
    categories: [],
    offers: [],
    links: [],
    created_at: new Date(),
    updated_at: new Date(),
}

const DEFAULT_EXPIRATION_DAYS = 30

const CreationModal = () => {
    // Providers
    const { t } = useTranslation()
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const { newsletterId } = useParams()
    const { fetchPosts, loadingPostsError } = useCompanyPosts()
    const alert = useAlertContext()
    const navigate = useNavigate()

    // States
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [newsletter, setNewsletter] = useState<NewsletterModel | null>(null)
    const [previousNewsletterState, setPreviousNewsletterState] = useState<string | null>(null)
    const [editingOffer, setEditingOffer] = useState<OfferModel | null>(null)
    const [images, setImages] = useState<Array<File>>([])
    const [offers, setOffers] = useState<OfferModel[]>([])

    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)
    const [finalizeConfirmation, setFinalizeConfirmation] = useState<boolean>(false)
    const [ignoreUnsavedOfferConfirmation, setIgnoreUnsavedOfferConfirmation] =
        useState<boolean>(false)
    const [unsavedOfferConfirmationOptions, setUnsavedOfferConfirmationOptions] = useState({
        draft: true,
    })

    const [unpublishConfirmation, setUnpublishConfirmation] = useState<boolean>(false)

    // Refs
    const offerFormRef = useRef<HTMLFormElement>(null)

    // Methods
    const uploadImages = async () => {
        const imageUploadPromises: Promise<any>[] = []
        images.forEach((image) => {
            imageUploadPromises.push(uploadImage(image))
        })

        const uploadedImages = await Promise.all(imageUploadPromises)
        if (uploadedImages) {
            setImages([])
        }
        return uploadedImages
    }

    const handleUpdateNewsletterPreflight = async ({ draft }: { draft: boolean }) => {
        const unfinishedOffer = offerFormRef.current?.checkUnfinishedOffer()
        if (unfinishedOffer) {
            setUnsavedOfferConfirmationOptions({ draft })
            setIgnoreUnsavedOfferConfirmation(true)
            return
        }
        handleSubmit({ draft })
    }

    const setNewsletterValues = async ({
        newsletter,
        draft,
        company_name,
    }: {
        newsletter: NewsletterModel
        draft: boolean
        company_name: string
    }): Promise<NewsletterModel | string> => {
        newsletter.company_name = company_name

        if (draft) {
            newsletter.state = "draft"
        } else {
            newsletter.state = "public"
        }

        newsletter.offers = offers
            .filter((offer) => offer.id != undefined)
            .map((offer) => offer.id!)

        return uploadImages()
            .then((uploadedImages) => {
                newsletter.images = [...newsletter.images, ...uploadedImages]
                return Promise.resolve(newsletter)
            })
            .catch((err) => {
                console.error("uploadImages failed: ", err)
                alert.setAlert({
                    alertSeverity: "error",
                    alertMessage: t("post.errorUploadImage"),
                })
                return Promise.reject("post.errorUploadImage")
            })
    }

    const handleSubmit = async ({ draft }: { draft: boolean }) => {
        if (loading) {
            return Promise.reject()
        }
        if (currentCompany == null || newsletter == null) {
            return Promise.reject()
        }

        setLoading(true)

        setNewsletterValues({
            newsletter: { ...newsletter },
            draft: draft,
            company_name: currentCompany.company_name,
        })
            .then((newsletter) => {
                let req: (newsletter: NewsletterModel) => Promise<any>

                if (newsletterId) {
                    req = updateNewsletter
                } else {
                    req = createNewsletter
                }

                req(newsletter as NewsletterModel)
                    .then(() => {
                        navigate(Paths.posts + "/" + currentCompany.company_name)
                    })
                    .catch((err) => {
                        console.error("update post failed: ", err)
                        alert.setAlert({
                            alertSeverity: "error",
                            alertMessage: "failed to update post",
                        })
                    })
            })
            .finally(() => setLoading(false))
    }

    const handleDeleteNewsletter = async () => {
        if (newsletter == null || !newsletter.id || !currentCompany) {
            navigate(Paths.posts + "/" + currentCompany?.company_name)
            return
        }

        await deleteNewsletter(currentCompany.company_name, newsletter.id)
        navigate(Paths.posts + "/" + currentCompany?.company_name)
    }

    /**********************************************************/
    /*                       Effects                          */
    /**********************************************************/

    useEffect(() => {
        if (loadingPostsError) {
            alert.setAlert({
                alertSeverity: "error",
                alertMessage: loadingPostsError.response?.data.message || t("error"),
            })
        }
    }, [alert, loadingPostsError, t])

    useEffect(() => {
        if (isMounted || !currentCompany?.company_name) {
            return
        }
        const mounted = async () => {
            setIsMounted(true)
            setLoading(true)

            const _defaultNewsletter = {
                ...defaultNewsletter,
                company_name: currentCompany.company_name,
            }

            if (newsletterId) {
                await fetchPosts()
                const newsletterRes = await getNewsletter(
                    currentCompany.company_name,
                    newsletterId
                ).catch((error) => {
                    console.error(error)
                    alert.setAlert({
                        alertSeverity: "error",
                        alertMessage: error.message,
                    })
                    setLoading(false)
                })

                if (!newsletterRes) {
                    return
                }

                setNewsletter(newsletterRes.newsletter)
                setPreviousNewsletterState(newsletterRes.newsletter.state)

                const offerPromises = newsletterRes.newsletter.offers.map((offerId: string) => {
                    return getOffer(newsletterRes.newsletter.company_name, offerId)
                })
                const offerResults = (await Promise.all(offerPromises).catch((error) => {
                    console.error(error)
                    alert.setAlert({
                        alertSeverity: "error",
                        alertMessage: error.message || t("error"),
                    })
                    setLoading(false)
                })) as OfferModel[]

                setOffers(offerResults)
            } else {
                setNewsletter(_defaultNewsletter)
                setPreviousNewsletterState(_defaultNewsletter.state)
                setOffers([])
            }
            setLoading(false)
        }
        mounted()
    }, [isMounted, currentCompany, newsletter, newsletterId, fetchPosts, alert, t])

    /**********************************************************/
    /*                        Render                          */
    /**********************************************************/
    if (!newsletter) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        )
    }
    return (
        <div className="w-full">
            {loading && (
                <div className="fixed top-0 left-0 h-screen w-screen z-50 flex items-center justify-center bg-gray-400 opacity-50">
                    <Spinner />
                </div>
            )}
            <Popup
                open={ignoreUnsavedOfferConfirmation}
                setOpen={setIgnoreUnsavedOfferConfirmation}
                variant={PopupVariants.confirmation}
                onConfirm={() => handleSubmit(unsavedOfferConfirmationOptions)}
                title={t("post.unsavedOfferChanges")}
                content={t("post.finishWithoutSavingOfferChanges", {
                    type: unsavedOfferConfirmationOptions.draft
                        ? t("common.saveX", { type: t("common.draft") })
                        : t("common.publish"),
                })}
                cancelButtonText={t("common.cancel")}
                cofirmationsButtonText={
                    unsavedOfferConfirmationOptions.draft
                        ? t("common.saveX", { type: t("common.draft") })
                        : t("common.publish")
                }
            />
            <Popup
                open={deleteConfirmation}
                setOpen={setDeleteConfirmation}
                variant={PopupVariants.deleteConfirmation}
                onConfirm={handleDeleteNewsletter}
                title={t("common.deleteX", {
                    type: newsletter.state == "draft" ? t("common.draft") : t("common.post"),
                })}
                content={t("common.deleteConfirmation", {
                    type: newsletter.state == "draft" ? t("common.draft") : t("common.post"),
                })}
                cancelButtonText={t("common.cancel")}
                cofirmationsButtonText={t("common.delete")}
            />

            <Popup
                open={unpublishConfirmation}
                setOpen={setUnpublishConfirmation}
                variant={PopupVariants.deleteConfirmation}
                onConfirm={() => handleUpdateNewsletterPreflight({ draft: true })}
                title={t("common.unpublishX", {
                    type: newsletter.state == "draft" ? t("common.draft") : t("common.post"),
                })}
                content={t("post.unpublishConfirmation", {
                    type: newsletter.state == "draft" ? t("common.draft") : t("common.post"),
                })}
                cancelButtonText={t("common.cancel")}
                cofirmationsButtonText={t("common.unpublish")}
            />

            <Popup
                open={finalizeConfirmation}
                setOpen={setFinalizeConfirmation}
                variant={PopupVariants.confirmation}
                onConfirm={() => handleUpdateNewsletterPreflight({ draft: false })}
                title={
                    previousNewsletterState === "public"
                        ? t("common.updateX", { type: t("common.post") })
                        : t("common.publishX", { type: t("common.post") })
                }
                content={t("common.publishConfirmation", { type: t("common.post") })}
                cancelButtonText={t("common.cancel")}
                cofirmationsButtonText={
                    previousNewsletterState === "public" ? t("common.update") : t("common.publish")
                }
            />

            <div>
                <div className="grid grid-cols-3 gap-6 my-6">
                    <NewsletterForm
                        newsletter={newsletter}
                        onTitleChange={(title) => {
                            setNewsletter({ ...newsletter, title })
                        }}
                        onContentChange={(content) => {
                            setNewsletter({ ...newsletter, content })
                        }}
                        onCategoriesChange={(categories) => {
                            setNewsletter({ ...newsletter, categories })
                        }}
                    />

                    <SettingsForm
                        data={newsletter}
                        onExpirationChange={(expiration?) => {
                            if (newsletter.expiration === null) {
                                expiration = dateFromToday(DEFAULT_EXPIRATION_DAYS)
                            }
                            setNewsletter({ ...newsletter, expiration })
                        }}
                        onPublishDateChange={(publishTime) => {
                            setNewsletter({ ...newsletter, publish_time: publishTime })
                        }}
                        onShowCountDownChange={(showCountdown) => {
                            const _newsletter = { ...newsletter, show_countdown: showCountdown }
                            if (showCountdown && !newsletter.expiration) {
                                _newsletter.expiration = dateFromToday(DEFAULT_EXPIRATION_DAYS)
                            }
                            setNewsletter(_newsletter)
                        }}
                    />

                    <NewsletterImagesForm
                        newsletterImages={newsletter.images}
                        images={images}
                        onNewsletterImagesChange={(newImages) => {
                            setNewsletter({ ...newsletter, images: newImages })
                        }}
                        onImagesChange={(newImages) => {
                            setImages(newImages)
                        }}
                    />

                    <Paper>
                        <div className="flex justify-between">
                            <h3 className="text-xl font-body">{t("common.links")}</h3>
                            <ToolTip content={t("post.tooltip.links")} />
                        </div>
                        <LinkForm
                            onLinkCreate={(newLink) => {
                                setNewsletter({
                                    ...newsletter,
                                    links: [...newsletter.links, newLink],
                                })
                            }}
                        />
                        {newsletter?.links.length > 0 && (
                            <Links
                                links={newsletter.links || []}
                                onChange={(links) => {
                                    setNewsletter({ ...newsletter, links: links })
                                }}
                            />
                        )}
                    </Paper>
                </div>
                {
                    <Paper>
                        <OfferForm
                            ref={offerFormRef}
                            editOffer={editingOffer}
                            newsletter={newsletter}
                            onLoadingChange={setLoading}
                            onOfferCreate={(offer) => {
                                let existing = offers.find((o) => o.id === offer.id)
                                if (!existing) setOffers([...offers, offer])
                            }}
                            onOfferUpdate={(offer) => {
                                const index = offers.findIndex((o) => o.id === offer.id)
                                if (index !== -1) {
                                    const _offers = [...offers]
                                    _offers[index] = offer
                                    setOffers(_offers)
                                }
                            }}
                        />
                        <Offers
                            offers={offers}
                            onEditOffer={(offer) => {
                                setEditingOffer(offer)
                            }}
                            onChange={(offers) => {
                                setOffers(offers)
                            }}
                        />
                    </Paper>
                }
                <Paper className="p-4 mt-6 bg-primary">
                    <div className={`flex justify-between`}>
                        <div className={`flex justify-between`}>
                            {previousNewsletterState === "draft" && (
                                <React.Fragment>
                                    <Button
                                        variant={ButtonVariants.success}
                                        className={`mr-2`}
                                        onClick={() =>
                                            handleUpdateNewsletterPreflight({ draft: true })
                                        }
                                    >
                                        {newsletterId
                                            ? t("common.updateX", { type: t("common.draft") })
                                            : t("common.saveX", { type: t("common.draft") })}
                                    </Button>
                                </React.Fragment>
                            )}
                            <Button
                                variant={ButtonVariants.danger}
                                onClick={() => setDeleteConfirmation(true)}
                            >
                                {t("common.deleteX", {
                                    type:
                                        newsletter.state === "draft"
                                            ? t("common.draft")
                                            : t("common.post"),
                                })}
                            </Button>
                        </div>

                        {/* horizontal space */}

                        <div className={`flex justify-between`}>
                            {previousNewsletterState === "public" && (
                                <React.Fragment>
                                    <Button
                                        variant={ButtonVariants.danger}
                                        className={`mr-2`}
                                        onClick={() => setUnpublishConfirmation(true)}
                                    >
                                        {t("common.unpublishX", { type: t("common.post") })}
                                    </Button>
                                    <Button
                                        name="post:submit"
                                        className={`font-bold`}
                                        onClick={() => setFinalizeConfirmation(true)}
                                    >
                                        {newsletterId
                                            ? t("common.updateX", { type: t("common.post") })
                                            : t("common.publishX", { type: t("common.post") })}
                                    </Button>
                                </React.Fragment>
                            )}
                            {previousNewsletterState === "draft" && (
                                <React.Fragment>
                                    <Button
                                        name="post:submit"
                                        className={`font-bold`}
                                        onClick={() => setFinalizeConfirmation(true)}
                                    >
                                        {t("common.publishX", { type: t("common.post") })}
                                    </Button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default CreationModal
