const backend_scheme = process.env.REACT_APP_BACKEND_SCHEME
const backend_url = process.env.REACT_APP_BACKEND_URL
const backend_port = process.env.REACT_APP_BACKEND_PORT

const iup_scheme = process.env.REACT_APP_IUP_SCHEME
const iup_url = process.env.REACT_APP_IUP_URL
const iup_port = process.env.REACT_APP_IUP_PORT

const google_oauth_client_id = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID

const posthog_url = process.env.REACT_APP_POSTHOG_URL

const env = process.env.REACT_APP_ENV

const CONSTS = {
    BACKEND_HOST: `${backend_scheme}://${backend_url}:${backend_port}`,
    BACKEND_BASE_URL: `${backend_scheme}://${backend_url}:${backend_port}/admin`,
    IUP_BASE_URL: `${iup_scheme}://${iup_url}:${iup_port}/v1/image`,
    GOOGLE_OAUTH_CLIENT_ID: google_oauth_client_id,
    POSTHOG_URL: posthog_url,
    ENV: env,
}

// @ts-ignore
export default CONSTS
