import InviteAccept from "components/InviteAccept"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

const Invites = () => {
    const { t } = useTranslation()

    const { code } = useParams<{ code: string }>()

    return (
        <div>
            <div className="text-xl font-body font-bold tracking-wide px-4 py-2">
                {t("profile.sidebar.invites")}
            </div>
            {code ? (
                <InviteAccept code={code} />
            ) : (
                <div className="flex justify-center text-xl font-body tracking-wide px-4 py-2 mt-12">
                    {t("profile.invites.empty")}
                </div>
            )}
        </div>
    )
}
export default Invites
