import React, { InputHTMLAttributes } from "react"
import InputLabel from "../InputLabel"

enum InputVariants {
    primary,
    smallPrimary,
    secondary,
    smallSecondary,
    danger,
    icon,
    contained,
    outlined,
    text,
}

interface PropTypes extends InputHTMLAttributes<HTMLTextAreaElement> {
    label?: string
    labelClass?: string
    // tooltip is only shown if label is set as well
    tooltip?: string
    color?: string
    variant?: InputVariants
    disableElevation?: boolean
    spinnerColor?: string
    error?: boolean
    errorMessage?: string
}

const TextArea = (props: PropTypes) => {
    const { labelClass, error, errorMessage, ...textareaProps } = props

    const input = (
        <textarea
            {...textareaProps}
            id={props.id}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            className={`rounded-md mt-1 border border-gray-300 hover:border-gray-500 w-full ${
                props.error ? "border-danger" : "border-hoverDark"
            } ${props.className}`}
            placeholder={props.placeholder}
        />
    )

    if (props.label && props.label !== "") {
        return (
            <InputLabel label={props.label} tooltip={props.tooltip} className={labelClass}>
                {input}
                {errorMessage && <div className="text-danger mb-1">{errorMessage}</div>}
            </InputLabel>
        )
    }

    return <>{input}</>
}

export default TextArea
export { InputVariants }
