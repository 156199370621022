import { useCallback, useEffect } from "react"

// Providers
import { useTranslation } from "react-i18next"

// Models
import { ContentState } from "models/ContentState"

// Components
import Paper from "components/base/Paper/Paper"
import Input from "components/base/Input/Input"
import Checkbox from "components/base/Checkbox/Checkbox"

// Helpers
import { dateFromToday, daysFromToday } from "helper/DateUtils"
import DatePicker from "components/base/Datepicker/Datepicker"
import Switch from "components/base/Switch/Switch"

// Constants
const DEFAULT_EXPIRATION_DAYS = 30

type PropTypes = {
    data: { expiration?: Date | null; show_countdown?: boolean; state?: ContentState }
    showStateToggle?: boolean
    onStateToggle?: (state: ContentState) => void
    onPublishDateChange?: (publishDate: Date) => void
    onExpirationChange?: (expirationDate: Date | null) => void
    onShowCountDownChange?: (showCountDown: boolean) => void
}

const SettingsForm = ({
    data,
    onStateToggle = () => {},
    onPublishDateChange = () => {},
    onExpirationChange = () => {},
    onShowCountDownChange = () => {},
    showStateToggle = false,
}: PropTypes) => {
    // Providers
    const { t } = useTranslation()

    // Methods
    const scheduleWarningDate = () => {
        // Warn user if they set a schedule date too far in the future
        var date = new Date()
        date.setDate(date.getDate() + DEFAULT_EXPIRATION_DAYS)
        return date
    }

    const handleExpirationDaysChange = useCallback(
        (days: number) => {
            const expireDate = dateFromToday(days)
            if (expireDate !== null) {
                onExpirationChange(expireDate)
            }
        },
        [onExpirationChange]
    )

    const hasExpired = () => {
        if (!data.expiration) return false
        const today = new Date()
        return today.getTime() > data.expiration.getTime()
    }

    // Effects
    useEffect(() => {
        if (!data.expiration === undefined) {
            handleExpirationDaysChange(DEFAULT_EXPIRATION_DAYS)
        }
    }, [data, handleExpirationDaysChange])

    // Render
    return (
        <Paper>
            <div className="grid grid-cols-2 m-auto justify-between flex-grow">
                <div className="text-xl font-body mb-4">{t("common.settings")}</div>
                <div className={`col-span-2`}>
                    {showStateToggle && data.state && (
                        <Switch
                            className="mb-4"
                            leftLabel={t("post.draft")}
                            rightLabel={t("post.public")}
                            onLeftClick={() => onStateToggle("draft")}
                            onRightClick={() => onStateToggle("public")}
                            defaultOn={data.state === "draft" ? "left" : "right"}
                        />
                    )}
                    {hasExpired() && <p className="text-warning">{t("post.hasExpired")}</p>}
                    <div className={"flex flex-row mt-3"}>
                        <Checkbox
                            label={t("post.autoExpire")}
                            tooltip={t("post.tooltip.autoExpire")}
                            name="publicCheckbox"
                            checked={Boolean(data.expiration)}
                            className="form-checkbox mt-1 "
                            onChange={(e) =>
                                onExpirationChange(e.target.checked ? new Date() : null)
                            }
                        />
                    </div>

                    {data.expiration && (
                        <div>
                            <div className={"flex flex-row mt-3"}>
                                <Checkbox
                                    name="publicCheckbox"
                                    label={t("post.showCountdown")}
                                    tooltip={t("post.showCountdownTip")}
                                    checked={Boolean(data.show_countdown)}
                                    className="form-checkbox mt-1 "
                                    onChange={(e) => onShowCountDownChange(e.target.checked)}
                                />
                            </div>
                        </div>
                    )}

                    {data.expiration && (
                        <DatePicker
                            className="mt-6"
                            value={data.expiration}
                            label={t("post.expirationTime", {
                                days: daysFromToday(data.expiration),
                            })}
                            minDate={new Date()}
                            warnAfter={scheduleWarningDate()}
                            warnMessage={t("post.warning.postWillExpire", {
                                days: daysFromToday(data.expiration),
                            })}
                            onChange={(date) => {
                                if (date) onExpirationChange(date)
                            }}
                        />
                    )}
                </div>
            </div>
        </Paper>
    )
}

export default SettingsForm
