import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Link } from "react-router-dom"
import {
    Dashboard,
    Notifications,
    Settings,
    DesignServices,
    Groups,
    Analytics,
    Newspaper,
    Brush,
    Business,
    Description,
} from "@mui/icons-material"

// Store
import { useCompanies } from "providers/company/CompaniesProvider"
import { useUser } from "providers/UserProvider"

import { Paths, AdminPaths } from "constants/ROUTES"

// models
import { UserRole } from "models/User.d"

interface LinkType {
    text: string
    icon: React.ReactElement
    path: string
    check: () => void
}

const Sidebar = () => {
    const { t } = useTranslation()
    const { user } = useUser({ autoFetch: false })!
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const [links, setLinks] = useState<Array<LinkType>>()
    const [adminLinks, setAdminLinks] = useState<Array<LinkType>>()

    useEffect(() => {
        const links = [
            {
                text: currentCompany?.company_name ?? "",
                icon: <Dashboard />,
                path: Paths.dashboard,
                check: () => {
                    return currentCompany !== null
                },
            },
            {
                text: t("sidebar.notifications"),
                icon: <Notifications />,
                path: Paths.notifications,
                check: () => {
                    return (
                        currentCompany &&
                        currentCompany.notification &&
                        currentCompany.notification.enabled
                    )
                },
            },
            // {
            //     text: t("Company"),
            //     icon: <span />,
            //     path: Paths.companies,
            //     check: () => {
            //         return (
            //             currentCompany &&
            //             currentCompany.application &&
            //             currentCompany.application.type !== ApplicationType.Webview
            //         )
            //     },
            // },
            {
                text: t("sidebar.posts"),
                path: `/posts/${currentCompany?.company_name}`,
                icon: <Newspaper />,
                check: () => {
                    return currentCompany && currentCompany.application
                },
            },
            // {
            //     check: () => {
            //         return (
            //             currentCompany &&
            //             currentCompany.application &&
            //             currentCompany.application.type !== ApplicationType.Webview
            //         )
            //     },
            //     text: t("Offers"),
            //     path: Paths.companies,
            //     icon: <span />,
            // },
            {
                text: t("sidebar.settings"),
                icon: <Settings />,
                path: Paths.settings,
                check: () => {
                    return (
                        currentCompany &&
                        currentCompany.notification &&
                        currentCompany.notification.enabled
                    )
                },
            },
            {
                text: t("sidebar.appTheme"),
                icon: <Brush />,
                path: Paths.appTheme,
                check: () => {
                    return currentCompany
                },
            },
            {
                text: t("sidebar.appData"),
                icon: <Description />,
                path: Paths.appData,
                check: () => {
                    return currentCompany
                },
            },
            // {
            //     text: t("Profile"),
            //     icon: <Person />,
            //     path: Paths.profile,
            //     check: () => {
            //         return user !== undefined
            //     },
            // },
        ]
        setLinks(links)

        // admin links

        if (user && user.role === UserRole.SystemAdmin) {
            const adminLinks = [
                {
                    check: () => {
                        return true
                    },
                    text: "CRM",
                    icon: <Groups />,
                    path: AdminPaths.crm,
                },
                {
                    check: () => {
                        return true
                    },
                    text: "Companies",
                    path: AdminPaths.companies,
                    icon: <Business />,
                },
                {
                    text: t("Template"),
                    icon: <DesignServices />,
                    path: Paths.appTemplates,
                    check: () => {
                        return true
                    },
                },
                {
                    text: t("Analytics"),
                    icon: <Analytics />,
                    path: AdminPaths.analytics,
                    check: () => {
                        return true
                    },
                },
            ]
            setAdminLinks(adminLinks)
        }
    }, [currentCompany, user, t])

    return (
        <nav className="flex w-full h-full flex-col overflow-y-auto font-semibold font-body pt-10 tracking-wide px-4">
            {links &&
                links.filter((link) => link.check()).map((link) => SidebarLink({ link: link }))}

            {adminLinks && <div className=" border-b-2 my-3 border-white"></div>}

            {adminLinks &&
                adminLinks
                    .filter((link) => link.check())
                    .map((link) => SidebarLink({ link: link }))}
        </nav>
    )
}

const SidebarLink = ({ link }: { link: LinkType }) => {
    return (
        <Link
            key={link.path}
            to={link.path}
            className="flex p-2 rounded text-lg w-full items-center
                       hover:bg-text-light hover:text-primary transition duration-200"
        >
            {link.icon}
            <p className="pl-2">{link.text}</p>
        </Link>
    )
}

export default Sidebar
