import Input from "components/base/Input"
import { ComponentModel, ProfileComponent } from "template/components"
import { useEffect, useState } from "react"
import { Color, SketchPicker } from "react-color"
import { Collapse } from "@mui/material"
import {
    AddCircle,
    ArrowCircleDown,
    ArrowCircleUp,
    ExpandLess,
    ExpandMore,
    RemoveCircle,
} from "@mui/icons-material"

/**
 * Generic component for creating a list of values
 */
function ListPicker<Type>({
    defaultValue,
    currentValue,
    onResultChange,
    ValuePicker,
}: {
    defaultValue: Type
    currentValue: Type[]
    onResultChange: (results: Type[]) => void
    ValuePicker: ({
        activeValue,
        onChange,
    }: {
        activeValue: Type
        onChange: (value: Type) => void
    }) => JSX.Element
}) {
    const [results, setResults] = useState<Type[]>(
        currentValue.length > 0 ? currentValue : [defaultValue]
    )

    useEffect(() => {
        onResultChange(results.filter((result) => result !== null) as Type[])
    }, [results])

    return (
        <>
            {results.map((_, index) => {
                return (
                    <div key={index} className="flex flex-row items-center m-2">
                        <ValuePicker
                            activeValue={results[index]}
                            onChange={(value) => {
                                const newResults = [...results]
                                newResults[index] = value
                                setResults(newResults)
                            }}
                        />
                        <RemoveCircle
                            className="cursor-pointer m-2"
                            color="error"
                            fontSize="large"
                            onClick={() => {
                                console.log(results)
                                setResults([...results].filter((_, i) => i !== index))
                                console.log([...results].filter((_, i) => i !== index))
                            }}
                        >
                            Remove
                        </RemoveCircle>
                        <ArrowCircleUp
                            className="cursor-pointer m-2"
                            color="primary"
                            fontSize="large"
                            onClick={() => {
                                if (results.length > 1) {
                                    const newIndex = (index + results.length - 1) % results.length
                                    const newResults = [...results]
                                    newResults[index] = newResults[newIndex]
                                    newResults[newIndex] = results[index]
                                    setResults(newResults)
                                }
                            }}
                        />
                        <ArrowCircleDown
                            className="cursor-pointer m-2"
                            color="primary"
                            fontSize="large"
                            onClick={() => {
                                if (results.length > 1) {
                                    const newIndex = (index + 1) % results.length
                                    const newResults = [...results]
                                    newResults[index] = newResults[newIndex]
                                    newResults[newIndex] = results[index]
                                    setResults(newResults)
                                }
                            }}
                        />
                    </div>
                )
            })}
            <AddCircle
                className="cursor-pointer m-2"
                color="primary"
                fontSize="large"
                onClick={() => setResults([...results, defaultValue])}
            />
        </>
    )
}

export default ListPicker
