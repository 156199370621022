import { ComponentModel, Design01Component } from "../components"
import NotImplementedEditor from "./NotImplementedEditor"

const Design01Editor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: Design01Component
    save: () => void
}) => {
    return <NotImplementedEditor parent={parent} component={component} save={save} />
}
export default Design01Editor
