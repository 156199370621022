function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const av = a[orderBy]
    const bv = b[orderBy]
    if (!av && !bv) {
        return 0
    } else if (!av && bv) {
        return 1
    } else if (av && !bv) {
        return -1
    }
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: any,
    b: any
    // a: { [key in Key]: number | string | Date },
    // b: { [key in Key]: number | string | Date }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export default getComparator
