import { ProviderGuardMultiComponent, ComponentModel } from "template/components"

const ProviderGuardEditor = ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ProviderGuardMultiComponent
    save: () => void
}) => {
    return (
        <form>
            <div className="grid grid-cols-2">
                <p>Provider</p>
                {component.providers.map((provider, index) => {
                    return (
                        <input
                            key={index}
                            type={"text"}
                            className="border-solid border-2 border-grey-400"
                            value={provider as string}
                            onChange={(e) => {
                                component.providers[index] = e.target.value
                                save()
                            }}
                        />
                    )
                })}
            </div>
        </form>
    )
}

export default ProviderGuardEditor
