import { TableCell, TableSortLabel } from "@mui/material"
import { SortOrder, TableData } from "./Table"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

export interface HeadCellType {
    disablePadding?: boolean
    id?: keyof TableData
    label?: string
    numeric?: boolean
}

type HeadCellProps = HeadCellType & {
    order?: SortOrder
    orderBy?: string
    onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof TableData) => void
    defaultOrder?: SortOrder
}

const HeadCell = ({
    disablePadding = true,
    id,
    label,
    numeric,
    order,
    orderBy,
    onRequestSort,
    defaultOrder,
}: HeadCellProps) => {
    const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
        if (onRequestSort) onRequestSort(event, property)
    }

    return (
        <TableCell
            key={id}
            align={"center"}
            padding={disablePadding ? "none" : "normal"}
            sortDirection={orderBy === id ? order : false}
        >
            {id && label && (
                <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : defaultOrder}
                    onClick={createSortHandler(id)}
                    IconComponent={KeyboardArrowDownIcon}
                >
                    <div className="text-left text-bold text-xl font-body text-blue-600">
                        {label}
                    </div>
                </TableSortLabel>
            )}
        </TableCell>
    )
}

export default HeadCell
