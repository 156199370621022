import { validate, ValidationConstraint, ModelValidateResult } from "models/helpers"
import { ScaledImage } from "./ScaledImage"
import { ContentState } from "./ContentState"

// internal representation
export interface NewsletterModel {
    id?: string
    company_name: string

    title: string
    content: string

    images: ScaledImage[]

    expiration?: Date | null
    publish_time: Date
    state: ContentState
    show_countdown: boolean
    categories: string[]
    offers: string[]
    links: Link[]
    created_at: Date
    updated_at: Date
}

const newsletterStateString = (newsletter: {
    state: ContentState
    expiration?: Date | null
}): "public" | "deleted" | "draft" | "expired" => {
    if (newsletter.expiration && new Date(newsletter.expiration).getTime() < new Date().getTime()) {
        return "expired"
    }
    return newsletter.state
}

export interface Link {
    title: string
    content: string
    url: string
}

// response from backend
export interface NewsletterRespModel extends NewsletterModel {
    id: string
}

const newsletterConstraints: ValidationConstraint[] = [
    { field: "company_name", type: "string" },
    { field: "title", type: "string" },
    { field: "content", type: "string" },
    { field: "images", type: "array" },
    { field: "expiration", type: "ISOdate", optional: true },
    { field: "offers", type: "array" },
    { field: "state", type: "string" },
]

const newsletterRespConstraints: ValidationConstraint[] = [
    ...newsletterConstraints,
    { field: "id", type: "string" },
]

const ValidateNewsletterModel = (n: any): ModelValidateResult<NewsletterModel> => {
    return validate<NewsletterModel>(n, newsletterConstraints)
}
const ValidateNewsletterRespModel = (n: any): ModelValidateResult<NewsletterRespModel> => {
    return validate<NewsletterRespModel>(n, newsletterRespConstraints)
}

export { ValidateNewsletterModel, ValidateNewsletterRespModel, newsletterStateString }
