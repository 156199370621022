import { ComponentModel } from "template/components"
export default ({
    parent,
    component,
    save,
}: {
    parent: ComponentModel
    component: ComponentModel
    save: () => void
}) => {
    return <div>No editor implemened for component {component.type}</div>
}
