import { Alert, Box, Fade } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { AlertType, useAlertContext } from "providers/AlertProvider"
import { useTranslation } from "react-i18next"

type AlertMap = { [id: string]: AlertType }

const AlertPopup = ({ fadeAnimationTimeInMilliSeconds = 500 }) => {
    const alertContext = useAlertContext()
    const { t } = useTranslation()

    const [alerts, setAlerts] = useState<AlertMap>({})

    const [currentFadeOut, setCurrentFadeOut] = useState("")

    const removeAlert = useCallback(
        (id: string) => {
            setCurrentFadeOut(id)
            setTimeout(() => {
                setAlerts((alerts) => {
                    // remove key from object
                    const { [id]: _, ...rest } = alerts

                    return rest
                })
            }, fadeAnimationTimeInMilliSeconds)
        },
        [fadeAnimationTimeInMilliSeconds]
    )

    useEffect(() => {
        const generateRandomUUID = () => {
            return Math.random().toString(36).substring(2, 15)
        }

        const id = generateRandomUUID()
        //  add alert to the FIFO queue
        // start fadeout then removal after 5 seconds
        if (alertContext.alertMessage && alertContext.alertSeverity) {
            const message = alertContext.alertMessage || t("Something went wrong")

            setAlerts((alerts: AlertMap) => {
                return {
                    ...alerts,
                    [id]: {
                        alertMessage: message,
                        alertSeverity: alertContext.alertSeverity,
                        timeOutInMilliSeconds: alertContext.timeoutInMilliSeconds,
                    },
                }
            })

            if (alertContext.timeoutInMilliSeconds > 0) {
                setTimeout(() => {
                    removeAlert(id)
                }, alertContext.timeoutInMilliSeconds)
            }
            // check if alert is set to undefined, remove all alerts
        } else if (!Boolean(alertContext.alertMessage)) {
            setAlerts({})
        }
    }, [alertContext.alertEvent, setAlerts, t, removeAlert])

    return (
        <Box sx={{ w: "full" }}>
            {Object.entries(alerts).map(([id, alert], index) => (
                <Box key={index} sx={{ p: 1 }}>
                    <Fade in={id !== currentFadeOut}>
                        <Alert
                            severity={alert.alertSeverity}
                            onClose={() => {
                                removeAlert(id)
                            }}
                        >
                            {alert.alertMessage}
                        </Alert>
                    </Fade>
                </Box>
            ))}
        </Box>
    )
}

export default AlertPopup
