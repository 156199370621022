import React, { InputHTMLAttributes } from "react"

interface PropTypes extends InputHTMLAttributes<HTMLDivElement> {
    variant?: PaperVariants
}

enum PaperVariants {
    light,
    dark,
}

const Paper = (props: PropTypes) => {
    let className = ""
    if (props.className) {
        className += props.className + " "
    }
    className += "px-6 py-4 rounded-xl "

    switch (props.variant) {
        case PaperVariants.dark:
            className += "bg-darkPaper "
            break

        case PaperVariants.light:
        default:
            className += "bg-light box-border shadow "
            break
    }

    return (
        <div {...props} className={className}>
            {props.children}
        </div>
    )
}

export default Paper
export { PaperVariants }
