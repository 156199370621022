// Utils
let passwordValidator = require("password-validator")

type InputValidatorType = {
    [inputName: string]: (input: string) => string | undefined
}

const InputValidator: InputValidatorType = {
    url: (url: string) => {
        try {
            new URL(url)
            return
        } catch (err) {
            return "error.invalidUrl"
        }
    },
    password: (password: string) => {
        let passwordScheme = new passwordValidator()
        passwordScheme
            .is()
            .min(8, "settings.security.errors.minLength") // Minimum length 8
            .is()
            .max(100, "settings.security.errors.maxLength") // Maximum length 100
            .has()
            .uppercase(1, "settings.security.errors.uppercaseLetter") // Must have uppercase letters
            .has()
            .lowercase(1, "settings.security.errors.lowercaseLetter") // Must have lowercase letters
            .has()
            .digits(1, "settings.security.errors.requireDigit") // Must have at least 1 digit
            .has()
            .not()
            .spaces(1, "settings.security.errors.noSpaces") // Should not have spaces
            .is()
            .not()
            .oneOf(["Passw0rd", "Password123"]) // Blacklist these values

        const validationErrors = passwordScheme.validate(password, { details: true })

        if (validationErrors.length === 0) return
        return validationErrors[0].message
    },
}

export default InputValidator
