import { Input } from "@mui/material"
import { useState } from "react"
import { CustomerRelationTableData } from "./CustomerRelationTable"
import Button, { ButtonVariants } from "components/base/Button"
import { CRMModel } from "models/CRM"
import { deleteCustomerRelation, updateCustomerRelation } from "api/crm"
import { useNavigate } from "react-router-dom"
import { AdminPaths } from "constants/ROUTES"

const EditCustomerRelation = ({
    customerRelation,
}: {
    customerRelation: CustomerRelationTableData
}) => {
    const navigate = useNavigate()
    const [updatedCustomerRelation, setUpdatedCustomerRelation] =
        useState<CustomerRelationTableData>(customerRelation)

    const update = () => {
        let updated: CRMModel = {
            id: updatedCustomerRelation.id,
            schema_version: updatedCustomerRelation.schemaVersion,
            company_name: updatedCustomerRelation.companyName,
            email: updatedCustomerRelation.email,
            phone: updatedCustomerRelation.phone,
            address: updatedCustomerRelation.address,
            contact_person: updatedCustomerRelation.contactPerson,
            stage: updatedCustomerRelation.stage,
            notes: updatedCustomerRelation.notes,
            company_id: updatedCustomerRelation.companyID,
            website: updatedCustomerRelation.website,
            created_at: updatedCustomerRelation.createdAt,
            changed_at: updatedCustomerRelation.changedAt,
        }

        updateCustomerRelation(updated, updatedCustomerRelation.id)
            .then((res) => {
                window.location.reload()
            })
            .catch((err) => {
                console.error("failed to update customer relation: ", err)
            })
    }

    const deleteRelation = () => {
        deleteCustomerRelation(updatedCustomerRelation.id).then((res) => {
            navigate(0)
        })
    }

    return (
        <form
            className="m-auto max-w-screen-lg"
            onSubmit={() => {
                return false
            }}
        >
            <div className="mb-8">
                <div className={""}>
                    <div>
                        <div className="text-xl font-body font-bold tracking-wide tracking-wide px-4 py-2 ">
                            {customerRelation.companyName}
                        </div>
                        <div className={"grid grid-cols-2 gap-1"}>
                            <div className={"p-4"}>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>{"Company name"}</p>
                                    <Input
                                        name="companyName"
                                        value={updatedCustomerRelation.companyName}
                                        placeholder={"Company name"}
                                        onChange={(e) =>
                                            setUpdatedCustomerRelation({
                                                ...updatedCustomerRelation,
                                                companyName: e.target.value,
                                            })
                                        }
                                        className={`
                                            w-full rounded-md mt-1`}
                                    />
                                </label>
                            </div>
                            <div className={"p-4"}>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>{"Email"}</p>
                                    <Input
                                        name="email"
                                        value={updatedCustomerRelation.email}
                                        placeholder={"Email"}
                                        onChange={(e) =>
                                            setUpdatedCustomerRelation({
                                                ...updatedCustomerRelation,
                                                email: e.target.value,
                                            })
                                        }
                                        className={`
                                            w-full rounded-md mt-1`}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"grid grid-cols-2 gap-1"}>
                            <div className={"p-4"}>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>{"Contact person"}</p>
                                    <Input
                                        name="contactPerson"
                                        value={updatedCustomerRelation.contactPerson}
                                        placeholder={"Contact person"}
                                        onChange={(e) =>
                                            setUpdatedCustomerRelation({
                                                ...updatedCustomerRelation,
                                                contactPerson: e.target.value,
                                            })
                                        }
                                        className={`
                                            w-full rounded-md mt-1`}
                                    />
                                </label>
                            </div>
                            <div className={"p-4"}>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>{"Website"}</p>
                                    <Input
                                        name="website"
                                        value={updatedCustomerRelation.website}
                                        placeholder={"Website"}
                                        onChange={(e) =>
                                            setUpdatedCustomerRelation({
                                                ...updatedCustomerRelation,
                                                website: e.target.value,
                                            })
                                        }
                                        className={`
                                            w-full rounded-md mt-1`}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"grid grid-cols-2 gap-1"}>
                            <div className={"p-4"}>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>{"Phone"}</p>
                                    <Input
                                        name="phone"
                                        value={updatedCustomerRelation.phone}
                                        placeholder={"Phone"}
                                        onChange={(e) =>
                                            setUpdatedCustomerRelation({
                                                ...updatedCustomerRelation,
                                                phone: e.target.value,
                                            })
                                        }
                                        className={`
                                            w-full rounded-md mt-1`}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"grid grid-cols-1"}>
                            <div className={"p-4"}>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>{"Notes"}</p>
                                    <textarea
                                        name="notes"
                                        value={updatedCustomerRelation.notes}
                                        placeholder={"Notes"}
                                        onChange={(e) =>
                                            setUpdatedCustomerRelation({
                                                ...updatedCustomerRelation,
                                                notes: e.target.value,
                                            })
                                        }
                                        className={`
                                            w-full rounded-md mt-1 h-28 p-1 border`}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={`flex justify-between`}>
                            <Button
                                variant={ButtonVariants.primary}
                                onClick={() => {
                                    update()
                                }}
                            >
                                Update
                            </Button>
                            <Button
                                variant={ButtonVariants.danger}
                                onClick={() => {
                                    deleteRelation()
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default EditCustomerRelation
