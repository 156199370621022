import { SketchPicker, ColorResult, RGBColor } from "react-color"

const ColorPicker = ({
    activeValue,
    onChange,
}: {
    activeValue: RGBColor
    onChange: (value: RGBColor) => void
}) => {
    return (
        <SketchPicker
            color={activeValue}
            onChange={(value: ColorResult) => {
                onChange(value.rgb)
            }}
            onChangeComplete={(value: ColorResult) => {
                onChange(value.rgb)
            }}
        />
    )
}

export default ColorPicker
