import { useCallback, useEffect, useRef, useState } from "react"
import { CircularProgress } from "@mui/material"
import { enableAnalytics } from "api/company"
import { useCompanies } from "providers/company/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"
import CONSTS from "constants/CONSTS"
import { useTranslation } from "react-i18next"
import Fallback from "./components/Fallback"

const timeoutInSeconds = 5

const Analytics = () => {
    // Hooks
    const { t } = useTranslation()
    const { currentCompany, companiesFetching, fetchCompanies } = useCompanies({ autoFetch: true })!
    const [enabled, setEnabled] = useState(false)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [iframeLoaded, setIframeLoaded] = useState(false)

    const onLoad = useCallback(() => {
        setIframeLoaded(true)
    }, [])

    useEffect(() => {
        // Show fallback if iframe doesn't load within timeout
        setTimeout(() => {
            setIframeLoaded(true)
        }, timeoutInSeconds * 1000)
    }, [])

    const alert = useAlertContext()

    useEffect(() => {
        const enable = (companyName: string) => {
            enableAnalytics(companyName)
                .then(() => {
                    fetchCompanies()
                })
                .catch((err) => {
                    alert.setAlert({
                        alertMessage: t("analytics.error.enable", { error: err.message }),
                        alertSeverity: "error",
                    })
                })
        }

        if (currentCompany?.application?.analytics_access_token === "" && !enabled) {
            enable(currentCompany.company_name)
            setEnabled(true)
        }
    }, [companiesFetching, currentCompany, alert, fetchCompanies, enabled])

    if (
        companiesFetching ||
        !currentCompany ||
        currentCompany?.application.analytics_access_token === ""
    ) {
        return (
            <div className="grid w-full justify-center">
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="w-full h-full relative">
            <div className={`w-full h-full top-0 left-0 absolute z-1`}>
                <iframe
                    ref={iframeRef}
                    onLoad={onLoad}
                    className="w-full h-full "
                    title="analytics"
                    src={`${CONSTS.POSTHOG_URL}/embedded/${currentCompany.application.analytics_access_token}`}
                />
            </div>
            <div className="z-0">
                {iframeLoaded ? (
                    <div className="top-0 left-0 m-2">
                        <Fallback errorMessage={t("analytics.error.onLoad")} />
                    </div>
                ) : (
                    <div className="grid w-full justify-center">
                        <CircularProgress />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Analytics
