import { useState } from "react"

// Template
import {
    ComponentModel,
    ProviderGuardMultiComponent,
    RouterComponent,
    RouteComponent,
    DefaultRouterComponent,
    DefaultNewsletterPageComponent,
    DefaultDesign02Component,
    DefaultDesign01Component,
} from "template/components"
import ProviderConfig from "template/provider/model"

// Components
import Input from "components/base/Input"
import Design01Editor from "./DesignEditors/Design01Editor"
import Design02Editor from "./DesignEditors/Design02Editor"

// Assets
import naturePreview from "assets/images/previews/nature.png"
import holaPreview from "assets/images/previews/hola.png"

// Types
import { ExpectedCmp } from "./verifyTheme"

export type DesignProps = {
    isDemo?: boolean
    onChange: (root: ComponentModel) => void
    onDesignChange?: (designName: string) => void
    component: ComponentModel
    uploadImage: (image: File) => Promise<void>
    validationErrors?: Map<string, string>
}
export type DesignRef = {
    getDesign: () => ComponentModel
    getProviders: () => ProviderConfig[]
} | null

const homeTabExpectedStructure = (componentName: string): ExpectedCmp => {
    return {
        type: "router",
        components: [
            {
                type: "route",
                route: "/",
                components: [
                    { type: "provider_guard_multi", components: [{ type: componentName }] },
                ],
            },
            { type: "route", route: "/newsletter", components: [{ type: "newsletter_page" }] },
        ],
    }
}

export type HomeTabDesign = {
    name: string
    fields: { name: string; type: string }[]
    expected_components: ExpectedCmp
    providers: ProviderConfig[]
    editor: (props: DesignProps) => JSX.Element
    default: () => ComponentModel
}

interface HomeTabDesignTemplate extends HomeTabDesign {
    previewImage: string
}

export const homeTabDesigns: { [index: string]: HomeTabDesignTemplate } = {
    design01: {
        name: "Hola",
        previewImage: holaPreview,
        fields: [
            { name: "header", type: "string" },
            { name: "header_image", type: "string" },
        ],

        default: () => DefaultDesign01Component(),
        expected_components: homeTabExpectedStructure("design01"),
        providers: [
            {
                name: "newsletters",
                url: "{env.baseurl}/companies/{env.companyname}/newsletters",
                multi: true,
                schema: {},
            },
            {
                name: "offers",
                url: "{env.baseurl}/companies/{env.companyname}/offers",
                multi: true,
                schema: {},
            },
        ],
        editor: Design01Editor,
    },
    design02: {
        name: "Nature",
        previewImage: naturePreview,
        fields: [
            { name: "header", type: "string" },
            { name: "header_image", type: "string" },
        ],
        default: () => DefaultDesign02Component(),
        expected_components: homeTabExpectedStructure("design02"),
        providers: [
            {
                name: "newsletters",
                url: "{env.baseurl}/companies/{env.companyname}/newsletters",
                multi: true,
                schema: {},
            },
            {
                name: "offers",
                url: "{env.baseurl}/companies/{env.companyname}/offers",
                multi: true,
                schema: {},
            },
        ],
        editor: Design02Editor,
    },
}

export function getHomeTabComponent(
    designName: string,
    designComponent: ComponentModel
): RouterComponent {
    if (!homeTabDesigns[designName]) {
        return DefaultRouterComponent()
    }
    return {
        type: "router",
        default_route: "/",
        components: [
            {
                type: "route",
                route: "/",
                components: [
                    {
                        type: "provider_guard_multi",
                        components: [designComponent],
                        providers: homeTabDesigns[designName].providers.map(
                            (provider: ProviderConfig) => provider.name
                        ),
                    } as ProviderGuardMultiComponent,
                ],
            } as RouteComponent,
            {
                type: "route",
                route: "/newsletter",
                components: [{ ...DefaultNewsletterPageComponent() }],
            } as RouteComponent,
        ],
    } as RouterComponent
}

const HomeTab = (props: DesignProps) => {
    const [designName, setDesignName] = useState<string>(props.component.type)

    return (
        <div>
            <div className="flex flex-row flex-wrap">
                {Object.keys(homeTabDesigns).map((key, index) => (
                    <div
                        className="w-1/3 p-4 flex flex-col items-center cursor-pointer"
                        key={index}
                        onClick={() => {
                            setDesignName(key)
                            if (props.onDesignChange) props.onDesignChange(key)
                        }}
                    >
                        <img
                            src={homeTabDesigns[key].previewImage}
                            alt={`${homeTabDesigns[key].name} preview`}
                            className="mb-6 shadow-lg"
                        />
                        <Input
                            type="radio"
                            checked={designName === key}
                            onChange={() => {
                                /*Intentional empty function*/
                            }}
                            className="accent-black h-6 w-6"
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HomeTab
